import UseCase from '../../../core/app/usecase';
import { ExportColumn } from '../../../presentation/components/Views/Export/ExportDialogData';
import ExportRepository from '../../repositories/exportRepository';

interface Args {
	companyId: string;
	topic: string;
}

class ExportItemColumns implements UseCase<Args, ExportColumn[]> {
	private exportRepository: ExportRepository;

	constructor(exportRepository: ExportRepository) {
		this.exportRepository = exportRepository;
	}

	async Call(args: Args): Promise<ExportColumn[]> {
		return await this.exportRepository.exportItemColumns(
			args.companyId,
			args.topic
		);
	}
}

export default ExportItemColumns;
