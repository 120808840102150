import FormInput from "../../components/Common/FormInput";
import { useTranslation } from "react-i18next";
import { Path, UseFormReturn } from "react-hook-form";
import {
  FormType,
  SYSTEM_STAFF_ROLES,
} from "../../hooks/Onboarding/useOnboardingViewModel";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import { SignUpCompany as SignUpCompanyType } from "../../../domain/interactors/auth/args";
import { Dispatch, SetStateAction } from "react";

type PROPS = {
  methods: UseFormReturn<FormType>;
  activeStep: number;
  staffIndex: number;
  setStaffIndex: Dispatch<SetStateAction<number>>;
};

const SignUpCompanyStep2 = ({
  activeStep,
  methods,
  staffIndex,
  setStaffIndex,
}: PROPS) => {
  const { t } = useTranslation("onboarding");

  return (
    <Accordion
      allowToggle
      sx={{ display: activeStep === 1 ? "block" : "none" }}
      index={staffIndex}
      onChange={(index: number) => setStaffIndex(index)}
    >
      {SYSTEM_STAFF_ROLES.map((role) => (
        <AccordionItem key={role} mt={2}>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                color={methods.formState.errors[role] && "red"}
              >
                {t("systemStaffRole." + role, { ns: "enum" })}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <FormInput
              inputProps={methods.register(
                (role + ".name") as Path<SignUpCompanyType>,
                {
                  required: t(`errors.staff.first name is required`),
                },
              )}
              label={t("ui.first name")}
              isRequired
              error={methods.formState.errors[role]?.name?.message ?? false}
              type="text"
            />
            <FormInput
              inputProps={methods.register(
                (role + ".surname") as Path<SignUpCompanyType>,
                {
                  required: t(`errors.staff.last name is required`),
                },
              )}
              label={t("ui.last name")}
              isRequired
              error={methods.formState.errors[role]?.surname?.message ?? false}
              type="text"
            />
            <FormInput
              inputProps={methods.register(
                (role + ".email") as Path<SignUpCompanyType>,
                {
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: t("errors.insert a valid email"),
                  },
                }
              )}
              label={t("ui.email")}
              error={methods.formState.errors[role]?.email?.message ?? false}
              type="email"
            />
            <FormInput
              inputProps={methods.register(
                (role + ".phone") as Path<SignUpCompanyType>,
              )}
              label={t("ui.phone")}
              error={methods.formState.errors[role]?.phone?.message ?? false}
              type="text"
            />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default SignUpCompanyStep2;
