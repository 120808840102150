import { FC } from "react";
import SignInHeader from "./SignInHeader";
import { useTranslation } from "react-i18next";
import OnboardingHeader from "./OnboardingHeader";
import OnboardingFooter from "./OnboardingFooter";
import {
  Button,
  HStack,
  VStack,
  Divider,
  CardHeader,
  CardBody,
  CardFooter,
  Spacer,
} from "@chakra-ui/react";

interface OnboardingSelectorProps {
  onSelectSignIn?: () => void;
  onSelectContactUs?: () => void;
  onSelectSignUpUser?: () => void;
  onSelectSignUpCompany?: () => void;
}

const selectorElementsSize = 200;

const OnboardingSelector: FC<OnboardingSelectorProps> = ({
  onSelectSignIn,
  onSelectContactUs,
  onSelectSignUpUser,
  onSelectSignUpCompany,
}) => {
  const { t } = useTranslation("onboarding");

  return (
    <>
      <CardHeader>
        <VStack spacing={2}>
          <SignInHeader onClick={onSelectSignIn} />
          <OnboardingHeader title={t("ui.how do you want to sign up?")} />
        </VStack>
      </CardHeader>

      <CardBody>
        <HStack spacing={6}>
          <Button
            variant="ghost"
            color="white"
            textColor="#32A2DB"
            _hover={{ bg: "#32A2DB", color: "#FFF" }}
            width={selectorElementsSize}
            height={selectorElementsSize}
            onClick={onSelectSignUpCompany}
          >
            {t("ui.as a company")}
          </Button>
          <Divider orientation="vertical" height={selectorElementsSize} />
          <Button
            variant="ghost"
            color="white"
            _hover={{ bg: "#32A2DB", color: "#FFF" }}
            textColor="#32A2DB"
            width={selectorElementsSize}
            height={selectorElementsSize}
            onClick={onSelectSignUpUser}
          >
            {t("ui.as a user")}
          </Button>
        </HStack>
      </CardBody>

      <CardFooter>
        <OnboardingFooter />
      </CardFooter>
    </>
  );
};

export default OnboardingSelector;
