import UseCase from '../../../core/app/usecase';
import CompanyRepository from '../../repositories/companyRepository';
import {TranslationRecord} from "../../entities/translationRecord";

type GetTranslationsParams = {
  companyId: string;
  records: TranslationRecord[];
};

class SetTranslations implements UseCase<GetTranslationsParams, void> {
	private companyRepository: CompanyRepository;

	constructor(companyRepository: CompanyRepository) {
		this.companyRepository = companyRepository;
	}

	async Call({ companyId, records }: GetTranslationsParams): Promise<void> {
		await this.companyRepository.setTranslations(companyId, records);
	}
}

export default SetTranslations;
