import { useMemo, useState } from "react";
import { useAuth } from "../../providers/Auth0JWTProvider";
import ExportViewModel from "../../viewmodels/export/ExportViewModel";
import { GetWorkingSiteFilter } from "../../../domain/repositories/supplierRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import Supplier from "../../../domain/entities/supplier";
import { WorkingSitesListViewModel } from "../../viewmodels/workingSites/WorkingSitesListViewModel";
import SitesListViewModel from "../../viewmodels/sites/SitesListViewModel";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import Site from "../../../domain/entities/site";
import { updateFilterWithDelete } from "../../../utils";
import { GetSiteResourcesAuditFilters } from "../Site/useSiteAuditsViewModel";
import { AuditsViewModel } from "../../viewmodels/sites/AuditsViewModel";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { ResourceType } from "../../screens/Site/ResourceSelectableTable";
import SiteSupplierDetailViewModel from "../../viewmodels/sites/SiteSupplierDetailViewModel";
import { SiteResourceDocument } from "../../../domain/entities/document";
import DocumentType from "../../../domain/entities/documentType";
import { ExportColumn, ExportItem } from "../../components/Views/Export/ExportDialogData";

const useExportViewModel = () => {
  const { companyId } = useAuth();

  const exportViewModel = useMemo(() => new ExportViewModel(), []);
  const workingSiteViewModel = useMemo(() => new WorkingSitesListViewModel(), []);
  const ownSitesViewModel = useMemo(() => new SitesListViewModel(companyId), [companyId]);
  const auditsViewModel = useMemo(() => new AuditsViewModel(), []);
  const siteSupplierViewModel = useMemo(() => new SiteSupplierDetailViewModel(), []);

  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [selectedResourceType, setSelectedResourceType] = useState<ResourceType>();
  const [isWorkingSite, setIsWorkingSite] = useState<boolean>(false);
  const [selectedExportItem, setSelectedExportItem] = useState<ExportItem>();
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  const { data: availableColumns, isLoading: isLoadingAvailableColumns } = useQuery<ExportColumn[], Error>(
    ["available-columns", companyId, selectedExportItem],
    async () => {

      if (!selectedExportItem?.topic){
        return [];
      }

      const availableCol = await exportViewModel.getExportItemColumns(companyId, selectedExportItem?.topic);
      setSelectedColumns(availableCol.map(column => column.columnKey));
      return availableCol;
    },
  );

  /* #region own sites */

  const [ownSiteSort, setOwnSiteSort] = useState<SortMeta>();
  const [ownSiteSearch, setOwnSiteSearch] = useState<string>();
  const [ownSiteFilter, setOwnSiteFilter] = useState<GetSitesFilter>({
    state: "active",
  });

  const getSites = useInfiniteQuery<Site[]>(
    ["get-sites-export", ownSiteFilter, ownSiteSearch, ownSiteSort, companyId],
    async ({ pageParam = 1 }) => {
      const filters = ownSiteSearch ? { ...ownSiteFilter, ownSiteSearch } : ownSiteFilter;
      const {results, count} = await ownSitesViewModel.get(filters, ownSiteSort, pageParam, true);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );

  const updateOwnSiteFilter = (
    field: string,
    value: string | string[] | [Date, Date]
  ) => {
    updateFilterWithDelete(setOwnSiteFilter, field, value);
  };

  const ownSites = getSites.data?.pages?.flat() ?? [];

  /* #endregion own sites */

  /* #region working sites */

  const [workingSiteFilter, setWorkingSiteFilter] = useState<GetWorkingSiteFilter>({
    name: null,
    company: null,
    address: null,
  });
  const [workingSiteSort, setWorkingSiteSort] = useState<SortMeta>();
  const [workingSiteSearch, setWorkingSiteSearch] = useState<string>();

  const { data: workingSites, isLoading: isLoadingWorkingSites } = useQuery<Supplier[], Error>(
    ["working-sites", companyId, workingSiteFilter, workingSiteSort, workingSiteSearch],
    async () => {
      const filters = workingSiteSearch ? { ...workingSiteFilter, workingSiteSearch } : workingSiteFilter;
      return await workingSiteViewModel.list(companyId, filters, workingSiteSort);
    },
  );

  const updateWorkingSiteFilter = (field: keyof GetWorkingSiteFilter, value: string) =>
    setWorkingSiteFilter({ ...workingSiteFilter, [field]: value });

  /* #endregion working sites */

  /* #region GET resources */

  const [filterSiteWorkers, setFilterSiteWorkers] =
    useState<GetSiteResourcesAuditFilters>({});
  const [siteWorkersSort, setSiteWorkersSort] = useState<SortMeta>(null);

  const [filterSiteMachines, setFilterSiteMachines] =
    useState<GetSiteResourcesAuditFilters>({});
  const [siteMachinesSort, setSiteMachinesSort] = useState<SortMeta>(null);

  const [filterSiteVehicles, setFilterSiteVehicles] =
    useState<GetSiteResourcesAuditFilters>({});
  const [siteVehiclesSort, setSiteVehiclesSort] = useState<SortMeta>(null);

  const [filterSiteTools, setFilterSiteTools] =
    useState<GetSiteResourcesAuditFilters>({});
  const [siteToolsSort, setSiteToolsSort] = useState<SortMeta>(null);

  const [filterSiteChemicals, setFilterSiteChemicals] =
    useState<GetSiteResourcesAuditFilters>({});
  const [siteChemicalsSort, setSiteChemicalsSort] = useState<SortMeta>(null);

  const [enableGetWorkers, setEnableGetWorkers] = useState(false);
  const [enableGetWorkersRequirements, setEnableGetWorkersRequirements] = useState(false);

  const [enableGetVehicles, setEnableGetVehicles] = useState(false);
  const [enableGetVehiclesRequirements, setEnableGetVehiclesRequirements] = useState(false);

  const [enableGetMachines, setEnableGetMachines] = useState(false);
  const [enableGetMachinesRequirements, setEnableGetMachinesRequirements] = useState(false);

  const [enableGetTools, setEnableGetTools] = useState(false);
  const [enableGetToolsRequirements, setEnableGetToolsRequirements] = useState(false);

  const [enableGetChemicals, setEnableGetChemicals] = useState(false);
  const [enableGetChemicalsRequirements, setEnableGetChemicalsRequirements] = useState(false);

  const [enabledSupplierDocuments, setEnabledSupplierDocuments] = useState(false);

  const siteWorkersQuery = useInfiniteQuery(
    [
      "site-workers-"+selectedSiteId,
      companyId,
      filterSiteWorkers,
      siteWorkersSort,
      "",
    ],
    async ({ pageParam = 1 }) => {
      const { results, count } = await auditsViewModel.getSiteWorkers(
        companyId,
        selectedSiteId,
        pageParam,
        filterSiteWorkers,
        siteWorkersSort,
        ""
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetWorkers && selectedSiteId!=undefined,
    }
  );

  const siteWorkersDocumentsQuery = useInfiniteQuery(
    ["site-workers-requirements"+selectedSiteId, companyId, selectedSiteId],
    async ({ pageParam = 1 }) => {
      let results;
      if (isWorkingSite){
        const response = await auditsViewModel.getSiteSupplierResourceDocuments(
          companyId,
          selectedSiteId,
          DocumentTypeCategory.WORKER,
          companyId,
          pageParam
        );
        results = response.results;
      }else{
        const response = await exportViewModel.getSiteResourceDocuments(
          companyId,
          selectedSiteId,
          DocumentTypeCategory.WORKER,
          companyId,
          pageParam
        );
        results = response.results;
      }
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetWorkersRequirements && selectedSiteId!=undefined,
    }
  );

  const siteVehiclesQuery = useInfiniteQuery(
    [
      "site-vehicles-"+selectedSiteId,
      companyId,
      filterSiteVehicles,
      siteVehiclesSort
    ],
    async ({ pageParam = 1 }) => {
      const { results, count } = await auditsViewModel.getSiteVehicles(
        companyId,
        selectedSiteId,
        pageParam,
        filterSiteVehicles,
        siteVehiclesSort,
        ""
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetVehicles && selectedSiteId!=undefined,
    }
  );

  const siteVehiclesDocumentsQuery = useInfiniteQuery(
    ["site-vehicles-requirements-"+selectedSiteId, companyId, selectedSiteId],
    async ({ pageParam = 1 }) => {
      const { results } = await auditsViewModel.getSiteSupplierResourceDocuments(
        companyId,
        selectedSiteId,
        DocumentTypeCategory.VEHICLE,
        companyId,
        pageParam
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetVehiclesRequirements && selectedSiteId!=undefined,
    }
  );

  const siteMachinesQuery = useInfiniteQuery(
    [
      "site-machines",
      companyId,
      filterSiteMachines,
      siteMachinesSort,
    ],
    async ({ pageParam = 1 }) => {
      const { results, count } = await auditsViewModel.getSiteMachines(
        companyId,
        selectedSiteId,
        pageParam,
        filterSiteMachines,
        siteMachinesSort,
        ""
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetMachines && selectedSiteId!=undefined,
    }
  );

  const siteMachinesDocumentsQuery = useInfiniteQuery(
    ["site-machines-requirements-"+selectedSiteId, companyId, selectedSiteId],
    async ({ pageParam = 1 }) => {
      const { results } = await auditsViewModel.getSiteSupplierResourceDocuments(
        companyId,
        selectedSiteId,
        DocumentTypeCategory.MACHINE,
        companyId,
        pageParam
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetMachinesRequirements && selectedSiteId!=undefined,
    }
  );

  const siteToolsQuery = useInfiniteQuery(
    ["site-tools", companyId, filterSiteTools, siteToolsSort],
    async ({ pageParam = 1 }) => {
      const { results, count } = await auditsViewModel.getSiteTools(
        companyId,
        selectedSiteId,
        pageParam,
        filterSiteTools,
        siteToolsSort,
        ""
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetTools && selectedSiteId!=undefined,
    }
  );

  const siteToolsDocumentsQuery = useInfiniteQuery(
    ["site-tools-requirements-"+selectedSiteId, companyId, selectedSiteId],
    async ({ pageParam = 1 }) => {
      const { results } = await auditsViewModel.getSiteSupplierResourceDocuments(
        companyId,
        selectedSiteId,
        DocumentTypeCategory.TOOL,
        companyId,
        pageParam
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetToolsRequirements && selectedSiteId!=undefined,
    }
  );

  const siteChemicalsQuery = useInfiniteQuery(
    [
      "site-chemicals",
      companyId,
      filterSiteChemicals,
      siteChemicalsSort,
    ],
    async ({ pageParam = 1 }) => {
      const { results, count } = await auditsViewModel.getSiteChemicals(
        companyId,
        selectedSiteId,
        pageParam,
        filterSiteChemicals,
        siteChemicalsSort,
        ""
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetChemicals && selectedSiteId!=undefined,
    }
  );

  const siteChemicalsDocumentsQuery = useInfiniteQuery(
    ["site-chemicals-requirements-"+selectedSiteId, companyId, selectedSiteId],
    async ({ pageParam = 1 }) => {
      const { results } = await auditsViewModel.getSiteSupplierResourceDocuments(
        companyId,
        selectedSiteId,
        DocumentTypeCategory.CHEMICAL,
        companyId,
        pageParam
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetChemicalsRequirements && selectedSiteId!=undefined,
    }
  );

  const siteWorkers = siteWorkersQuery.data?.pages?.flat() ?? [];
  const workersRequirements = siteWorkersDocumentsQuery.data?.pages?.flat() ?? [];

  const siteVehicles = siteVehiclesQuery.data?.pages?.flat() ?? [];
  const vehiclesRequirements = siteVehiclesDocumentsQuery.data?.pages?.flat() ?? [];

  const siteMachines = siteMachinesQuery.data?.pages?.flat() ?? [];
  const machinesRequirements = siteMachinesDocumentsQuery.data?.pages?.flat() ?? [];
  
  const siteTools = siteToolsQuery.data?.pages?.flat() ?? [];
  const toolsRequirements = siteToolsDocumentsQuery.data?.pages?.flat() ?? [];

  const siteChemicals = siteChemicalsQuery.data?.pages?.flat() ?? [];
  const chemicalsRequirements = siteChemicalsDocumentsQuery.data?.pages?.flat() ?? [];

  /* #endregion GET resources */

  const [filterCompanyDocuments, setFilterCompanyDocuments] = useState({});
  const [sortCompanyDocuments, setSortCompanyDocuments] = useState<SortMeta>();

  const siteResourceDocumentsQuery = useInfiniteQuery(
    [
      "supplier-documents-"+selectedSiteId,
      companyId,
      selectedSiteId,
      filterCompanyDocuments,
      sortCompanyDocuments,
    ],
    async ({ pageParam = 1 }) => {
      if (!selectedSiteId){
        return;
      }
      const data = await siteSupplierViewModel.getSiteResourceDocuments(
        companyId,
        selectedSiteId,
        companyId,
        filterCompanyDocuments,
        sortCompanyDocuments,
        pageParam,
        DocumentTypeCategory.SUPPLIER
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enabledSupplierDocuments
    }
  );

  const supplierDocuments: DocumentType[] = siteResourceDocumentsQuery?.data?.pages?.flat().map((doc: SiteResourceDocument) => ({
      id: doc?.documentTypeId, 
      name: doc?.documentTypeName,
      duration: doc?.documentTypeDuration,
      tags: [],
      description: doc?.documentTypeDescription,
      filesCount: doc?.filesCount
  })) ?? [];

  const initiateExportFiles = async (
    path: string,
    onComplete?: (ok: boolean) => void,
    id?: string,
    ids?: string[],
    startDate?: Date,
    endDate?: Date,
    permissionSites?: { [key: string]: string },
    resourcesIds?: string[],
    resourceDocumentsToExclude?: string[],
    selectedColumns?: string[]
  ) => {
    exportViewModel
      .export(companyId, path, id, ids, startDate, endDate, permissionSites, resourcesIds, resourceDocumentsToExclude, selectedColumns)
      .then((ok) => onComplete(ok))
      .catch(() => onComplete(false));
  };

  return {
    setSelectedSiteId,
    selectedResourceType,
    setSelectedResourceType,
    setIsWorkingSite,

    // own sites parameters

    ownSites,
    isLoading: getSites.isLoading,
    ownSiteSort,
    setOwnSiteSort,
    getSites,
    ownSiteFilter,
    setOwnSiteFilter,
    updateOwnSiteFilter,
    // working sites parameters

    workingSites, isLoadingWorkingSites, 
    workingSiteFilter, updateWorkingSiteFilter, 
    workingSiteSort, setWorkingSiteSort, setWorkingSiteSearch,

    // resources parameters

    siteWorkers,
    siteWorkersHasNextPage: siteWorkersQuery.hasNextPage,
    siteWorkersFetchNextPage: siteWorkersQuery.fetchNextPage,
    siteWorkersIsLoading: siteWorkersQuery.isLoading,
    setEnableGetWorkers,

    workersRequirements,
    workersRequirementsHasNextPage: siteWorkersDocumentsQuery.hasNextPage,
    workersRequirementsFetchNextPage: siteWorkersDocumentsQuery.fetchNextPage,
    workersRequirementsIsLoading: siteWorkersDocumentsQuery.isLoading,
    setEnableGetWorkersRequirements,

    siteVehicles,
    siteVehiclesHasNextPage: siteVehiclesQuery.hasNextPage,
    siteVehiclesFetchNextPage: siteVehiclesQuery.fetchNextPage,
    siteVehiclesIsLoading: siteVehiclesQuery.isLoading,
    setEnableGetVehicles,

    vehiclesRequirements,
    vehiclesRequirementsHasNextPage: siteVehiclesDocumentsQuery.hasNextPage,
    vehiclesRequirementsFetchNextPage: siteVehiclesDocumentsQuery.fetchNextPage,
    vehiclesRequirementsIsLoading: siteVehiclesDocumentsQuery.isLoading,
    setEnableGetVehiclesRequirements,

    siteMachines,
    siteMachinesHasNextPage: siteMachinesQuery.hasNextPage,
    siteMachinesFetchNextPage: siteMachinesQuery.fetchNextPage,
    siteMachinesIsLoading: siteMachinesQuery.isLoading,
    setEnableGetMachines,

    machinesRequirements,
    machinesRequirementsHasNextPage: siteMachinesDocumentsQuery.hasNextPage,
    machinesRequirementsFetchNextPage: siteMachinesDocumentsQuery.fetchNextPage,
    machinesRequirementsIsLoading: siteMachinesDocumentsQuery.isLoading,
    setEnableGetMachinesRequirements,

    siteTools,
    siteToolsHasNextPage: siteToolsQuery.hasNextPage,
    siteToolsFetchNextPage: siteToolsQuery.fetchNextPage,
    siteToolsIsLoading: siteToolsQuery.isLoading,
    setEnableGetTools,

    toolsRequirements,
    toolsRequirementsHasNextPage: siteToolsDocumentsQuery.hasNextPage,
    toolsRequirementsFetchNextPage: siteToolsDocumentsQuery.fetchNextPage,
    toolsRequirementsIsLoading: siteToolsDocumentsQuery.isLoading,
    setEnableGetToolsRequirements,

    siteChemicals,
    siteChemicalsHasNextPage: siteChemicalsQuery.hasNextPage,
    siteChemicalsFetchNextPage: siteChemicalsQuery.fetchNextPage,
    siteChemicalsIsLoading: siteChemicalsQuery.isLoading,
    setEnableGetChemicals,

    chemicalsRequirements,
    chemicalsRequirementsHasNextPage: siteChemicalsDocumentsQuery.hasNextPage,
    chemicalsRequirementsFetchNextPage: siteChemicalsDocumentsQuery.fetchNextPage,
    chemicalsRequirementsIsLoading: siteChemicalsDocumentsQuery.isLoading,
    setEnableGetChemicalsRequirements,

    setFilterSiteWorkers,
    setFilterSiteChemicals,
    setFilterSiteMachines,
    setFilterSiteVehicles,
    setFilterSiteTools,
    filterSiteChemicals,
    filterSiteMachines,
    filterSiteTools,
    filterSiteVehicles,
    filterSiteWorkers,

    supplierDocuments,
    supplierDocumentsHasNextPage: siteResourceDocumentsQuery.hasNextPage,
    supplierDocumentsFetchNextPage: siteResourceDocumentsQuery.fetchNextPage,
    siteResourceDocumentsRefetch: siteResourceDocumentsQuery.refetch,
    supplierDocumentsIsLoading: siteResourceDocumentsQuery.isLoading,
    setEnabledSupplierDocuments,

    selectedExportItem,
    setSelectedExportItem,

    availableColumns,
    isLoadingAvailableColumns,
    selectedColumns,
    setSelectedColumns,

    initiateExportFiles
  };
}

export default useExportViewModel;
