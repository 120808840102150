import { injectable } from 'tsyringe';
import { SiteResourceDocument } from '../../domain/entities/document';
import Evaluation from '../../domain/entities/evaluation';
import { PaginatedResults, SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import Requirement from '../../domain/entities/requirement';
import { ResourceEvaluationState } from '../../domain/entities/resourceEvaluationState.enum';
import { mapSiteWorker, SiteWorker } from '../../domain/entities/siteWorker';
import { SupplierEvaluationState } from '../../domain/entities/supplierEvaluationState.enum';
import { GetDocumentsFilter } from '../../domain/repositories/documentRepository';
import { GetResourceEvaluationFilter } from '../../domain/repositories/siteRepository';
import SiteWorkerRepository, {
  GetSiteWorkersFilters,
  GetWorkingSiteWorkersFilters,
  SiteWorkerMeta,
} from '../../domain/repositories/siteWorkerRepository';
import { NewResource } from '../../presentation/hooks/Site/useSiteResourcesViewModel';
import { removeEmptyAttributes } from '../../utils';
import { GetEvaluationsResponse, mapEvaluation } from '../responses/evaluations';
import { GetSiteWorkersResponse } from '../responses/siteWorkers';
import { ApiService } from '../utilities/apiService';
import { dateIntervals } from '../utilities/filters';

@injectable()
class ServerSiteWorkerRepository implements SiteWorkerRepository {
	constructor(private apiService: ApiService) { }

	private getURL = (companyId: string, siteId: string, workerId?: string): string => {
		return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/workers${workerId ? `/${workerId}` : ''}`;
	};

	// Public.
	async getSiteWorkers(
		companyId: string,
		siteId: string,
		page: number,
		perPage: number,
		archived?: boolean,
		filter?: GetSiteWorkersFilters,
		sort?: SortMeta,
		supplierId?: string
	): Promise<PaginatedResults<SiteWorker>> {
		const { badgeAvailable, variantIds, specializationIds, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: page.toString(),
			perPage: perPage.toString(),
			...(supplierId ? { companyId: supplierId } : {}),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...(badgeAvailable ? { badgeAvailable: badgeAvailable ? '1' : '0' } : {}),
			...sort,
		});

		if (archived === true) {
			params.append('archived', 'true');
		}
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId)}?${params.toString()}`);
		const data: GetSiteWorkersResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteWorker(siteId)),
		};
	}

	// Public.
	async getWorkingSiteWorkers(
		companyId: string,
		siteId: string,
		page: number,
		perPage: number,
		archived?: boolean,
		filter?: GetWorkingSiteWorkersFilters,
		sort?: SortMeta
	): Promise<PaginatedResults<SiteWorker>> {
		const { variantIds, specializationIds, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: page.toString(),
			perPage: perPage.toString(),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...sort,
		});

		if (archived === true) {
			params.append('archived', 'true');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${companyId}/workers?${params.toString()}`
		);

		if (!response.ok) {
			if (response.status === 403) {
				window.location.href = '/overview';
			}
			return;
		}
		const data: GetSiteWorkersResponse = await response.json();

		return {
			...data,
			results: data.results.map(mapSiteWorker(siteId)),
		};
	}

	// Public.
	async getSiteWorker(companyId: string, siteId: string, workerId: string): Promise<SiteWorker> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, workerId)}`);
		if (!response.ok) {
			throw new Error(`Failed to get site worker: ${response.status}`);
		}
		return response.json();
	}

	//TODO aggiungere filtri per selectAll
	async createSiteWorker(
		companyId: string,
		siteId: string,
		resources: NewResource[],
		selectAll: boolean,
		selectedResourceNumber: number,
		selectAllVariants: string,
		selectAllSpecializations: string[],
		copyLastEvaluation: boolean,
		filterSiteResources?: Record<string, string | string[]>
	): Promise<boolean> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId)}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				resources,
				selectAll,
				selectedResourceNumber,
				selectAllVariant: selectAllVariants,
				selectAllSpecializations,
				copyLastEvaluation,
				filterSiteResources,
			}),
		});
		return Promise.resolve(true);
	}

	async deleteSiteWorker(companyId: string, siteId: string, workerId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, workerId)}`, {
			method: 'DELETE',
		});
	}

	async restoreSiteWorker(companyId: string, siteId: string, workerId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, workerId)}/restore`, {
			method: 'PUT',
		});
	}

	async updateSiteWorkerVariant(companyId: string, siteId: string, workerId: string, variantId?: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, workerId)}/variants`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: variantId ? JSON.stringify({ variantId: variantId }) : null,
		});
	}

	async updateSiteWorkerSpecializations(companyId: string, siteId: string, workerId: string, specializationIds: string[]): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, workerId)}/specializations`, {
			method: 'PUT',
			body: JSON.stringify({ specializationsIds: specializationIds }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	async getSiteWorkerEvaluations(
		companyId: string,
		siteId: string,
		workerId: string,
		pageParam: number,
		sort?: SortMeta,
		filter?: GetResourceEvaluationFilter
  ): Promise<Evaluation<ResourceEvaluationState>[]> {
		const { createdAt, expiresAt, ...restFilter } = filter ?? {};
		const query = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...sort,
      ...removeEmptyAttributes(restFilter),
      ...dateIntervals(createdAt),
      ...dateIntervals(expiresAt, 'fromExpiresAtDate', 'toExpiresAtDate'),
		}).toString();

		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, workerId)}/evaluations?${query}`);
		const { results }: GetEvaluationsResponse<ResourceEvaluationState> = await response.json();
		return results.map(mapEvaluation);
	}

	async evaluateSiteWorker(
		companyId: string,
		siteId: string,
		workerId: string,
		value: ResourceEvaluationState | SupplierEvaluationState
	): Promise<void> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, workerId)}/evaluations`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ value }),
		});
		return response.json();
	}

	async autoEvaluateSiteWorker(companyId: string, siteId: string, workerId: string): Promise<void> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, workerId)}/evaluate`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw new Error(`Failed to auto evaluate site worker: ${response.status}`);
		}
	}

	//TODO rename this method in getSiteWorkerRequirement
	async getSiteWorkerDocuments(
		companyId: string,
		siteId: string,
		workerId: string,
		filter: GetDocumentsFilter,
		sort: SortMeta,
		pageParam: number
	): Promise<SiteResourceDocument[]> {
		const { tags, expiresAt, ...restFilter } = filter ?? {};

		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...(tags ? { tags: tags.join(',') } : {}),
			...sort,
		});

		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, workerId)}/requirements?${params.toString()}`);
		const { results } = await response.json();
		return results;
	}

	async getSiteWorkerDocumentsRejected(
		companyId: string,
		siteId: string,
		workerId: string
	): Promise<SiteResourceDocument[]> {

		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, workerId)}/requirements/blocking`);
		const { results } = await response.json();
		return results;
	}

	async addRequirementToSiteWorker(
		companyId: string,
		siteId: string,
		workerId: string,
		requirements: {
			documentTypeId: string;
			isOptional: boolean;
			graceDays: number;
		}[]
	): Promise<Requirement> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, workerId)}/requirements`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ requirements }),
		});
		return response.json();
	}

	async updateSiteWorkerMeta(companyId: string, siteId: string, workerId: string, meta: SiteWorkerMeta): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, workerId)}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ meta }),
		});
	}

	async updateSiteWorkerGlobalStatus(companyId: string, siteId: string, workerId: string, isGlobal: boolean): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, workerId)}/global`, {
			method: 'PUT',
			body: JSON.stringify({ isGlobal }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw new Error(`Failed to update site worker global status: ${response.status}`);
		}
		const { hasRequirements = true } = await response.json();
		return hasRequirements;
	}
}

export default ServerSiteWorkerRepository;
