import UseCase from '../../../core/app/usecase';
import ExportRepository from '../../repositories/exportRepository';

interface Args {
	companyId: string;
	path: string;
	siteId?: string;
	siteIds?: string[];
	startDate?: Date;
	endDate?: Date;
	permissionSites?: { [key: string]: string };
	resourcesIds?: string[];
	resourceDocumentsToExclude?: string[];
	selectedColumns?: string[];
}

class ExportFiles implements UseCase<Args, boolean> {
	private exportRepository: ExportRepository;

	constructor(exportRepository: ExportRepository) {
		this.exportRepository = exportRepository;
	}

	async Call(args: Args): Promise<boolean> {
		return await this.exportRepository.export(
			args.companyId,
			args.path,
			args.siteId,
			args.siteIds,
			args.startDate,
			args.endDate,
			args.permissionSites,
			args.resourcesIds,
			args.resourceDocumentsToExclude,
			args.selectedColumns
		);
	}
}

export default ExportFiles;
