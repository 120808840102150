import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PermissionCheck, useAuth } from "../../../providers/Auth0JWTProvider";
import BaseModal from "../../Common/alerts/BaseModal";
import Company from "../../../../domain/entities/company";
import { LicenseType } from "../../../../domain/interactors/auth/args";
import { Alert } from "../../../screens/Common/Alert";
import useChangeTenantViewModel from "../../../hooks/Company/useChangeTenantViewModel";
import TenantsTable from "./TenantsTable";
import CreateDerivedTenantModal from "../../../screens/Suppliers/CreateDerivedTenantModal";
import RenderIf from "../../Permissions/RenderIf";
import { Permission } from "../../Permissions/Permissions";

interface ChangeTenantModalProps {
  onClose: () => void;
  setSelectedTenant: (company: Company) => void;
  enableGetCompanies: boolean;
}
export type GetTenantsFilters = {
  name?: string;
  image?: any;
  licenseType?: LicenseType | null;
  licenseState?: string;
};

const ChangeTenantModal = ({
  onClose,
  setSelectedTenant,
  enableGetCompanies,
}: ChangeTenantModalProps) => {
  const { t } = useTranslation("common");

  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { companyId: defaultCompany, company, user, companyUser } = useAuth();
  const [showDisabledCompany, setShowDisabledCompany] = useState(false);
  const [showCreationDerivedTenants, setShowCreationDerivedTenants] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState(0);

  const {         
    tenants, tenantsHasNextPage, tenantsGetNextPage, tenantsIsLoading,
    sort, setSort,
    search, setSearch,
    filters, setFilters,
  
    tenantsDerived,
    tenantsDerivedRefetch,
    sortDerived, setSortDerived,
    searchDerived, setSearchDerived,
    filtersDerived, setFiltersDerived,
    tenantsDerivedHasNextPage, 
    tenantsDerivedGetNextPage, 
    tenantsDerivedIsLoading
  
  } = useChangeTenantViewModel();

  const lastTenantSelected = localStorage.getItem("lastTenantSelected");
  const lastUserLogged = localStorage.getItem("lastUserLogged");

  const manageTenantSelection = (selectedTenant: Company) => {
    if (selectedTenant.state === "disabled") {
      setShowDisabledCompany(true);
    } else {
      setSelectedTenant(selectedTenant);
      localStorage.setItem(
        "lastTenantSelected",
        JSON.stringify(selectedTenant),
      );
      localStorage.setItem("lastUserLogged", JSON.stringify(user));
    }
  };
  //I need this effect to set the filter state to enabled by default.
  useEffect(() => {
    if (lastTenantSelected && lastUserLogged) {
      try {
        const parsedLastTenantSelected = JSON.parse(lastTenantSelected);
        const parsedLastUserLogged = JSON.parse(lastUserLogged);
        if (
          parsedLastTenantSelected.id !== defaultCompany &&
          parsedLastUserLogged.id === user.id
        ) {
          setSelectedTenant(parsedLastTenantSelected);
        }
      } catch (error) {
        console.error("Error parsing JSON from localStorage", error);
      }
    }
  }, [lastTenantSelected, defaultCompany]);

  return (
    <BaseModal
      title={t("changeTenant")}
      onClose={onClose}
      onCancel={false}
      size="5xl"
      bodyOverflow="hidden"
    >

      <Tabs onChange={(index) => setTabIndex(index)}>
        {(company.licenseDerivedTenants || company.derivedFromCompanyId) &&
        <TabList>
            <Tab width={"100%"} key={"tenants"}>
                  {t("tenants", { ns: "supplier" })}
            </Tab>
              <Tab width={"100%"} key={"derivedTenants"}>
                    {t("derivedTenants", { ns: "supplier" })}
              </Tab>
        </TabList>
        }

        <TabPanels>
          <TabPanel paddingLeft={0} paddingRight={0}>
              <TenantsTable
                tenants={tenants}
                tenantsHasNextPage={tenantsHasNextPage}
                tenantsFetchNextPage={tenantsGetNextPage}
                tenantsIsLoading={tenantsIsLoading}
                setSearch={setSearch}
                filters={filters}
                setFilters={setFilters}
                defaultCompany={defaultCompany}
                companyUser={companyUser}
                sort={sort}
                setSort={setSort}
                manageTenantSelection={manageTenantSelection}/>

          </TabPanel>
          {(company.licenseDerivedTenants || company.derivedFromCompanyId) &&
            <TabPanel paddingLeft={0} paddingRight={0}>
                {tabIndex == 1 &&
                  <Box 
                    flexDirection="column" 
                    gap="0" 
                    marginTop="0" 
                    alignItems="flex-start" 
                    justifyContent="flex-end" // Makes the button move to the right
                    position="relative"
                    marginBottom={0}>
                    <TenantsTable
                      tenants={tenantsDerived}
                      tenantsHasNextPage={tenantsDerivedHasNextPage}
                      tenantsFetchNextPage={tenantsDerivedGetNextPage}
                      tenantsIsLoading={tenantsDerivedIsLoading}
                      setSearch={setSearchDerived}
                      filters={filtersDerived}
                      setFilters={setFiltersDerived}
                      defaultCompany={defaultCompany}
                      companyUser={companyUser}
                      sort={sortDerived}
                      setSort={setSortDerived}
                      manageTenantSelection={manageTenantSelection}
                      tableId={"derived-tenants-table"}/>
                    {!company.derivedFromCompanyId &&
                      <RenderIf
                          permissions={Permission.Other_CreateDerivedTenants ?? []}
                          check={PermissionCheck.All}
                        >
                        <Flex flexDirection={"row"}justifyContent={"right"}>
                          <Button
                            marginTop={5}
                            colorScheme="blue"
                            onClick={() => setShowCreationDerivedTenants(true)}
                            marginRight={0}
                            alignSelf="flex-end"
                            >
                            {t("createDerivedTenant", { ns: "supplier" })}
                          </Button>
                        </Flex>
                      </RenderIf>
                    }
                  </Box>
                }
            </TabPanel>
          }
        </TabPanels>
      </Tabs>

      {showCreationDerivedTenants && (
          <CreateDerivedTenantModal
            onCancel={() => setShowCreationDerivedTenants(false)}
            onConfirm={() => {
              tenantsDerivedRefetch();
              setShowCreationDerivedTenants(false);
            }}
          />
        )}

      {showDisabledCompany && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t("tenantMissingLicense", { ns: "common" })}
          variant="info"
          onClose={() => {
            setShowDisabledCompany(false);
          }}
        />
      )}
    </BaseModal>
  );
};

export default ChangeTenantModal;
