import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

const Germany = (props: IconProps) => {
  return (
    <Icon
      viewBox="0 0 640 480"
      {...props}
    >
      <path fill="#fc0" d="M0 320h640v160H0z"/>
      <path fill="#000001" d="M0 0h640v160H0z"/>
      <path fill="red" d="M0 160h640v160H0z"/>
    </Icon>
  );
};

export default Germany;
