import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { parse } from "date-fns";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import { SiteResourceDocument } from "../../../domain/entities/document";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import Evaluation from "../../../domain/entities/evaluation";
import { SortDirection, SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";
import { SiteChemical } from "../../../domain/entities/siteChemical";
import { SiteMachine } from "../../../domain/entities/siteMachine";
import { SiteTool } from "../../../domain/entities/siteTool";
import { SiteVehicle } from "../../../domain/entities/siteVehicle";
import { SiteWorker } from "../../../domain/entities/siteWorker";
import Specialization from "../../../domain/entities/specialization";
import Staff from "../../../domain/entities/staff";
import Supplier from "../../../domain/entities/supplier";
import { SupplierEvaluationState } from "../../../domain/entities/supplierEvaluationState.enum";
import { SupplierStatus } from "../../../domain/entities/supplierStatus.enum";
import Variant from "../../../domain/entities/variant";
import { GetDocumentEvaluationFilter } from "../../../domain/repositories/documentRepository";
import { getDateFormat, updateFilterWithDelete } from "../../../utils";
import { mapRejectionGroups, RejectionReasonGroup } from "../../components/Views/Documents/RejectionSummary";
import { useAuth } from "../../providers/Auth0JWTProvider";
import SiteSupplierDetailViewModel from "../../viewmodels/sites/SiteSupplierDetailViewModel";
import StaffViewModel from "../../viewmodels/staff/StaffViewModel";
import useAddDocumentType, { UseAddDocumentType } from "../Document/useAddDocumentType";
import useDocumentAI, { UseDocumentAI } from "../Document/useDocumentAI";
import useDocumentCommunicationViewModel, {
  DocumentCommunicationHook
} from "../Document/useDocumentCommunicationVIewModel";
import useDocumentInfo, { UseDocumentInfo } from "../Document/useDocumentInfo";
import { GetSiteResourcesFilters } from "./useSiteResourcesViewModel";

export type EvaluateDocumentParams = {
  documentId: string;
  result: ResourceDocumentEvaluationState;
  validity?: string;
  noEvaluationExpiration?: boolean;
};

export type Filter = { [key: string]: string | string[] | [Date, Date] };
export type UpdateFilter = (
  column: string,
  value: string | string[] | [Date, Date]
) => void;
export type Sort = { [key: string]: SortDirection };
export type UpdateSort = (column: string, value: SortDirection) => void;

export type useSupplierDetailViewModel = {
  siteResource: Supplier;
  siteResourceRefetch: () => void;
  siteResourceDocumentsRejected: RejectionReasonGroup[];
  siteResourceDocuments: SiteResourceDocument[];
  siteResourceDocumentsHasNextPage: boolean;
  siteResourceDocumentsFetchNextPage: () => void;
  siteResourceDocumentsRefetch: () => void;
  siteResourceDocumentsIsLoading: boolean;
  siteResourceSiteDocumentsRejected: RejectionReasonGroup[];
  siteResourceSiteDocuments: SiteResourceDocument[];
  siteResourceSiteDocumentsHasNextPage: boolean;
  siteResourceSiteDocumentsFetchNextPage: () => void;
  siteResourceSiteDocumentsRefetch: () => void;
  siteResourceSiteDocumentsIsLoading: boolean;
  siteResourceEvaluations: Evaluation<SupplierEvaluationState>[];
  siteResourceEvaluationsHasNextPage: boolean;
  siteResourceEvaluationsFetchNextPage: () => void;
  documentEvaluations: Evaluation<ResourceDocumentEvaluationState>[];
  documentEvaluationsFetching: boolean;
  siteSupplierResources: (
    | SiteWorker
    | SiteChemical
    | SiteTool
    | SiteMachine
    | SiteVehicle
  )[];
  siteSupplierResourcesHasNextPage: boolean;
  siteSupplierResourcesFetchNextPage: () => void;
  siteSupplierResourcesFetching: boolean;
  setSiteSupplierResourcesSort: (sort: SortMeta) => void;
  siteSupplierResourcesSort: SortMeta;
  filterSiteSupplierResources: Record<string, string | string[]>;
  updateFilterSiteSupplierResources: (
    column: string,
    value: string | string[]
  ) => void;
  updateSiteResourceVariant: (variantId?: string) => void;

  sortCompanyDocuments: SortMeta;
  updateSortCompanyDocuments: (sort: SortMeta) => void;
  filterCompanyDocuments: Filter;
  updateFilterCompanyDocuments: UpdateFilter;

  sortDocuments: SortMeta;
  updateSortDocuments: (sort: SortMeta) => void;
  filterDocuments: Filter;
  updateFilterDocuments: UpdateFilter;
  variants: Variant[];
  siteVariants: Variant[];
  siteSpecializations: Specialization[];
  getSiteResourceEvaluations: () => void;
  getSiteResourceEvaluationsIsLoading: boolean;
  siteResourceEvaluationsIsFetching: boolean;
  setEnableGetEvaluationsHistory: (value: boolean) => void;
  enableGetEvaluationsHistory: boolean;
  hookDocumentIdAndType: string;
  setHookDocumentIdAndType: (documentId: string) => void;
  addRequirementsToSiteResource: (
    requirements: {
      documentTypeId: string;
      isOptional: boolean;
      graceDays: number;
    }[]
  ) => void;
  evaluateSiteResource: (result: SupplierEvaluationState) => void;
  autoEvaluateSiteResource: () => void;
  evaluateDocument: (props: EvaluateDocumentParams) => void;
  filterResourceEvaluations: GetDocumentEvaluationFilter;
  updateFilterResourceEvaluations: UpdateFilter;
  sortResourceEvaluations: Sort;
  updateSortResourceEvaluations: UpdateSort;
  updateFilterDocumentEvaluations: UpdateFilter;
  filterDocumentEvaluations: Filter;
  sortDocumentEvaluations: Sort;
  updateSortDocumentEvaluations: UpdateSort;
  documentTypesProps: UseAddDocumentType;
  documentInfoProps: UseDocumentInfo;

  setSupplierDocumentType: Dispatch<SetStateAction<DocumentTypeCategory>>;
  setSupplierStatus: (status: SupplierStatus) => Promise<void>;
  isCompanyDocumentsFetching: boolean;
  isSiteDocumentsFetching: boolean;
  deleteRequirement: (id: string, subject: string, resourceId: string) => void;
  communicationProps: DocumentCommunicationHook;
  statusIsLoading: boolean;
  systemStaff: Staff[];
  setSupplierId?: (supplierId: string) => void;
  documentAICompanyProps: UseDocumentAI;
  documentAISiteProps: UseDocumentAI;
  resourceType?: string;
  setResourceType?: (resourceType: string) => void;
  getRequirementTemplateUrl?: (requirementId: string, templateId: string, onComplete: (url: string) => Promise<void>) => Promise<void>
};

const updateFilter = (setter) => (column: string, value: string | string[]) => {
  updateFilterWithDelete(setter, column, value);
};

const updateSort = (setter) => (column: string, value: SortDirection) => {
  setter({ [column]: value });
};

function useSiteSupplierDetailViewModel(
  siteId: string,
  resourceId: string,
  isWorkingSite?: boolean,
  hookCompanySectionCode?: AiTaskSectionCode,
  hookSiteSectionCode?: AiTaskSectionCode,
  resourceTypeFromView?: string,
  isSupplier?: boolean
): useSupplierDetailViewModel {
  const { companyId } = useAuth();
  const viewModel = new SiteSupplierDetailViewModel();
  const staffViewModel = new StaffViewModel();
  const [documentIdAndType, setDocumentIdAndType] = useState<string>(null);

  const [filterCompanyDocuments, setFilterCompanyDocuments] = useState({});
  const [sortCompanyDocuments, setSortCompanyDocuments] = useState<SortMeta>();

  const [filterDocuments, setFilterDocuments] = useState({});
  const [filterDocumentEvaluations, setFilterDocumentEvaluations] = useState(
    {}
  );
  const [sortDocuments, setSortDocuments] = useState<SortMeta>();
  const [sortDocumentEvaluations, setSortDocumentEvaluations] = useState({});

  const [filterResourceEvaluations, setFilterResourceEvaluations] = useState(
    {}
  );
  const [sortResourceEvaluations, setSortResourceEvaluations] = useState({});
  const [resourceType, setResourceType] = useState<string>(resourceTypeFromView);

  const [supplierDocumentType, setSupplierDocumentType] =
    useState<DocumentTypeCategory>(isSupplier ? DocumentTypeCategory.SUPPLIER_COMPANY : DocumentTypeCategory.COMPANY);
  const [filterSiteSupplierResources, setFilterSiteSupplierResources] =
    useState<GetSiteResourcesFilters>({});
  const [enableGetEvaluationsHistory, setEnableGetEvaluationsHistory] =
    useState<boolean>(false);
  const [siteSupplierResourcesSort, setSiteSupplierResourcesSort] =
    useState<SortMeta>(null);
  const communicationProps = useDocumentCommunicationViewModel(
    resourceId,
    DocumentTypeCategory.SUPPLIER,
    siteId,
    isWorkingSite
  );

  useEffect(() => { 
    if (resourceTypeFromView) {  
      setResourceType(resourceTypeFromView);
    }
  },[resourceTypeFromView])

  const updateFilterSiteSupplierResources = (column: string, value: string) => {
    updateFilterWithDelete(setFilterSiteSupplierResources, column, value);
  };

  const siteResourceQuery = useQuery(
    ["supplier", companyId, siteId, resourceId],
    () => viewModel.getSiteResource(companyId, siteId, resourceId)
  );

  const siteResourceDocumentsQuery = useInfiniteQuery(
    [
      "supplier-documents", companyId, siteId,
      resourceId, filterCompanyDocuments, sortCompanyDocuments,
    ],
    async ({ pageParam = 1 }) => {
      const data = await viewModel.getSiteResourceDocuments(
        companyId, siteId, resourceId,
        filterCompanyDocuments, sortCompanyDocuments,
        pageParam, DocumentTypeCategory.COMPANY
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );

  const siteResourceDocumentsRejectedQuery = useQuery(
    ["company-documents-rejected", companyId, siteId, resourceId],
    async () => {
      const documents = await viewModel.getSiteResourceDocumentsRejected(
        companyId, siteId, resourceId, DocumentTypeCategory.COMPANY
      );

      const rejectionGroups = mapRejectionGroups([
        {
          resource: "company",
          documents: documents,
        },
      ]);

      return rejectionGroups;
    }
  );

  const siteResourceSiteDocumentsQuery = useInfiniteQuery(
    [
      "supplier-site-documents", companyId, siteId,
      resourceId, filterDocuments, sortDocuments
    ],
    async ({ pageParam = 1 }) => {
      return viewModel.getSiteResourceSiteDocuments(
        companyId, siteId, resourceId,
        filterDocuments, sortDocuments, pageParam,
        DocumentTypeCategory.SITE
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );

  const siteResourceSiteDocumentsRejectedQuery = useQuery(
    ["site-documents-rejected", companyId, siteId, resourceId],
    async () => {
      const documents = await viewModel.getSiteResourceDocumentsRejected(
        companyId, siteId, resourceId, DocumentTypeCategory.SITE
      );

      const rejectionGroups = mapRejectionGroups([
        {
          resource: "site",
          documents: documents,
        },
      ]);

      return rejectionGroups;
    }
  );

  const { data: systemStaff } = useQuery<Staff[], Error>(
    ["system-staff-list", resourceId],
    async () => staffViewModel.getSystemStaff(resourceId),
    {
      initialData: [],
    }
  );

  const documentAICompanyProps = useDocumentAI(
    viewModel,
    siteId,
    resourceId,
    DocumentTypeCategory.COMPANY,
    hookCompanySectionCode,
    isSupplier,
    'company'
  );
  const documentAISiteProps = useDocumentAI(
    viewModel,
    siteId,
    resourceId,
    DocumentTypeCategory.SITE,
    hookSiteSectionCode,
    isSupplier,
    'site'
  );

  const documentInfoProps = useDocumentInfo(
    viewModel,
    siteId,
    `${supplierDocumentType}`,
    resourceId
  );
  const documentTypesProps = useAddDocumentType(
    viewModel,
    supplierDocumentType
  );

  const siteResourceEvaluationsQuery = useInfiniteQuery(
    [
      "supplier-evaluations",
      companyId,
      siteId,
      resourceId,
      sortResourceEvaluations,
      filterResourceEvaluations,
    ],
    async ({ pageParam = 1 }) => {
      const data = await viewModel.getSiteResourceEvaluations(
        companyId,
        siteId,
        resourceId,
        pageParam,
        Object.keys(sortResourceEvaluations).length > 0
          ? {
              field: Object.keys(sortResourceEvaluations)[0],
              direction: Object.values(
                sortResourceEvaluations
              )[0] as SortDirection,
            }
          : undefined,
        filterResourceEvaluations
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetEvaluationsHistory,
    }
  );

  const variantsQuery = useQuery(
    ["company-variants", companyId, siteId],
    () => viewModel.getVariants(companyId, siteId),
    {
      initialData: [],
    }
  );

  const siteVariantsQuery = useQuery(
    ["site-variants", companyId, siteId, resourceType],
    () => viewModel.getSiteVariants(companyId, siteId, resourceType),
    {
      initialData: [],
      enabled: resourceType != undefined
    },
  );

  const siteSpecializationsQuery = useQuery(
    ["site-specializations", companyId, siteId, resourceType],
    () => viewModel.getSiteSpecializations(companyId, siteId, resourceType),
    {
      initialData: [],
      enabled: resourceType != undefined
    },
  );

  const getDocumentEvaluationsQuery = useQuery(
    [
      "document-evaluations",
      companyId,
      siteId,
      documentIdAndType,
      filterDocumentEvaluations,
      sortDocumentEvaluations,
    ],
    () => {
      return documentIdAndType
        ? viewModel.getDocumentEvaluations(
          companyId,
          siteId,
          supplierDocumentType,
          resourceId,
          documentIdAndType,
          filterDocumentEvaluations,
          Object.keys(sortDocumentEvaluations).length > 0
            ? {
              field: Object.keys(sortDocumentEvaluations)[0],
              direction: Object.values(
                sortDocumentEvaluations,
              )[0] as SortDirection,
            }
            : undefined,
        )
        : [];
    }
  );

  const mutateSupplierStatus = useMutation(
    async (status: SupplierStatus) =>
      await viewModel.setSupplierStatus(companyId, siteId, resourceId, status),
    { onSuccess: () => siteResourceQuery.refetch() }
  );

  const updateSiteResourceVariantMutation = useMutation(
    async (tagId?: string) =>
      await viewModel.updateSiteResourceVariant(
        companyId,
        resourceId,
        siteId,
        tagId
      ),
    {
      onSuccess: () => {
        siteResourceQuery.refetch();
        siteResourceDocumentsQuery.refetch();
        siteResourceSiteDocumentsQuery.refetch();
        siteResourceDocumentsRejectedQuery.refetch();
        siteResourceSiteDocumentsRejectedQuery.refetch();
        communicationProps.refetchTaggableDocuments();
      },
    }
  );

  const createRequirementMutation = useMutation(
    ["create-requirement", companyId, siteId, resourceId],
    (params: {
      requirements: {
        documentTypeId: string;
        isOptional: boolean;
        graceDays: number;
      }[],
      source?: DocumentTypeCategory
    }) => {
      return viewModel.addRequirementToSiteResource(
        companyId,
        resourceId,
        siteId,
        params.requirements,
        params.source
      );
    },
    {
      onSuccess: (data, params) => {
        if (params.source === DocumentTypeCategory.COMPANY) {
          siteResourceDocumentsQuery.refetch();
          siteResourceDocumentsRejectedQuery.refetch();
        } else {
          siteResourceSiteDocumentsQuery.refetch();
          siteResourceSiteDocumentsRejectedQuery.refetch();
        }
        siteResourceQuery.refetch();
        communicationProps.refetchTaggableDocuments();
      },
    }
  );

  const evaluateSiteResourceMutation = useMutation(
    ["evaluate-supplier", companyId, siteId, resourceId],
    async (result: string) =>
      viewModel.evaluateSiteResource(
        companyId,
        siteId,
        resourceId,
        result as SupplierEvaluationState
      ),
    {
      onSuccess: () => {
        siteResourceQuery.refetch();
      },
    }
  );

  const autoEvaluateSiteResourceMutation = useMutation(
    ["auto-evaluate-supplier", companyId, siteId, resourceId],
    async () => viewModel.autoEvaluateSiteResource(companyId, siteId, resourceId),
    {
      onSuccess: () => {
        siteResourceQuery.refetch();
      },
    }
  );

  const evaluateDocumentMutation = useMutation(
    [
      "evaluate-supplier-document",
      companyId,
      siteId,
      resourceId,
      supplierDocumentType,
    ],
    async ({
      result,
      validity,
      documentId,
      noEvaluationExpiration,
    }: EvaluateDocumentParams) => {
      const date =
        validity !== "" && validity !== null
          ? parse(validity, getDateFormat(), new Date())
          : null;

      return viewModel.evaluateSiteSupplierDocument(
        companyId,
        siteId,
        documentId,
        result,
        result === "available" && validity ? date : null,
        noEvaluationExpiration,
        supplierDocumentType,
        resourceId
      );
    },
    {
      onSuccess: () => {
        if (documentTypesProps.hookDocumentCategory === DocumentTypeCategory.COMPANY) {
          siteResourceDocumentsQuery.refetch();
          siteResourceDocumentsRejectedQuery.refetch();
        } else {
          siteResourceSiteDocumentsQuery.refetch();
          siteResourceSiteDocumentsRejectedQuery.refetch();
        }
        siteResourceQuery.refetch();
        getDocumentEvaluationsQuery.refetch();
      },
    }
  );

  const addRequirementsToSiteResource = async (
    requirements: {
      documentTypeId: string;
      isOptional: boolean;
      graceDays: number;
    }[]
  ) => {
    const mappedRequirements = requirements.map((inputRequirement) => ({
      documentTypeId: inputRequirement["id"],
      isOptional: inputRequirement.isOptional,
      graceDays: inputRequirement.graceDays,
    }));
    await createRequirementMutation.mutateAsync({ requirements: mappedRequirements, source: documentTypesProps.hookDocumentCategory });
  };

  const setSupplierStatus = async (status: SupplierStatus) => {
    await mutateSupplierStatus.mutateAsync(status);
  };

  const getSiteSupplierResources = useInfiniteQuery(
    [
      "siteSupplierResources",
      companyId,
      siteId,
      resourceType,
      filterSiteSupplierResources,
      siteSupplierResourcesSort,
      resourceId,
    ],
    async ({ pageParam = 1 }) => {
      const { results } = await viewModel.getSiteSupplierResources(
        companyId,
        siteId,
        resourceType,
        siteSupplierResourcesSort,
        25,
        filterSiteSupplierResources,
        pageParam,
        resourceId
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );

  const deleteMutation = useMutation(
    (params: {
      companyId: string;
      siteId: string;
      requirementId: string;
      subject: RequirementSubject;
      resourceId: string;
    }) =>
      viewModel.deleteSiteSupplierRequirements(
        companyId,
        siteId,
        params.requirementId,
        params.subject,
        params.resourceId
      ),
    {
      onSuccess: (data, params) => {
        siteResourceQuery.refetch(); 
        if (params.subject===RequirementSubject.COMPANY) {
          siteResourceDocumentsQuery.refetch();
          siteResourceDocumentsRejectedQuery.refetch();
        } else {
          siteResourceSiteDocumentsQuery.refetch();
          siteResourceSiteDocumentsRejectedQuery.refetch();
        }
      },

      onError: (err) => console.error(err),
    }
  );

  const deleteRequirement = async (
    requirementId: string,
    subject: RequirementSubject,
    resourceId: string
  ) => {
    try {
      await deleteMutation.mutateAsync({
        companyId,
        siteId,
        requirementId,
        subject,
        resourceId,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getRequirementTemplateUrl = async (requirementId: string, templateId: string, onComplete: (url: string) => Promise<void>) => {
    return await viewModel.getRequirementsTemplateUrl(companyId, siteId,requirementId, templateId).then(onComplete)
  }

  const siteResourceDocuments = useMemo(() => siteResourceDocumentsQuery.data?.pages?.flat() ?? [], [siteResourceDocumentsQuery.data]);
  const siteResourceSiteDocuments = useMemo(() => siteResourceSiteDocumentsQuery.data?.pages?.flat() ?? [], [siteResourceSiteDocumentsQuery.data]);
  const siteSupplierResources = useMemo(() => getSiteSupplierResources.data?.pages?.flat() ?? [], [getSiteSupplierResources.data]);
  const siteResourceEvaluations = useMemo(() => siteResourceEvaluationsQuery.data?.pages?.flat() ?? [], [siteResourceEvaluationsQuery.data]);

  return {
    siteResource: siteResourceQuery.data,
    siteResourceRefetch: siteResourceQuery.refetch,
    variants: variantsQuery.data,
    siteVariants: siteVariantsQuery.data,
    siteSpecializations: siteSpecializationsQuery.data,
    siteSupplierResources,
    siteSupplierResourcesHasNextPage: getSiteSupplierResources.hasNextPage,
    siteSupplierResourcesFetchNextPage:
      getSiteSupplierResources.fetchNextPage,
    siteSupplierResourcesFetching: getSiteSupplierResources.isLoading,
    filterSiteSupplierResources,
    updateFilterSiteSupplierResources,
    setSiteSupplierResourcesSort,
    siteSupplierResourcesSort,
    updateSiteResourceVariant: updateSiteResourceVariantMutation.mutateAsync,
    siteResourceDocumentsRejected: siteResourceDocumentsRejectedQuery.data,
    siteResourceDocuments,
    siteResourceDocumentsHasNextPage: siteResourceDocumentsQuery.hasNextPage,
    siteResourceDocumentsFetchNextPage:
      siteResourceDocumentsQuery.fetchNextPage,
    siteResourceDocumentsRefetch: siteResourceDocumentsQuery.refetch,
    siteResourceDocumentsIsLoading: siteResourceDocumentsQuery.isLoading,
    siteResourceSiteDocumentsRejected : siteResourceSiteDocumentsRejectedQuery.data,
    siteResourceSiteDocuments,
    siteResourceSiteDocumentsHasNextPage:
      siteResourceSiteDocumentsQuery.hasNextPage,
    siteResourceSiteDocumentsFetchNextPage:
      siteResourceSiteDocumentsQuery.fetchNextPage,
    siteResourceSiteDocumentsRefetch: siteResourceSiteDocumentsQuery.refetch,
    siteResourceSiteDocumentsIsLoading: siteResourceSiteDocumentsQuery.isLoading,
    hookDocumentIdAndType: documentIdAndType,
    setHookDocumentIdAndType: setDocumentIdAndType,
    addRequirementsToSiteResource,
    documentEvaluations: getDocumentEvaluationsQuery.data ?? [],
    documentEvaluationsFetching: getDocumentEvaluationsQuery.isLoading,

    sortCompanyDocuments,
    updateSortCompanyDocuments: setSortCompanyDocuments,
    filterCompanyDocuments,
    updateFilterCompanyDocuments: updateFilter(setFilterCompanyDocuments),

    sortDocuments,
    filterDocuments,
    evaluateSiteResource: evaluateSiteResourceMutation.mutateAsync,
    autoEvaluateSiteResource: autoEvaluateSiteResourceMutation.mutateAsync,
    evaluateDocument: evaluateDocumentMutation.mutateAsync,

    siteResourceEvaluations,
    siteResourceEvaluationsHasNextPage:
      siteResourceEvaluationsQuery.hasNextPage,
    siteResourceEvaluationsFetchNextPage: siteResourceEvaluationsQuery.fetchNextPage,
    setEnableGetEvaluationsHistory,
    enableGetEvaluationsHistory,
    getSiteResourceEvaluations: siteResourceEvaluationsQuery.refetch,
    getSiteResourceEvaluationsIsLoading: siteResourceEvaluationsQuery.isLoading,
    siteResourceEvaluationsIsFetching: siteResourceEvaluationsQuery.isFetching,
    filterResourceEvaluations,
    sortResourceEvaluations,
    updateFilterResourceEvaluations: updateFilter(setFilterResourceEvaluations),
    updateSortResourceEvaluations: updateSort(setSortResourceEvaluations),

    updateFilterDocuments: updateFilter(setFilterDocuments),
    updateFilterDocumentEvaluations: updateFilter(setFilterDocumentEvaluations),
    updateSortDocumentEvaluations: updateSort(setSortDocumentEvaluations),
    updateSortDocuments: setSortDocuments,

    filterDocumentEvaluations,
    sortDocumentEvaluations,

    documentInfoProps,
    documentTypesProps,
    setSupplierDocumentType,
    setSupplierStatus,
    isCompanyDocumentsFetching: siteResourceDocumentsQuery.isLoading,
    isSiteDocumentsFetching: siteResourceSiteDocumentsQuery.isLoading,
    deleteRequirement,
    communicationProps,
    statusIsLoading: mutateSupplierStatus.isLoading,
    systemStaff,
    resourceType,
    setResourceType,
    documentAICompanyProps,
    documentAISiteProps,
    getRequirementTemplateUrl
  };
}

export { useSiteSupplierDetailViewModel };
