import { Button, Flex, Grid, GridItem, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PermissionBox from "./PermissionBox";
import { COLORS } from "../../assets/theme/colors";

const AccessDeniedView = () => {
  const { t } = useTranslation("permissions");
  const location = useLocation();
  const routeName = location?.state?.routeName || null;
  const blockedUser = location?.state?.blockedUser || null;
  const permissions = location?.state?.permissions || null;

  return (
    <Flex
      flex={1}
      h="100%"
      w="100%"
      bg="white"
      padding={10}
      justifyContent="start"
      flexDirection="column"
    >
      {blockedUser ? (
        <Text borderRadius={10} padding={4} bg="red.100" fontWeight="semibold">
          {t("accessDenied.blockedUserOnTenant")}
        </Text>
      ) : (
        <>
          <VStack spacing={2} borderRadius={10} padding={4} bg="red.100">
            {routeName ? (
              <Text fontSize="large" fontWeight="semibold">
                {t("accessDenied.messageWithRouteName", {
                  section: t(`routes.${routeName}`),
                })}
              </Text>
            ):  (
              <Text fontSize="large" fontWeight="semibold">
                {t("accessDenied.message")}
              </Text>
            )}
            <Text>{t("accessDenied.hint")}</Text>
          </VStack>

          {permissions && (
            <>
              <Text fontSize="large" fontWeight="semibold" marginTop={8}>
                {t("accessDenied.requiredPermissions")}
              </Text>

              <Grid templateColumns={"repeat(2, 1fr)"} marginTop={2} gap={2}>
                {permissions?.map((p) => (
                  <GridItem key={p} marginBottom={8}>
                    <PermissionBox permission={p} />
                  </GridItem>
                ))}
              </Grid>
            </>
          )}
          <Flex justifyContent={'center'} my={4}>
            <Button
              bg={COLORS.sikuroBlue}
              color="white"
              size="lg"
              width={44}
              _hover={{ backgroundColor: COLORS.yellow, color: "black" }}
            >
              <a href={`${window.location.origin}/overview`}>{t('goToDashboard', {ns: 'errors'})}</a>
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default AccessDeniedView;
