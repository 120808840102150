import UseCase from '../../../core/app/usecase';
import CompanyRepository from '../../repositories/companyRepository';

type GetTranslationsParams = {
  companyId: string;
};

class GetTranslations implements UseCase<null, Record<string, string>> {
	private companyRepository: CompanyRepository;

	constructor(companyRepository: CompanyRepository) {
		this.companyRepository = companyRepository;
	}

	async Call({ companyId }: GetTranslationsParams): Promise<Record<string, string>> {
		return await this.companyRepository.getTranslations(companyId);
	}
}

export default GetTranslations;
