import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { RenameModel } from "../../../domain/entities/documentType";
import { Evaluable } from "../../../domain/entities/evaluable.enum";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { formatISODate } from "../../../infrastructure/utilities/filters";
import { updateFilterWithDelete } from "../../../utils";
import { useAuth } from "../../providers/Auth0JWTProvider";
import SiteApproveEvaluationsViewModel from "../../viewmodels/sites/SiteApproveEvaluationsViewModel";

export type PendingEvaluationsFilters = {
  company?: string;
  resource?: string;
  requirement?: string;
  createdAt?: string;
  result?: string;
  firstName?: string;
  lastName?: string;
  expiresAt?: string
};

export type PendingEvaluationsReqFilters = {
  company?: string;
  resource?: string;
  requirement?: string;
  createdAt?: string;
  result?: string;
  firstName?: string;
  lastName?: string;
  fromDate?: string
  toDate?: string
};

const useSiteApproveEvaluationsViewModel = (
  siteId: string,
  type: Evaluable,
) => {
  const { companyId } = useAuth();
  const viewModel = new SiteApproveEvaluationsViewModel(
    companyId,
    siteId,
    type,
  );
  const [sort, setSort] = useState<SortMeta>();
  const [filterEvaluations, setFilterEvaluations] =
    useState<PendingEvaluationsFilters>();
  const [search, setSearch] = useState<string>();
  const [evaluationId, setEvaluationId] = useState<string>()
  const [selectAllEvaluations, setSelectAllEvaluation] = useState(false);
  const [documentId, setDocumentId] = useState<string>()
  const [filesCount, setFilesCount] = useState<number>()
  const [docTypeId, setDocTypeId] = useState<string>()
  const [evaluationIds, setEvaluationIds] = useState<string[]>([]);
  const [countPendingEvaluations, setCountPendingEvaluations] = useState<number>()
  const [noRejectEvaluation, setNoRefejectEvaluation] = useState<string>()
  const [enableGetFiles, setEnableGetFiles] = useState(false)

  const getPendignEvaluationsQuery = useInfiniteQuery(
    ["company-suppliers", companyId, filterEvaluations, search, sort, type],
    async ({ pageParam = 1 }) => {
      const filters = search
        ? { ...filterEvaluations, search }
        : filterEvaluations;
				const filterKeys = Object.keys(filters);
      const reqFilters: PendingEvaluationsReqFilters = {}
      
      filterKeys.forEach((k) => {
        if (k == 'expiresAt') {
          const fromDate = filterEvaluations[k][0] ?
            formatISODate(new Date(filterEvaluations[k][0])) :
            null;
          if (fromDate) {
            reqFilters.fromDate = fromDate;
          }
      
          const toDate = filterEvaluations[k][1] ?
            formatISODate(new Date(filterEvaluations[k][1])) :
            null;
          if (toDate) {
            reqFilters.toDate = toDate;
          }
        }
      
        const v = filterEvaluations[k];
        if (v && v !== '') {
          reqFilters[k] = filterEvaluations[k];
        }
      });
			delete reqFilters['expiresAt'];
      
      const {results, count} = await viewModel.get(reqFilters, sort, pageParam);
      setCountPendingEvaluations(count)
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    },
  );

  const getPendingEvaluationFiles = useQuery(
    ["document-files", documentId, enableGetFiles],
    async () => {
      const { results, count } = await viewModel.getPendingEvaluationFiles(documentId);
      setFilesCount(count)
      return results;
    },
    {
       enabled: !!enableGetFiles,
    },
  );

  const getRenameModel = useQuery<RenameModel, Error>(
    ["rename-models", docTypeId],
    async () => {
      const result = await viewModel.getRenameModel(companyId, docTypeId);
      return result;
    },
    {
      enabled: !!docTypeId,
      retry: false,
    },
  );

  const approvePendingEvaluationMutation = useMutation(
    ["approve-pending-evaluation", companyId, siteId, type, filterEvaluations],
    async ({
      resource,
      evaluationIds,
      isApproved
    }: {
      resource: string;
      evaluationIds: string[];
      isApproved: boolean;
    }) => viewModel.approve(resource, evaluationIds, isApproved, selectAllEvaluations, filterEvaluations),
    {
      onSuccess: () => getPendignEvaluationsQuery.refetch(),
      onError: (err: Error) => { setNoRefejectEvaluation(err.message)}
    },
  );

  const updateFilterEvaluations = (
    field: string,
    value: string | string[] | [Date, Date] | undefined,
  ) => {
    updateFilterWithDelete(setFilterEvaluations, field, value);
  };

  return {
    evaluations: getPendignEvaluationsQuery?.data?.pages?.flat() ?? [],
    evaluationsIsLoading: getPendignEvaluationsQuery.isLoading,
    pendingEvaluationsHasNextPage: getPendignEvaluationsQuery.hasNextPage,
    pendingEvaluationsFetchNextPage: getPendignEvaluationsQuery.fetchNextPage,
    filterEvaluations,
    setFilterEvaluations,
    updateFilterEvaluations,
    sort,
    setSort,
    approve: approvePendingEvaluationMutation.mutateAsync,
    approveIsLoading: approvePendingEvaluationMutation.isLoading,
    setSearch,
    evaluationFiles: getPendingEvaluationFiles.data,
    evaluationFilesIsLoading: getPendingEvaluationFiles.isLoading,
    setEvaluationId,
    selectAllEvaluations,
    setSelectAllEvaluation,
    setDocumentId,
    documentId,
    filesCount, setDocTypeId, docTypeId,
    renameModels: getRenameModel.data,
    evaluationIds,
    setEvaluationIds,
    countPendingEvaluations,
    noRejectEvaluation,
    setNoRefejectEvaluation,
    setEnableGetFiles,
    enableGetFiles
  };
};

export default useSiteApproveEvaluationsViewModel;
