export enum Permission {
  //Dashboard
  Dashboard_ShowExpiringCompanyOrSiteEvaluations = "dashboard:show-expiring-company-or-site-evaluations",
  Dashboard_ShowExpiringDocuments = "dashboard:show-expiring-resources-documents",
  Dashboard_ShowExpiringCompanyDocuments = "dashboard:show-expiring-company-documents",
  Dashboard_ShowExpiringEvaluations = "dashboard:show-expiring-evaluations",
  Dashboard_ShowExpiringResourcesEvaluations = "dashboard:show-expiring-resources-evaluations",
  Dashboard_ShowInvitedSuppliers = "dashboard:show-invited-suppliers",
  Dashboard_ShowOwnedSitesCount = "dashboard:show-owned-sites-count",
  Dashboard_ShowPendingEvaluations = "dashboard:show-pending-evaluations",
  Dashboard_ShowSuppliersCount = "dashboard:show-suppliers-count",
  Dashboard_ShowUnevaluatedDocuments = "dashboard:show-unevaluated-documents",
  Dashboard_ShowWorkingSitesCount = "dashboard:show-working-sites-count",
  //Sites
  Sites_Show = "company_sites:show",
  Sites_Add = "company_sites:add",
  //Working Sites
  Worksite_Show = "company_workingsites:show",
  //Company
  Company_AIManageFile = "company:ai",
  Company_ShowDetails = "company:show-details",
  Company_EditDetails = "company:edit-details",
  Company_ShowStaff = "company:show-staff",
  Company_EditStaff = "company:edit-staff",
  Company_ShowCompanyDocs = "company:show-company-docs",
  Company_AddNewCompanyDoc = "company:add-new-company-doc",
  Company_EditCompanyDocs = "company:edit-company-docs",
  Company_DeleteCompanyDoc = "company:delete-company-doc",
  Company_DownloadDocs = "company:download-docs",
  Company_ShowCompanyDocsEvaluation = "company:show-company-docs-evaluation",
  Company_EvalCompanyDocs = "company:eval-company-docs",
  Company_ShowCompanyDocExpiration = "company:show-company-doc-expiration",
  Company_SetCompanyDocExpiration = "company:set-company-doc-expiration",
  Company_ShowPreset = "company:show-preset",
  Company_EditPreset = "company:edit-preset",
  Company_DeletePreset = "company:delete-preset",
  Company_ShowDocType = "company:show-doc-type",
  Company_EditDocType = "company:edit-doc-type",
  Company_DeleteDocType = "company:delete-doc-type",
  Company_ShowReqGroup = "company:show-req-group",
  Company_AddReqGroup = "company:add-req-group",
  Company_EditReqGroup = "company:edit-req-group",
  Company_AddReq = "company:add-req-to-group",
  Company_DeleteReq = "company:delete-req-from-group",
  Company_DeleteReqGroup = "company:delete-req-group",
  Company_ShowBadgeDetails = "company:show-badges",
  Company_AddBadge = "company:add-badge",
  Company_DeleteBadge = "company:delete-badge",
  Company_ImportBadge = "company:import-badge",
  Company_SetBadgeStatus = "company:set-badge-status",
  Company_LinkBadgeResource = "company:link-badge-resource", //Valuratare se non cambiare in link to site , in quanto la risorsa viene aggiunta al sito
  Company_UnlinkBadgeResource = "company:unlink-badge-resource",
  //Resources - workers
  Resources_ShowWorkersDetails = "resources:show-workers",
  Resources_AddWorker = "resources:add-worker",
  Resources_ImportWorker = "resources:import-worker",
  Resources_DeleteWorker = "resources:delete-worker",
  Resources_ShowWorkerSites = "resources:show-worker-sites",
  Resources_ShowWorkerDocs = "resources:show-worker-docs",
  Resources_AddWorkerDoc = "resources:add-worker-doc",
  Resources_EditWorkerDocs = "resources:edit-worker-docs",
  Resources_DeleteWorkerDoc = "resources:delete-worker-doc",
  Resources_DownloadWorkerDocs = "resources:download-worker-docs",
  Resources_ShowWorkerDocExpiration = "resources:show-worker-doc-expiration",
  Resources_SetWorkerDocExpiration = "resources:set-worker-doc-expiration",
  Resources_ShowWorkerTypology = "resources:show-worker-typology",
  Resources_EditWorkerTypology = "resources:edit-worker-typology",
  Resources_ShowWorkerDocsEvaluation = "resources:show-worker-docs-evaluation",
  Resources_ShowWorkerDocsEvaluationExpiration = "resources:show-worker-docs-evaluation-expiration",
  Resources_EvalWorkerDocs = "resources:eval-worker-docs",
  //Resources - vehicles
  Resources_ShowVehicleDetails = "resources:show-vehicles",
  Resources_AddVehicle = "resources:add-vehicle",
  Resources_ImportVehicle = "resources:import-vehicle",
  Resources_DeleteVehicle = "resources:delete-vehicle",
  Resources_ShowVehicleSites = "resources:show-vehicle-sites",
  Resources_ShowVehicleDocs = "resources:show-vehicle-docs",
  Resources_AddVehicleDoc = "resources:add-vehicle-doc",
  Resources_EditVehicleDocs = "resources:edit-vehicle-docs",
  Resources_DeleteVehicleDoc = "resources:delete-vehicle-doc",
  Resources_DownloadVehicleDocs = "resources:download-vehicle-docs",
  Resources_ShowVehicleDocExpiration = "resources:show-vehicle-doc-expiration",
  Resources_SetVehicleDocExpiration = "resources:set-vehicle-doc-expiration",
  Resources_ShowVehicleTypology = "resources:show-vehicle-typology",
  Resources_EditVehicleTypology = "resources:edit-vehicle-typology",
  Resources_ShowVehicleDocsEvaluation = "resources:show-vehicle-docs-evaluation",
  Resources_ShowVehicleDocsEvaluationExpiration = "resources:show-vehicle-docs-evaluation-expiration",
  Resources_EvalVehicleDocs = "resources:eval-vehicle-docs",
  Resources_ConvertVehicleToMachine = "resources:convert-vehicle-to-machine",
  //Resources - machines
  Resources_ShowMachineDetails = "resources:show-machines",
  Resources_AddMachine = "resources:add-machine",
  Resources_ImportMachine = "resources:import-machine",
  Resources_DeleteMachine = "resources:delete-machine",
  Resources_ShowMachineSites = "resources:show-machine-sites",
  Resources_ShowMachineDocs = "resources:show-machine-docs",
  Resources_AddMachineDoc = "resources:add-machine-doc",
  Resources_EditMachineDocs = "resources:edit-machine-docs",
  Resources_DeleteMachineDoc = "resources:delete-machine-doc",
  Resources_DownloadMachineDocs = "resources:download-machine-docs",
  Resources_ShowMachineDocExpiration = "resources:show-machine-doc-expiration",
  Resources_SetMachineDocExpiration = "resources:set-machine-doc-expiration",
  Resources_ShowMachineTypology = "resources:show-machine-typology",
  Resources_EditMachineTypology = "resources:edit-machine-typology",
  Resources_ShowMachineDocsEvaluation = "resources:show-machine-docs-evaluation",
  Resources_ShowMachineDocsEvaluationExpiration = "resources:show-machine-docs-evaluation-expiration",
  Resources_EvalMachineDocs = "resources:eval-machine-docs",
  Resources_ConvertMachineToVehicle = "resources:convert-machine-to-vehicle",
  Resources_ConvertMachineToTool = "resources:convert-machine-to-tool",
  //Resources - tools
  Resources_ShowToolDetails = "resources:show-tools",
  Resources_AddTool = "resources:add-tool",
  Resources_ImportTool = "resources:import-tool",
  Resources_DeleteTool = "resources:delete-tool",
  Resources_ShowToolSites = "resources:show-tool-sites",
  Resources_ShowToolDocs = "resources:show-tool-docs",
  Resources_AddToolDoc = "resources:add-tool-doc",
  Resources_EditToolDocs = "resources:edit-tool-docs",
  Resources_DeleteToolDoc = "resources:delete-tool-doc",
  Resources_DownloadToolDocs = "resources:download-tool-docs",
  Resources_ShowToolDocExpiration = "resources:show-tool-doc-expiration",
  Resources_SetToolDocExpiration = "resources:set-tool-doc-expiration",
  Resources_ShowToolTypology = "resources:show-tool-typology",
  Resources_EditToolTypology = "resources:edit-tool-typology",
  Resources_ShowToolDocsEvaluation = "resources:show-tool-docs-evaluation",
  Resources_ShowToolDocsEvaluationExpiration = "resources:show-tool-docs-evaluation-expiration",
  Resources_EvalToolDocs = "resources:eval-tool-docs",
  Resources_ConvertToolToMachine = "resources:convert-tool-to-machine",
  //Resources - chemicals
  Resources_ShowChemicalDetails = "resources:show-chemicals",
  Resources_AddChemical = "resources:add-chemical",
  Resources_ImportChemical = "resources:import-chemical",
  Resources_DeleteChemical = "resources:delete-chemical",
  Resources_ShowChemicalSites = "resources:show-chemical-sites",
  Resources_ShowChemicalDocs = "resources:show-chemical-docs",
  Resources_AddChemicalDoc = "resources:add-chemical-doc",
  Resources_EditChemicalDocs = "resources:edit-chemical-docs",
  Resources_DeleteChemicalDoc = "resources:delete-chemical-doc",
  Resources_DownloadChemicalDocs = "resources:download-chemical-docs",
  Resources_ShowChemicalDocExpiration = "resources:show-chemical-doc-expiration",
  Resources_SetChemicalDocExpiration = "resources:set-chemical-doc-expiration",
  Resources_ShowChemicalTypology = "resources:show-chemical-typology",
  Resources_EditChemicalTypology = "resources:edit-chemical-typology",
  Resources_ShowChemicalDocsEvaluation = "resources:show-chemical-docs-evaluation",
  Resources_ShowChemicalDocsEvaluationExpiration = "resources:show-chemical-docs-evaluation-expiration",
  Resources_EvalChemicalDocs = "resources:eval-chemical-docs",
  //Suppliers
  Supplier_Show = "supplier:show",
  Supplier_ShowDetails = "supplier:show-details",
  Supplier_ShowDocuments = "supplier:show-documents",
  Supplier_DownloadAll = "supplier:download-all",
  Supplier_ShowCompanyReqEvaluation = "supplier:show-company-req-evaluation",
  Supplier_EvalCompanyReq = "supplier:eval-company-req",
  Supplier_ShowWorkers = "supplier:show-workers",
  Supplier_ShowWorkerDocs = "supplier:show-worker-docs",
  Supplier_DownloadWorkerDocs = "supplier:download-worker-docs",
  Supplier_ShowWorkerDocReqEvaluation = "supplier:show-worker-req-evaluation",
  Supplier_EvalWorkerReq = "supplier:eval-worker-req",
  Supplier_ShowVehicles = "supplier:show-vehicles",
  Supplier_ShowVehicleDocs = "supplier:show-vehicle-docs",
  Supplier_DownloadVehicleDocs = "supplier:download-vehicle-docs",
  Supplier_ShowVehicleReqEvaluation = "supplier:show-vehicle-req-evaluation",
  Supplier_EvalVehicleReq = "supplier:eval-vehicle-req",
  Supplier_ShowMachines = "supplier:show-machines",
  Supplier_ShowMachineDocs = "supplier:show-machine-docs",
  Supplier_DownloadMachineDocs = "supplier:download-machine-docs",
  Supplier_ShowMachineReqEvaluation = "supplier:show-machine-req-evaluation",
  Supplier_EvalMachineReq = "supplier:eval-machine-req",
  Supplier_ShowTools = "supplier:show-tools",
  Supplier_ShowToolDocs = "supplier:show-tool-docs",
  Supplier_DownloadToolDocs = "supplier:download-tool-docs",
  Supplier_ShowToolReqEvaluation = "supplier:show-tool-req-evaluation",
  Supplier_EvalToolReq = "supplier:eval-tool-req",
  Supplier_ShowChemicals = "supplier:show-chemicals",
  Supplier_ShowChemicalDocs = "supplier:show-chemical-docs",
  Supplier_DownloadChemicalDocs = "supplier:download-chemical-docs",
  Supplier_ShowChemicalReqEvaluation = "supplier:show-chemical-req-evaluation",
  Supplier_EvalChemicalReq = "supplier:eval-chemical-req",
  //Calendar
  Calendar_ShowExpiration = "calendar:show-expiration",

  //Settings
  Settings_ShowRoles = "settings:show-roles",
  Settings_AddRole = "settings:add-role",
  Settings_EditRole = "settings:edit-role",
  Settings_DeleteRole = "settings:delete-role",
  Settings_ShowUsers = "settings:show-users",
  Settings_InviteUser = "settings:invite-user",
  Settings_DeleteUser = "settings:delete-user",
  Settings_ShowUserGeneralRole = "settings:show-user-general-role",
  Settings_EditUserGeneralRole = "settings:edit-user-general-role",
  Settings_ShowUserSiteRoles = "settings:show-user-site-roles",
  Settings_AddUserSiteRoles = "settings:add-user-site-roles",
  Settings_DeleteUserSiteRoles = "settings:delete-user-site-roles",
  Settings_ShowUserWorkingSiteRoles = "settings:show-user-working-site-roles",
  Settings_AddUserWorkingSiteRoles = "settings:add-user-working-site-roles",
  Settings_DeleteUserWorkingSiteRoles = "settings:delete-user-working-site-roles",
  Settings_ShowUserState = "settings:show-user-state",
  Settings_EditUserState = "settings:edit-user-state",
  Settings_EditWebHooks = "settings:edit-webhooks",
  Settings_EditCustomFields = "settings:edit-custom-fields",

  //Sites
  //Site Details
  Sites_Guardianship = "sites:guardianship",
  Sites_ShowDetails = "sites:show-details",
  Sites_EditDetails = "sites:edit-details",
  Sites_ShowState ="sites:show-state",
  Sites_EditState ="sites:edit-state",
  Sites_ShowStaff ="sites:show-staff",
  Sites_EditStaff ="sites:edit-staff",
  Sites_ShowSettings ="sites:show-settings",
  Sites_EditGeneralSettings ="sites:edit-general-settings",
  Sites_EditUsersRole ="sites:edit-users-role",
  //Site General Documents
  Sites_ShowGeneralDocs ="sites:show-general-docs",
  Sites_AddGeneralDocs ="sites:add-general-docs",
  Sites_EditGeneralDocs ="sites:edit-general-docs",
  Sites_DeleteGeneralDocs ="sites:delete-general-docs",
  Sites_ShowOwnerReq ="sites:show-owner-req",
  Sites_AddOwnerReq ="sites:add-owner-req",
  Sites_EditOwnerReq ="sites:edit-owner-req",
  Sites_DeleteOwnerReq ="sites:delete-owner-req",
  Sites_DownloadOwnerReq ="sites:download-owner-req",
  Sites_ShowOwnerReqEvaluation ="sites:show-owner-req-evaluation",
  Sites_EditOwnerVariant ="sites:edit-owner-variant",
  Sites_EvalOwnerReq ="sites:eval-owner-req",
  Sites_OwnerChat ="sites:owner-chat",
  Sites_ShowGlobalOwnerEvaluation ="sites:show-global-owner-evaluation",
  Sites_SetGlobalOwnerEvaluation ="sites:set-global-owner-evaluation",
  Sites_SetOwnerState ="sites:set-owner-state",
  Sites_EvaluationOverview ="sites:evaluations-overview",
  
  Sites_ShowWorkers ="sites:show-workers",
  Sites_AddWorker ="sites:add-worker",
  Sites_DeleteWorker ="sites:delete-worker",
  Sites_ShowWorkerReq ="sites:show-worker-req",
  Sites_AddWorkerReq ="sites:add-worker-req",
  Sites_EditWorkerReq ="sites:edit-worker-req",
  Sites_DeleteWorkerReq ="sites:delete-worker-req",
  Sites_EditWorkerVariantSpecializations ="sites:edit-worker-variant-specializations",
  Sites_DownloadWorkerReq ="sites:download-worker-req",
  Sites_ShowWorkerReqEvaluation ="sites:show-worker-req-evaluation",
  Sites_ShowWorkerReqEvaluationExpiration ="sites:show-worker-req-evaluation-expiration",
  Sites_EvalWorkerReq ="sites:eval-worker-req",
  Sites_ShowGlobalWorkerEvaluation ="sites:show-global-worker-evaluation",
  Sites_SetGlobalWorkerEvaluation ="sites:set-global-worker-evaluation",
  Sites_AccessWorkerChat = "sites:worker-chat",
  
  Sites_ShowVehicles ="sites:show-vehicles",
  Sites_AddVehicle ="sites:add-vehicle",
  Sites_DeleteVehicle ="sites:delete-vehicle",
  Sites_ShowVehicleReq ="sites:show-vehicle-req",
  Sites_AddVehicleReq ="sites:add-vehicle-req",
  Sites_EditVehicleReq ="sites:edit-vehicle-req",
  Sites_DeleteVehicleReq ="sites:delete-vehicle-req",
  Sites_EditVehicleVariantSpecializations ="sites:edit-vehicle-variant-specializations",
  Sites_DownloadVehicleReq ="sites:download-vehicle-req",
  Sites_ShowVehicleReqEvaluation ="sites:show-vehicle-req-evaluation",
  Sites_ShowVehicleReqEvaluationExpiration ="sites:show-vehicle-req-evaluation-expiration",
  Sites_EvalVehicleReq ="sites:eval-vehicle-req",
  Sites_ShowGlobalVehicleEvaluation ="sites:show-global-vehicle-evaluation",
  Sites_SetGlobalVehicleEvaluation ="sites:set-global-vehicle-evaluation",
  Sites_AccessVehicleChat = "sites:vehicle-chat",

  Sites_ShowMachines ="sites:show-machines",
  Sites_AddMachine ="sites:add-machine",
  Sites_DeleteMachine ="sites:delete-machine",
  Sites_ShowMachineReq ="sites:show-machine-req",
  Sites_AddMachineReq ="sites:add-machine-req",
  Sites_EditMachineReq ="sites:edit-machine-req",
  Sites_DeleteMachineReq ="sites:delete-machine-req",
  Sites_EditMachineVariantSpecializations ="sites:edit-machine-variant-specializations",
  Sites_DownloadMachineReq ="sites:download-machine-req",
  Sites_ShowMachineReqEvaluation ="sites:show-machine-req-evaluation",
  Sites_ShowMachineReqEvaluationExpiration ="sites:show-machine-req-evaluation-expiration",
  Sites_EvalMachineReq ="sites:eval-machine-req",
  Sites_ShowGlobalMachineEvaluation ="sites:show-global-machine-evaluation",
  Sites_SetGlobalMachineEvaluation ="sites:set-global-machine-evaluation",
  Sites_AccessMachineChat = "sites:machine-chat",

  Sites_ShowTools ="sites:show-tools",
  Sites_AddTool ="sites:add-tool",
  Sites_DeleteTool ="sites:delete-tool",
  Sites_ShowToolReq ="sites:show-tool-req",
  Sites_AddToolReq ="sites:add-tool-req",
  Sites_EditToolReq ="sites:edit-tool-req",
  Sites_DeleteToolReq ="sites:delete-tool-req",
  Sites_EditToolVariantSpecializations ="sites:edit-tool-variant-specializations",
  Sites_DownloadToolReq ="sites:download-tool-req",
  Sites_ShowToolReqEvaluation ="sites:show-tool-req-evaluation",
  Sites_ShowToolReqEvaluationExpiration ="sites:show-tool-req-evaluation-expiration",
  Sites_EvalToolReq ="sites:eval-tool-req",
  Sites_ShowGlobalToolEvaluation ="sites:show-global-tool-evaluation",
  Sites_SetGlobalToolEvaluation ="sites:set-global-tool-evaluation",
  Sites_AccessToolChat = "sites:tool-chat",

  Sites_ShowChemicals ="sites:show-chemicals",
  Sites_AddChemical ="sites:add-chemical",
  Sites_DeleteChemical ="sites:delete-chemical",
  Sites_ShowChemicalReq ="sites:show-chemical-req",
  Sites_AddChemicalReq ="sites:add-chemical-req",
  Sites_EditChemicalReq ="sites:edit-chemical-req",
  Sites_DeleteChemicalReq ="sites:delete-chemical-req",
  Sites_EditChemicalVariantSpecializations ="sites:edit-chemical-variant-specializations",
  Sites_DownloadChemicalReq ="sites:download-chemical-req",
  Sites_ShowChemicalReqEvaluation ="sites:show-chemical-req-evaluation",
  Sites_ShowChemicalReqEvaluationExpiration ="sites:show-chemical-req-evaluation-expiration",
  Sites_EvalChemicalReq ="sites:eval-chemical-req",
  Sites_ShowGlobalChemicalEvaluation ="sites:show-global-chemical-evaluation",
  Sites_SetGlobalChemicalEvaluation ="sites:set-global-chemical-evaluation",
  Sites_AccessChemicalChat = "sites:chemical-chat",
  
  Sites_ShowSuppliers ="sites:show-suppliers",
  Sites_InviteSupplier ="sites:invite-supplier",
  Sites_DeleteSupplier ="sites:delete-supplier",
  Sites_ShowSupplierState ="sites:show-supplier-state",
  Sites_SetSupplierState ="sites:set-supplier-state",
  Sites_SupplierChat ="sites:supplier-chat",
  Sites_ShowGlobalSupplierEvaluation ="sites:show-global-supplier-evaluation",
  Sites_SetGlobalSupplierEvaluation ="sites:set-global-supplier-evaluation",
  Sites_EditSupplierVariant ="sites:edit-supplier-variant",
  Sites_ShowSupplierCompanyReq ="sites:show-supplier-company-req",
  Sites_AddSupplierCompanyReq ="sites:add-supplier-company-req",
  Sites_EditSupplierCompanyReq ="sites:edit-supplier-company-req",
  Sites_DeleteSupplierCompanyReq ="sites:delete-supplier-company-req",
  Sites_DownloadSupplierCompanyReq ="sites:download-supplier-company-req",
  Sites_ShowSupplierCompanyReqEvaluation ="sites:show-supplier-company-req-evaluation",
  Sites_ShowSupplierCompanyReqEvaluationExpiration ="sites:show-supplier-company-req-evaluation-expiration",
  Sites_EvalSupplierCompanyReq ="sites:eval-supplier-company-req",
  Sites_ShowSupplierSiteReq ="sites:show-supplier-site-req",
  Sites_AddSupplierSiteReq ="sites:add-supplier-site-req",
  Sites_EditSupplierSiteReq ="sites:edit-supplier-site-req",
  Sites_DeleteSupplierSiteReq ="sites:delete-supplier-site-req",
  Sites_DownloadSupplierSiteReq ="sites:download-supplier-site-req",
  Sites_ShowSupplierSiteReqEvaluation ="sites:show-supplier-site-req-evaluation",
  Sites_ShowSupplierSiteReqEvaluationExpiration ="sites:show-supplier-site-req-evaluation-expiration",
  Sites_EvalSupplierSiteReq ="sites:eval-supplier-site-req",

  Sites_ShowRequirements ="sites:show-requirements",
  Sites_AddRequirement ="sites:add-requirement",
  Sites_EditRequirement ="sites:edit-requirement",
  Sites_DeleteRequirement ="sites:delete-requirement",

  Sites_ShowBadges ="sites:show-badges",
  Sites_LinkBadge ="sites:link-badge",
  Sites_UnlinkBadge ="sites:unlink-badge",
  Sites_LinkResourceToBadge ="sites:link-resource-to-badge",
  Sites_PrintBadges ="sites:print-badges",
  Sites_ShowBadgeState ="sites:show-badge-state",
  Sites_SetBadgeState ="sites:set-badge-state",
  Sites_ShowBadgeRecords ="sites:show-badge-records",
  Sites_AddBadgeManualRecord ="sites:add-badge-manual-record",
  Sites_DeleteBadgeRecord ="sites:delete-badge-record",
  Sites_ShowPhysicalBadgeReaders ="sites:show-physical-badge-readers",
  Sites_CreatePhysicalBadgeReader ="sites:create-physical-badge-reader",
  Sites_DeletePhysicalBadgeReader ="sites:delete-physical-badge-reader",
  Sites_EditPhysicalBadgeReader ="sites:edit-physical-badge-reader",
  Sites_ShowPhysicalBadgeReaderState ="sites:show-physical-badge-reader-state",
  Sites_SetPhysicalBadgeReaderState ="sites:set-physical-badge-reader-state",
  Sites_ShowPhysicalBadgeReaderRecords ="sites:show-physical-badge-reader-records",
  Sites_AddPhysicalBadgeReaderManualRecord ="sites:add-physical-badge-reader-manual-record",
  Sites_DeletePhysicalBadgeReaderRecord ="sites:delete-physical-badge-reader-record",
  Sites_ShowVirtualBadgeReaders ="sites:show-virtual-badge-readers",
  Sites_CreateVirtualBadgeReader ="sites:create-virtual-badge-reader",
  Sites_DeleteVirtualBadgeReader ="sites:delete-virtual-badge-reader",
  Sites_EditVirtualBadgeReader ="sites:edit-virtual-badge-reader",
  Sites_ShowVirtualBadgeReaderState ="sites:show-virtual-badge-reader-state",
  Sites_SetVirtualBadgeReaderState ="sites:set-virtual-badge-reader-state",
  Sites_ShowVirtualBadgeReaderRecords ="sites:show-virtual-badge-reader-records",
  Sites_AddVirtualBadgeReaderManualRecord ="sites:add-virtual-badge-reader-manual-record",
  Sites_DeleteVirtualBadgeReaderRecord ="sites:delete-virtual-badge-reader-record",
  Sites_ViewClockingHistoryExactly ="sites:view-clocking-history-exactly",
  Sites_ViewClockingHistoryRounded ="sites:view-clocking-history-rounded",
  Sites_ViewClockingHistoryTotal ="sites:view-clocking-history-total",
  Sites_ViewClockingHistoryDay ="sites:view-clocking-history-day",
  Sites_ShowCompanyPendingEvaluations ="sites:show-company-pending-evaluations",
  Sites_ShowCompanyPendingEvaluationExpiration ="sites:show-company-pending-evaluation-expiration",
  Sites_DownloadCompanyPendingReq ="sites:download-company-pending-req",
  Sites_ApproveCompanyPendingEvaluation ="sites:approve-company-pending-evaluation",
  Sites_ShowWorkerPendingEvaluations ="sites:show-worker-pending-evaluations",
  Sites_ShowWorkerPendingEvaluationExpiration ="sites:show-worker-pending-evaluation-expiration",
  Sites_DownloadWorkerPendingReq ="sites:download-worker-pending-req",
  Sites_ApproveWorkerPendingEvaluation ="sites:approve-worker-pending-evaluation",
  Sites_ShowVehiclePendingEvaluations ="sites:show-vehicle-pending-evaluations",
  Sites_ShowVehiclePendingEvaluationExpiration ="sites:show-vehicle-pending-evaluation-expiration",
  Sites_DownloadVehiclePendingReq ="sites:download-vehicle-pending-req",
  Sites_ApproveVehiclePendingEvaluation ="sites:approve-vehicle-pending-evaluation",
  Sites_ShowMachinePendingEvaluations ="sites:show-machine-pending-evaluations",
  Sites_ShowMachinePendingEvaluationExpiration ="sites:show-machine-pending-evaluation-expiration",
  Sites_DownloadMachinePendingReq ="sites:download-machine-pending-req",
  Sites_ApproveMachinePendingEvaluation ="sites:approve-machine-pending-evaluation",
  Sites_ShowToolPendingEvaluations ="sites:show-tool-pending-evaluations",
  Sites_ShowToolPendingEvaluationExpiration ="sites:show-tool-pending-evaluation-expiration",
  Sites_DownloadToolPendingReq ="sites:download-tool-pending-req",
  Sites_ApproveToolPendingEvaluation ="sites:approve-tool-pending-evaluation",
  Sites_ShowChemicalPendingEvaluations ="sites:show-chemical-pending-evaluations",
  Sites_ShowChemicalPendingEvaluationExpiration ="sites:show-chemical-pending-evaluation-expiration",
  Sites_DownloadChemicalPendingReq ="sites:download-chemical-pending-req",
  Sites_ApproveChemicalPendingEvaluation ="sites:approve-chemical-pending-evaluation",

  Sites_ShowReports ="sites:show-reports",
  Sites_AddReport ="sites:add-report",
  Sites_DeleteReport ="sites:delete-report",

  Worksite_ShowDetails = "worksite:show-details",
  Worksite_ShowGeneralDocs = "worksite:show-general-docs",
  Worksite_ShowStaff = "worksite:show-staff",
  Worksite_ShowSettings = "worksite:show-settings",
  Worksite_EditUsersRole = "worksite:edit-users-role",
  Worksite_ShowWorkers = "worksite:show-workers",
  Worksite_AddWorker = "worksite:add-worker",
  Worksite_DeleteWorker = "worksite:delete-worker",
  Worksite_ShowWorkerReq = "worksite:show-worker-req",
  Worksite_AddWorkerReq = "worksite:add-worker-req",
  Worksite_EditWorkerReq = "worksite:edit-worker-req",
  Worksite_DownloadWorkerReq = "worksite:download-worker-req",
  Worksite_ShowWorkerReqEvaluation = "worksite:show-worker-req-evaluation",
  Worksite_ShowWorkerReqEvaluationExpiration = "worksite:show-worker-req-evaluation-expiration",
  Worksite_ShowGlobalWorkerEvaluation = "worksite:show-global-worker-evaluation",
  Worksite_WorkerChat = "worksite:worker-chat",

  Worksite_ShowVehicles = "worksite:show-vehicles",
  Worksite_AddVehicle = "worksite:add-vehicle",
  Worksite_DeleteVehicle = "worksite:delete-vehicle",
  Worksite_ShowVehicleReq = "worksite:show-vehicle-req",
  Worksite_AddVehicleReq = "worksite:add-vehicle-req",
  Worksite_EditVehicleReq = "worksite:edit-vehicle-req",
  Worksite_DownloadVehicleReq = "worksite:download-vehicle-req",
  Worksite_ShowVehicleReqEvaluation = "worksite:show-vehicle-req-evaluation",
  Worksite_ShowVehicleReqEvaluationExpiration = "worksite:show-vehicle-req-evaluation-expiration",
  Worksite_ShowGlobalVehicleEvaluation = "worksite:show-global-vehicle-evaluation",
  Worksite_VehicleChat = "worksite:vehicle-chat",

  Worksite_ShowMachines = "worksite:show-machines",
  Worksite_AddMachine = "worksite:add-machine",
  Worksite_DeleteMachine = "worksite:delete-machine",
  Worksite_ShowMachineReq = "worksite:show-machine-req",
  Worksite_AddMachineReq = "worksite:add-machine-req",
  Worksite_EditMachineReq = "worksite:edit-machine-req",
  Worksite_DownloadMachineReq = "worksite:download-machine-req",
  Worksite_ShowMachineReqEvaluation = "worksite:show-machine-req-evaluation",
  Worksite_ShowMachineReqEvaluationExpiration = "worksite:show-machine-req-evaluation-expiration",
  Worksite_ShowGlobalMachineEvaluation = "worksite:show-global-machine-evaluation",
  Worksite_MachineChat = "worksite:machine-chat",

  Worksite_ShowTools = "worksite:show-tools",
  Worksite_AddTool = "worksite:add-tool",
  Worksite_DeleteTool = "worksite:delete-tool",
  Worksite_ShowToolReq = "worksite:show-tool-req",
  Worksite_AddToolReq = "worksite:add-tool-req",
  Worksite_EditToolReq = "worksite:edit-tool-req",
  Worksite_DownloadToolReq = "worksite:download-tool-req",
  Worksite_ShowToolReqEvaluation = "worksite:show-tool-req-evaluation",
  Worksite_ShowToolReqEvaluationExpiration = "worksite:show-tool-req-evaluation-expiration",
  Worksite_ShowGlobalToolEvaluation = "worksite:show-global-tool-evaluation",
  Worksite_ToolChat = "worksite:tool-chat",

  Worksite_ShowChemicals = "worksite:show-chemicals",
  Worksite_AddChemical = "worksite:add-chemical",
  Worksite_DeleteChemical = "worksite:delete-chemical",
  Worksite_ShowChemicalReq = "worksite:show-chemical-req",
  Worksite_AddChemicalReq = "worksite:add-chemical-req",
  Worksite_EditChemicalReq = "worksite:edit-chemical-req",
  Worksite_DownloadChemicalReq = "worksite:download-chemical-req",
  Worksite_ShowChemicalReqEvaluation = "worksite:show-chemical-req-evaluation",
  Worksite_ShowChemicalReqEvaluationExpiration = "worksite:show-chemical-req-evaluation-expiration",
  Worksite_ShowGlobalChemicalEvaluation = "worksite:show-global-chemical-evaluation",
  Worksite_ChemicalChat = "worksite:chemical-chat",

  Worksite_ShowReq = "worksite:show-req",
  Worksite_AddReq = "worksite:add-req",
  Worksite_EditReq = "worksite:edit-req",
  Worksite_DownloadReq = "worksite:download-req",
  Worksite_ShowReqEvaluation = "worksite:show-req-evaluation",
  Worksite_ShowGlobalEvaluation = "worksite:show-global-evaluation",
  Worksite_Chat = "worksite:chat",
  Worksite_ShowSubcontractors = "worksite:show-subcontractors",
  Worksite_InviteSubcontractor = "worksite:invite-subcontractor",
  //other
  Export_General = "other:export",
  Other_CreateDerivedTenants = "other:create-derived-tenants",

  //# ⚠️ PAY ATTENTION: Permissions used by the frontend start
  Supplier_Global_EditFile = "supplier:edit-file-req",
  Supplier_Global_AddFile = "supplier:add-file-req",
  Supplier_Global_DeleteFile = "supplier:delete-file-req",
  Supplier_Global_SetExpiration = "supplier:file-set-expiration",
  Supplier_Global_Chemical_AddFile = "supplier:global-chemical-add-file",
  Supplier_Global_Chemical_DeleteFile = "supplier:global-chemical-delete-file",
  Supplier_Global_Chemical_Edit = "supplier:global-chemical-edit",
  Supplier_Global_Chemical_Expiration = "supplier:global-chemical-set-expiration",

  Supplier_Global_Machine_AddFile = "supplier:global-machine-add-file",
  Supplier_Global_Machine_DeleteFile = "supplier:global-machine-delete-file",
  Supplier_Global_Machine_Edit = "supplier:global-machine-edit",
  Supplier_Global_Machine_Expiration = "supplier:global-machine-set-expiration",

  Supplier_Global_Tool_AddFile = "supplier:global-tool-add-file",
  Supplier_Global_Tool_DeleteFile = "supplier:global-tool-delete-file",
  Supplier_Global_Tool_Edit = "supplier:global-tool-edit",
  Supplier_Global_Tool_Expiration = "supplier:global-tool-set-expiration",

  Supplier_Global_Vehicle_AddFile = "supplier:global-vehicle-add-file",
  Supplier_Global_Vehicle_DeleteFile = "supplier:global-vehicle-delete-file",
  Supplier_Global_Vehicle_Edit = "supplier:global-vehicle-edit",
  Supplier_Global_Vehicle_Expiration = "supplier:global-vehicle-set-expiration",

  Supplier_Global_Worker_AddFile = "supplier:global-worker-add-file",
  Supplier_Global_Worker_DeleteFile = "supplier:global-worker-delete-file",
  Supplier_Global_Worker_Edit = "supplier:global-worker-edit",
  Supplier_Global_Worker_Expiration = "supplier:global-worker-set-expiration",
  Sites_EditReviewEval = "sites:edit-review-eval",
  Sites_EvaluateGeneralDocs = "sites:evaluate-general-docs",

  Worksite_EvalReq = "worksite:eval-req",
  Worksite_AddFile = "worksite:add-file",
  Worksite_DeleteFile = "worksite:delete-file",
  Worksite_EditFile = "worksite:file-edit",
  Worksite_SetExpiration = "worksite:set-expiration",
  Worksite_EditStaff = "worksite:edit-staff",

  //Worker
  Worksite_EvalWorkerReq = "worksite:eval-worker-req",
  Worksite_Worker_AddFile = "worksite:worker-add-file",
  Worksite_Worker_DeleteFile = "worksite:worker-delete-file",
  Worksite_Worker_EditFile = "worksite:worker-edit-file",
  Worksite_Worker_SetExpiration = "worksite:worker-set-expiration",
  Worksite_SetGlobalWorkerEvaluation = "worksite:set-global-worker-evaluation",
  //Vehicle
  Worksite_EvalVehicleReq = "worksite:eval-vehicle-req",
  Worksite_Vehicle_AddFile = "worksite:vehicle-add-file",
  Worksite_Vehicle_DeleteFile = "worksite:vehicle-delete-file",
  Worksite_Vehicle_EditFile = "worksite:vehicle-edit-file",
  Worksite_Vehicle_SetExpiration = "worksite:vehicle-set-expiration",
  Worksite_SetGlobalVehicleEvaluation = "worksite:set-global-vehicle-evaluation",

  //Machine
  Worksite_EvalMachineReq = "worksite:eval-machine-req",
  Worksite_Machine_AddFile = "worksite:machine-add-file",
  Worksite_Machine_DeleteFile = "worksite:machine-delete-file",
  Worksite_Machine_EditFile = "worksite:machine-edit-file",
  Worksite_Machine_SetExpiration = "worksite:machine-set-expiration",
  Worksite_SetGlobalMachineEvaluation = "worksite:set-global-machine-evaluation",

  //Chemical
  Worksite_EvalChemicalReq = "worksite:eval-chemical-req",
  Worksite_Chemical_AddFile = "worksite:chemical-add-file",
  Worksite_Chemical_DeleteFile = "worksite:chemical-delete-file",
  Worksite_Chemical_EditFile = "worksite:chemical-edit-file",
  Worksite_Chemical_SetExpiration = "worksite:chemical-set-expiration",
  Worksite_SetGlobalChemicalEvaluation = "worksite:set-global-chemical-evaluation",

  //Tool
  Worksite_EvalToolReq = "worksite:eval-tool-req",
  Worksite_Tool_AddFile = "worksite:tool-add-file",
  Worksite_Tool_DeleteFile = "worksite:tool-delete-file",
  Worksite_Tool_EditFile = "worksite:tool-edit-file",
  Worksite_Tool_SetExpiration = "worksite:tool-set-expiration",
  Worksite_SetGlobalToolEvaluation = "worksite:set-global-tool-evaluation",

  Worksite_Reports = "worksite:reports",
  Worksite_EditSubcontractor = "worksite:edit-subcontractor",
  //# ⚠️ PAY ATTENTION: Permissions used by the frontend end
}

export const permissionSectionMap = {
  //Dashboard
  "dashboard:show-expiring-company-or-site-evaluations": "dashboard",
  "dashboard:show-expiring-resources-documents": "dashboard",
  "dashboard:show-expiring-company-documents": "dashboard",
  "dashboard:show-expiring-evaluations": "dashboard",
  "dashboard:show-expiring-resources-evaluations": "dashboard",
  "dashboard:show-invited-suppliers": "dashboard",
  "dashboard:show-owned-sites-count": "dashboard",
  "dashboard:show-pending-evaluations": "dashboard",
  "dashboard:show-suppliers-count": "dashboard",
  "dashboard:show-unevaluated-documents": "dashboard",
  "dashboard:show-working-sites-count": "dashboard",
  //Sites
  "company_sites:show": "sites",
  //Working Sites
  "company_workingsites:show": "worksites",
  //Company
  "company:ai": "ai",
  "company:show-details": "company",
  "company:edit-details": "records",
  "company:show-staff": "records",
  "company:edit-staff": "records",
  "company:show-company-docs": "general-documents",
  "company:add-new-company-doc": "general-documents",
  "company:edit-company-docs": "general-documents",
  "company:delete-company-doc": "general-documents",
  "company:download-docs": "general-documents",
  "company:show-company-docs-evaluation": "general-documents",
  "company:eval-company-docs": "general-documents",
  "company:show-company-doc-expiration": "general-documents",
  "company:set-company-doc-expiration": "general-documents",
  "company:show-preset": "document-presets",
  "company:edit-preset": "document-presets",
  "company:delete-preset": "document-presets",
  "company:show-doc-type": "document-types",
  "company:edit-doc-type": "document-types",
  "company:delete-doc-type": "document-types",
  "company:show-req-group": "requirements-groups",
  "company:add-req-group": "requirements-groups",
  "company:edit-req-group": "requirements-groups",
  "company:add-req-to-group": "requirements-groups",
  "company:delete-req-from-group": "requirements-groups",
  "company:delete-req-group": "requirements-groups",
  "company:show-badges": "requirements-groups",
  "company:add-badge": "badge",
  "company:delete-badge": "badge",
  "company:import-badge": "badge",
  "company:set-badge-status": "badge",
  "company:link-badge-resource": "badge",
  "company:unlink-badge-resource": "badge",
  //Resources - workers
  "resources:show-workers": "resources",
  "resources:add-worker": "workers",
  "resources:import-worker": "workers",
  "resources:delete-worker": "workers",
  "resources:show-worker-sites": "workers",
  "resources:show-worker-docs": "workers",
  "resources:add-worker-doc": "workers",
  "resources:edit-worker-docs": "workers",
  "resources:delete-worker-doc": "workers",
  "resources:download-worker-docs": "workers",
  "resources:show-worker-doc-expiration": "workers",
  "resources:set-worker-doc-expiration": "workers",
  "resources:show-worker-typology": "workers",
  "resources:edit-worker-typology": "workers",
  "resources:show-worker-docs-evaluation": "workers",
  "resources:show-worker-docs-evaluation-expiration": "workers",
  "resources:eval-worker-docs": "workers",
  //Resources - vehicles
  "resources:show-vehicles": "resources",
  "resources:add-vehicle": "vehicles",
  "resources:import-vehicle": "vehicles",
  "resources:show-vehicle-sites": "vehicles",
  "resources:show-vehicle-docs": "vehicles",
  "resources:add-vehicle-doc": "vehicles",
  "resources:edit-vehicle-docs": "vehicles",
  "resources:delete-vehicle-doc": "vehicles",
  "resources:download-vehicle-docs": "vehicles",
  "resources:show-vehicle-doc-expiration": "vehicles",
  "resources:set-vehicle-doc-expiration": "vehicles",
  "resources:show-vehicle-typology": "vehicles",
  "resources:edit-vehicle-typology": "vehicles",
  "resources:show-vehicle-docs-evaluation": "vehicles",
  "resources:show-vehicle-docs-evaluation-expiration": "vehicles",
  "resources:eval-vehicle-docs": "vehicles",
  "resources:convert-vehicle-to-machine": "vehicles",
  //Resources - machines
  "resources:show-machines": "resources",
  "resources:add-machine": "machines",
  "resources:import-machine": "machines",
  "resources:show-machine-sites": "machines",
  "resources:show-machine-docs": "machines",
  "resources:add-machine-doc": "machines",
  "resources:edit-machine-docs": "machines",
  "resources:delete-machine-doc": "machines",
  "resources:download-machine-docs": "machines",
  "resources:show-machine-doc-expiration": "machines",
  "resources:set-machine-doc-expiration": "machines",
  "resources:show-machine-typology": "machines",
  "resources:edit-machine-typology": "machines",
  "resources:show-machine-docs-evaluation": "machines",
  "resources:show-machine-docs-evaluation-expiration": "machines",
  "resources:eval-machine-docs": "machines",
  "resources:convert-machine-to-tool": "machines",
  //Resources - tools
  "resources:show-tools": "resources",
  "resources:add-tool": "tools",
  "resources:import-tool": "tools",
  "resources:show-tool-sites": "tools",
  "resources:show-tool-docs": "tools",
  "resources:add-tool-doc": "tools",
  "resources:edit-tool-docs": "tools",
  "resources:delete-tool-doc": "tools",
  "resources:download-tool-docs": "tools",
  "resources:show-tool-doc-expiration": "tools",
  "resources:set-tool-doc-expiration": "tools",
  "resources:show-tool-typology": "tools",
  "resources:edit-tool-typology": "tools",
  "resources:show-tool-docs-evaluation": "tools",
  "resources:show-tool-docs-evaluation-expiration": "tools",
  "resources:eval-tool-docs": "tools",
  "resources:convert-tool-to-machine": "tools",
  //Resources - chemicals
  "resources:show-chemicals": "resources",
  "resources:add-chemical": "chemicals",
  "resources:import-chemical": "chemicals",
  "resources:show-chemical-sites": "chemicals",
  "resources:show-chemical-docs": "chemicals",
  "resources:add-chemical-doc": "chemicals",
  "resources:edit-chemical-docs": "chemicals",
  "resources:delete-chemical-doc": "chemicals",
  "resources:download-chemical-docs": "chemicals",
  "resources:show-chemical-doc-expiration": "chemicals",
  "resources:set-chemical-doc-expiration": "chemicals",
  "resources:show-chemical-typology": "chemicals",
  "resources:edit-chemical-typology": "chemicals",
  "resources:show-chemical-docs-evaluation": "chemicals",
  "resources:show-chemical-docs-evaluation-expiration": "chemicals",
  "resources:eval-chemical-docs": "chemicals",
  //Suppliers
  "supplier:show": "suppliers",
  "supplier:create-derived-tenants": "suppliers",
  "supplier:show-details": "records",
  "supplier:show-documents": "records",
  "supplier:download-all": "records",
  "supplier:show-company-req-evaluation": "records",
  "supplier:eval-company-req": "records",
  "supplier:show-workers": "workers",
  "supplier:show-worker-docs": "workers",
  "supplier:download-worker-docs": "workers",
  "supplier:show-worker-req-evaluation": "workers",
  "supplier:eval-worker-req": "workers",
  "supplier:show-vehicles": "vehicles",
  "supplier:show-vehicle-docs": "vehicles",
  "supplier:download-vehicle-docs": "vehicles",
  "supplier:show-vehicle-req-evaluation": "vehicles",
  "supplier:eval-vehicle-req": "vehicles",
  "supplier:show-machines": "machines",
  "supplier:show-machine-docs": "machines",
  "supplier:download-machine-docs": "machines",
  "supplier:show-machine-req-evaluation": "machines",
  "supplier:eval-machine-req": "machines",
  "supplier:show-tools": "tools",
  "supplier:show-tool-docs": "tools",
  "supplier:download-tool-docs": "tools",
  "supplier:show-tool-req-evaluation": "tools",
  "supplier:eval-tool-req": "tools",
  "supplier:show-chemicals": "chemicals",
  "supplier:show-chemical-docs": "chemicals",
  "supplier:download-chemical-docs": "chemicals",
  "supplier:show-chemical-req-evaluation": "chemicals",
  "supplier:eval-chemical-req": "chemicals",

  //Calendar
  "calendar:show-expiration": "calendar",

  //Settings
  "settings:show-roles": "settings",
  "settings:add-role": "settings",
  "settings:edit-role": "settings",
  "settings:delete-role": "settings",
  "settings:show-users": "settings",
  "settings:invite-user": "settings",
  "settings:delete-user": "settings",
  "settings:show-user-general-role": "settings",
  "settings:edit-user-general-role": "settings",
  "settings:show-user-site-roles": "settings",
  "settings:add-user-site-roles": "settings",
  "settings:delete-user-site-roles": "settings",
  "settings:show-user-working-site-roles": "settings",
  "settings:add-user-working-site-roles": "settings",
  "settings:delete-user-working-site-roles": "settings",
  "settings:show-user-state": "settings",
  "settings:edit-user-state": "settings",
  "settings:edit-webhooks": "settings",
  "settings:edit-custom-fields": "settings",
  //export
  "other:general": "export",
  "other:create-derived-tenants": "export",
  //site
  "sites:guardianship": "guardianship",
  "sites:show-details": "details",
  "sites:edit-details": "details",
  "sites:show-state": "details",
  "sites:edit-state": "details",
  "sites:show-staff": "details",
  "sites:edit-staff": "details",
  "sites:show-settings": "details",
  "sites:edit-general-settings": "details",
  "sites:edit-users-role": "details",
  //Site General Documents
  "sites:show-general-docs": "documents",
  "sites:add-general-docs": "documents",
  "sites:edit-general-docs": "documents",
  "sites:delete-general-docs": "documents",
  "sites:show-owner-req": "company-documents",
  "sites:add-owner-req": "company-documents",
  "sites:edit-owner-req": "company-documents",
  "sites:delete-owner-req": "company-documents",
  "sites:download-owner-req": "company-documents",
  "sites:show-owner-req-evaluation": "company-documents",
  "sites:eval-owner-req": "company-documents",
  "sites:owner-chat": "company-documents",
  "sites:show-global-owner-evaluation": "company-documents",
  "sites:set-global-owner-evaluation": "company-documents",
  "sites:set-owner-state": "company-documents",
  "sites:evaluations-overview" : "overview-documents",

  "sites:show-workers": "workers",
  "sites:add-worker": "workers",
  "sites:delete-worker": "workers",
  "sites:show-worker-req": "workers",
  "sites:add-worker-req": "workers",
  "sites:edit-worker-req": "workers", "sites:delete-worker-req": "workers",
  "sites:download-worker-req": "workers",
  "sites:show-worker-req-evaluation": "workers",
  "sites:show-worker-req-evaluation-expiration": "workers",
  "sites:eval-worker-req": "workers",
  "sites:show-global-worker-evaluation": "workers",
  "sites:set-global-worker-evaluation": "workers",
  "sites:worker-chat": "workers",

  "sites:show-vehicles": "vehicles",
  "sites:add-vehicle": "vehicles",
  "sites:delete-vehicle": "vehicles",
  "sites:show-vehicle-req": "vehicles",
  "sites:add-vehicle-req": "vehicles",
  "sites:edit-vehicle-req": "vehicles",
  "sites:delete-vehicle-req": "vehicles",
  "sites:download-vehicle-req": "vehicles",
  "sites:show-vehicle-req-evaluation": "vehicles",
  "sites:show-vehicle-req-evaluation-expiration": "vehicles",
  "sites:eval-vehicle-req": "vehicles",
  "sites:show-global-vehicle-evaluation": "vehicles",
  "sites:set-global-vehicle-evaluation": "vehicles",
  "sites:vehicle-chat": "vehicles",

  "sites:show-machines": "machines",
  "sites:add-machine": "machines",
  "sites:delete-machine": "machines",
  "sites:show-machine-req": "machines",
  "sites:add-machine-req": "machines",
  "sites:edit-machine-req": "machines",
  "sites:delete-machine-req": "machines",
  "sites:download-machine-req": "machines",
  "sites:show-machine-req-evaluation": "machines",
  "sites:show-machine-req-evaluation-expiration": "machines",
  "sites:eval-machine-req": "machines",
  "sites:show-global-machine-evaluation": "machines",
  "sites:set-global-machine-evaluation": "machines",
  "sites:machine-chat": "machines",

  "sites:show-tools": "tools",
  "sites:add-tool": "tools",
  "sites:delete-tool": "tools",
  "sites:show-tool-req": "tools",
  "sites:add-tool-req": "tools",
  "sites:edit-tool-req": "tools",
  "sites:delete-tool-req": "tools",
  "sites:download-tool-req": "tools",
  "sites:show-tool-req-evaluation": "tools",
  "sites:show-tool-req-evaluation-expiration": "tools",
  "sites:eval-tool-req": "tools",
  "sites:show-global-tool-evaluation": "tools",
  "sites:set-global-tool-evaluation": "tools",
  "sites:tool-chat": "tools",

  "sites:show-chemicals": "chemicals",
  "sites:add-chemical": "chemicals",
  "sites:delete-chemical": "chemicals",
  "sites:show-chemical-req": "chemicals",
  "sites:add-chemical-req": "chemicals",
  "sites:edit-chemical-req": "chemicals",
  "sites:delete-chemical-req": "chemicals",
  "sites:download-chemical-req": "chemicals",
  "sites:show-chemical-req-evaluation": "chemicals",
  "sites:show-chemical-req-evaluation-expiration": "chemicals",
  "sites:eval-chemical-req": "chemicals",
  "sites:show-global-chemical-evaluation": "chemicals",
  "sites:set-global-chemical-evaluation": "chemicals",
  "sites:chemical-chat": "chemicals",

  "sites:show-suppliers": "suppliers",
  "sites:invite-supplier": "suppliers",
  "sites:delete-supplier": "suppliers",
  "sites:show-supplier-state": "suppliers",
  "sites:set-supplier-state": "suppliers",
  "sites:supplier-chat": "suppliers",
  "sites:show-global-supplier-evaluation": "suppliers",
  "sites:set-global-supplier-evaluation": "suppliers",
  "sites:show-supplier-company-req": "suppliers",
  "sites:add-supplier-company-req": "suppliers",
  "sites:edit-supplier-company-req": "suppliers",
  "sites:delete-supplier-company-req": "suppliers",
  "sites:download-supplier-company-req": "suppliers",
  "sites:show-supplier-company-req-evaluation": "suppliers",
  "sites:show-supplier-company-req-evaluation-expiration": "suppliers",
  "sites:eval-supplier-company-req": "suppliers",
  "sites:show-supplier-site-req": "suppliers",
  "sites:add-supplier-site-req": "suppliers",
  "sites:edit-supplier-site-req": "suppliers",
  "sites:delete-supplier-site-req": "suppliers",
  "sites:download-supplier-site-req": "suppliers",
  "sites:show-supplier-site-req-evaluation": "suppliers",
  "sites:show-supplier-site-req-evaluation-expiration": "suppliers",
  "sites:eval-supplier-site-req": "suppliers",

  "sites:show-requirements": "requirements",
  "sites:add-requirement": "requirements",
  "sites:edit-requirement": "requirements",
  "sites:delete-requirement": "requirements",
  "sites:show-badges": "requirements",

  "sites:link-badge": "access",
  "sites:unlink-badge": "access",
  "sites:link-resource-to-badge": "access",
  "sites:print-badges": "access",
  "sites:show-badge-state": "access",
  "sites:set-badge-state": "access",
  "sites:show-badge-records": "access",
  "sites:add-badge-manual-record": "access",
  "sites:delete-badge-record": "access",
  "sites:show-physical-badge-readers": "access",
  "sites:create-physical-badge-reader": "access",
  "sites:delete-physical-badge-reader": "access",
  "sites:edit-physical-badge-reader": "access",
  "sites:show-physical-badge-reader-state": "access",
  "sites:set-physical-badge-reader-state": "access",
  "sites:show-physical-badge-reader-records": "access",
  "sites:add-physical-badge-reader-manual-record": "access",
  "sites:delete-physical-badge-reader-record": "access",
  "sites:show-virtual-badge-readers": "access",
  "sites:create-virtual-badge-reader": "access",
  "sites:delete-virtual-badge-reader": "access",
  "sites:edit-virtual-badge-reader": "access",
  "sites:show-virtual-badge-reader-state": "access",
  "sites:set-virtual-badge-reader-state": "access",
  "sites:show-virtual-badge-reader-records": "access",
  "sites:add-virtual-badge-reader-manual-record": "access",
  "sites:delete-virtual-badge-reader-record": "access",
  "sites:view-clocking-history-exactly": "access",
  "sites:view-clocking-history-rounded": "access",
  "sites:view-clocking-history-total": "access",
  "sites:view-clocking-history-day": "access",

  "sites:show-company-pending-evaluations": "pending-evaluations",
  "sites:download-company-pending-req": "pending-evaluations",
  "sites:approve-company-pending-evaluation": "pending-evaluations",
  "sites:show-worker-pending-evaluations": "pending-evaluations",
  "sites:download-worker-pending-req": "pending-evaluations",
  "sites:approve-worker-pending-evaluation": "pending-evaluations",
  "sites:show-vehicle-pending-evaluations": "pending-evaluations",
  "sites:download-vehicle-pending-req": "pending-evaluations",
  "sites:approve-vehicle-pending-evaluation": "pending-evaluations",
  "sites:show-machine-pending-evaluations": "pending-evaluations",
  "sites:download-machine-pending-req": "pending-evaluations",
  "sites:approve-machine-pending-evaluation": "pending-evaluations",
  "sites:show-tool-pending-evaluations": "pending-evaluations",
  "sites:download-tool-pending-req": "pending-evaluations",
  "sites:approve-tool-pending-evaluation": "pending-evaluations",
  "sites:show-chemical-pending-evaluations": "pending-evaluations",
  "sites:download-chemical-pending-req": "pending-evaluations",
  "sites:approve-chemical-pending-evaluation": "pending-evaluations",

  "sites:show-reports": "reports",
  "sites:add-report": "reports",
  "sites:delete-report": "reports",

  //Working site
  "worksite:show-details" : "details",
  "worksite:show-general-docs" : "details",
  "worksite:show-staff": "details",
  "worksite:show-settings": "details",
  "worksite:edit-users-role": "details",
  "worksite:show-workers": "workers",
  "worksite:add-worker": "workers",
  "worksite:delete-worker": "workers",
  "worksite:show-worker-req": "workers",
  "worksite:add-worker-req": "workers",
  "worksite:edit-worker-req": "workers",
  "worksite:download-worker-req": "workers",
  "worksite:show-worker-req-evaluation": "workers",
  "worksite:show-worker-req-evaluation-expiration": "workers",
  "worksite:show-global-worker-evaluation": "workers",
  "worksite:worker-chat": "workers",

  "worksite:show-vehicles": "vehicles",
  "worksite:add-vehicle": "vehicles",
  "worksite:delete-vehicle": "vehicles",
  "worksite:show-vehicle-req": "vehicles",
  "worksite:add-vehicle-req": "vehicles",
  "worksite:edit-vehicle-req": "vehicles",
  "worksite:download-vehicle-req": "vehicles",
  "worksite:show-vehicle-req-evaluation": "vehicles",
  "worksite:show-vehicle-req-evaluation-expiration": "vehicles",
  "worksite:show-global-vehicle-evaluation": "vehicles",
  "worksite:vehicle-chat": "vehicles",

  "worksite:show-machines": "machine",
  "worksite:add-machine": "machine",
  "worksite:delete-machine": "machine",
  "worksite:show-machine-req": "machine",
  "worksite:add-machine-req": "machine",
  "worksite:edit-machine-req": "machine",
  "worksite:download-machine-req": "machine",
  "worksite:show-machine-req-evaluation": "machine",
  "worksite:show-machine-req-evaluation-expiration": "machine",
  "worksite:show-global-machine-evaluation": "machine",
  "worksite:machine-chat": "machine",

  "worksite:show-tools": "tools",
  "worksite:add-tool": "tools",
  "worksite:delete-tool": "tools",
  "worksite:show-tool-req": "tools",
  "worksite:add-tool-req": "tools",
  "worksite:edit-tool-req": "tools",
  "worksite:download-tool-req": "tools",
  "worksite:show-tool-req-evaluation": "tools",
  "worksite:show-tool-req-evaluation-expiration": "tools",
  "worksite:show-global-tool-evaluation": "tools",
  "worksite:tool-chat": "tools",

  "worksite:show-chemicals": "chemicals",
  "worksite:add-chemical": "chemicals",
  "worksite:delete-chemical": "chemicals",
  "worksite:show-chemical-req": "chemicals",
  "worksite:add-chemical-req": "chemicals",
  "worksite:edit-chemical-req": "chemicals",
  "worksite:download-chemical-req": "chemicals",
  "worksite:show-chemical-req-evaluation": "chemicals",
  "worksite:show-chemical-req-evaluation-expiration": "chemicals",
  "worksite:show-global-chemical-evaluation": "chemicals",
  "worksite:chemical-chat": "chemicals",

  "worksite:show-req": "documents",
  "worksite:add-req": "documents",
  "worksite:edit-req": "documents",
  "worksite:download-req": "documents",
  "worksite:show-req-evaluation": "documents",
  "worksite:show-global-evaluation": "documents",
  "worksite:chat": "documents",
  "worksite:show-subcontractors": "subcontractors",
  "worksite:invite-subcontractor": "subcontractors"
}

export const getSectionPermissions = (
  // this string represent the substring before the semicolon in the key (e.g. "worksite:tool-chat": "tools", in this case is worksite)
  main: string,
  // this string represent the substring after the semicolon (e.g. "worksite:tool-chat": "tools", in this case is tools)
  sub: string | string[] = null
) => {
  return (Object.keys(permissionSectionMap) as Permission[]).filter(
    (permission) => {
      return permission.startsWith(main) && (sub ? verifySub(sub, permission) : true);
    }
  );
}

const verifySub = (sub: string | string[], permission: Permission) => {
  return Array.isArray(sub)
    ? sub.includes(permissionSectionMap[permission])
    : permissionSectionMap[permission] === sub;
}
