import { useTranslation } from "react-i18next";
import {TbBuildingFactory} from "react-icons/tb";
import WorkerIcon from "../Common/icons/WorkerIcon";
import VehicleIcon from "../Common/icons/VehicleIcon";
import MachineIcon from "../Common/icons/MachineIcon";
import ToolIcon from "../Common/icons/ToolIcon";
import ChemicalIcon from "../Common/icons/ChemicalIcon";
import {GiCrane} from "react-icons/gi";
import {Permission} from "../Permissions/Permissions";
import {NavItemProps} from "../Common/NavItem";
import SubNavBar from "../Common/SubNavBar";
import { useAuth } from "../../providers/Auth0JWTProvider";


const CustomFieldsSubNav = () => {
  const { t } = useTranslation("navbar");
  const { company: { type } } = useAuth();

  const items: NavItemProps[] = [
    {
      icon: TbBuildingFactory,
      path: "/settings/custom-fields/company",
      name: t("sub.companies"),
      permissions: [Permission.Settings_EditCustomFields],
    },
    {
      Icon: WorkerIcon,
      path: "/settings/custom-fields/worker",
      name: t("sub.workers"),
      permissions: [Permission.Settings_EditCustomFields],
    },
    {
      Icon: VehicleIcon,
      path: "/settings/custom-fields/vehicle",
      name: t("sub.vehicles"),
      permissions: [Permission.Settings_EditCustomFields],
    },
    {
      Icon: MachineIcon,
      path: "/settings/custom-fields/machine",
      name: t("sub.machines"),
      permissions: [Permission.Settings_EditCustomFields],
    },
    {
      Icon: ToolIcon,
      path: "/settings/custom-fields/tool",
      name: t("sub.tools"),
      permissions: [Permission.Settings_EditCustomFields],
    },
    {
      Icon: ChemicalIcon,
      path: "/settings/custom-fields/chemical",
      name: t("sub.chemicals"),
      permissions: [Permission.Settings_EditCustomFields],
    },
    ...(type === "master" ? [
      {
        icon: GiCrane,
        path: "/settings/custom-fields/site",
        name: t("sub.site"),
        permissions: [Permission.Settings_EditCustomFields],
      }
    ] : []),
  ];

  return <SubNavBar items={items} />;
};

export default CustomFieldsSubNav;
