import ExportFiles from '../../../../domain/interactors/export/exportFiles';
import ExportItemColumns from '../../../../domain/interactors/export/exportItemColumns';
import { makeExportRepository } from './repositories';

export const makeExport = () => {
	return new ExportFiles(makeExportRepository());
};

export const makeExportItemColumns = () => {
	return new ExportItemColumns(makeExportRepository());
}
