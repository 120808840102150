import {FormControl, FormErrorMessage, Td, Tr, } from "@chakra-ui/react";
import DeleteButton from "../../../components/Common/table/DeleteButton";
import { useTranslation } from "react-i18next";
import {Controller, useFormContext} from "react-hook-form";
import Select from "react-select";
import MultiLanguageTextInput from "./MultiLanguageTextInput";
import {FormValues} from "../../../hooks/CustomFields/useCustomFieldsViewModel";

interface TranslationRowProps {
  index: number;
  remove: (index: number) => void;
  usedKeys: string[];
  allAllowedKeys: string[];
}
const TranslationRow = ({ index, remove, usedKeys, allAllowedKeys }: TranslationRowProps) => {
  const { t } = useTranslation("settings");
  const {
    control,
    formState: { errors: { records: errors } },
  } = useFormContext<FormValues>();

  const usedKeysWithoutCurrent = usedKeys.filter((_, recordIndex) => recordIndex !== index)

  const allowedKeys = allAllowedKeys.filter((key) => !usedKeysWithoutCurrent.includes(key));

  const getLabel = (value: string) => {
    const [type, key] = value.split("_");
    return t(
      ["vatCountryCode", "fiscalCodeCountryCode"].includes(key) ? `${key}.title` : key, {ns: type === "company" || key === "fiscalCodeCountryCode" ? "companies" : `${type}s`})
  }
  return (
    <Tr>
      <Td w="calc(50% - 30px)">
        <FormControl isInvalid={!!errors?.[index]?.key?.message}>
          <Controller
            control={control}
            name={`records.${index}.key`}
            rules={{required: true}}
            render={({ field: { onChange, onBlur, value, name: fieldName, ref }}) => (
              <Select
                isMulti={false}
                options={allowedKeys.map((value) => ({value, label: getLabel(value)}))}
                menuPortalTarget={document.getElementById("root")}
                menuPosition="fixed"
                menuPlacement="bottom"
                onBlur={onBlur}
                name={fieldName}
                ref={ref}
                isClearable={false}
                onChange={(option) => {
                  onChange(option?.value);
                }}
                value={{value, label: getLabel(value)}}
              />
            )}
          />
          <FormErrorMessage>{errors?.[index]?.key?.message}</FormErrorMessage>
        </FormControl>
      </Td>
      <Td w="calc(50% - 30px)">
        <MultiLanguageTextInput index={index} namespace="records" />
      </Td>
      <Td w="60px">
        <DeleteButton onClick={() => remove(index)} />
      </Td>
    </Tr>
  );
};

export default TranslationRow;