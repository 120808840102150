import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { GetTenantsFilters } from "../../components/Views/common/ChangeTenantModal";
import { CompaniesViewModel } from "../../viewmodels/company/CompaniesList";

const useChangeTenantViewModel = () => {
    const viewModel = useMemo(()=>new CompaniesViewModel(),[]);
    const [sort, setSort] = useState<SortMeta>();
    const [search, setSearch] = useState<string>();
    const [filters, setFilters] = useState<GetTenantsFilters>({
        licenseState: "enabled", 
      });

    const [sortDerived, setSortDerived] = useState<SortMeta>();
    const [searchDerived, setSearchDerived] = useState<string>();
    const [filtersDerived, setFiltersDerived] = useState<GetTenantsFilters>();

    const getCompanies = useInfiniteQuery(
        ["user-tenants", filters, sort, search],
        async ({ pageParam = 1 }) => {
          const companyFilters = search ? { ...filters, search } : filters;
          const tenants = viewModel.getCompanies(companyFilters, sort, pageParam);
          return await tenants;
        },
        {
          getNextPageParam: (lastPage, pages) => {
            if (lastPage?.length === 25) {
              return pages.length + 1;
            }
          }
        },
      );

    const getDerivedCompanies = useInfiniteQuery(
    ["user-derived-tenants", filtersDerived, sortDerived, searchDerived],
    async ({ pageParam = 1 }) => {
        const companyDerivedFilters = searchDerived ? { ...filtersDerived, searchDerived } : filtersDerived;
        const tenantsDerived = viewModel.getCompanies(companyDerivedFilters, sortDerived, pageParam, true);
        return await tenantsDerived;
    },
    {
        getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
            return pages.length + 1;
        }
        }
    },
    );

    const tenants = getCompanies.data?.pages?.flat() ?? [];
    const tenantsDerived = getDerivedCompanies.data?.pages?.flat() ?? [];

    return {
        tenants,
        sort, setSort,
        search, setSearch,
        filters, setFilters,
        tenantsHasNextPage: getCompanies.hasNextPage, 
        tenantsGetNextPage: getCompanies.fetchNextPage, 
        tenantsIsLoading: getCompanies.isLoading,

        tenantsDerived,
        tenantsDerivedRefetch: getDerivedCompanies.refetch,
        sortDerived, setSortDerived,
        searchDerived, setSearchDerived,
        filtersDerived, setFiltersDerived,
        tenantsDerivedHasNextPage: getDerivedCompanies.hasNextPage, 
        tenantsDerivedGetNextPage: getDerivedCompanies.fetchNextPage, 
        tenantsDerivedIsLoading: getDerivedCompanies.isLoading
    }
}

export default useChangeTenantViewModel;