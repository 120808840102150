import {
  Button,
  Flex,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState, MouseEvent } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FiAlertTriangle, FiSettings, FiUpload } from "react-icons/fi";
import File from "../../../../domain/entities/file";
import ImportInfo from "../../../../domain/entities/importInfo";
import { COLORS } from "../../../assets/theme/colors";
import LoadingView from "../../../screens/Common/LoadingView";
import DragDropFileView from "../../Common/DragDropFileView";
import BaseModal from "../../Common/alerts/BaseModal";
import ExcelJS from "exceljs";

type ResourceImportModalProps = {
  resourceTypeTranslation: string;
  resourceType: string;
  title: string;
  onClose: () => void;
  onConfirm: (file: File) => Promise<ImportInfo>;
  error: string;
  typologies: string[];
};
const TEMPLATE_FIELDS_MAP = {
  worker: {
    fileName: "lavoratori_template.xlsx",
    columns: ['Nome', 'Cognome', 'Codice fiscale', 'Nazione codice fiscale', 'Data di nascita', 'Luogo di nascita', 'Email', 'Telefono', 'Mansione', 'Data di assunzione', 'Tipologia'],
    typologiesColumn: "K"
  },
  vehicle:{
    fileName: "veicoli_template.xlsx",
    columns: ['Nome', 'Targa', 'Modello', 'Tipologia'],
    typologiesColumn: "D"
  },
  machine:{
    fileName: "macchinari_template.xlsx",
    columns: ['Nome', 'Modello', 'Targa', 'Tipologia'],
    typologiesColumn: "D"
  },
  tool: {
    fileName: "attrezzature_template.xlsx",
    columns: ['Nome', "Seriale", "Tipologia"],
    typologiesColumn: "C"
  },
  chemical: {
    fileName: "prodotti_chimici_template.xlsx",
    columns: ['Nome', "Tipologia"],
    typologiesColumn: "B"
  },
};

const ResourceImportModal = ({
  onClose,
  onConfirm,
  resourceType,
  title,
  resourceTypeTranslation,
  error,
  typologies,
}: ResourceImportModalProps) => {
  const { t } = useTranslation("common");
  const [file, setFile] = useState<File | null>(null);
  const [result, setResult] = useState<ImportInfo>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onUpload = async () => {
    if (file) {
      setIsLoading(true);
      const result = await onConfirm(file);
      setIsLoading(false);
      setResult(result);
    }
  };

  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  const handleConfirm = () => {
    if (!result && !error) {
      return onUpload;
    } else {
      return undefined;
    }
  };

  const generateExcelTemplate = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const template = TEMPLATE_FIELDS_MAP[resourceType]

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Template');
    const headerRow = worksheet.addRow(template.columns);

    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4472C4' },
      };
      cell.font = {
        color: { argb: 'FFFFFF' },
        bold: true,
      };
    });

    const startRow = 2;
    const endRow = 1000;

    for (let row = startRow; row <= endRow; row++) {
      worksheet.getCell(`${template.typologiesColumn}${row}`).dataValidation = {
        type: 'list',
        allowBlank: false,
        formulae: [`"${typologies.join(',')}"`],
        showErrorMessage: true,
        errorStyle: 'error',
        errorTitle: 'Tipologia non valida',
        error: 'Seleziona una tipologia valida dalla lista.',
      };
      if (resourceType === "worker") {
        worksheet.getCell(`D${row}`).dataValidation = {
          type: 'list',
          allowBlank: false,
          formulae: [`"IT,-"`],
          showErrorMessage: true,
          errorStyle: 'error',
          errorTitle: 'Nazione codice fiscale non valida',
          error: 'Seleziona una nazione valida dalla lista.',
        };
      }
    }

    worksheet.columns.forEach((column) => {
      let maxLength = 0;

      if (column.header) {
        maxLength = column.header.toString().length;
      }

      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : '';
        if (cellValue.length > maxLength) {
          maxLength = cellValue.length;
        }
      });

      column.width = maxLength + 5;
    });

    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = template.fileName;  // Dynamic filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);  // Cleanup
    } catch (err) {
      console.error('Error generating Excel file:', err);
    }
  };

  return (
    <BaseModal
      size={"3xl"}
      title={title}
      onClose={onClose}
      onCancelLabel={result ? t("close") : undefined}
      onConfirm={handleConfirm}
      footer={
        <>
          {!error && (
            <Button
              colorScheme="blue"
              disabled={!file}
              onClick={result ? onClose : onUpload}
              isLoading={isLoading}
            >
              {t(result ? "close" : "confirm", { ns: "common" })}
            </Button>
          )}
          {!result && (
            <Button colorScheme="red" onClick={onClose}>
              {t("cancel", { ns: "common" })}
            </Button>
          )}
        </>
      }
    >
      <Flex flexDirection={"column"}>
        {isLoading && (
          <Flex padding={5} justifyContent={"center"} alignItems={"center"}>
            <LoadingView width="100" height="100" />
          </Flex>
        )}

        {result && !isLoading && (
          <>
            <List spacing={3}>
              <ListItem>
                <ListIcon as={FiUpload} color={COLORS.sikuroBlue} />
                {`${t("uploadedResources", { ns: "common" })} : ${result?.rows}`}
              </ListItem>
              <ListItem>
                <ListIcon as={FiSettings} color={COLORS.sikuroBlue} />
                {`${t("processedResources", { ns: "common" })} : ${result?.resources}`}
              </ListItem>
              {result.errors.length && (
                <ListItem>
                  <ListIcon as={FiAlertTriangle} color={COLORS.red} />
                  {t("errorsProcessing", { ns: "common" })} :
                  {result &&
                    result.errors.map((error, idx) => (
                      <Text px={2} key={idx}>
                        {t("errorsInfo", {
                          ns: "common",
                          error: error.col,
                          row: error.row,
                        })}
                      </Text>
                    ))}
                </ListItem>
              )}
            </List>
          </>
        )}
        {!result && !isLoading && !error && (
          <>
            <Text py={4}>
              <Trans
                i18nKey="importMessage"
                target={resourceTypeTranslation}
                components={{
                  a: (
                    <Link
                      sx={{ color: COLORS.sikuroBlue }}
                      py={2}
                      onClick={generateExcelTemplate}
                    />
                  ),
                }}
              />
            </Text>
            <DragDropFileView
              onUploaded={setFile}
              acceptedExtensions=".xlsx,.xls"
            />
          </>
        )}
        {error && !isLoading && !result && (
          <Text>
            <Trans
              i18nKey={error}
              ns={"common"}
              components={{
                a: (
                  <Link
                    sx={{ color: COLORS.sikuroBlue }}
                    py={2}
                    onClick={generateExcelTemplate}
                  />
                ),
              }}
            />
          </Text>
        )}
      </Flex>
    </BaseModal>
  );
};

export default ResourceImportModal;
