import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import {CustomField, CustomFieldWithLang} from "../../../domain/entities/customFields";
import {CustomFieldsViewModel} from "../../viewmodels/customFields/CustomFieldsViewModel";
import {LANGUAGE, TranslationRecord} from "../../../domain/entities/translationRecord";

export type FormValues = {
  customFields: CustomFieldWithLang[];
  records: TranslationRecord[];
};

export type UseCustomFieldsViewModel = ReturnType<typeof useCustomFieldsViewModel>

const useCustomFieldsViewModel = () => {
  const { companyId, updateTranslations } = useAuth();
  const viewModel = new CustomFieldsViewModel(companyId);

  const getCustomFields = useQuery(
    ["get-custom-fields", companyId],
    async () => await viewModel.getCustomFields(),
  );

  const getTranslations = useQuery(
    ["get-translation-records", companyId],
    async () => await viewModel.getTranslations(),
  );

  const setFields = useMutation(async ({customFields, records}: FormValues) => {

    const mappedRecords = records.filter(r => !r.key.split("_")[0].endsWith("Custom")).map((record) => ({
        ...record,
        lang:  Object.values(LANGUAGE).reduce((obj, lang) => {
          obj[lang] = record.lang[lang] === "" ? record.lang[LANGUAGE.IT] : record.lang[lang];
          return obj;
        }, {})
      } as TranslationRecord))
      const customRecords: TranslationRecord[] = []

      const mappedCustomFields = customFields.map(({lang, ...field}) => {
        const key = field.key ? field.key : slugify(lang.it);
        const langValues = Object.values(LANGUAGE).reduce((obj, lng) => {
          obj[lng] = lang[lng] === "" ? lang[LANGUAGE.IT] : lang[lng];
          return obj;
        }, {} as TranslationRecord["lang"])
        customRecords.push({ key: `${field.subject}Custom_${key}`, lang: langValues });
        return ({ ...field, key })
      })

      await Promise.all([
        viewModel.setCustomFields(mappedCustomFields),
        viewModel.setTranslations([...mappedRecords, ...customRecords])
      ]);
    },
    {
      onSuccess: async () => {
        await Promise.all([
          getCustomFields.refetch(),
          getTranslations.refetch(),
          updateTranslations(companyId)
        ])
      },
    },
  );

  const getCustomFieldLang = (field: CustomField) => {
    const defaultLang = Object.values(LANGUAGE).reduce((obj, lang) => {
      obj[lang] = "";
      return obj;
    }, {}) as TranslationRecord['lang'];
    return getTranslations.data?.find((record) => record.key === `${field.subject}Custom_${field.key}`)?.lang ?? defaultLang;
  }

  return {
    customFields: {
      data: getCustomFields.data?.map((field) => ({...field, lang: getCustomFieldLang(field)})),
      status: getCustomFields.status,
      error: getCustomFields.error?.toString(),
    },
    setFields: {
      mutate: setFields.mutateAsync,
      status: setFields.status,
      error: setFields.error?.toString(),
    },
    translations: {
      data: getTranslations.data,
      status: getTranslations.status,
      error: getTranslations.error?.toString(),
    },
  };
};

function slugify(text) {
  return text
    .toLowerCase() // Convert to lowercase
    .trim() // Trim leading/trailing whitespace
    .replace(/[^\w\s-]/g, '') // Remove special characters (except hyphens and spaces)
    .replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : '')) // Convert spaces/hyphens to camelCase
}

export default useCustomFieldsViewModel;
