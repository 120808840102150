import { Tag } from "@chakra-ui/react";
import { ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ResourceListView from "../ResourceListView";
import Worker from "../../../../domain/entities/worker";
import { Permission } from "../../../components/Permissions/Permissions";
import FormTextField from "../../../components/Common/forms/FormTextField";
import FormSelectField from "../../../components/Common/forms/FormSelectField";
import { EditableFormFieldProps } from "../../../components/Common/forms/FormField";
import { WorkersListViewModel } from "../../../viewmodels/workers/WorkersListViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import {
  fiscalCodeCountries,
  validateIdentityCode,
} from "../../../../infrastructure/utilities/validator";
import FormImageField from "../../../components/Common/forms/FormImageField";
import { GetResourcesFilter } from "../../../../domain/repositories/workerRepository";
import FormDateField from "../../../components/Common/forms/FormDateField";
import {getFieldLabel} from "../../../../utils";
import CodiceFiscale from 'codice-fiscale-js';

const WorkersView = () => {
  const { t } = useTranslation("workers");
  const render = (column: string, value: string): React.ReactNode => {
    if (column !== "status") {
      return <>{value}</>;
    }

    return (
      <Tag colorScheme={value === "active" ? "green" : "red"}>{t(value)}</Tag>
    );
  };
  const columns = [
    { field: "lastName", type: "text", label: getFieldLabel(t, 'lastName', 'worker') },
    { field: "firstName", type: "text", label: getFieldLabel(t, 'firstName', 'worker') },
    { field: "fiscalCode", type: "text", label: getFieldLabel(t, 'fiscalCode', 'worker') },
    { field: "jobTitle", type: "text", label: getFieldLabel(t, 'jobTitle', 'worker') },
  ] as {
    field: keyof GetResourcesFilter;
    type: "text" | "tags";
    label: string;
  }[];

  const formMethods = useForm<Worker>({ mode: "all" });
  const { watch, setError, clearErrors, setValue, reset } = formMethods;
  const requiredRule = { required: t("requiredField", { ns: "common" }) };

  const firstName = watch("firstName");
  const lastName = watch("lastName");
  const dateOfBirth = watch("dateOfBirth");
  const placeOfBirth = watch("placeOfBirth");
  const fiscalCode = watch("fiscalCode");
  const gender = watch("gender");

  useEffect(() => {
    if (firstName && lastName && dateOfBirth && placeOfBirth && gender && !isForeignFiscalCode) {
      try {
        const dob = new Date(dateOfBirth);
        const cfObject = new CodiceFiscale({
          name: firstName,
          surname: lastName,
          gender: gender,
          day: dob.getDate(),
          month: dob.getMonth() + 1,
          year: dob.getFullYear(),
          birthplace: placeOfBirth,
          birthplaceProvincia: "",
        });
        setValue("fiscalCode", cfObject.cf);
        validateFiscalCode(cfObject.cf)
      } catch (error) {
        console.error("Error calculating fiscal code:", error);
      }
    }
  }, [firstName, lastName, dateOfBirth, placeOfBirth, gender, setValue]);

  useEffect(() => {
    if (
      typeof fiscalCode === "string" &&
      fiscalCode.length === 16 &&
      !(
        firstName &&
        lastName &&
        dateOfBirth &&
        placeOfBirth &&
        gender &&
        isForeignFiscalCode
      )
    ) {
      try {
        const cfObject = new CodiceFiscale(fiscalCode);
        const birthDate = new Date(
          cfObject.year,
          cfObject.month - 1,
          cfObject.day,
        );
        setValue("dateOfBirth", birthDate);
        setValue("placeOfBirth", cfObject.birthplace.nome);
      } catch (error) {
        console.error("Error calculating place and date birth:", error);
      }
    }
  },[setValue, fiscalCode])

  const fiscalCodeCountryCode = (
    watch("fiscalCodeCountryCode") ?? "-"
  ).toUpperCase();
  const isForeignFiscalCode = fiscalCodeCountryCode === "-";
  const validateFiscalCode = (code: string) => {
    let message = undefined;

    if (isForeignFiscalCode && code?.trim() === "") {
      message = t("fiscalCodeIsRequired", { ns: "companies" });
    } else if (!isForeignFiscalCode && !validateIdentityCode(code)) {
      message = t("notItalianFiscalCode", { ns: "companies" });
    }

    if (message) {
      setError("fiscalCode", { message });
      return message;
    }

    clearErrors("fiscalCode");
    return true;
  };

  const resetFiscalCode = () => {
    setValue("fiscalCode", "");
  };

  const emailRegex = new RegExp(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  );

  const handleChangeEmail = (email?: string) => {
    let message = undefined;
    if (email && !emailRegex.test(email)) {
      message = t("errors.invalidEmail", { ns: "supplier" });
    }

    if (message) {
      setError("email", { message });
      return message;
    }

    clearErrors("email");
    return true;
  };

  const fields: Array<ReactElement<Omit<EditableFormFieldProps, "isEditing">>> =
    [
      <FormTextField
        key="firstName"
        label={getFieldLabel(t, 'firstName', 'worker')}
        name="firstName"
        rules={requiredRule}
      />,
      <FormTextField
        key="lastName"
        label={getFieldLabel(t, 'lastName', 'worker')}
        name="lastName"
        rules={requiredRule}
      />,
      <FormSelectField
        key="gender"
        onChange={(e) => setValue("gender", e.target.value)}
        label={getFieldLabel(t, "gender", 'worker')}
        name="gender"
        rules={(!fiscalCode) && requiredRule}
        options={[
          { id: '-', name: '-' },
          { id: 'M', name: t('male') },
          { id: 'F', name: t('female') }
        ]}
      />,
      <FormDateField
        key="dateOfBirth"
        name="dateOfBirth"
        label={getFieldLabel(t, "dateOfBirth", 'worker')}
        rules={requiredRule}
      />,
      <FormTextField
        key="placeOfBirth"
        label={getFieldLabel(t, "placeOfBirth", 'worker')}
        name="placeOfBirth"
        rules={(!fiscalCode) && requiredRule}
      />,
      <FormSelectField
        key="fiscalCodeCountryCode"
        onChange={resetFiscalCode}
        name="fiscalCodeCountryCode"
        label={getFieldLabel(t, "fiscalCodeCountryCode", 'worker', 'companies', "fiscalCodeCountryCode.title")}
        displayValue={t(
          `fiscalCodeCountryCode.${watch("fiscalCodeCountryCode")}`,
          { ns: "companies" },
        )}
        options={fiscalCodeCountries.map((c) => {
          return {
            id: c.id,
            name: t(`fiscalCodeCountryCode.${c.name}`, { ns: "companies" }),
          };
        })}
      />,
      <FormTextField
        key="fiscalCode"
        label={getFieldLabel(t, "fiscalCode", "worker")}
        name="fiscalCode"
        rules={{ validate: validateFiscalCode }}
      />,
      <FormTextField
        key="email"
        label={getFieldLabel(t, "email", "worker")}
        name="email"
        rules={{ validate: handleChangeEmail }}
        hideRequiredMark
      />,
      <FormTextField
        key="phone"
        label={getFieldLabel(t, "phone", 'worker')}
        name="phone"
        type="number"
      />,
      <FormTextField
        key="jobTitle"
        label={getFieldLabel(t, 'jobTitle', 'worker')}
        name="jobTitle" />,
      <FormDateField
        key="dateOfEmployment"
        name="dateOfEmployment"
        label={getFieldLabel(t, 'dateOfEmployment', 'worker')}
      />,
      <FormImageField
        key="photo"
        label={getFieldLabel(t, "photo", "worker")}
        name="photo"
        colSpan={2}
      />,
    ];

  return (
    <ResourceListView<Worker>
      type={DocumentTypeCategory.WORKER}
      viewModel={new WorkersListViewModel()}
      columns={columns}
      cellRender={render}
      namespace="workers"
      permissions={{
        add: Permission.Resources_AddWorker,
        delete: Permission.Resources_DeleteWorker,
        import: Permission.Resources_ImportWorker,
        showResourceSites: Permission.Resources_ShowWorkerSites,
        download: Permission.Resources_DownloadWorkerDocs,
        showCopyLastEvaluation: Permission.Resources_EvalWorkerDocs,
      }}
      formMethods={formMethods}
      createFields={fields}
      viewDetailsPermissions={[Permission.Resources_ShowWorkersDetails]}
    />
  );
};

export default WorkersView;
