import {RequirementSubject} from "./requirementSubject.enum";
import {LANGUAGE} from "./translationRecord";

export type CustomField = {
  key: string;
  type: FieldType;
  subject: RequirementSubject;
}

export type CustomFieldWithLang = CustomField & {
  lang: {
    [key in LANGUAGE]: string;
  }
}

export enum FieldType {
  TEXT = 'text',
  DATE = 'date',
  NUMBER = 'number',
}