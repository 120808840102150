import {
  makeGetTranslationRecords,
  makeSetTranslations
} from "../../../core/app/factory/companies/usecases";
import {TranslationRecord} from "../../../domain/entities/translationRecord";

class TranslationsViewModel {
	private getTranslationsUseCase = makeGetTranslationRecords();
	private setTranslationsUseCase = makeSetTranslations();

	constructor(protected companyId: string) {}

	async getTranslations(): Promise<TranslationRecord[]> {
		return await this.getTranslationsUseCase.Call({ companyId: this.companyId });
	}

	async setTranslations($records: TranslationRecord[]) {
		await this.setTranslationsUseCase.Call({ companyId: this.companyId, records: $records });
	}
}

export { TranslationsViewModel };
