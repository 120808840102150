import { Flex, Tbody, Td, Th, Thead, Tr, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {  useParams } from "react-router-dom";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { ResourceEvaluationState } from "../../../domain/entities/resourceEvaluationState.enum";
import { SiteChemical } from "../../../domain/entities/siteChemical";
import { SiteMachine } from "../../../domain/entities/siteMachine";
import { SiteTool } from "../../../domain/entities/siteTool";
import { SiteVehicle } from "../../../domain/entities/siteVehicle";
import { SiteWorker } from "../../../domain/entities/siteWorker";
import Specialization from "../../../domain/entities/specialization";
import Variant from "../../../domain/entities/variant";
import BaseModal from "../../components/Common/alerts/BaseModal";
import StateTag from "../../components/Common/StateTag";
import CellTagList from "../../components/Common/table/CellTagList";
import ColumnFilterComponent from "../../components/Common/table/ColumnFilterComponent";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import {getFieldLabel} from "../../../utils";
import { SiteResourceColumns } from "../../hooks/Site/useSiteResourcesViewModel";
import { useState } from "react";
import useOpenNewTab from "../../hooks/Common/useOpenNewTab";

type SiteSupplierResourcesProps = {
  type: DocumentTypeCategory;
  resourceColumns: string[];
  resources: (
    | SiteWorker
    | SiteChemical
    | SiteTool
    | SiteMachine
    | SiteVehicle
  )[];
  isWorkingSite: boolean;
  siteResourcesHasNextPage: boolean;
  siteResourcesFetchNextPage: () => void;
  setShowResources: (show: boolean) => void;
  isFetching: boolean;
  setSiteSupplierResourcesSort: (sort: SortMeta) => void;
  siteSupplierResourcesSort: SortMeta;
  filterSiteSupplierResources: Record<string, string | string[]>;
  updateFilterSiteSupplierResources: (
    column: string,
    value: string | string[]
  ) => void;
  variants: Variant[];
  specializations: Specialization[];
};

const SiteSupplierResourcesViewModal = ({
  type,
  resources,
  resourceColumns,
  isWorkingSite,
  siteResourcesHasNextPage,
  siteResourcesFetchNextPage,
  isFetching,
  setShowResources,
  setSiteSupplierResourcesSort,
  siteSupplierResourcesSort,
  filterSiteSupplierResources,
  updateFilterSiteSupplierResources,
  variants,
  specializations,
}: SiteSupplierResourcesProps) => {
  const { t } = useTranslation("siteResources");
  const { siteId } = useParams();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [filteredColumn, setFilteredColumn] = useState<string>();
  const { handleCustomClick, handleCustomOnMouse } = useOpenNewTab();


  const columns = [
    ...resourceColumns.map((col) => ({
      field: col,
      type: "text",
      label: getFieldLabel(t, col, type, "siteResources", ["machine", "vehicle"].includes(type) && col === "name" ? "brand" : col)
    })),
    { field: "variantIds", type: "tags", label: t("variant") },
    { field: "specializationIds", type: "tags", label: t("specialization") },
  ] as {
    field: keyof SiteResourceColumns;
    type: "text" | "tags" | "select";
    label: string;
  }[];

  if (!isWorkingSite) {
    // Remove the 'company' column from its previous position.
    const companyColumnIndex = columns.findIndex(
      (col) => col.field === "company"
    );
    if (companyColumnIndex !== -1) {
      columns.splice(companyColumnIndex, 1);
    }

    // Insert the 'company' column into the desired new position, specifically before the 'variantIds' column."
    const variantColumnIndex = columns.findIndex(
      (col) => col.field === "variantIds"
    );
    columns.splice(variantColumnIndex, 0, {
      field: "company",
      type: "text",
      label: t("company"),
    });
    const companyNewIndex = columns.findIndex((col) => col.field === "company");

    columns.splice(companyNewIndex + 1, 0, {
      field: "state",
      type: "select",
      label: t("status"),
    });
  }

  return (
    <BaseModal
      title={t(`resources.${type + "s"}`, { ns: "supplier" })}
      size="6xl"
      onClose={() => (setShowResources(false), updateFilterSiteSupplierResources(filteredColumn, undefined))}
      onCancel={false}
    >
      <Flex
        flexDirection="column"
        alignItems="start"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="10px"
        width={isMobile ? "calc(100vw - 120px)" : "100%"}
        position="relative"
        overflow="auto"
        marginTop={3}
        id="table-container-modal"
      >
        <InfiniteTable
          autosize
          maxHeight="calc(100vh - 400px)"
          tableId="site-supplier-resources-table"
          infiniteScroll={{
            dataLength: resources.length,
            hasNextPage: siteResourcesHasNextPage,
            fetchNextPage: siteResourcesFetchNextPage,
          }}
          isLoading={isFetching}
          showEmptyText={resources.length === 0}
          emptyText={t("noActiveSiteResource", { ns: "siteResources" })}
        >
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th width={200} key={column.field}>
                  <TableColumnHeader
                    text={column.label}
                    filter={{
                      component: (
                        <ColumnFilterComponent
                          type={column.type}
                          value={filterSiteSupplierResources[column.field]}
                          updateFilter={(value) => {
                            setFilteredColumn(column.field);
                            updateFilterSiteSupplierResources(
                              column.field,
                              value as string | string[]
                            );
                          }
                          }
                          tags={
                            column.field === "variantIds"
                              ? variants
                              : specializations
                          }
                          selectOptions={ResourceEvaluationState}
                          optionsTranslationContext={
                            column.field === "state" && "combinedEvaluation"
                          }
                          namespace={
                            column.field === "state"
                              ? "enum"
                              : "siteResources"
                          }
                        />
                      ),
                      isActive: !!(Array.isArray(
                        filterSiteSupplierResources[column.field]
                      )
                        ? filterSiteSupplierResources[column.field][0]
                        : filterSiteSupplierResources[column.field]),
                    }}
                    sort={
                      column.field !== "variantIds" &&
                      column.field !== "specializationIds" && {
                        handler: (direction) =>
                          setSiteSupplierResourcesSort({
                            field: column.field,
                            direction,
                          }),
                        direction:
                          siteSupplierResourcesSort &&
                            siteSupplierResourcesSort.field === column.field
                            ? siteSupplierResourcesSort.direction
                            : null,
                      }
                    }
                    isInModal
                  />
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {Array.isArray(resources) &&
              resources.map((siteResource) => (
                <Tr
                  key={siteResource.id}
                  onClick={(event) => {
                      !isWorkingSite
                        ? handleCustomClick(event, `/sites/${siteId}/resources/${type}s/${siteResource.id}`)
                        : handleCustomClick(event,`/workingsites/${siteId}/resources/${type}s/${siteResource.id}`)
                  }}
                  onMouseDown={(event) => {
                    !isWorkingSite
                      ? handleCustomOnMouse(event, `/sites/${siteId}/resources/${type}s/${siteResource.id}`)
                      : handleCustomOnMouse(event,`/workingsites/${siteId}/resources/${type}s/${siteResource.id}`)
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  {Array.isArray(columns) &&
                    columns.map((column) => (
                      <Td width={column.field === "fiscalCode" ? 230 : 200} key={column.field}>
                        {column.field === "state" && siteResource.state ? (
                          <StateTag
                            value={siteResource.state}
                            type="supplierEvaluation"
                          />
                        ) : column.type === "tags" ? (
                          <CellTagList
                            tags={
                              column.field === "variantIds"
                                ? [siteResource.variant]
                                : siteResource.specializations
                            }
                            selectedTags={
                              column.field === "variantIds"
                                ? [siteResource["variant"]]
                                : siteResource["specializations"]
                            }
                          />
                        ) : (
                          siteResource[column.field]
                        )}
                      </Td>
                    ))}
                </Tr>
              ))}
          </Tbody>
        </InfiniteTable>
      </Flex>
    </BaseModal>
  );
};

export default SiteSupplierResourcesViewModal;
