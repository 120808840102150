import {FormControl, FormErrorMessage, Td, Tr, } from "@chakra-ui/react";
import DeleteButton from "../../../components/Common/table/DeleteButton";
import { useTranslation } from "react-i18next";
import {Controller, FieldError, useFormContext} from "react-hook-form";
import {FieldType} from "../../../../domain/entities/customFields";
import Select from "react-select";
import {FormValues} from "../../../hooks/CustomFields/useCustomFieldsViewModel";
import MultiLanguageTextInput from "./MultiLanguageTextInput";

interface WebHookRowProps {
  index: number;
  remove: (index: number) => void;
  isHidden: boolean
}
const CustomFieldRow = ({ index, remove, isHidden }: WebHookRowProps) => {
  const { t } = useTranslation("settings");
  const {
    control,
    formState: { errors: { customFields: errors } },
  } = useFormContext<FormValues>();

  return (
    <Tr sx={isHidden ? {display: 'none'} : {}}>
      <Td w="calc(50% - 30px)">
        <FormControl isInvalid={!!(errors?.[index]?.type as FieldError)?.message}>
          <Controller
            control={control}
            name={`customFields.${index}.type`}
            rules={{required: true}}
            render={({ field: { onChange, onBlur, value, name: fieldName, ref }}) => (
                <Select
                  isMulti={false}
                  options={Object.values(FieldType).map((value) => ({ value, label: t(`customFields.${value}`, {ns: 'settings'})}))}
                  menuPortalTarget={document.getElementById("root")}
                  menuPosition="fixed"
                  menuPlacement="bottom"
                  onBlur={onBlur}
                  name={fieldName}
                  ref={ref}
                  isClearable={false}
                  onChange={(option) => {
                    onChange(option?.value);
                  }}
                  value={{value, label: t(`customFields.${value}`, {ns: 'settings'})}}
                />
            )}
          />
          <FormErrorMessage>{(errors?.[index]?.type as FieldError)?.message}</FormErrorMessage>
        </FormControl>
      </Td>
      <Td w="calc(50% - 30px)">
        <MultiLanguageTextInput index={index} namespace="customFields" />
      </Td>
      <Td w="60px">
        <DeleteButton onClick={() => remove(index)} />
      </Td>
    </Tr>
  );
};

export default CustomFieldRow;