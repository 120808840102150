import TagList from "../Common/TagList";
import { FC, useEffect, useMemo, useState } from "react";
import LoadingView from "../Common/LoadingView";
import { COLORS } from "../../assets/theme/colors";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { Trans, useTranslation } from "react-i18next";
import ContentLayout from "../../layout/ContentLayout";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";
import TagSelect from "../../components/Common/TagSelect";
import ActionBar from "../../components/Common/ActionBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdClose, MdOutlineHistory } from "react-icons/md";
import PlusButton from "../../components/Common/PlusButton";
import ToolIcon from "../../components/Common/icons/ToolIcon";
import { DeleteActionAlert } from "../Common/DeleteActionAlert";
import BaseModal from "../../components/Common/alerts/BaseModal";
import WorkerIcon from "../../components/Common/icons/WorkerIcon";
import ActionBarItem from "../../components/Common/ActionBarItem";
import DetailsHeader from "../../components/Common/DetailsHeader";
import MachineIcon from "../../components/Common/icons/MachineIcon";
import VehicleIcon from "../../components/Common/icons/VehicleIcon";
import ChemicalIcon from "../../components/Common/icons/ChemicalIcon";
import { Permission } from "../../components/Permissions/Permissions";
import { InfoBanner } from "../../components/Common/alerts/InfoBanner";
import SupplierStatus from "../../components/Views/Sites/SupplierStatus";
import DownloadAllButton from "../../components/Common/DownloadAllButton";
import SiteSupplierResourcesViewModal from "./SiteSupplierResourcesViewModal";
import RenderIf, { useHasPermissions } from "../../components/Permissions/RenderIf";
import { DownloadFileScope } from "../../../domain/repositories/documentsDownloader";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import AddDocumentTypesModal from "../../components/Views/common/AddDocumentTypesModal";
import SiteResourceDocuments, {
  ResourceDocumentsPermissions
} from "../../components/Views/common/SiteResourceDocuments";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { SupplierEvaluationState } from "../../../domain/entities/supplierEvaluationState.enum";
import { useSiteSupplierDetailViewModel } from "../../hooks/Site/useSiteSupplierDetailViewModel";
import DocumentCommunicationModal from "../../components/Views/common/DocumentCommunicationModal";
import ResourceEvaluationHistoryModal from "../../components/Views/common/ResourceEvaluationHistoryModal";
import ResourceEvaluationToggle, { EvaluationButton } from "../../components/Common/ResourceEvaluationToggle";
import {
  useDocumentsFilesDownloader,
  useSupplierOrSiteFilesDownloader
} from "../../hooks/Document/useDocumentsFilesDownloader";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  IconButton,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
  useToast,
  VStack
} from "@chakra-ui/react";
import RejectionSummary from "../../components/Views/Documents/RejectionSummary";
import DOMPurify from "dompurify";
import ContactField from "../../components/Common/table/ContactField";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import AiButton from "../../components/Common/AiButton";
import { AiTaskStatus } from "../../../domain/entities/aiTask";
import { PermissionCheck, useAuth } from "../../providers/Auth0JWTProvider";
import { WarningBanner } from "../../components/Common/alerts/WarningBanner";
import useSiteDetailsViewModel from "../../hooks/Site/useSiteDetailsViewModel";
import {getFieldLabel} from "../../../utils";

interface Props {
  fromList: boolean;
  permissions: {
    showGlobalEvaluation: Permission;
    setGlobalEvaluation: Permission;
    showStatus: Permission;
    setStatus: Permission;
    manageDocumentWithAi: Permission;
    editVariant: Permission;
    chat: Permission;
    report: Permission;
    company: SiteSupplierDetailPermissions;
    site: SiteSupplierDetailPermissions;
  };
  isWorkingSite?: boolean;
  isSubcontract?: boolean;
  deltaWidth?: string;
  aiTaskCompanySectionCode?: AiTaskSectionCode;
  aiTaskSiteSectionCode?: AiTaskSectionCode;
}

type SiteSupplierDetailPermissions = {
  downloadDocuments: Permission;
  viewRequirements: Permission;
  addRequirement: Permission;
  documents: ResourceDocumentsPermissions;
};

const SiteSupplierDetailView: FC<Props> = ({
                                             fromList,
                                             permissions,
                                             isWorkingSite = false,
                                             isSubcontract,
                                             deltaWidth,
                                             aiTaskCompanySectionCode,
                                             aiTaskSiteSectionCode
                                           }) => {
  const { siteId } = useParams();
  const { supplierId } = useParams();
  const { t } = useTranslation("supplier");
  const navigate = useNavigate();
  const location = useLocation();
  const [isTablet] = useMediaQuery("(max-width: 1300px)");

  const [showResources, setShowResources] = useState<boolean>(false);
  const [selectedRequirement, setSelectedRequirement] = useState<string>();
  const downloader = useSupplierOrSiteFilesDownloader(siteId, "site", supplierId);
  const [selectedSubject, setSelectedSubject] = useState<RequirementSubject>();
  const [showAskConfirmDelete, setShowAskConfirmDelete] =
    useState<boolean>(false);
  const [showNeedsRequirementsModal, setShowNeedsRequirementsModal] =
    useState<boolean>(false);
  const [
    showConfirmEvaluateResourceModal,
    setShowConfirmEvaluateResourceModal
  ] = useState<false | SupplierEvaluationState>(false);
  const [
    showResourceEvaluationHistoryModal,
    setShowResourceEvaluationHistoryModal
  ] = useState(false);
  const [showInfoBanner, setShowInfoBanner] = useState(true);
  const [showWarningBanner, setShowWarningBanner] = useState(true);
  const [deleteIsLoading, setDeleteIsLoading] = useState<boolean>(false);
  const [showCommunicationModal, setShowCommunicationModal] = useState(false);
  const variantTranslation = t("showUpdateTagsModal", { ns: "siteResources" });
  const [showAddRequirementModal, setShowAddRequirementModal] = useState(false);
  const [showUpdateVariantsModal, setShowUpdateVariantsModal] = useState<
    false | string
  >(false);
  const [showManageCompanyTaskWithAI, setShowManageCompanyTaskWithAI] =
    useState<boolean>(false);
  const [showManageSiteTaskWithAI, setShowManageSiteTaskWithAI] =
    useState<boolean>(false);
  const [addRequirementsIsLoading, setAddRequirementsIsLoading] =
    useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(
    location.state?.fromPrevUrl === "site" ? 1 : 0
  );
  const [typeTab, setTypeTab] = useState<("company" | "site")[]>([]);

  const { hasPermissions } = useAuth();
  const editVariant = useHasPermissions(permissions.editVariant, PermissionCheck.All);
  const currentType = typeTab[selectedTab];

  const canViewCompanyRequirements = useHasPermissions(permissions.company.viewRequirements)
  const canViewSiteRequirements = useHasPermissions(permissions.site.viewRequirements)

  const siteHook = useSiteDetailsViewModel(siteId);

  const canManageFileWithAI = hasPermissions(
    [
      permissions.site.addRequirement,
      Permission.Sites_Show,
      Permission.Supplier_Global_AddFile,
      permissions.site.addRequirement
    ],
    PermissionCheck.All
  );


  const {
    siteResource,
    siteResourceRefetch,
    variants,
    siteSupplierResources,
    siteSupplierResourcesHasNextPage,
    siteSupplierResourcesFetchNextPage,
    siteSupplierResourcesFetching,
    setSiteSupplierResourcesSort,
    siteSupplierResourcesSort,
    filterSiteSupplierResources,
    updateFilterSiteSupplierResources,
    evaluateSiteResource,
    updateSiteResourceVariant,

    siteResourceDocumentsRejected,
    siteResourceDocuments,
    siteResourceDocumentsIsLoading,
    siteResourceDocumentsFetchNextPage,
    siteResourceDocumentsHasNextPage,
    siteResourceDocumentsRefetch,

    siteResourceSiteDocumentsRejected,
    siteResourceSiteDocuments,
    siteResourceSiteDocumentsIsLoading,
    siteResourceSiteDocumentsFetchNextPage,
    siteResourceSiteDocumentsHasNextPage,
    siteResourceSiteDocumentsRefetch,

    siteResourceEvaluations,
    getSiteResourceEvaluations,
    getSiteResourceEvaluationsIsLoading,
    siteResourceEvaluationsHasNextPage,
    siteResourceEvaluationsFetchNextPage,
    setEnableGetEvaluationsHistory,
    evaluateDocument,
    filterResourceEvaluations,
    updateFilterResourceEvaluations,
    sortResourceEvaluations,
    updateSortResourceEvaluations,

    sortCompanyDocuments,
    updateSortCompanyDocuments,
    updateFilterCompanyDocuments,
    filterCompanyDocuments,

    sortDocuments,
    updateSortDocuments,
    updateFilterDocuments,
    filterDocuments,
    hookDocumentIdAndType,
    setHookDocumentIdAndType,
    documentEvaluations,
    documentEvaluationsFetching,
    addRequirementsToSiteResource,
    filterDocumentEvaluations,
    updateFilterDocumentEvaluations,
    updateSortDocumentEvaluations,
    sortDocumentEvaluations,
    documentTypesProps,
    documentInfoProps,

    siteVariants,
    siteSpecializations,
    setSupplierDocumentType,
    setSupplierStatus,
    isCompanyDocumentsFetching,
    isSiteDocumentsFetching,
    autoEvaluateSiteResource,
    deleteRequirement,
    communicationProps,
    statusIsLoading,
    systemStaff,
    resourceType,
    setResourceType,
    documentAICompanyProps,
    documentAISiteProps,
    getRequirementTemplateUrl
  } = useSiteSupplierDetailViewModel(
    siteId,
    supplierId,
    isWorkingSite,
    aiTaskCompanySectionCode,
    aiTaskSiteSectionCode,
    undefined,
    !!supplierId
  );

  const handlePermissionsByTab = (type: 'company' | 'site') => {
    if (type === "company") {
      return {
        ...permissions.company.documents,
        chat: permissions.chat,
        report: permissions.report,
      }
    }else{
      return {
        ...permissions.site.documents,
        chat: permissions.chat,
        report: permissions.report,
      }
    }
  }

  useEffect(() => {
    const updatedTabs: ("company" | "site")[] = [];
    if (canViewCompanyRequirements) {
      updatedTabs.push("company");
    }
    if (canViewSiteRequirements) {
      updatedTabs.push("site");
    }
    setTypeTab(updatedTabs);
    setSupplierDocumentType(updatedTabs[0] === "company" ? DocumentTypeCategory.SUPPLIER_COMPANY : DocumentTypeCategory.SUPPLIER_SITE);
    documentTypesProps.setHookDocumentCategory(updatedTabs[0] === "company" ? DocumentTypeCategory.COMPANY : DocumentTypeCategory.SITE);
  }, [canViewCompanyRequirements, canViewSiteRequirements]);


  const resourceColumns = ["name"];
  const resourceWorkerColumns = [
    "lastName",
    "firstName",
    "fiscalCode",
    "jobTitle"
  ];
  const resourceVehicleColumns = ["name", "plate"];
  const resourceToolColumns = ["name", "serial"];

  const availableCompanyDocumentTypes = useMemo(() => {
    return documentTypesProps.documentTypes.filter((documentType) => {
      return !siteResourceDocuments.find(
        (document) => document.documentTypeId === documentType.id
      );
    });
  }, [documentTypesProps.documentTypes, siteResourceDocuments]);

  const availableSiteDocumentTypes = useMemo(() => {
    return documentTypesProps.documentTypes.filter((documentType) => {
      return !siteResourceSiteDocuments.find(
        (document) => document.documentTypeId === documentType.id
      );
    });
  }, [documentTypesProps.documentTypes, siteResourceSiteDocuments]);

  const handleAddRequirements = async (documentTypes) => {
    setAddRequirementsIsLoading(true);
    await addRequirementsToSiteResource(documentTypes);
    setShowAddRequirementModal(false);
    setAddRequirementsIsLoading(false);
  };

  const askConfirmDelete = (id: string, subject: RequirementSubject) => {
    setSelectedRequirement(id);
    setSelectedSubject(subject);
    setShowAskConfirmDelete(true);
  };

  const cancelConfirmDelete = () => {
    setShowAskConfirmDelete(false);
    setSelectedRequirement(undefined);
  };

  const confirmDelete = async () => {
    setDeleteIsLoading(true);
    await deleteRequirement(
      selectedRequirement,
      currentType === 'company' ? DocumentTypeCategory.COMPANY : DocumentTypeCategory.SITE,
      supplierId);
    cancelConfirmDelete();
    setDeleteIsLoading(false);
  };

  const street = siteResource?.company?.address?.street;
  const cap = siteResource?.company?.address?.cap;
  const city = siteResource?.company?.address?.city;
  const addressString = [
    street && `${street}`,
    cap && `${cap}`,
    city && `${city}`
  ]
    .filter(Boolean)
    .join(" - ");

  const detailAttributes = [
    "name",
    "fiscalCode",
    "vat",
    "email",
    "pec",
    "address",
    "phoneNumber"
  ].map((column) => ({
    label: getFieldLabel(t, column, 'company', "companies"),
    value: column === "address" ? addressString : siteResource?.company[column],
  }));

  const contactAttributes = [
    "doctor",
    "rspp",
    "contact",
    "employer",
    "rls"
  ].map((role) => ({
    label: t(role, { ns: "companies" }),
    value: (
      <ContactField staff={systemStaff?.find((staff) => staff.role === role)} />
    )
  }));

  const onTabSelect = (tabIndex) => {
    const selectedType = typeTab[tabIndex];
    setSupplierDocumentType(selectedType === "company" ? DocumentTypeCategory.SUPPLIER_COMPANY : DocumentTypeCategory.SUPPLIER_SITE);
    documentTypesProps.setHookDocumentCategory(selectedType === "company" ? DocumentTypeCategory.COMPANY : DocumentTypeCategory.SITE);
    setSelectedTab(tabIndex)
  };

  const documentDownloaderCompany = useDocumentsFilesDownloader(
    siteId,
    supplierId,
    "company" as DownloadFileScope
  );

  const toast = useToast();
  const canGloballyEvaluateSupplier = useHasPermissions( permissions.setGlobalEvaluation);
  const sanitizedHtmlContent = DOMPurify.sanitize(
    t("tagSelect", {
      ns: "siteResources",
      tag: t("variant", { ns: "siteResources" }),
      color: COLORS.sikuroBlue
    })
  );
  useEffect(() => {
    documentTypesProps.setSupplierId(supplierId);
  }, []);

  const showToast = (ok: boolean) => {
    toast({
      duration: 5000,
      isClosable: true,
      colorScheme: ok ? "green" : "red",
      icon: ok ? <FiInfo /> : <FiAlertTriangle />,
      description: ok
        ? t("downloadAllFilesOk", {
          ns: "documents"
        })
        : t("downloadAllFilesError", {
          ns: "documents"
        })
    });
  };

  const refetchCompanyTasks = () => {
    documentAICompanyProps.aiTasksRefetch();
    setShowManageCompanyTaskWithAI(true);
  };

  const refetchSiteTasks = () => {
    documentAISiteProps.aiTasksRefetch();
    setShowManageSiteTaskWithAI(true);
  };

  useEffect(() => {
    communicationProps?.refetchResourceDocumentComments();
  }, []);

  const refetchCommentsAndNotes = () => {
    communicationProps.refetchMissingDocuments();
    communicationProps.refetchResourceDocumentComments();
    communicationProps.refetchResourceDocumentNotes();
    communicationProps.refetchNotificationStatus();
  };

  if (!siteResource) {
    return <LoadingView />;
  }

  return (
    <ContentLayout
      action={
        <ActionBar>
          {fromList && (
            <ActionBarItem
              bgColor={COLORS.sikuroBlue}
              color="white"
              icon={MdClose}
              description={t("close", { ns: "common" })}
              onClick={() => navigate(`/sites/${siteId}/suppliers`)}
            />
          )}
          <RenderIf permissions={permissions.chat} check={PermissionCheck.All}>
            <ActionBarItem
              bgColor={COLORS.yellow}
              color="black"
              icon={HiOutlineChatAlt2}
              description={t("chat", { ns: "communication" })}
              onClick={() => {
                refetchCommentsAndNotes();
                setShowCommunicationModal(true);
              }}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      {siteResource && (
        <>
          <Flex
            px={3}
            py={5}
            gap="20px"
            flexWrap={isTablet ? "wrap" : "nowrap"}
          >
            <DetailsHeader
              isLoaded={!!siteResource.company.name}
              title={t("details", { ns: "common" })}
              shouldShowImage={true}
              image={{ url: siteResource.company.logo, disableFlag: true }}
              attributes={[...detailAttributes, ...contactAttributes]}
            />
            <VStack gap={2} flex="1 1">
              <RenderIf permissions={permissions.showStatus} check={PermissionCheck.All}>
                <SupplierStatus
                  status={siteResource.status}
                  onChange={(s) => setSupplierStatus(s)}
                  permissions={permissions.setStatus}
                  statusIsLoading={statusIsLoading}
                />
              </RenderIf>
              <RenderIf permissions={[Permission.Sites_ShowWorkers, Permission.Sites_ShowChemicals, Permission.Sites_ShowMachines, Permission.Sites_ShowVehicles, Permission.Sites_ShowTools]} check={PermissionCheck.Some}>
                <ResourceLinks
                  setType={setResourceType}
                  showResourcesModal={setShowResources}
                />
              </RenderIf>
            </VStack>
          </Flex>
          <Flex padding={3} justifyContent={'center'}>
            <VStack
              rounded="md"
              borderWidth="1px"
              borderColor={ canViewCompanyRequirements || canViewSiteRequirements ? "gray.300" : 'transparent'}
              px={5}
              pt={3}
              width="100%"
            >
              <RenderIf permissions={permissions.showGlobalEvaluation} check={PermissionCheck.All}>
                <RenderIf permissions={permissions.setGlobalEvaluation} check={PermissionCheck.All}>
                  {!siteResource.isGlobal && siteResource.isForcedEvaluation && (
                    <Alert my={2} mx={-3} py={1} status="error" width="100%">
                      <AlertIcon />
                      <div>
                        <Trans t={t} i18nKey="evaluationForced" ns={"supplier"}>
                          Warning: the evaluation is forced.
                          <Link
                            sx={{ fontWeight: 700, textDecoration: "underline" }}
                            onClick={() => autoEvaluateSiteResource()}
                          >
                            Click here
                          </Link>
                          to remove the force
                        </Trans>
                      </div>
                    </Alert>
                  )}
                </RenderIf>
                <Flex
                  justifyContent="center"
                  gap={5}
                  alignItems="center"
                  w="100%"
                >
                  {siteResource.isGlobal ? (
                    <EvaluationButton
                      evaluationType="resource"
                      value={siteResource.evaluationResult}
                      disabled={!canGloballyEvaluateSupplier}
                    />
                  ) : (
                    <ResourceEvaluationToggle
                      type="resource"
                      status={siteResource.evaluationResult}
                      disabled={!canGloballyEvaluateSupplier}
                      onChange={(status) =>
                        setShowConfirmEvaluateResourceModal(
                          status as unknown as SupplierEvaluationState
                        )
                      }
                      showExpirationDate={false}
                    />
                  )}
                    <IconButton
                      fontSize="3xl"
                      backgroundColor="transparent"
                      onClick={() => {
                        setEnableGetEvaluationsHistory(true);
                        setShowResourceEvaluationHistoryModal(true);
                      }}
                      aria-label="document-history"
                    >
                      <MdOutlineHistory />
                    </IconButton>
                </Flex>
              </RenderIf>
              <Flex
                gap={5}
                mt={5}
                width={"full"}
                minHeight={"100"}
                justifyContent="space-between"
                alignItems="center"
              >
                <RenderIf permissions={[permissions.company.viewRequirements, permissions.site.viewRequirements]} check={PermissionCheck.Some}>
                  <Box sx={{ minWidth: "200px" }}>
                  {siteResource.isGlobal ? (
                    <div>
                      {siteResource?.variant ? (
                        <Flex>
                          <Text
                            fontSize={14}
                            textColor={COLORS.sikuroBlue}
                            marginRight={5}
                          >
                            {t("variants", { ns: "requirements" })}
                          </Text>
                          <TagList
                            tags={
                              siteResource?.variant
                                ? [siteResource?.variant]
                                : []
                            }
                          />
                        </Flex>
                      ) : (
                        <Flex>
                          <Text
                            mt={-4}
                            fontSize={14}
                            textColor={COLORS.sikuroBlue}
                          >
                            {t("variants", { ns: "requirements" })}
                          </Text>
                          <Text mt={-3} fontSize={10} marginLeft="5">
                            {t("noVariantSelected", { ns: "workers" })}
                          </Text>
                        </Flex>
                      )}
                    </div>
                  ) : (
                    <div>
                      <TagSelect
                        tags={variants}
                        isMulti={false}
                        style={{ zIndex: "2" }}
                        selectedTags={[siteResource?.variant?.id].filter(
                          (v) => v
                        )}
                        setSelectedTags={(variant) => {
                          setShowUpdateVariantsModal(variant[0] ?? undefined);
                        }}
                        label={t("variant", { ns: "siteResources" })}
                        editable={editVariant}
                        defaultMenuIsOpen={false}
                        isDisabled={!editVariant}
                      />
                      <Text
                        mt={-4}
                        fontSize={10}
                        dangerouslySetInnerHTML={{
                          __html: sanitizedHtmlContent
                        }}
                      />
                    </div>
                  )}
                </Box>
                </RenderIf>
                <Flex flexGrow={1} />
                {(siteResource.isGlobal || !canManageFileWithAI) ? null : (
                  <RenderIf
                    check={PermissionCheck.All}
                    permissions={permissions.manageDocumentWithAi}
                  >
                    <AiButton
                      tooltipMessage={t("manageAi", {
                        ns: "documents"
                      })}
                      onClick={() => {
                        documentAICompanyProps.setEnableGetAiTasksQuery(true);
                        currentType === 'company'
                          ? refetchCompanyTasks()
                          : refetchSiteTasks();
                      }}
                      isAnimated={
                        currentType === 'company'
                          ? documentAICompanyProps.aiTasks.some(
                            (task) => task.status === AiTaskStatus.RUNNING
                          )
                          : documentAISiteProps.aiTasks.some(
                            (task) => task.status === AiTaskStatus.RUNNING
                          )
                      }
                    />
                  </RenderIf>
                )}

                {siteResource.isGlobal ? null : (
                  <RenderIf
                    check={PermissionCheck.All}
                    permissions={[ currentType === 'company' ? permissions.company.addRequirement : permissions.site.addRequirement]}
                  >
                    <PlusButton
                      onClick={() => {
                        setSupplierDocumentType(
                          currentType === 'company' ?
                            DocumentTypeCategory.SUPPLIER_COMPANY :
                            DocumentTypeCategory.SUPPLIER_SITE
                        );
                        documentTypesProps.setEnableGetTags(true);
                        setShowAddRequirementModal(true);
                      }}
                    />
                  </RenderIf>
                )}
                {siteResourceDocuments.length > 0 && (
                  <RenderIf permissions={[permissions.company.downloadDocuments , permissions.site.downloadDocuments]} check={PermissionCheck.All}>
                    <DownloadAllButton
                      onClick={() => {
                        documentDownloaderCompany.initiateDocumentsZipDownload(
                          (ok: boolean) => {
                            showToast(ok);
                          }
                        );
                      }}
                    />
                  </RenderIf>
                )}
              </Flex>
              <RenderIf permissions={[permissions.site.viewRequirements, permissions.company.viewRequirements]} check={PermissionCheck.Some}>
                <Tabs
                  onChange={onTabSelect}
                  width="100%"
                  height="100%"
                  defaultIndex={selectedTab}
                  marginTop={5}
                  marginBottom={5}
                >
                  <TabList>
                  {typeTab.map((type) => (
                    <Tab key={type} width="100%">
                      {type === "company"
                        ? t("companyDocuments", { ns: "documents" })
                        : t("siteDocuments", { ns: "documents" })}
                    </Tab>
                  ))}
                  </TabList>
                  <TabPanels>
                    {typeTab.map((type: "company" | "site") => (
                      <TabPanel key={type}>
                          <>
                            <Box mt={2}>
                              <RejectionSummary
                                groups={type === "company"
                                  ? siteResourceDocumentsRejected
                                  : siteResourceSiteDocumentsRejected}
                              />
                            </Box>
                            {showInfoBanner && (
                              <RenderIf permissions={type === "company" ? permissions.company.documents.addFile : permissions.site.documents.addFile ?? []}>
                                <InfoBanner
                                  text={t("dragAndDropInfo", { ns: "documents" })}
                                  onClose={() => setShowInfoBanner(false)}
                                />
                              </RenderIf>
                            )}
                            {showWarningBanner && siteId && !isWorkingSite ? (
                              <WarningBanner
                                description={
                                  <Trans
                                    i18nKey="siteActionAlert"
                                    ns="documents"
                                    values={{ siteName: siteHook.site?.name }}
                                    components={{ strong: <Text as="span" fontWeight="semibold" /> }}
                                  />
                                }
                                onClose={() => setShowWarningBanner(false)}
                              />
                            ) : null}
                          </>
                        <SiteResourceDocuments
                          downloader={downloader}
                          evaluateDocument={evaluateDocument}
                          siteResourceRefetch={siteResourceRefetch}
                          isSubcontract={isSubcontract}
                          showCommunications={true}
                          {...(type === "company"
                            ? {
                              documents: siteResourceDocuments,
                              documentsRefetch: siteResourceDocumentsRefetch,
                              documentsHasNextPage:
                              siteResourceDocumentsHasNextPage,
                              documentsFetchNextPage:
                              siteResourceDocumentsFetchNextPage,
                              isLoadingDocuments: siteResourceDocumentsIsLoading,
                              sortDocuments: sortCompanyDocuments,
                              filterDocuments: filterCompanyDocuments,
                              updateFilterDocuments:
                              updateFilterCompanyDocuments,
                              updateSortDocuments: updateSortCompanyDocuments,
                              isWorkingSite: siteResource.isGlobal
                            }
                            : {
                              documents: siteResourceSiteDocuments,
                              documentsRefetch:
                              siteResourceSiteDocumentsRefetch,
                              documentsHasNextPage:
                              siteResourceSiteDocumentsHasNextPage,
                              documentsFetchNextPage:
                              siteResourceSiteDocumentsFetchNextPage,
                              isLoadingDocuments: siteResourceSiteDocumentsIsLoading,
                              sortDocuments: sortDocuments,
                              filterDocuments: filterDocuments,
                              updateFilterDocuments: updateFilterDocuments,
                              updateSortDocuments: updateSortDocuments,
                              isWorkingSite: false
                            })}
                          setSiteResourceHookDocumentId={setHookDocumentIdAndType}
                          documentEvaluations={documentEvaluations}
                          documentEvaluationsFetching={
                            documentEvaluationsFetching
                          }
                          filterDocumentEvaluations={filterDocumentEvaluations}
                          updateFilterDocumentEvaluations={
                            updateFilterDocumentEvaluations
                          }
                          sortDocumentEvaluations={sortDocumentEvaluations}
                          updateSortDocumentEvaluations={
                            updateSortDocumentEvaluations
                          }
                          {...documentInfoProps}
                          isAddingFile={
                            isCompanyDocumentsFetching || isSiteDocumentsFetching
                          }
                          isFetchingDocuments={
                            isCompanyDocumentsFetching || isSiteDocumentsFetching
                          }
                          isReadOnly={
                            isWorkingSite ||
                            (siteResource.isGlobal && type === "company")
                          }
                          onDelete={(id, subject) =>
                            askConfirmDelete(id, subject)
                          }
                          requirementSubject={type as RequirementSubject}
                          communicationProps={communicationProps}
                          permissions={handlePermissionsByTab(type)}
                          resourceCategory={type as DocumentTypeCategory}
                          resourceId={supplierId}
                          deltaWidth={deltaWidth}
                          aiTaskSectionCode={
                            type === "company"
                              ? aiTaskCompanySectionCode
                              : aiTaskSiteSectionCode
                          }
                          documentsAiHook={
                            type === "company"
                              ? documentAICompanyProps
                              : documentAISiteProps
                          }
                          showManageTaskWithAi={
                            type === "company"
                              ? showManageCompanyTaskWithAI
                              : showManageSiteTaskWithAI
                          }
                          setShowManageTaskWIthAi={
                            type === "company"
                              ? setShowManageCompanyTaskWithAI
                              : setShowManageSiteTaskWithAI
                          }
                          isTablet={isTablet}
                          getRequirementTemplateUrl={getRequirementTemplateUrl}
                        />
                      </TabPanel>
                    ))}
                  </TabPanels>
                </Tabs>
              </RenderIf>
            </VStack>
          </Flex>
        </>
      )}

      {showAddRequirementModal && (
        <AddDocumentTypesModal
          {...documentTypesProps}
          isRequirement
          documentTypes={
            documentTypesProps.hookDocumentCategory ===
            DocumentTypeCategory.COMPANY
              ? availableCompanyDocumentTypes
              : availableSiteDocumentTypes
          }
          onClose={() => setShowAddRequirementModal(false)}
          onConfirm={handleAddRequirements}
          isAddingDocumentsApi={addRequirementsIsLoading}
          isFetching={documentTypesProps.documentTypesIsFetching}
          showAvailableDocuments={
            documentTypesProps.hookDocumentCategory ===
            DocumentTypeCategory.COMPANY
          }
          showSourceSelection={true}
          canViewCompanyRequirements={canViewCompanyRequirements}
          canViewSiteRequirements={canViewSiteRequirements}
        />
      )}

      {showResources && (
        <SiteSupplierResourcesViewModal
          type={resourceType as unknown as DocumentTypeCategory}
          resourceColumns={
            resourceType === "worker" ?
              resourceWorkerColumns :
              resourceType === "vehicle" ||  resourceType === "machine" ?
                resourceVehicleColumns : resourceType === "tool" ? resourceToolColumns :
                resourceColumns
          }
          resources={siteSupplierResources}
          isWorkingSite={location.pathname.includes("workingsite")}
          siteResourcesHasNextPage={siteSupplierResourcesHasNextPage}
          siteResourcesFetchNextPage={siteSupplierResourcesFetchNextPage}
          isFetching={siteSupplierResourcesFetching}
          setShowResources={(show) => setShowResources(show)}
          setSiteSupplierResourcesSort={(sort) =>
            setSiteSupplierResourcesSort(sort)
          }
          siteSupplierResourcesSort={siteSupplierResourcesSort}
          filterSiteSupplierResources={filterSiteSupplierResources}
          updateFilterSiteSupplierResources={(
            column: string,
            value: string | string[]
          ) => updateFilterSiteSupplierResources(column, value)}
          variants={siteVariants}
          specializations={siteSpecializations}
        />
      )}

      {showConfirmEvaluateResourceModal && (
        <BaseModal
          title={t("supplierEvaluationManulOverride")}
          onClose={() => setShowConfirmEvaluateResourceModal(false)}
          onConfirm={() => {
            evaluateSiteResource(
              showConfirmEvaluateResourceModal as SupplierEvaluationState
            );
          }}
        >
          <Box>{t("showConfirmEvaluateSupplierModal")}</Box>
        </BaseModal>
      )}

      {showUpdateVariantsModal && (
        <BaseModal
          title={t("warning", { ns: "common" })}
          onClose={() => setShowUpdateVariantsModal(false)}
          onConfirm={() => updateSiteResourceVariant(showUpdateVariantsModal)}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t(variantTranslation, {
                tag: t("variant", { ns: "workers" })
              })
            }}
          />
        </BaseModal>
      )}

      {showResourceEvaluationHistoryModal && (
        <ResourceEvaluationHistoryModal
          onClose={() => {
            setEnableGetEvaluationsHistory(false);
            setShowResourceEvaluationHistoryModal(false);
          }}
          evaluations={siteResourceEvaluations}
          evaluationsHasNextPage={siteResourceEvaluationsHasNextPage}
          evaluationsFetchNextPage={siteResourceEvaluationsFetchNextPage}
          getEvaluations={getSiteResourceEvaluations}
          getEvaluationsIsLoading={getSiteResourceEvaluationsIsLoading}
          filterResourceEvaluations={filterResourceEvaluations}
          updateFilterResourceEvaluations={updateFilterResourceEvaluations}
          sortResourceEvaluations={sortResourceEvaluations}
          updateSortResourceEvaluations={updateSortResourceEvaluations}
        />
      )}

      {showNeedsRequirementsModal && (
        <BaseModal
          title={t("warning", { ns: "common" })}
          onClose={() => setShowNeedsRequirementsModal(false)}
        >
          <Text>{t("needRequirements")}</Text>
        </BaseModal>
      )}

      {showCommunicationModal && (
        <DocumentCommunicationModal
          onClose={() => setShowCommunicationModal(false)}
          hook={communicationProps}
          isSite={siteId ? true : false}
          permissions={handlePermissionsByTab(currentType)}
        />
      )}
      <DeleteActionAlert
        isLoading={deleteIsLoading}
        onConfirm={confirmDelete}
        onCancel={cancelConfirmDelete}
        mainTitle={t("warning", { ns: "common" })}
        title={t("confirmDelete", { ns: "requirements" })}
        leftButtonText={t("confirm", { ns: "common" })}
        rightButtonText={t("cancel", { ns: "common" })}
        isOpen={showAskConfirmDelete}
      />
    </ContentLayout>
  );
};

export const ResourceLinks = ({
                                setType,
                                showResourcesModal
                              }: {
  setType: (type: DocumentTypeCategory) => void;
  showResourcesModal: (show: boolean) => void;
}) => {
  const { t } = useTranslation("supplier");
  const [isTablet] = useMediaQuery("(max-width: 1550px)");

  const buttons = [
    {
      type: DocumentTypeCategory.WORKER,
      Icon: WorkerIcon,
      label: t("goToWorkers"),
      permissions: [Permission.Sites_ShowWorkers, Permission.Worksite_ShowWorkers],
    },
    {
      type: DocumentTypeCategory.VEHICLE,
      Icon: VehicleIcon,
      label: t("goToVehicles"),
       permissions:  [Permission.Sites_ShowVehicles,  Permission.Worksite_ShowVehicles]
    },
    {
      type: DocumentTypeCategory.MACHINE,
      Icon: MachineIcon,
      label: t("goToMachines"),
       permissions:  [Permission.Sites_ShowMachines,  Permission.Worksite_ShowMachines]
    },
    {
      type: DocumentTypeCategory.TOOL,
      Icon: ToolIcon,
      label: t("goToTools"),
       permissions:  [Permission.Sites_ShowTools,  Permission.Worksite_ShowTools]
    },
    {
      type: DocumentTypeCategory.CHEMICAL,
      Icon: ChemicalIcon,
      label: t("goToChemicals"),
       permissions:  [Permission.Sites_ShowChemicals, Permission.Worksite_ShowChemicals]
    }
  ];
  return (
    <Flex
      direction={"column"}
      rounded="md"
      borderWidth="1px"
      borderColor="gray.300"
      p="10px 40px"
      width="100%"
    >
      <Box textStyle="h2">{t("resources", { ns: "sites" })}</Box>
      <Flex flexWrap={"wrap"} gap={isTablet && 4}>
        {buttons.map(({ type, label, Icon, permissions }) => (
          <RenderIf permissions={permissions} key={label}>
            <Button
              key={type}
              sx={{
                marginTop: "5px",
                width: "232px",
                height: "50px",
                borderRadius: "4px",
                border: "1px solid #E2E8F0",
                backgroundColor: "#FFFFFF",
                fontSize: "16px",
                fontWeight: 300,
                padding: "0  20px 0 70px",
                position: "relative",
                justifyContent: "flex-start",
                textWrap: "wrap",
                textAlign: "left"
              }}
              _hover={{
                backgroundColor: COLORS.sikuroBlue,
                color: "#FFFFFF"
              }}
              onClick={() => {
                return setType(type), showResourcesModal(true);
              }}
            >
              <Box
                sx={{
                  backgroundColor: COLORS.yellow,
                  height: "50px",
                  width: "50px",
                  position: "absolute",
                  left: 0,
                  top: 0,
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Icon fontSize="20px" />
              </Box>
              {label}
            </Button>
          </RenderIf>
        ))}
      </Flex>
    </Flex>
  );
};

export default SiteSupplierDetailView;
