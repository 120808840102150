import UseCase from '../../../core/app/usecase';
import CompanyRepository from '../../repositories/companyRepository';
import {TranslationRecord} from "../../entities/translationRecord";

type GetTranslationsParams = {
  companyId: string;
};

class GetTranslationRecords implements UseCase<null, TranslationRecord[]> {
	private companyRepository: CompanyRepository;

	constructor(companyRepository: CompanyRepository) {
		this.companyRepository = companyRepository;
	}

	async Call({ companyId }: GetTranslationsParams): Promise<TranslationRecord[]> {
		return await this.companyRepository.getTranslationRecords(companyId);
	}
}

export default GetTranslationRecords;
