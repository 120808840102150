import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

const Italy = (props: IconProps) => {
  return (
    <Icon
      viewBox="0 0 640 480"
      {...props}
    >
      <g fillRule="evenodd" strokeWidth="1pt">
        <path fill="#fff" d="M0 0h640v480H0z"/>
        <path fill="#009246" d="M0 0h213.3v480H0z"/>
        <path fill="#ce2b37" d="M426.7 0H640v480H426.7z"/>
      </g>
    </Icon>
  );
};

export default Italy;
