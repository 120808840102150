import {useTranslation} from "react-i18next";
import {Flex, Tbody, Th, Thead, Tr, useMediaQuery,} from "@chakra-ui/react";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import ContentLayout from "../../../layout/ContentLayout";
import ActionBar from "../../../components/Common/ActionBar";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import {FiPlus} from "react-icons/fi";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import {BiReset} from "react-icons/bi";
import {FaRegSave} from "react-icons/fa";
import {COLORS} from "../../../assets/theme/colors";
import SimpleTable from "../../../components/Common/table/SimpleTable";
import {useEffect, useMemo} from "react";
import {FormValues, UseCustomFieldsViewModel} from "../../../hooks/CustomFields/useCustomFieldsViewModel";
import {FieldType} from "../../../../domain/entities/customFields";
import {RequirementSubject} from "../../../../domain/entities/requirementSubject.enum";
import CustomFieldRow from "./CustomFieldRow";
import {useLocation} from "react-router-dom";
import {LANGUAGE, TranslationRecord} from "../../../../domain/entities/translationRecord";
import TranslationRow from "./TranslationRow";
import Section from "./Section";

const CustomFieldsContainer = ({ hook }: { hook: UseCustomFieldsViewModel }) => {
  const { t } = useTranslation("settings");
  const [isTablet] = useMediaQuery("(max-width: 1300px)");

  const location = useLocation();
  const segments = location.pathname.split("/");
  const subject = segments.pop() as RequirementSubject;
  const { customFields, setFields, translations } = hook;

  const onValid = async (values: FormValues) => {
    await setFields.mutate(values);
  }

  const defaultValues = useMemo(
    () => ({ customFields: customFields.data, records: translations.data }),
    [customFields.data, translations.data],
  );

  const methods = useForm<FormValues>({ defaultValues });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "customFields",
  });

  const { fields: translationFields, append: translationAppend, remove: translationRemove } = useFieldArray({
    control,
    name: "records",
  });

  const isFetching =
    setFields.status === "loading" || customFields.status === "error" || translations.status === "error"

  const isSetFetchingOrFormNotDirty = isFetching || !isDirty;

  const records = methods.watch("records");

  const usedKeys = (records ?? []).map((record) => record.key);
  const allAllowedKeys = ALLOWED_FIELDS[subject].map(field => [subject, field].join("_"))
  const allowedKeys = allAllowedKeys.filter((key) => !usedKeys.includes(key));

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset, subject]);

  const initLang = Object.values(LANGUAGE).reduce((obj, lang) => {
    obj[lang] = "";
    return obj;
  } , {}) as TranslationRecord['lang'];

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            onClick={handleSubmit(onValid)}
            icon={FaRegSave}
            description={t("save", { ns: 'common' })}
            isDisabled={isSetFetchingOrFormNotDirty}
            bgColor={
              isSetFetchingOrFormNotDirty ? COLORS.lightGrey : undefined
            }
            color={
              isSetFetchingOrFormNotDirty ? COLORS.gray : undefined
            }
          />
          <ActionBarItem
            onClick={() =>
              append({ key: "", lang: initLang, type: FieldType.TEXT, subject })
            }
            isDisabled={isFetching}
            icon={FiPlus}
            description={t("add")}
            bgColor={
              isFetching ? COLORS.lightGrey : COLORS.sikuroBlue
            }
            color={isFetching ? COLORS.gray : COLORS.white}
          />
          <ActionBarItem
            onClick={() =>
              allowedKeys.length > 0 && translationAppend({ key: allowedKeys[0], lang: initLang })
            }
            isDisabled={isFetching || allowedKeys.length === 0}
            icon={MdOutlineDriveFileRenameOutline}
            description={t("translations.add-record")}
            bgColor={
              (isFetching || allowedKeys.length === 0) ? COLORS.lightGrey : COLORS.sikuroBlue
            }
            color={(isFetching || allowedKeys.length === 0) ? COLORS.gray : COLORS.white}
          />
          <ActionBarItem
            onClick={() => reset(defaultValues)}
            isDisabled={isSetFetchingOrFormNotDirty}
            icon={BiReset}
            description={t("reset", { ns: "common" })}
            bgColor={
              isSetFetchingOrFormNotDirty
                ? COLORS.lightGrey
                : COLORS.sikuroBlue
            }
            color={
              isSetFetchingOrFormNotDirty ? COLORS.gray : COLORS.white
            }
          />
        </ActionBar>
      }
    >
      <FormProvider {...methods}>
        <Flex flexDirection="column" width={"calc(100vw - 190px)"}>
          <Section title={t("customFields.title")} subtitle={t("customFields.subtitle")}>
            <SimpleTable
              isLoading={isFetching}
              emptyText={t("customFields.noFieldFound")}
              showEmptyText={fields.filter(f => f.subject === subject).length === 0}
            >
              <Thead>
                <Tr>
                  <Th w={isTablet && fields.length > 0 ? "300px" : "calc(50% - 30px)"}>
                    {t("customFields.type")}
                  </Th>
                  <Th w={isTablet && fields.length > 0 ? "300px" : "calc(50% - 30px)"}>
                    {t("customFields.field")}
                  </Th>
                  <Th w="60px" />
                </Tr>
              </Thead>
              <Tbody>
                {fields.map((field, index) => (
                  <CustomFieldRow
                    key={field.id}
                    index={index}
                    remove={remove}
                    isHidden={field.subject !== subject}
                  />
                ))}
              </Tbody>
            </SimpleTable>
          </Section>

          <Section title={t("translations.title")} subtitle={t("translations.subtitle")}>
            <SimpleTable
              isLoading={isFetching}
              emptyText={t("translations.noFieldFound")}
              showEmptyText={translationFields.filter(field => allAllowedKeys.includes(field.key)).length === 0}
            >
              <Thead>
                <Tr>
                  <Th w={isTablet && translationFields.length > 0 ? "300px" : "calc(50% - 30px)"}>
                    {t("translations.key")}
                  </Th>
                  <Th w={isTablet && translationFields.length > 0 ? "300px" : "calc(50% - 30px)"}>
                    {t("translations.lang")}
                  </Th>
                  <Th w="60px" />
                </Tr>
              </Thead>
              <Tbody>
                {translationFields.map((field, index) =>
                  allAllowedKeys.includes(records[index].key) && (
                    <TranslationRow
                      key={field.id}
                      index={index}
                      remove={translationRemove}
                      usedKeys={usedKeys}
                      allAllowedKeys={allAllowedKeys}
                    />
                ))}
              </Tbody>
            </SimpleTable>
          </Section>
        </Flex>
      </FormProvider>
    </ContentLayout>
  );
};

const ALLOWED_FIELDS = {
  company: [
    "name",
    "street",
    "city",
    "cap",
    "businessSize",
    "vat",
    "vatCountryCode",
    "fiscalCode",
    "pec",
    "phoneNumber",
    "email",
    "ccnl"
  ],
  site: [
    "name",
    "protocol",
    "description",
    "contract",
    "startDate",
    "lifespan",
    "address",
    "zipCode",
    "stakeholder"
  ],
  worker: [
    "firstName",
    "lastName",
    "fiscalCode",
    "fiscalCodeCountryCode",
    "jobTitle",
    "dateOfEmployment",
    "dateOfBirth",
    "placeOfBirth",
    "phone",
    "email",
    'gender'
  ],
  machine: [
    "name",
    "model",
    "plate"
  ],
  vehicle: [
    "name",
    "model",
    "plate"
  ],
  tool: [
    "name",
    "serial"
  ],
  chemical: [
    "name"
  ]
}

export default CustomFieldsContainer;
