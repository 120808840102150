import {ReactNode} from "react";
import {Flex, Text} from "@chakra-ui/react";
import {COLORS} from "../../../assets/theme/colors";

const Section = ({children, title, subtitle}: {children: ReactNode, title: string, subtitle: string}) => (
  <Flex
    flex={1}
    h="100%"
    width="100%"
    padding="40px 10px 40px 40px"
    flexDirection="column"
    alignItems="start"
    justifyContent="start"
  >
    <Text textColor={COLORS.sikuroBlue} fontSize={20} fontWeight={"bold"}>
      {title}
    </Text>
    <Text>
      {subtitle}
    </Text>
    <Flex
      flexDirection={"column"}
      alignItems={"start"}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="10px"
      width={"calc(100vw - 240px)"}
      position="relative"
      overflow={"auto"}
      marginTop={3}
    >
      {children}
    </Flex>
  </Flex>
)

export default Section