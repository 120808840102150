import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { SiteRequirementsResource } from "../../../domain/entities/siteRequirementsResource";
import { GetSiteRequirementsResourceFilter } from "../../../domain/repositories/documentRepository";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { SiteRequirementsDocumentViewModel } from "../../viewmodels/documents/SiteRequirementsDocumentViewModel";
import { useParams } from "react-router-dom";
import { updateFilterWithDelete } from "../../../utils";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

export type UseSiteRequirementsDocumentProps = {
  indexTab: number,
  setIndexTab: (index: number) => void,
  siteReqResource: SiteRequirementsResource[];
  siteReqResourceSort: SortMeta;
  siteReqResourceFilter: GetSiteRequirementsResourceFilter;
  setSiteReqResourceSort: (sort: SortMeta) => void;
  updateFilterSiteReqResource: (
    field: string,
    value: string | string[] | [Date, Date],
  ) => void;
  siteReqResourceLoading: boolean;
  siteReqResourceHasNextPage: boolean;
  siteReqResourceFetchNextPage: () => void;
  siteReqSupplier: SiteRequirementsResource[];
  siteReqSupplierSort: SortMeta;
  siteReqSupplierFilter: GetSiteRequirementsResourceFilter;
  setSiteReqSupplierSort: (sort: SortMeta) => void;
  siteReqSupplierLoading: boolean;
  siteReqSupplierHasNextPage: boolean;
  siteReqSupplierFetchNextPage: () => void;
  updateFilterSiteReqSupplier: (
    field: string,
    value: string | string[] | [Date, Date],
  ) => void;
  setSearchResources?: (query: string) => void;
  setSearchSuppliers?: (query: string) => void;
};

const useSiteRequirementsDocumentViewModel =
  (): UseSiteRequirementsDocumentProps => {
    const { companyId } = useAuth();
    const { siteId } = useParams();
    const [searchResources, setSearchResources] = useState<string>();
    const [searchSuppliers, setSearchSuppliers] = useState<string>();
    const [siteReqResourceSort, setSiteReqResourceSort] = useState<SortMeta>();
    const [siteReqSupplierSort, setSiteReqSupplierSort] = useState<SortMeta>();
    const [siteReqResourceFilter, setSiteReqResourceFilter] =
      useState<GetSiteRequirementsResourceFilter>({});
    const [siteReqSupplierFilter, setSiteReqSupplierFilter] =
      useState<GetSiteRequirementsResourceFilter>({});
    const [indexTab, setIndexTab] = useState<number>(
      location.pathname.includes("supplier") ? 1 : 0,
    );

    const viewModel = useMemo(
      () => new SiteRequirementsDocumentViewModel(),
      [],
    );

    const updateSiteReqResourceFilter = (
      field: string,
      value: string | string[] | [Date, Date],
    ) => {
      updateFilterWithDelete(setSiteReqResourceFilter, field, value);
    };

    const updateSiteReqSupplierFilter = (
      field: string,
      value: string | string[] | [Date, Date],
    ) => {
      updateFilterWithDelete(setSiteReqSupplierFilter, field, value);
    };

    const availableResources = [
      DocumentTypeCategory.WORKER,
      DocumentTypeCategory.CHEMICAL,
      DocumentTypeCategory.TOOL,
      DocumentTypeCategory.MACHINE,
      DocumentTypeCategory.VEHICLE,
    ];
    const availableDocuments = [
      // DocumentTypeCategory.SITE, // Removed to avoid BE duplicates.
      DocumentTypeCategory.COMPANY,
    ];

    const getSiteRequirementsResource = useInfiniteQuery(
      [
        "site-req-resource",
        companyId,
        siteId,
        availableResources,
        siteReqResourceSort,
        siteReqResourceFilter,
        searchResources,
      ],
      async ({ pageParam = 1 }) => {
        const filters = searchResources
          ? { ...siteReqResourceFilter, search: searchResources }
          : siteReqResourceFilter;
        return await viewModel.getSiteRequirementsResource(
          companyId,
          siteId,
          availableResources,
          pageParam,
          siteReqResourceSort,
          filters,
        );
      },
      {
        getNextPageParam: (lastPage, pages) => {
          if (lastPage && lastPage.length === 25) {
            return pages.length + 1;
          }
        },
        enabled: indexTab === 0
      },
    );

    const getSiteRequirementsSupplier = useInfiniteQuery(
      [
        "site-req-supplier",
        companyId,
        siteId,
        availableDocuments,
        siteReqSupplierSort,
        siteReqSupplierFilter,
        searchSuppliers,
      ],
      async ({ pageParam = 1 }) => {
        const filters = searchSuppliers
          ? { ...siteReqSupplierFilter, search: searchSuppliers }
          : siteReqSupplierFilter;
        return await viewModel.getSiteRequirementsResource(
          companyId,
          siteId,
          availableDocuments,
          pageParam,
          siteReqSupplierSort,
          filters,
        );
      },
      {
        getNextPageParam: (lastPage, pages) => {
          if (lastPage && lastPage.length === 25) {
            return pages.length + 1;
          }
        },
        enabled: indexTab === 1
      },
    );

    const siteReqResource =
      getSiteRequirementsResource.data?.pages?.flat() ?? [];
    const siteReqSupplier =
      getSiteRequirementsSupplier.data?.pages?.flat() ?? [];

    return {
      indexTab,
      setIndexTab,
      siteReqResource,
      siteReqResourceSort,
      setSiteReqResourceSort,
      siteReqResourceFilter,
      updateFilterSiteReqResource: updateSiteReqResourceFilter,
      siteReqResourceLoading: getSiteRequirementsResource.isLoading,
      siteReqResourceHasNextPage: getSiteRequirementsResource.hasNextPage,
      siteReqResourceFetchNextPage: getSiteRequirementsResource.fetchNextPage,

      siteReqSupplier,
      siteReqSupplierSort,
      setSiteReqSupplierSort,
      siteReqSupplierFilter,
      siteReqSupplierLoading: getSiteRequirementsSupplier.isLoading,
      siteReqSupplierHasNextPage: getSiteRequirementsSupplier.hasNextPage,
      siteReqSupplierFetchNextPage: getSiteRequirementsSupplier.fetchNextPage,
      updateFilterSiteReqSupplier: updateSiteReqSupplierFilter,
      setSearchResources,
      setSearchSuppliers,
    };
  };

export default useSiteRequirementsDocumentViewModel;
