import { extendTheme } from '@chakra-ui/react';
import { COLORS } from './colors';
export const theme = extendTheme({
	styles: {
		global: {
			html: {
				overflow: 'hidden',
			},
			body: {
				overflow: 'hidden',
			},
		},
	},
	textStyles: {
		h1: {
			color: COLORS.sikuroBlue,
			fontSize: '20px',
			fontWeight: 'bold',
		},
		h2: {
			fontSize: '18px',
			fontWeight: 'bold',
		},
		h3: {
			fontSize: '15px',
		},
		h4: {
			fontSize: ['16px', '24px'],
			fontWeight: 'bold',
		},
		h5: {
			fontSize: ['14px', '16px'],
			fontWeight: 'bold',
		},
		h6: {
			fontSize: ['12px', '14px'],
			fontWeight: 'bold',
		},
	},
	components: {
		Button: {
			baseStyle: {
				borderRadius: '16px',
				height: '38px'
			},
		},
		Form: {
			baseStyle: {
				borderRadius: '16px',
				height: '38px'
			},
			variants: {
				floating: {
					container: {
						label: {
							top: '-8px',
							left: '8px',
							position: 'absolute',
							fontSize: '10px',
							padding: '0 10px',
							backgroundColor: 'white',
							color: 'gray.700',
							pointerEvents: 'none',
						},
					},
				},
			},
		},
		FormError: {
			baseStyle: {
			  text: {
				marginLeft: '10px',
				color: 'red.500',
				fontSize: '15px',
			  },
			},
		  },
		Modal: {
			baseStyle: {
				dialog: {
					maxHeight: '90vh',
				},
				body: {
					overflowY: 'auto',
				},
			},
		},
		Table: {
			baseStyle: {
				thead: {
					tr: {
						backgroundColor: '#EAEAEA',
					},
					th: {
						color: '#545454',
					},
				},
				tbody: {
					overflowY: 'auto',
					tr: {
						_hover: {
							backgroundColor: 'linkedin.100',
						},
					},
				},
			},
		},
	},
});
