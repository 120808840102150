import {
  Box,
  Button,
  Checkbox,
  Flex,
  Select,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import Site from "../../../../domain/entities/site";
import { GetSitesFilter } from "../../../../domain/repositories/siteRepository";
import { UpdateFilter } from "../../../hooks/Site/useSiteResourceDetailViewModel";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import InfiniteTable from "../../Common/table/InfiniteTable";
import { Permission } from "../../Permissions/Permissions";
import { FaExclamationCircle } from "react-icons/fa";
import { COLORS } from "../../../assets/theme/colors";

type SelectSitesViewProps = {
  title?: string;
  subtitle?: string;
  reminderText?: string;
  alertText?: string;
  onClose?: () => void;
  siteSelectedAction?: (sitesIds: string[]) => void;
  onSaveAction?: (siteIds: string[], selectAll: boolean) => void;
  siteList?: Site[];
  action?: string;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  isFetchingSites?: boolean;
  filterResourceSites?: GetSitesFilter;
  updateFilterResourceSites?: UpdateFilter;
  setSortResourceSites?: (sort: SortMeta) => void;
  sortResourceSites?: SortMeta;
  includeFooterButton?: boolean;
  operationIsBusy?: boolean;
  autosize?: boolean;
  isDisabled?: boolean;
  hideSubtitles?: boolean;
  isTablet?: boolean;
  siteCount?: number;
  selectAllSites?: boolean;
  setSelectAllSites?: React.Dispatch<React.SetStateAction<boolean>>;
  showSelectAll: boolean;
  showPermissionsSelect?: boolean;
  setPermissionsSites?: (value) => void;
  showCopyLastEvaluation?: boolean
  copyLastEvaluation?: boolean,
  setCopyLastEvaluation?: (value: boolean) => void
};
const SelectSitesView = ({
  title,
  subtitle,
  reminderText,
  alertText,
  onClose,
  siteSelectedAction,
  siteList,
  hasNextPage,
  fetchNextPage,
  isFetchingSites,
  filterResourceSites,
  updateFilterResourceSites,
  setSortResourceSites,
  sortResourceSites,
  includeFooterButton,
  showSelectAll,
  onSaveAction,
  operationIsBusy,
  autosize,
  isDisabled,
  hideSubtitles,
  action,
  isTablet,
  siteCount,
  selectAllSites,
  setSelectAllSites,
  showPermissionsSelect,
  setPermissionsSites,
  showCopyLastEvaluation,
  copyLastEvaluation,
  setCopyLastEvaluation
}: SelectSitesViewProps) => {
  const { t } = useTranslation("documents");
  const siteColumns = ["name", "protocol", "location"];
  const [siteIds, setSiteIds] = useState<string[]>([]);
  const [error, setError] = useState<string>();
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllPaginatedSites, setSelectAllPaginatedSites] =
    useState<boolean>(false);
  const [showClearButton, setShowClearButton] = useState(false)

  const siteFilterColumnTypes = {
    name: "text",
    protocol: "text",
  };
  const fSites = filterResourceSites ?? {
    name: "",
    protocol: "",
  };
  const excludeSort = new Set(["location"]);

  const toggleItem = (id: string) => {
    let updatedSiteIds;

    if (siteIds && !siteIds.includes(id)) {
      updatedSiteIds = [...siteIds, id];
    } else {
      updatedSiteIds = siteIds.filter((i) => i !== id);
    }

    setSiteIds(updatedSiteIds);

    if (updatedSiteIds.length === siteList.length) {
      setSelectAll(true);
      setSelectAllSites && setSelectAllSites(true);
    } else {
      setSelectAll(false);
      setSelectAllSites && setSelectAllSites(false);
    }

    if (siteSelectedAction) {
      siteSelectedAction(updatedSiteIds);
    }
  };

  const handleSaveChanges = () => {
    if (siteSelectedAction && siteIds.length > 0) {
      siteSelectedAction(siteIds);
    } else if (onSaveAction) {
      if (action === "evaluate" && siteIds.length === 0) {
        setError(t("selectSite"));
      } else {
        onSaveAction(siteIds, selectAllSites);
      }
    }
  };

  const toggleSelectAll = (value: boolean) => {
    setSelectAll(value);
    if (!value) {
      setSiteIds([]);
      setSelectAllPaginatedSites(false);
    } else {
      setSelectAllSites && setSelectAllSites(value);
      setSiteIds(siteList.map((site) => site.id));
      if (siteSelectedAction) {
        siteSelectedAction(siteList.map((site) => site.id));
      }
    }
  };

  const handleSelectionButton = () => {
    setShowClearButton(!showClearButton)
    if (showClearButton) {
      setSiteIds([]);
      setSelectAll(false);
      setSelectAllSites && setSelectAllSites(false);
      setSelectAllPaginatedSites(false);
    } else {
      setSelectAllPaginatedSites(true);
      setSelectAllSites && setSelectAllSites(true);
      setSelectAll(true);
      setSiteIds(siteList.map((site) => site.id));
    }
  };

  useEffect(() => {
    if (siteList.length > 0 && (selectAllPaginatedSites || selectAll) && showClearButton) {
      const ids = siteList.map((site) => site.id);
      const allIds = [...ids, ...siteIds];
      const uniqueIds = Array.from(new Set(allIds));
      setSiteIds(uniqueIds);
    }
  }, [siteList, selectAll, selectAllPaginatedSites, showClearButton]);

  return (
    <Flex
      flex={1}
      w="100%"
      rounded="md"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      flexDirection="column"
    >
      <Box py={4} width="100%" textAlign="start">
        <Flex justifyContent={'space-between'} alignItems={"center"}>
       {title && <Text
          fontSize={18}
          fontWeight={600}
          alignSelf={"flex-start"}
          marginBottom={5}
        >
          {title}
        </Text>}
        <div
          dangerouslySetInnerHTML={{
            __html: subtitle,
          }}
        />
        {showCopyLastEvaluation && <Flex justifyContent={'flex-end'} marginTop={!title ? 5 : 0}>
                <Text>
                  <Tooltip
                    placement="bottom"
                    label={t("enableCopyLastEvaluation", {ns: 'siteResources'})}
                    fontSize="md"
                  >
                    <span>
                      <FaExclamationCircle
                        style={{ fill: COLORS.sikuroBlue, marginTop: 2 }}
                      />
                    </span>
                  </Tooltip>
                </Text>
                <Checkbox
                  marginLeft={3}
                  marginBottom={5}
                  isChecked={copyLastEvaluation}
                  onChange={() => setCopyLastEvaluation(!copyLastEvaluation)}
                >
                  <Text fontSize="sm">
                    {t("copyLastEvaluation", {ns: 'siteResources'})}
                  </Text>
                </Checkbox>
            </Flex>}
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="start"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          width={isTablet ? "calc(100vw - 120px)" : "100%"}
          position="relative"
          overflow="auto"
          marginTop={6}
          id="table-container-modal"
        >
          <InfiniteTable
            autosize={autosize}
            bottomThreshold={300}
            infiniteScroll={{
              dataLength: siteList.length,
              hasNextPage: hasNextPage,
              fetchNextPage: fetchNextPage,
            }}
            emptyText={t("noItems", { ns: "common" })}
            isLoading={isFetchingSites}
            tableId="select-sites-table"
            isCheckboxTable={true}
          >
            <Thead>
              <Tr width="100%">
                {showSelectAll && (
                  <Th key={"selectAllCheckbox"} width={10}>
                    <Checkbox
                      borderColor={"gray.500"}
                      isChecked={selectAll || siteIds.length === siteCount}
                      onChange={() => toggleSelectAll(!selectAll)}
                    />
                  </Th>
                )}
                {siteColumns.map((row) => {
                  return (
                    <Th width={200} key={row}>
                      <TableColumnHeader
                        text={t(row)}
                        filter={{
                          component: (
                            <ColumnFilterComponent
                              type={siteFilterColumnTypes[row]}
                              value={fSites[row]}
                              updateFilter={(value) =>
                                updateFilterResourceSites ?
                                  updateFilterResourceSites(row, value) :
                                  null
                              }
                            />
                          ),
                          isActive: !!(Array.isArray(fSites[row])
                            ? fSites[row][1]
                            : fSites[row]),
                        }}
                        sort={
                          excludeSort.has(row)
                            ? undefined
                            : {
                                handler: (direction) => {
                                  return setSortResourceSites({
                                    field: row,
                                    direction,
                                  });
                                },
                                direction:
                                  sortResourceSites?.field === row
                                    ? sortResourceSites.direction
                                    : undefined,
                              }
                        }
                        isInModal
                      />
                    </Th>
                  );
                })}
                {showPermissionsSelect && (
                  <Th width={300}>{t("hourFormat")}</Th>
                )}
              </Tr>
            </Thead>
            <Tbody width={"100%"}>
              <Tr>
                {selectAll && (
                  <Tr width="100%">
                    <Th
                      colSpan={siteColumns.length + 2}
                      backgroundColor={"gray.100"}
                    >
                      <Text textAlign="center" mx="auto">
                        {!selectAllPaginatedSites && t("sitesSelectedVisible")}
                        {selectAllPaginatedSites &&
                          t("sitesSelectedNotVisible", {
                            count: siteCount,
                          })}
                        {hasNextPage && (
                          <Button
                            mt="10px"
                            ml="4px"
                            colorScheme="blue"
                            variant="link"
                            onClick={() => handleSelectionButton()}
                          >
                            {t(showClearButton ? "clearSelection" : "sitesSelectAll")}
                          </Button>
                        )}
                      </Text>
                    </Th>
                  </Tr>
                )}
              </Tr>
              {siteList?.map((site) => {
                const relevantActions: Permission[] = [
                  Permission.Sites_ViewClockingHistoryExactly,
                  Permission.Sites_ViewClockingHistoryTotal,
                  Permission.Sites_ViewClockingHistoryRounded,
                  Permission.Sites_ViewClockingHistoryDay,
                ];

                const permissions = site?.permissions
                  ?.filter((permissionSite) =>
                    relevantActions.includes(
                      permissionSite.name as Permission
                    )
                  )
                  .map((permissionSite) => permissionSite.name);

                return (
                  <Tr key={site?.id} width={"100%"}>
                    <Td width={10}>
                      <Checkbox
                        isChecked={
                          siteIds && siteIds.length > 0
                            ? siteIds?.includes(site?.id)
                            : false
                        }
                        onChange={() => toggleItem(site?.id)}
                      />
                    </Td>
                    <Td width={200}>{site?.name}</Td>
                    <Td width={200}>{t(site?.protocol)}</Td>
                    <Td width={200}>
                      {site?.address && site?.address + ","}{" "}
                      {site?.city && site?.city + ","} {site?.zipCode}
                    </Td>
                    {showPermissionsSelect && (
                      <Td width={300}>
                        <Select
                          disabled={!siteIds.includes(site.id)}
                          onChange={(e) => {
                            setPermissionsSites((prevState) => ({
                              ...prevState,
                              [site?.id]: e.target.value,
                            }));
                          }}
                        >
                          <option value="null">
                            {t("select", { ns: "common" })}
                          </option>
                          {permissions.map((p) => {
                            return (
                              <option key={p} value={p}>
                                {t(
                                  `permissions.sites_${p.split(":")[1]}_title`,
                                  { ns: "permissions" }
                                )}
                              </option>
                            );
                          })}
                        </Select>
                      </Td>
                    )}
                  </Tr>
                );
              })}
            </Tbody>
          </InfiniteTable>
        </Flex>
      </Box>

      {!hideSubtitles && (
        <>
          {siteIds.length === 0 && (
            <Text
              p="3"
              fontSize={16}
              width={"100%"}
              alignSelf={"start"}
              textAlign={"start"}
            >
              {reminderText}
            </Text>
          )}
          {siteIds.length > 0 && (
            <Text
              p="3"
              fontSize={16}
              width={"100%"}
              alignSelf={"start"}
              textAlign={"start"}
            >
              {alertText}
            </Text>
          )}
        </>
      )}

      {error && (
        <Text color={"red"}>
          <Text fontWeight={"bold"}>{t("warning", { ns: "common" })}</Text>{" "}
          {error}
        </Text>
      )}

      {includeFooterButton && (
        <Flex justifyContent="flex-end" gap={2} mt={5} width="100%">
          <Button
            colorScheme="blue"
            onClick={handleSaveChanges}
            isLoading={operationIsBusy}
            isDisabled={isDisabled}
          >
            {t("confirm", { ns: "common" })}
          </Button>
          <Button colorScheme="red" onClick={() => onClose()}>
            {t("cancel", { ns: "common" })}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default SelectSitesView;
