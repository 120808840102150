import { makeCompanyRepository } from './repositories';
import GetCompany from '../../../../domain/interactors/company/getCompany';
import UpdateCompany from '../../../../domain/interactors/company/updateCompany';
import GetCompanyDocuments from '../../../../domain/interactors/company/getCompanyDocuments';
import GetCompanies from '../../../../domain/interactors/company/getCompanies';
import GetCustomFields from "../../../../domain/interactors/company/getCustomFields";
import SetCustomFields from "../../../../domain/interactors/company/setCustomFields";
import GetTranslations from "../../../../domain/interactors/company/getTranslations";
import GetTranslationRecords from "../../../../domain/interactors/company/getTranslationRecords";
import SetTranslations from "../../../../domain/interactors/company/setTranslations";

export const makeGetCompany = () => {
	return new GetCompany(makeCompanyRepository());
};

export const makeUpdateCompany = () => {
	return new UpdateCompany(makeCompanyRepository());
};

export const makeCompanyDocuments = () => {
	return new GetCompanyDocuments(makeCompanyRepository());
};

export const makeGetCompanies = () => {
	return new GetCompanies(makeCompanyRepository());
};

export const makeGetCustomFields = () => {
  return new GetCustomFields(makeCompanyRepository());
};

export const makeSetCustomFields = () => {
  return new SetCustomFields(makeCompanyRepository());
};

export const makeGetTranslations = () => {
  return new GetTranslations(makeCompanyRepository());
};

export const makeGetTranslationRecords = () => {
  return new GetTranslationRecords(makeCompanyRepository());
};

export const makeSetTranslations = () => {
  return new SetTranslations(makeCompanyRepository());
};

