export type TranslationRecord = {
  key: string;
  lang: {
    [key in LANGUAGE]: string;
  }
}

export enum LANGUAGE {
  IT = "it",
  EN = "en",
  FR = "fr",
  ES = "es",
  DE = "de"
}