import { makeExport, makeExportItemColumns } from "../../../core/app/factory/export/useCases";
import { makeGetSiteResourcesDocuments } from "../../../core/app/factory/sites/usecases";
import DocumentType from "../../../domain/entities/documentType";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { PaginatedResults } from "../../../domain/entities/interfaces/paginatedResults";
import { ExportColumn } from "../../components/Views/Export/ExportDialogData";

class ExportViewModel {

  async getSiteResourceDocuments(
    companyId: string,
    siteId: string,
    resourceType: DocumentTypeCategory,
    supplierId: string,
    pageParam?: number,
  ): Promise<PaginatedResults<DocumentType>> {
    return await makeGetSiteResourcesDocuments().Call({
      companyId,
      siteId,
      resourceType,
      supplierId,
      pageParam,
    });
  }

  async getExportItemColumns(
    companyId: string,
    topic: string
  ): Promise<ExportColumn[]> {

    return await makeExportItemColumns().Call({
      companyId,
      topic
    });
  }

  async export(
    companyId: string,
    path: string,
    siteId?: string,
    siteIds?: string[],
    startDate?: Date,
    endDate?: Date,
    permissionSites?: { [key: string]: string },
    resourcesIds?: string[],
    resourceDocumentsToExclude?: string[],
    selectedColumns?: string[]
  ): Promise<boolean> {
    return await makeExport().Call({
      companyId,
      path,
      siteId,
      siteIds,
      startDate,
      endDate,
      permissionSites,
      resourcesIds,
      resourceDocumentsToExclude,
      selectedColumns
    });
  }
}

export default ExportViewModel;
