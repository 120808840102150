import {
  Flex,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useMediaQuery
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { CompanyResourceType } from "../../../../domain/entities/companyResourceType.enum";
import { ResourceEvaluationState } from "../../../../domain/entities/resourceEvaluationState.enum";
import useOpenNewTab from "../../../hooks/Common/useOpenNewTab";
import {
  GetSiteResourcesFilters,
  useSiteResourcesViewModel,
} from "../../../hooks/Site/useSiteResourcesViewModel";
import ContentLayout from "../../../layout/ContentLayout";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";
import { ConfirmAlert } from "../../../screens/Common/ConfirmAlert";
import SearchInput from "../../../screens/Common/SearchInput";
import ActionBar from "../../Common/ActionBar";
import ActionBarItem from "../../Common/ActionBarItem";
import ArchivedResourceToggle from "../../Common/ArchivedResourceToggle";
import StateTag from "../../Common/StateTag";
import CellTagList from "../../Common/table/CellTagList";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import DeleteButton from "../../Common/table/DeleteButton";
import InfiniteTable from "../../Common/table/InfiniteTable";
import RestoreButton from "../../Common/table/RestoreButton";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import { Permission } from "../../Permissions/Permissions";
import RenderIf, { useHasPermissions } from "../../Permissions/RenderIf";
import CreateSiteResourceModal from "./CreateSiteResourceModal";
import {getFieldLabel} from "../../../../utils";

type SiteResourcesListViewProps<Resource> = {
  type: "worker" | "machine" | "vehicle" | "tool" | "chemical";
  useViewModel: useSiteResourcesViewModel<Resource>;
  resourceColumns: string[];
  addToSitePermissions: Permission[];
  evaluatePermissions?: Permission;
  removeFromSitePermissions: Permission[];
  showStatePermission: Permission[];
};

const SiteResourcesListView = <Resource extends { [key: string]: any }>({
  type,
  useViewModel,
  resourceColumns,
  addToSitePermissions,
  evaluatePermissions,
  removeFromSitePermissions,
  showStatePermission,
}: SiteResourcesListViewProps<Resource>) => {
  const { t } = useTranslation("siteResources");
  const [showCreateSiteResourceModal, setShowCreateSiteResourceModal] =
    useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState<string>();
  const [restoreCandidate, setRestoreCandidate] = useState<string>();
  const [isTablet] = useMediaQuery("(max-width: 1024px)");

  const {
    siteResources,
    siteAvailableResourceTotalCount,
    ownedResources,
    ownedResourcesFetching,
    variants,
    specializations,
    siteResourcesHasNextPage,
    siteResourcesFetchNextPage,
    siteResourcesIsLoading,
    deleteSiteResource,
    createSiteResource,
    restoreSiteResource,
    showArchived,
    setShowArchived,
    filterSiteResources,
    filterOwnedResources,
    updateFilterSiteResources,
    updateFilterOwnedResources,
    resetFilters,
    siteResourcesSort,
    setSiteResourcesSort,
    searchOwnedResources,
    setSearchOwnedResources,
    sortOwnedResources,
    setSortOwnedResources,
    setSiteResourceCreationActive,
    ownedResourcesHasNextPage,
    ownedResourcesFetchNextPage,
    deleteCandidateIsLoading,
    badgesAvailable,
    badgesAvailableType,
    setSearchActive,
    setSearchArchived,
  } = useViewModel;
  const { handleCustomClick, handleCustomOnMouse } = useOpenNewTab();

  const columns = [
    ...resourceColumns.map((col) => ({
      field: col,
      type: "text",
      label: getFieldLabel(t, col, type, "siteResources", ["machine", "vehicle"].includes(type) && col === "name" ? "brand" : col),
      width: col === "fiscalCode" ? "230px" : "180px",
    })),
    { field: "variantIds", type: "tags", label: t("variant"), width: "200px" },
    { field: "specializationIds", type: "tags", label: t("specialization"), width: "180px" },
  ] as {
    field: keyof GetSiteResourcesFilters;
    type: "text" | "tags" | "select";
    label: string;
    width: string;
    options?: Record<string, string>;
  }[];

  const variantColumnIndex = columns.findIndex(
    (col) => col.field === "variantIds"
  );
  columns.splice(variantColumnIndex, 0, {
    field: "company",
    type: "text",
    label: t("company"),
    width: "200px",
  });

  const companyNewIndex = columns.findIndex((col) => col.field === "company");
  const canRemoveOrRestore = useHasPermissions(removeFromSitePermissions, PermissionCheck.All);
  const canViewState = useHasPermissions(showStatePermission, PermissionCheck.All);

  canViewState && columns.splice(companyNewIndex + 1, 0, {
    field: "state",
    type: "select",
    label: t("status"),
    width: "200px",
    options: ResourceEvaluationState,
  });

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf permissions={addToSitePermissions} check={PermissionCheck.All}>
            <ActionBarItem
              onClick={() => {
                setSiteResourceCreationActive(true);
                setShowCreateSiteResourceModal(true);
                resetFilters()
              }}
              icon={FiPlus}
              description={t(`${type}.titleStep1`, { ns: "siteResources" })}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      <Flex
        alignItems="center"
        justifyContent="start"
        textAlign="center"
        flex={1}
        h="100%"
        w="100%"
        padding={10}
        bg="white"
        flexDirection="column"
      >
        <Flex
          flexDirection={isTablet ? "column" : "row"}
          justifyContent="space-between"
          gap={4}
          alignItems={isTablet ? "start" : "center"}
          width={"calc(100vw - 264px)"}
        >
          <ArchivedResourceToggle
            type={type as CompanyResourceType}
            selectTab={(flag) => setShowArchived(flag)}
            showArchived={showArchived}
          />
          <SearchInput
            onSearch={(query) => {
              if (showArchived) {
                setSearchArchived(query);
              } else {
                setSearchActive(query);
              }
            }}
          />
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="start"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          width="calc(100vw - 264px)"
          marginTop={3}
          position="relative"
          overflow="auto"
          id="table-container"
        >
          <InfiniteTable
            autosize
            tableId="site-resources-table"
            isLoading={siteResourcesIsLoading}
            infiniteScroll={{
              dataLength: siteResources?.length,
              hasNextPage: siteResourcesHasNextPage,
              fetchNextPage: siteResourcesFetchNextPage,
            }}
            emptyText={t("noActiveSiteResource", { ns: "siteResources" })}
            showEmptyText={siteResources?.length === 0}
          >
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column.field} width={column.width}>
                    <TableColumnHeader
                      overflowHidden={
                        (column.type as "text" | "tags") !== "tags"
                      }
                      text={column.label}
                      filter={{
                        component: (
                          <ColumnFilterComponent
                            type={column.type}
                            value={filterSiteResources[column.field]}
                            updateFilter={(value) =>
                              updateFilterSiteResources(
                                column.field,
                                value as string | string[]
                              )
                            }
                            tags={
                              column.field === "variantIds"
                                ? variants
                                : specializations
                            }
                            optionsTranslationContext={
                              column.field === "state" && "combinedEvaluation"
                            }
                            namespace={
                              column.field === "state"
                                ? "enum"
                                : "siteResources"
                            }
                            selectOptions={column.options}
                          />
                        ),
                        isActive: !!(Array.isArray(
                          filterSiteResources[column.field]
                        )
                          ? filterSiteResources[column.field][0]
                          : filterSiteResources[column.field]),
                      }}
                      sort={
                        column.field !== "variantIds" &&
                        column.field !== "specializationIds" && {
                          handler: (direction) =>
                            setSiteResourcesSort({
                              field: column.field,
                              direction,
                            }),
                          direction:
                            siteResourcesSort &&
                              siteResourcesSort.field === column.field
                              ? siteResourcesSort.direction
                              : null,
                        }
                      }
                    />
                  </Th>
                ))}
                <Th w={10} />
              </Tr>
            </Thead>
            <Tbody>
              {siteResources?.map((siteResource) => (
                <Tr
                  key={siteResource.id}
                  onClick={(event) => handleCustomClick(event, `${location.pathname}/${siteResource.id}`)}
                  onMouseDown={(event) => handleCustomOnMouse(event, `${location.pathname}/${siteResource.id}`)}
                  sx={{ cursor: "pointer", width: "100%" }}
                >
                  {Array.isArray(columns) &&
                    columns.map((column) => {
                      const key = column.field;
                      return (
                        <Td
                          key={column.field}
                          width={column.width}
                        >
                          {canViewState && column.field === "state" && siteResource.state ? (
                            <StateTag
                              value={siteResource.state}
                              type="supplierEvaluation"
                            />
                          ) : column.type === "tags" ? (
                            <CellTagList
                              tags={
                                column.field === "variantIds"
                                  ? [siteResource.variant]
                                  : siteResource.specializations
                              }
                              selectedTags={
                                column.field === "variantIds"
                                  ? [siteResource["variant"]]
                                  : siteResource["specializations"]
                              }
                            />
                          ) : (
                            <>
                              {isTablet ? (
                                <div style={{ width: 200 }}>
                                  {siteResource[key]}
                                </div>
                              ) : (
                                siteResource[key]
                              )}
                            </>
                          )}
                        </Td>
                      );
                    })}
                  <Td p={0} w={10}>
                    {canRemoveOrRestore && showArchived && (
                      <RestoreButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setRestoreCandidate(siteResource.id);
                        }}
                        tooltipLabel={"restore"}
                      />
                    )}

                    {canRemoveOrRestore && !showArchived && (
                      <DeleteButton
                        tooltipLabel="archive"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteCandidate(siteResource.id);
                        }}
                      />
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </InfiniteTable>
        </Flex>
      </Flex>
      {showCreateSiteResourceModal && (
        <CreateSiteResourceModal<Resource>
          type={type}
          columns={resourceColumns}
          resources={ownedResources}
          ownedResourcesFetching={ownedResourcesFetching}
          resourcesHasNextPage={ownedResourcesHasNextPage}
          resourcesFetchNextPage={ownedResourcesFetchNextPage}
          onConfirm={createSiteResource}
          variants={variants}
          specializationsFromList={specializations}
          search={searchOwnedResources}
          setSearch={setSearchOwnedResources}
          updateFilters={updateFilterOwnedResources}
          sort={sortOwnedResources}
          setSort={setSortOwnedResources}
          onClose={() => {
            setSiteResourceCreationActive(false);
            setShowCreateSiteResourceModal(false);
            resetFilters()
          }}
          selectAllAvailable={true}
          availableResourcesTotalCount={siteAvailableResourceTotalCount}
          filterSiteResources={filterSiteResources}
          filterOwnedResources={filterOwnedResources}
          badgesAvailable={badgesAvailable}
          badgesAvailableType={badgesAvailableType}
          copyLastEvaluationPermission={evaluatePermissions}
        />
      )}
      {deleteCandidate && (
        <ConfirmAlert
          variant="warning"
          title={t("warning")}
          message={t("confirmRemoveResource")}
          onCancel={() => setDeleteCandidate(undefined)}
          isLoading={deleteCandidateIsLoading}
          onConfirm={async () => {
            await deleteSiteResource({ resourceId: deleteCandidate });
            setDeleteCandidate(undefined);
          }}
        />
      )}
      {restoreCandidate && (
        <ConfirmAlert
          variant="question"
          title={t("confirmRestore")}
          message={t("confirmRestoreResource")}
          onCancel={() => setRestoreCandidate(undefined)}
          onConfirm={() => {
            restoreSiteResource({ resourceId: restoreCandidate });
            setRestoreCandidate(undefined);
          }}
        />
      )}
    </ContentLayout>
  );
};

export default SiteResourcesListView;
