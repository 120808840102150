import {LANGUAGE} from "../../../../domain/entities/translationRecord";
import {
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Collapse, useDisclosure
} from "@chakra-ui/react";
import {FieldError, useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import UK from "../../../components/Common/icons/flags/UK";
import Italy from "../../../components/Common/icons/flags/Italy";
import Germany from "../../../components/Common/icons/flags/Germany";
import France from "../../../components/Common/icons/flags/France";
import Spain from "../../../components/Common/icons/flags/Spain";
import React from "react";
import { BsTranslate } from "react-icons/bs";
import {COLORS} from "../../../assets/theme/colors";
import {FormValues} from "../../../hooks/CustomFields/useCustomFieldsViewModel";


type MultiLanguageTextInputProps = {
  index: number;
  namespace: 'records' | 'customFields';
}

const MultiLanguageTextInput = ({index, namespace}: MultiLanguageTextInputProps) => {
  const {
    register, watch,
    formState: { errors: { [namespace]: errors } },
  } = useFormContext<FormValues>();
  const { t } = useTranslation("settings");
  const { isOpen, onToggle } = useDisclosure()

  const customFields = watch("customFields");

  const validateUniqueLabel = (label: string) => {
    const labelOccurrences = customFields.filter(
      (field, idx) => field.lang.it === label && idx !== index && field.subject === customFields[index].subject
    ).length;
    return labelOccurrences === 0 || t("customFields.errors.labelUniqueSameType");
  };

  return (
    <Collapse in={isOpen} startingHeight={errors?.[index]?.lang?.it ? 70 : 40} animateOpacity>
      {Object.values(LANGUAGE).map((lang) => (
        <FormControl
          key={lang}
          isInvalid={!!(errors?.[index]?.lang?.[lang])}
          style={{
            ...(lang !== LANGUAGE.IT ? { marginTop: "10px"} : {}),
          }}
        >
          <InputGroup flex="1">
            <InputLeftElement pointerEvents='none'>
              {FlagIcon[lang]}
            </InputLeftElement>
            <Input
              size="md"
              placeholder={t(`${namespace}.labelPlaceholder`)}
              _placeholder={{ color: 'gray.500' }}
              {...register(`${namespace}.${index}.lang.${lang}`, lang === LANGUAGE.IT ? {
                ...(lang === LANGUAGE.IT ? {required: `${namespace}.errors.valueRequired`} : {}),
                ...(namespace === 'customFields' ? { validate: validateUniqueLabel } : {})
              } : {})}
            />
            {lang === LANGUAGE.IT && (
              <InputRightElement>
                <BsTranslate
                  onClick={onToggle}
                  style={{
                    cursor: "pointer",
                    color: isOpen ? COLORS.sikuroBlue : COLORS.gray
                  }}
                />
              </InputRightElement>
            )}
          </InputGroup>
          <FormErrorMessage>{(errors?.[index]?.lang?.[lang] as FieldError)?.message}</FormErrorMessage>
        </FormControl>
      ))}
    </Collapse>
  )
}

const FlagIcon = {
  [LANGUAGE.EN]: <UK />,
  [LANGUAGE.IT]: <Italy />,
  [LANGUAGE.DE]: <Germany />,
  [LANGUAGE.FR]: <France />,
  [LANGUAGE.ES]: <Spain />,
}

export default MultiLanguageTextInput