import googleMapReact from "google-map-react";
import { useEffect, useMemo, useState } from "react";
import { UseFormClearErrors, UseFormSetError, UseFormTrigger, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Company from "../../../domain/entities/company";
import { CustomField } from "../../../domain/entities/customFields";
import { vatCodeValidators } from "../../../infrastructure/utilities/validator";
import DataBox from "../../components/Common/DataBox";
import FormSelectField from "../../components/Common/forms/FormSelectField";
import FormTextField from "../../components/Common/forms/FormTextField";
import useUpdateCompanyDetailsViewModel from "../../hooks/Company/useUpdateCompanyDetailsViewModel";
import { vatCountryCodes } from "../../../constants";
import {getFieldLabel} from "../../../utils";


type RecordsPanelProps = {
  trigger:  UseFormTrigger<Company>
  watch: UseFormWatch<Company>;
  setError: UseFormSetError<Company>;
  clearErrors: UseFormClearErrors<Company>;
  coordinates: googleMapReact.Coords;
  isEditing: boolean;
  isFetching: boolean;
  uploadedImageFile: File;
  setUploadedImageFile: (f: File) => void;
  company: Company;
  customFields: CustomField[];
}

const RecordsPanel = ({trigger, watch, setError, clearErrors, coordinates, isEditing, isFetching, uploadedImageFile, setUploadedImageFile, company, customFields }: RecordsPanelProps) => {
  const { t } = useTranslation("companies");

  const vatCountryCode = watch("vatCountryCode");
  const vatCodeRegex = vatCodeValidators.get(vatCountryCode);
  const {
    vatCountries,
    businessSizes,
  } = useUpdateCompanyDetailsViewModel();

  const countries = vatCountries ? vatCountryCodes(t, vatCountries) : [];

  const sizes = businessSizes?.map((size) => ({
    name: t(`size.${size.value}`),
    id: size.value,
  }));
  const validateVat = (vat: string) => {
    let message = undefined;
    if (!vat) {
      message = t("vatFormatError.-");
    } else if (!vatCodeRegex?.exec(vat)) {
      message = t(`vatFormatError.${vatCountryCode}`);
    }

    if (message) {
      setError("vat", { message });
      return message;
    }

    clearErrors("vat");
    return true;
  };

  const checkVat = async () => {
    validateVat(company.vat);
  };

  const [link, setLink] = useState(null);

  const validateFiscalCode = (code: string) => {
    let message = undefined;

    if (code?.trim() === "") {
      message = t("fiscalCodeIsRequired");
    }

    if (message) {
      setError("fiscalCode", { message });
      return message;
    }

    clearErrors("fiscalCode");
    return true;
  };

  useEffect(() => {
    if (coordinates) {
      setLink(
        `https://www.google.com/maps/place/${coordinates.lat},${coordinates.lng}`,
      );
    }
  }, [coordinates]);

  const imageObject = useMemo(()=>{
    return {
      url: uploadedImageFile
        ? URL.createObjectURL(uploadedImageFile)
        : company.logo,
      onUpdate: (f) => setUploadedImageFile(f),
    }
  },[uploadedImageFile, company.logo, setUploadedImageFile]);

  return (
    <DataBox
      title={t("details", { ns: "common" })}
      isEditing={isEditing}
      isLoading={isFetching}
      image={imageObject}
      fields={[
        <FormTextField
          key="name"
          name="name"
          label={getFieldLabel(t, "name", "company", "companies")}
          rules={{ required: t("companyNameIsRequired") }}
        />,

        <FormTextField
          key="fiscalCode"
          name="fiscalCode"
          label={getFieldLabel(t, "fiscalCode", "company", "companies")}
          rules={{
            validate: async (value) => validateFiscalCode(value),
          }}
        />,

        <FormTextField
          key="phoneNumber"
          name="phoneNumber"
          label={getFieldLabel(t, "phoneNumber", "company", "companies")}
        />,
        <FormSelectField
          key="vatCountryCode"
          onChange={checkVat}
          name="vatCountryCode"
          label={getFieldLabel(t, "vatCountryCode", "company", "companies", "vatCountryCode.title")}
          rules={{ required: t("errors.required", {ns: "common"}) }}
          displayValue={t(
            `vatCountryCode.${watch("vatCountryCode")}`,
          )}
          options={countries}
        />,
        <FormTextField
          key="vat"
          name="vat"
          label={getFieldLabel(t, "vat", "company", "companies")}
          rules={{ validate: async (value) => validateVat(value) }}
        />,
        <FormTextField
          key="pec"
          name="pec"
          type="email"
          label={getFieldLabel(t, "pec", "company", "companies")}
          rules={{
            required: t("errors.required", { ns: "common" }),
            pattern: {
            value: /\S+@\S+\.\S+/,
            message: t("errors.insert a valid email", { ns: "onboarding" }),
            },
          }}
        />,
        <FormTextField
          key="email"
          name="email"
          type="email"
          label={getFieldLabel(t, "email", "company", "companies")}
          rules={{
            required: t("emailIsRequired"),
            pattern: {
            value: /\S+@\S+\.\S+/,
            message: t("errors.insert a valid email", { ns: "onboarding" }),
            },
          }}
        />,
        <FormSelectField
          key="businessSize"
          name="businessSize"
          label={getFieldLabel(t, "businessSize", "company", "companies")}
          rules={{ required: t("errors.required", {ns: "common"}) }}
          displayValue={t(`size.${watch("businessSize")}`)}
          options={sizes}
        />,
        <FormTextField
          key="ccnl"
          name="ccnl"
          rules={{
            required: t("errors.required", { ns: "common" }),
            minLength: { value: 1, message: t("errors.company.ccnl_is_empty", {ns: "onboarding"}) },
            validate: (value) => value.trim() !== '' || t("errors.company.ccnl_is_empty", {ns: "onboarding"}),
            onChange: () => {
              trigger("ccnl");
            },
          }}
          label={getFieldLabel(t, "ccnl", "company", "companies", "contractType")}
        />,
        <FormTextField
          key="street"
          name="address.street"
          label={getFieldLabel(t, "street", "company", "companies")}
          link={link}
        />,
        <FormTextField
          key="city"
          name="address.city"
          label={getFieldLabel(t, "city", "company", "companies")}
        />,
        <FormTextField
          key="zipCode"
          name="address.cap"
          label={getFieldLabel(t, "zipCode", "company", "companies")}
        />,
      ]}
      customFields={customFields}
    />
  )
}

export default RecordsPanel;