import { useAuth } from "../../../providers/Auth0JWTProvider";
import Tool from "../../../../domain/entities/tool";
import { ToolViewModel } from "../../../viewmodels/tools/ToolViewModel";
import { useMemo, useState } from "react";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { GetSitesFilter, GetSitesWithBadgesFilter } from "../../../../domain/repositories/siteRepository";
import { updateFilterWithDelete } from "../../../../utils";
import { ResourceType } from "../../../screens/Site/ResourceSelectableTable";
import useResourceConversionHook from "../useResourceConversionHook";
import {CustomField} from "../../../../domain/entities/customFields";

const useToolViewModel = (id: string) => {
  const { companyId } = useAuth();
  const [sortResourceDocumentSites, setSortResourceDocumentSites] = useState<SortMeta>();
  const [filterResourceDocumentSites, setFilterResourceDocumentSites] =
    useState<GetSitesFilter>();
  const [sortResourceSites, setSortResourceSites] = useState<SortMeta>();
  const [filterResourceSites, setFilterResourceSites] =
  useState<GetSitesWithBadgesFilter>();
  const [sortSiteCollection, setSortSiteCollection] = useState<SortMeta>();
  const [filterSiteCollection, setFilterSiteCollection] =
    useState<GetSitesFilter>();
  const [resourceLinkableSitesCount, setResourceLinkableSitesCount] = useState<number>();
  const [selectAll, setSelectAll] = useState(false);
  const [showInfoSuccess, setShowInfoSuccess] = useState<string>(null);
  const [documentTypeId, setDocumentTypeId] = useState<string>();
  const [act, setAct] = useState<string>();
  const viewModel = useMemo(() => new ToolViewModel(), []);
  const queryClient = useQueryClient();
  const [enableGetToolSiteCollection, setEnableGetToolSiteCollection] = useState<boolean>(false);

  const {
    data: tool,
    isFetching,
    refetch: getTool,
    isLoading
  } = useQuery<Tool, Error>(
    ["tool", companyId, id],
    async () => await viewModel.get(companyId, id),
    {
      retry: false,
      initialData: undefined,
    },
  );

  const updateMutation = useMutation(
    ({ tool, imageFile }: { tool: Tool; imageFile?: File }) =>
      viewModel.update(companyId, tool, imageFile),
    {
      onError: (err) => {
        console.error(err);
      },
      onSuccess: () => getTool(),
    },
  );

  const updateTool = (tool: Tool) => {
    return updateMutation.mutateAsync({ tool });
  };

  const updateImage = (tool: Tool, imageFile?: File) => {
    updateMutation.mutate({
      tool,
      imageFile,
    });
  };

  const getToolDocumentSites = useInfiniteQuery(
    ["resource-document-sites", companyId, id, sortResourceDocumentSites, filterResourceDocumentSites, documentTypeId],
    async ({ pageParam = 1 }) => {
      const includeWorkingSites = act === "add" || act === "repropagateFiles" ;
      return await viewModel.getToolSites(
        companyId,
        id,
        sortResourceDocumentSites,
        filterResourceDocumentSites,
        pageParam,
        includeWorkingSites,
        false,
        documentTypeId
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: !!sortResourceDocumentSites || !!filterResourceDocumentSites,
    },
  );

  const getToolSites = useInfiniteQuery(
    ["resource-sites", companyId, id, sortResourceSites, filterResourceSites],
    async ({ pageParam = 1 }) => {
      return await viewModel.getToolSites(
        companyId,
        id,
        sortResourceSites,
        filterResourceSites,
        pageParam,
        false,
        true,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
    },
  );

  const hasSites = async (action, documentTypeId) => {
    setAct(action);
    setDocumentTypeId(documentTypeId);
    await queryClient.invalidateQueries([
      "resource-sites",
      companyId,
      id,
      sortResourceSites,
      filterResourceSites,
      action,
    ]);
    const data = await getToolDocumentSites.refetch();
    const sites = data.data?.pages.flatMap((page) => page.results) ?? [];
    return sites.length > 0;
  };

  const getResourceLinkableSites = useInfiniteQuery(
    [
      "site-collection",
      companyId,
      id,
      sortSiteCollection,
      filterSiteCollection,
    ],
    async ({ pageParam = 1 }) => {
      const {results, count} = await viewModel.getResourceLinkableSites(
        companyId,
        "tool" as ResourceType,
        id,
        sortSiteCollection,
        filterSiteCollection,
        pageParam,
      );
      setResourceLinkableSitesCount(count);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetToolSiteCollection,
    },
  );
  const linkToolToSites = (siteIds: string[], copyLastEvaluation: boolean, selectAll?: boolean) => {
    const params = {siteIds, copyLastEvaluation, selectAll, filters: filterSiteCollection}
    linkToolToSitesMutation.mutateAsync(params);
  };
  const linkToolToSitesMutation = useMutation(
    async (params:{ siteIds: string[] , copyLastEvaluation: boolean, selectAll?: boolean, filters?: GetSitesFilter }) => {
      return await viewModel.linkResourceToSites(companyId, id, params.siteIds, params.copyLastEvaluation, params.selectAll, params.filters);
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => {
        getToolSites.refetch();
        setShowInfoSuccess('resourceAssociatedToSite')
      }
    },
  );

  const getToolHasSites = useQuery(
    ["vehicle-has-sites", companyId, id],
    async () => {
      const result =  await viewModel.getToolSites(
        companyId,
        id,
        sortResourceSites,
        filterResourceSites,
        1,
        true,
        true,
      );
      return result.results.length > 0;
    },
  );

  const resourceSites =
    getToolSites.data?.pages?.flatMap((page) => page.results) ?? [];
  const totalSiteCount = getToolSites.data?.pages?.[0]?.count ?? 0;

  const resourceDocumentSites =
  getToolDocumentSites.data?.pages?.flatMap((page) => page.results) ?? [];
  const totalResourceDocumentSiteCount = getToolDocumentSites.data?.pages?.[0]?.count ?? 0;

  /*const conversionProps = useResourceConversionHook({
    companyId,
    resourceIds: [id],
    baseType: "tool" as const,
    twinTypes: ["machine" as const],
    hasSites: getToolHasSites.data,
    viewModel
  })*/
  const getCustomFieldsQuery = useQuery<CustomField[], Error>(
    ["tool-custom-fields", companyId],
    async () => await viewModel.getCustomFields(companyId),
  );

  return {
    tool,
    getTool,
    isFetching,
    hasSites,
    updateImage,
    updateTool,

    resourceDocumentSites,
    isFetchingResourceDocumentSites: getToolDocumentSites.isFetching,
    resourceDocumentSitesHasNextPage: getToolDocumentSites.hasNextPage,
    resourceDocumentSitesFetchNextPage: getToolDocumentSites.fetchNextPage,
    updateFilterResourceDocumentSites: (field, value) => {
      field !== null && updateFilterWithDelete(setFilterResourceDocumentSites, field, value);
    },
    filterResourceDocumentSites,
    setSortResourceDocumentSites,
    sortResourceDocumentSites,
    totalResourceDocumentSiteCount,

    resourceSites,
    setSortResourceSites,
    sortResourceSites,
    setFilterResourceSites,
    filterResourceSites,
    isFetchingSites: getToolSites.isFetching,
    hasNextPage: getToolSites.hasNextPage,
    fetchNextPage: getToolSites.fetchNextPage,
    updateFilterResourceSites: (field, value) => {
      field !== null && updateFilterWithDelete(setFilterResourceSites, field, value);
    },
    siteCollection: getResourceLinkableSites.data?.pages?.flat() ?? [],
    sortSiteCollection,
    setSortSiteCollection,
    filterSiteCollection,
    setFilterSiteCollection,
    updateFilterSiteCollection: (field, value) => {
      field !== null && updateFilterWithDelete(setFilterSiteCollection, field, value);
    },
    setEnableGetToolSiteCollection: setEnableGetToolSiteCollection,
    siteCollectionIsLoading: getResourceLinkableSites.isLoading,
    siteCollectionHasNextPage: getResourceLinkableSites.hasNextPage,
    siteCollectionFetchNextPage: getResourceLinkableSites.fetchNextPage,
    refetchSiteCollection: getResourceLinkableSites.refetch,
    linkToolToSites,
    isLinking: linkToolToSitesMutation.isLoading,
    selectAll,
    setSelectAll,
    //conversionProps,
    totalSiteCount,
    showInfoSuccess,
    setShowInfoSuccess,
    resourceLinkableSitesCount,
    customFields: {
      data: getCustomFieldsQuery.data,
      isLoading: getCustomFieldsQuery.isLoading,
      error: getCustomFieldsQuery.error
    },
    loading: getCustomFieldsQuery.isLoading || isLoading,
  };
};

export default useToolViewModel;
