import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Step,
  StepIcon,
  StepIndicator,
  StepSeparator,
  StepStatus,
  Stepper,
  Text,
  VStack,
  useSteps,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useOnboardingViewModel, {
  SYSTEM_STAFF_ROLES,
} from "../../hooks/Onboarding/useOnboardingViewModel";
import { Alert } from "../Common/Alert";
import OnboardingFooter from "./OnboardingFooter";
import OnboardingHeader from "./OnboardingHeader";
import SignUpCompanyStep1 from "./SignUpCompanyStep1";
import SignUpCompanyStep2 from "./SignUpCompanyStep2";
import SignUpCompanyStep3 from "./SignUpCompanyStep3";

interface SignUpCompanyProps {
  onCancel: () => void;
}

const SignUpCompany: FC<SignUpCompanyProps> = ({ onCancel }) => {
  const { t } = useTranslation("onboarding");
  const STEPS = [1, 2, 3];
  const navigate = useNavigate();
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: STEPS.length,
  });
  const [staffIndex, setStaffIndex] = useState<number>(0);
  const [selectedBusinessType, setSelectedBusinessType] = useState<string>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const handleSubmitError = (error: any) => {
    if (
      error.businessName ||
      error.vat ||
      error.businessSize ||
      error.businessType ||
      error.pec ||
      error.ccnl
    )
      setActiveStep(0);
    else if (SYSTEM_STAFF_ROLES.some((role) => error[role])) {
      setActiveStep(1);
      setStaffIndex(
        SYSTEM_STAFF_ROLES.indexOf(
          SYSTEM_STAFF_ROLES.find((role) => error[role])
        )
      );
    }
  };

  const {
    methods,
    error,
    setError,
    isLoading,
    successCreateAccount,
    createBusinessAccount,
  } = useOnboardingViewModel(handleSubmitError);
  
  const routeChange = () => {
    navigate("/?screen=sign-in");
  };
  const onContinue = () => {
    activeStep === 2
      ? createBusinessAccount()
      : setActiveStep(
          selectedBusinessType === "self_employed" ? 2 : activeStep + 1
        );
  };

  const isStepFieldsEmpty = (fields) => {
    return Object.values(fields).some((field) => !field || (typeof field === "string" && field.trim() === ""));
  };

  const updateIsDisabled = () => {
    const checkErrors = (fields) =>
      fields.some((field) => !!methods.formState.errors[field]);

    if (activeStep === 0) {
      const { businessName, vat, pec, ccnl } = methods.watch();
      setIsDisabled(
        isStepFieldsEmpty({ businessName, vat, pec, ccnl }) ||
          checkErrors(["vat", "pec", "ccnl"])
      );
    } else if (activeStep === 1) {
      const { employer, rls, rspp, doctor, contact } = methods.watch();
      setIsDisabled(
        isStepFieldsEmpty({
          employer: employer?.name && employer?.surname,
          rls: rls?.name && rls?.surname,
          rspp: rspp?.name && rspp?.surname,
          doctor: doctor?.name && doctor?.surname,
          contact: contact?.name && contact?.surname,
        }) ||
        SYSTEM_STAFF_ROLES.some(
          (role) => methods.formState?.errors?.[role]?.email)
      );
    } else if (activeStep === 2) {
      const { email, password, confirmPassword, privacyPolicy } =
        methods.watch();
      setIsDisabled(
        isStepFieldsEmpty({
          email,
          password,
          confirmPassword,
          privacyPolicy,
        }) || checkErrors(["email", "password", "confirmPassword"])
      );
    }
  };

  useEffect(() => {
    updateIsDisabled();
  }, [methods.formState]);

  return (
    <>
      {!successCreateAccount && (
        <>
          <CardHeader>
            <OnboardingHeader
              title={t("ui.businessSteps.step" + (activeStep + 1))}
              onBack={onCancel}
            />
            <Stepper size="sm" mt={2} p={2} index={activeStep} gap="0">
              {STEPS.map((step, index) => (
                <Step key={index}>
                  <StepIndicator onClick={() => setActiveStep(index)}>
                    <StepStatus complete={<StepIcon />} />
                  </StepIndicator>
                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </CardHeader>

          <CardBody pt={0} overflow="auto" maxHeight="calc(100vh - 350px)">
            <SignUpCompanyStep1
              activeStep={activeStep}
              methods={methods}
              setSelectedBusinessType={setSelectedBusinessType}
            />

            {selectedBusinessType !== "self_employed" && (
              <SignUpCompanyStep2
                activeStep={activeStep}
                methods={methods}
                staffIndex={staffIndex}
                setStaffIndex={setStaffIndex}
              />
            )}

            <SignUpCompanyStep3
              activeStep={activeStep}
              methods={methods}
            />
          </CardBody>

          <CardFooter>
            <VStack width="100%" spacing={8}>
              <Button
                isLoading={isLoading}
                variant="ghost"
                color="white"
                _hover={{ bg: "#32A2DB", color: "#FFF" }}
                textColor="#32A2DB"
                onClick={onContinue}
                isDisabled={isDisabled}
              >
                {t(activeStep === 2 ? "confirm" : "next", { ns: "common" })}
              </Button>
              <OnboardingFooter />
            </VStack>
          </CardFooter>
        </>
      )}

      {error && (
        <Alert
          title={t("apiErrors.cannotCreateAccount")}
          message={t(`apiErrors.${error}`)}
          onClose={() => setError(undefined)}
          variant="warning"
        />
      )}

      {successCreateAccount && (
        <CardHeader
          padding={8}
          display={"flex"}
          flexDirection={"column"}
          gap={4}
          alignItems={"center"}
        >
          <Flex flexDirection={"column"} alignItems={"center"} gap={2}>
            <Text textAlign={"center"}>{t("ui.accountCreated")}</Text>
            <Text textAlign={"center"}>{t("ui.verifyEmail")}</Text>
          </Flex>
          <Button
            isLoading={isLoading}
            variant="ghost"
            color="white"
            _hover={{ bg: "#32A2DB", color: "#FFF" }}
            textColor="#32A2DB"
            onClick={routeChange}
          >
            {t("ui.sign in")}
          </Button>
        </CardHeader>
      )}
    </>
  );
};

export default SignUpCompany;
