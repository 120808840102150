import { injectable } from 'tsyringe';
import ExportRepository from '../../domain/repositories/exportRepository';
import { ExportColumn } from '../../presentation/components/Views/Export/ExportDialogData';
import { ApiService } from '../utilities/apiService';
import { formatISODate } from '../utilities/filters';

@injectable()
class ServerExportRepository implements ExportRepository {
	constructor(private apiService: ApiService) {}

	async export(
		companyId: string,
		pathFromItem: string,
		siteId?: string,
		siteIds?: string[],
		startDate?: Date,
		endDate?: Date,
		permissionSites?: { [key: string]: string },
		resourcesIds?: string[],
		resourceDocumentsToExclude?: string[],
		selectedColumns?: string[]
	): Promise<boolean> {

		const buildPath = (basePath: string) =>
			pathFromItem.includes('ws-') ? `sites/${siteId}/suppliers/${companyId}/${pathFromItem.replace('ws-', '')}` : basePath;

		const basePath = siteId ? `sites/${siteId}/${pathFromItem}` : pathFromItem;
		const path = buildPath(basePath);

		const buildRequestBody = (customBody: object = {}) => ({
			...customBody,
			selectedColumns,
		});

		let response = undefined;

		const requirementsTopics = [
			'workers-requirements', 'vehicles-requirements', 'machines-requirements',
			'tools-requirements', 'chemicals-requirements',
			'ws-workers-requirements', 'ws-vehicles-requirements', 'ws-machines-requirements',
			'ws-tools-requirements', 'ws-chemicals-requirements',
			'requirements',
		];

		if (requirementsTopics.includes(pathFromItem)){

			const requestBody = buildRequestBody({
				resourceIds: pathFromItem !== 'requirements' ? resourcesIds : {},
				excludedResourceDocumentTypes: resourceDocumentsToExclude,
			});

			response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/exports/` + path,
				{
					method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(
							requestBody
						),
				}
			);
		}

		const badgesTopics = ['badges-records', 'badges-records-denied'];

		if (badgesTopics.includes(pathFromItem)) {
			const value = Object.entries(permissionSites).map(([key, value]) => {
				const parts = value.split('-');
				return { siteId: key, permission: parts.pop() };
			});
			const requestBody = buildRequestBody({
				siteIds: Object.keys(permissionSites || {}).length > 0 ? value : siteIds,
				fromDate: formatISODate(startDate),
				toDate: formatISODate(endDate),
			});

			const endpoint = pathFromItem === 'badges-records'
                ? `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/exports/${path}`
                : `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/exports/badges-failed-records`;


			if (pathFromItem === 'badges-records'){
				response = await this.apiService.fetchWithToken(
					endpoint,
					{
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(requestBody),
					}
				);
			}else{
				response = await this.apiService.fetchWithToken(
					endpoint,
					{
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(requestBody),
					}
				);
			}
		} 
		if (!response) {

			const requestBody = buildRequestBody();

			response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/exports/` + path,
				{
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(requestBody)
				}
			);
		}

		if (!response.ok) {
			console.error('cannot download file', response.statusText);
		}

		return Promise.resolve(response.ok);
	}

	async exportItemColumns(
		companyId: string,
		topic: string
	): Promise<ExportColumn[]> {

		const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/exports/columns?topic=${topic}`,
				{
					method: 'GET',
						headers: { 'Content-Type': 'application/json' }
				}
			);

		const columns = await response.json();
		return columns;
	}
}

export default ServerExportRepository;
