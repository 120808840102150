import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  useMediaQuery,
  useToast
} from "@chakra-ui/react";
import { format, parse } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Document, { SiteResourceDocument } from "../../../../domain/entities/document";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import FileEntity, { FileFeedback, FileFeedbackEnum } from "../../../../domain/entities/file";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import PendingEvaluation from "../../../../domain/entities/pending-evaluation";
import { RequirementSubject } from "../../../../domain/entities/requirementSubject.enum";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import Site from "../../../../domain/entities/site";
import { LicenseType } from "../../../../domain/interactors/auth/args";
import { GetSitesFilter } from "../../../../domain/repositories/siteRepository";
import { formatDateBasedOnLanguage, getDateFormat, processUploadedFiles } from "../../../../utils";
import { COLORS } from "../../../assets/theme/colors";
import { DocumentCommunicationHook } from "../../../hooks/Document/useDocumentCommunicationVIewModel";
import { UseDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import { UpdateFilter } from "../../../hooks/Site/useSiteResourceDetailViewModel";
import { PermissionCheck, useAuth } from "../../../providers/Auth0JWTProvider";
import { Alert } from "../../../screens/Common/Alert";
import { DeleteActionAlert } from "../../../screens/Common/DeleteActionAlert";
import LoadingView from "../../../screens/Common/LoadingView";
import ImportButton from "../../Common/ImportButton";
import RenderIf, { useHasPermissions } from "../../Permissions/RenderIf";
import { DocumentDetailHeader } from "./DocumentDetailHeader";
import DocumentExpirationDate from "./DocumentExpirationDate";
import { DocumentFileList } from "./DocumentFileList";
import { PreviewFile } from "./PreviewFile";
import { PreviewFileNotReadable } from "./PreviewFileNotReadable";
import { RequirementDetailHeader } from "./RequirementDetailHeader";
import SelectSitesView from "./SelectSitesView";
import { ResourceDocumentsPermissions } from "./SiteResourceDocuments";


interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  setFilterSites?: (value: any) => void;
  isReadOnly: boolean;
  files: FileEntity[];
  document: Document | SiteResourceDocument | PendingEvaluation;
  renameModels?: string[];
  deleteFile?: (fileId: string, siteIds?: string[], selectAll?: boolean) => void;
  addFile?: (files: FileEntity, siteIds: string[], selectAll: boolean) => void;
  editFile?: (
    id?: string,
    name?: string,
    expiresAt?: Date,
    feedback?: FileFeedbackEnum,
    siteIds?: string[],
    selectAll?: boolean
  ) => void;
  editFileIsLoading?: boolean;
  editDocument?: (
    expiresAt?: Date,
    isPublic?: boolean,
    noExpiration?: boolean,
    siteIds?: string[],
    file?: FileEntity,
    selectAll?: boolean
  ) => void;
  evaluateDocument?: (
    documentId: string,
    evaluationState?: ResourceDocumentEvaluationState,
    expirationDate?: string,
    noEvaluationExpiration?: boolean,
    siteIds?: string[],
    selectAll?: boolean,
    showCommunications?: boolean
  ) => void;
  evaluateDocumentIsLoading?: boolean;
  communicationProps?: DocumentCommunicationHook;
  sites?: Site[];
  setDocumentSites?: (sites: Site[]) => void;
  onPrev: () => void;
  onNext: () => void;
  type: "document" | "requirements" | 'evaluation';
  downloader?: UseDownloader;
  showDownloadAll?: boolean;
  canSetExpirationDate?: boolean;
  canViewExpirationDate?: boolean;
  siteGeneralDocument?: boolean;
  fileIsLoading?: boolean;
  requirementSubject?: RequirementSubject;
  isWorkingSite?: boolean;
  canAddFile?: boolean;
  hasSites?: (action: string, documentTypeId?: string) => Promise<boolean>;
  hasSitesNextPage?: boolean;
  fetchSitesNextPage?: () => void;
  siteCount?: number;
  isFetchingSites?: boolean;
  filterSites?: GetSitesFilter;
  updateFilterSites?: UpdateFilter;
  setSortSites?: (sort: SortMeta) => void;
  sortSites?: SortMeta;
  documentsFetching?: boolean;
  deleteIsLoading?: boolean;
  addFileIsLoading?: boolean;
  docDetailPermissions: ResourceDocumentsPermissions;
  resourceId?: string;
  resourceCategory?: DocumentTypeCategory;
  errorMsg?: string | null;
  setErrorMsg?: (value: string) => void;
  selectAllSites?: boolean;
  setSelectAllSites?: (value: boolean) => void;
  showCommunications?: boolean;
  showRepropagate?: boolean
  repropagateEvaluationDocument?: (
    documentId: string,
    siteIds?: string[],
    selectAll?: boolean,
  ) => void,
  repropagateFileDocument?:(
    documentId: string,
    siteIds?: string[],
    selectAll?: boolean,
    selectAllFiles?: boolean,
    fileIds?: string[]
  ) => void,
  repropagateFileToDocumentIsLoading?: boolean,
  documentFilesFetching?: boolean,
  repropagateEvaluationIsLoading?: boolean
  successMsg?: string
  setSuccessMsg?: (value: null) => void
  filesCount?: number
  validateEvaluation?: (evaluationIds: string[], isApproved: boolean) => Promise<void>
  approveIsLoading?: boolean
}

export const DocumentDetailModal = ({
  isOpen,
  onClose,
  isReadOnly,
  files,
  document,
  renameModels,
  communicationProps,
  downloader,
  siteGeneralDocument,
  documentsFetching,
  addFileIsLoading,
  deleteIsLoading,
  errorMsg,
  setErrorMsg,
  showDownloadAll,
  requirementSubject,
  fileIsLoading,
  isWorkingSite,
  isFetchingSites,
  hasSites,
  filterSites,
  updateFilterSites,
  setSortSites,
  sortSites,
  deleteFile,
  addFile,
  editDocument,
  evaluateDocument,
  evaluateDocumentIsLoading,
  sites,
  onPrev,
  onNext,
  type,
  editFile,
  editFileIsLoading,
  docDetailPermissions,
  resourceId,
  resourceCategory,
  hasSitesNextPage,
  fetchSitesNextPage,
  siteCount,
  canAddFile,
  showCommunications,
  showRepropagate,
  repropagateEvaluationDocument,
  repropagateFileDocument,
  repropagateFileToDocumentIsLoading,
  documentFilesFetching,
  repropagateEvaluationIsLoading,
  successMsg,
  setSuccessMsg,
  setFilterSites,
  filesCount,
  validateEvaluation,
  approveIsLoading
}: Props) => {
  const { t } = useTranslation("documents");
  const toast = useToast();
  const [isTablet] = useMediaQuery("max-width: 1300px");
  const [selectedSites, setSelectedSites] = useState<string[]>([]);
  const [updatedFile, setUpdatedFile] = useState<{
    name: string;
    date: string;
    feedback: FileFeedback;
  }>();
  const [selectedEvaluationState, setSelectedEvaluationState] =
    useState<ResourceDocumentEvaluationState>(
      (!isPendingEvaluation(document) && type === "requirements")
        ? document?.evaluationResult
        : ResourceDocumentEvaluationState.UNDEFINED
    );
  const [activeFile, setActiveFile] = useState<FileEntity>();
  const [fileToAdd, setFileToAdd] = useState<FileEntity>();
  const [askConfirmDelete, setAskConfirmDelete] = useState<boolean>(false);
  const [invalidFilename, setInvalidFilename] = useState<boolean>(false);
  const [evaluationDate, setEvaluationDate] = useState<Date>(
    (document as Document)?.evaluationExpiresAt
  );

  function isPendingEvaluation(doc: PendingEvaluation | Document | SiteResourceDocument): doc is PendingEvaluation {
    return doc && typeof doc === 'object' && 'evaluation' in doc; // Modifica 'someSpecificProperty' con una proprietà unica di PendingEvaluation
  }
  function isDocumentWithEvaluationResult(doc: Document | SiteResourceDocument | PendingEvaluation): doc is Document | SiteResourceDocument {
    return 'evaluationResult' in doc && doc.evaluationResult !== undefined;
  }
  const [noEvaluationExpiration, setNoEvaluationExpiration] = useState<boolean>(
    isPendingEvaluation(document) ? undefined :document?.noEvaluationExpiration
  );
  const [documentEvaluationValidity, setDocumentEvaluationValidity] =
    useState<string>((document as SiteResourceDocument)?.suggestExpiration);
  const [action, setAction] = useState<
    "add" | "delete" | "evaluate" | "edit" | "editFile" | 'repropagateEvaluation' | 'repropagateFiles' | string | null
  >();

  const [showErrorDate, setShowErrorDate] = useState(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showSites, setShowSites] = useState<boolean>(false);
  const [showUpdateEvaluation, setShowUpdateEvaluation] =
    useState<boolean>(false);
  const [showErrorEvalution, setShowErrorEvalution] = useState(false);
  const [showErrorPropagation, setShowErrorPropagation] = useState(false);
  const [showSelectAllFiles, setShowSelectAllFiles] = useState(false)
  const [selectAllSites, setSelectAllSites] = useState(false);
  const [selectAllFiles, setSelectAllFiles] = useState(false);
  const canAddFileInDoc = useHasPermissions(docDetailPermissions.addFile, PermissionCheck.All);
  const canAddFileInDocument = (isWorkingSite && canAddFile && canAddFileInDoc) ||
    (!isWorkingSite && canAddFileInDoc)
  
  const [editFileNameAndDate, setEditFileNameAndDate] = useState<string>();
  const [fileIds, setFileIds] = useState<string[]>([])
  const [propagationType, setPropagationType] = useState<'files' | 'evaluation'>()
  const { company } = useAuth();
  
  const dateFormat = getDateFormat();

  const notReadableExtensions = ["xlsx", "xls", "zip", "doc", "docx"];
  const handleDeleteFile = async (siteIds?: string[], selectAll?: boolean) => {
    await deleteFile(activeFile?.id, siteIds ? siteIds : [], selectAll);
    setShowEdit(false);
    setSelectAllSites(false)
    setActiveFile(null);
  };

  const handleAddFile = async (
    file?: FileEntity,
    siteIds?: string[],
    selectAll?: boolean
  ) => {
    setShowEdit(false);
    if (document.id) {
      await addFile(file, siteIds ? siteIds : [], selectAll);
    } else {
      await editDocument(
        (document as Document).expiresAt,
        (document as Document)?.isPublic,
        (document as Document).noExpiration,
        selectedSites,
        file,
        selectAll
      );
    }
    setFileToAdd(null);
    setShowEdit(false);
    if (hasSites && !hasSites("add", type === "document" && (document as Document).type.id )) {
      setAction(null);
    }
    setSelectAllSites(false)
  };
  

  const handleDragIn = () => {
    if(canAddFileInDocument) {
      if (!toast.isActive("uploadToast")) {
        toast({ id: "uploadToast", description: t("dropDocument") });
      }
    } else {
      toast({
        id: "uploadToast",
        description: t("noPermission", { ns: "documents" }),
        status: "error"
      });
    }
  };
  const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    canAddFileInDocument  && setAction("add");

    if (hasSites && !hasSites("add", type === "document" && (document as Document).type.id )) {
      setAction(null);
    }

    if(canAddFileInDocument) {
      if (e?.dataTransfer?.files[0]) {
        const fileList = processUploadedFiles(e.dataTransfer.files);        
        addFileToDoc(fileList, "add");
        setFileToAdd(fileList);
      }
    }
  };

  const handleEditDocument = async (
    validity?: Date,
    isPublic?: boolean,
    noExpiration?: boolean
  ) => {
    setShowSelectAllFiles(false)
    editDocument(validity, isPublic, noExpiration);
  };

  const handleEvaluation = async (
    documentId: string,
    evaluationState: ResourceDocumentEvaluationState
  ) => {
    const hasSiteItems = hasSites && (await hasSites("evaluate", type === "document" && (document as Document).type.id ));
    const isDocumentType = type === "document";
    const isRequirementType = type === "requirements";
    const isAvailableState =
      evaluationState === ResourceDocumentEvaluationState.AVAILABLE;

    if (isAvailableState) {
      setShowEdit(true);
      setAction("evaluate");
      if (isDocumentType) {
        if (hasSiteItems) {
          setShowSites(true);
        } else {
          setShowUpdateEvaluation(true);
          setShowSites(false);
        }
      } else if (isRequirementType) {
        setShowUpdateEvaluation(true);
      }
    } else if (!isAvailableState && isDocumentType && hasSiteItems) {
      setShowEdit(true);
      setShowSites(true);
    } else {
      evaluateDocument(documentId, evaluationState);
      setShowUpdateEvaluation(false);
    }
  };

  const handleSave = async (siteIds?: string[], selectAll?: boolean) => {
    setSelectedSites(siteIds);
    switch (action) {
      case "delete":
        return await handleDeleteFile(siteIds, selectAll);
      case "add":
        return await handleAddFile(fileToAdd, siteIds, selectAll);
      case "edit":
        editDocument(
          (document as Document)?.expiresAt,
          (document as Document)?.isPublic,
          (document as Document).noExpiration,
          siteIds,
          fileToAdd,
          selectAll
        );
        setShowEdit(false);
        return;

      case "evaluate": {
        let evaluationResult: ResourceDocumentEvaluationState | undefined;;
        if(isDocumentWithEvaluationResult(document)) {
          evaluationResult = document.evaluationResult
        } else {
          evaluationResult = undefined
        }
        evaluateDocument(
          document.id,
          selectedEvaluationState || evaluationResult,
          selectedEvaluationState ===
          ResourceDocumentEvaluationState.AVAILABLE &&
          showUpdateEvaluation &&
          evaluationDate
            ? format(evaluationDate, dateFormat)
            : undefined,
          noEvaluationExpiration,
          siteIds,
          selectAll
        );
        setShowEdit(false);
        setSelectAllSites(false)
        setShowUpdateEvaluation(false);
        setSelectedEvaluationState(undefined)
        return;
      }
      case "editFile":
        editFile(
          activeFile?.id,
          updatedFile?.name,
          updatedFile.date
            ? parse(updatedFile.date, dateFormat, new Date())
            : null,
          updatedFile?.feedback?.type,
          siteIds,
          selectAll
        );
        setShowEdit(false);
        return;
      
        case 'repropagateEvaluation': 
        if((document as Document)?.isPublic){
          repropagateEvaluationDocument(
            document.id,
            siteIds,
            selectAll
          )
          setShowEdit(false);
          setSelectAllSites(false)
        }
        return
      
        case 'repropagateFiles': 
        if((document as Document)?.isPublic){
          repropagateFileDocument(
            document.id,
            siteIds,
            selectAll,
            selectAllFiles,
            fileIds
          )
          setShowEdit(false);
          setSelectAllFiles(false)
          setSelectAllSites(false)
          setFileIds([])
          setShowSelectAllFiles(false)
        }
        return

      default:
        break;
    }
  };

  const addFileToDoc = async (file: FileEntity, action: string) => {
    setAction(action);

    if (!(document as Document)?.isPublic) {
      await handleAddFile(file);
      return;
    }

    const hasSiteItems = hasSites && (await hasSites(action, type === "document" && (document as Document).type.id ));
    if (hasSiteItems) {
      setShowEdit(true);
      setShowSites(true);
    } else {
      await handleAddFile(file);
    }
    toast.closeAll();
  };

  const deleteFileFromDoc = async (file: FileEntity) => {
    setAction("delete");
    if (!(document as Document)?.isPublic) {
      setAskConfirmDelete(true);
      return;
    }
    if (siteGeneralDocument) {
      setAskConfirmDelete(true);
    } else {
      const hasSiteItems = hasSites && (await hasSites("delete", type === "document" && (document as Document).type.id ));
      if (hasSiteItems) {
        setShowEdit(true);
        setShowSites(true);
      } else {
        setAskConfirmDelete(true);
      }
    }
  };

  const editFileDetails = async (fileId?: string, fileName?: string, expireDate?: Date, feedbackToUpdate? : FileFeedbackEnum) => {
    setShowEdit(true);
    setAction("editFile");
    if (!(document as Document)?.isPublic) {
      editFile(
        fileId? fileId : activeFile?.id,
        fileName? fileName : updatedFile?.name,
        expireDate ? expireDate : updatedFile?.date
          ? parse(updatedFile?.date, dateFormat, new Date())
          : null,
        feedbackToUpdate ? feedbackToUpdate : updatedFile?.feedback?.type
      );
      setShowEdit(false);
      setAction(null);
      return;
    }
    const hasSiteItems = hasSites && (await hasSites(action, type === "document" && (document as Document).type.id ));
    setShowEdit(false);
    setAction(null);

    if (hasSiteItems) {
      setUpdatedFile((prev) => ({
        ...prev,
        feedback: feedbackToUpdate ? {type: feedbackToUpdate} : updatedFile?.feedback
      }));

      setShowEdit(true);
      setShowSites(true);
      setAction("editFile");
    } else {
      setShowEdit(false);
      editFile(
        fileId? fileId : activeFile?.id,
        fileName? fileName : updatedFile?.name,
        expireDate ? expireDate : updatedFile?.date
          ? parse(updatedFile?.date, dateFormat, new Date())
          : null,
        feedbackToUpdate ? feedbackToUpdate : updatedFile?.feedback?.type
      );
    }
  };

  function isDocument(doc: Document | SiteResourceDocument): doc is Document {
    return (doc as Document).type !== undefined;
  }

  useEffect(() => {
      if ((document as Document)?.expiresAt) {
        setEvaluationDate(new Date((document as Document).expiresAt));
      }
      if (type === "requirements" && document) {
        const formattedDate = format(
          new Date((document as SiteResourceDocument).suggestExpiration),
          dateFormat
        );
        setDocumentEvaluationValidity(formattedDate);
      }
  }, [document, type]);

  useEffect(() => {
    if (addFileIsLoading) {
    setActiveFile(null);
    }else if (files) {
      setActiveFile(files[0]);
    }
  }, [addFileIsLoading, files]);

  useEffect(() => {
    if (document) {
      setAction(null);
    }
  }, [document]);

  useEffect(() => {
    if (activeFile) {
      const formatted =
        activeFile?.expiresAt &&
        formatDateBasedOnLanguage(activeFile?.expiresAt);
      setUpdatedFile((prev) => ({
        ...prev,
        name: activeFile?.name,
        date: formatted || null,
        feedback: activeFile?.feedback
      }));
    }
  }, [activeFile, files]);

  useEffect(() => {
    setAction(null);
  }, [errorMsg]);

  const handleSelectAll = (value) => {
    setSelectAllFiles(value)
    if(!value) {
      setFileIds([])
    } else {
      setSelectAllFiles(value);
      setFileIds(files.map((file) => file.id));
    }
  }


  const handleCheckItem = (id: string) => {
    let updatedFileIds;

    if (fileIds && !fileIds.includes(id)) {
      updatedFileIds = [...fileIds, id];
    } else {
      updatedFileIds = fileIds.filter((i) => i !== id);
    }

    setFileIds(updatedFileIds);

    if (updatedFileIds.length === files.length) {
      setSelectAllFiles(true);
    } else {
      setSelectAllFiles(false);
    }
  };

  const handleRepropagation = async (propType: 'files' | 'evaluation') => {
    setPropagationType(propType)
      if(propType === 'files' ) {
        setShowSelectAllFiles(!showSelectAllFiles)
      } else {
        const hasSiteItems = hasSites && (await hasSites(action, type === "document" && (document as Document).type.id ));
        if (hasSiteItems) {
        setAction('repropagateEvaluation')
        setShowEdit(true)
        setShowSites(true);
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setActiveFile(null);
      }}
      size={"6xl"}
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent h={"85vh"} w={"full"}>
        <ModalCloseButton />
        <Grid
          templateRows="repeat(12, 1fr)"
          templateColumns="repeat(3, 1fr)"
          borderRadius={"md"}
          h={"full"}
        >
          {type === "document" ? (
            <DocumentDetailHeader
              document={document as Document}
              renameModels={renameModels}
              isReadOnly={isReadOnly}
              downloader={downloader}
              editDocument={(validity, isPublic, noExpiration) =>
                handleEditDocument(validity, isPublic, noExpiration)
              }
              evaluateDocument={(documentId, evaluationState) =>
                handleEvaluation(documentId, evaluationState)
              }
              action={action}
              setAction={setAction}
              selectedEvaluationState={selectedEvaluationState}
              setSelectedEvaluationState={setSelectedEvaluationState}
              setActiveFile={(file) => {
                setActiveFile(file);
              }}
              showOverlay={showUpdateEvaluation}
              communicationProps={communicationProps}
              setShowEdit={setShowEdit}
              setShowUpdateEvaluationDate={setShowUpdateEvaluation}
              onPrev={onPrev}
              onNext={onNext}
              showDownloadAll={showDownloadAll}
              siteGeneralDocument={siteGeneralDocument}
              hasSites={hasSites}
              documentsFetching={documentsFetching}
              disabledFunctions={fileToAdd && action !== null}
              setFileToAdd={setFileToAdd}
              setShowSites={setShowSites}
              permissions={docDetailPermissions}
              setShowErrorEvalution={setShowErrorEvalution}
              handleRepropagation={handleRepropagation}
              fileIds={fileIds}
              showRepropagate={company?.type !== LicenseType.Smart && showRepropagate}
              showSelectAllFiles={showSelectAllFiles}
            />
          ) : (
            <RequirementDetailHeader
              resourceId={resourceId}
              resourceCategory={resourceCategory}
              document={document as SiteResourceDocument}
              documentEvaluation={document as PendingEvaluation}
              requirementSubject={requirementSubject}
              renameModels={renameModels}
              downloader={downloader}
              evaluateDocument={(documentId, evaluationState) =>
                handleEvaluation(documentId, evaluationState)
              }
              selectedEvaluationState={selectedEvaluationState}
              setSelectedEvaluationState={setSelectedEvaluationState}
              setShowEdit={setShowEdit}
              setShowUpdateEvaluationDate={setShowUpdateEvaluation}
              setActiveFile={(file) => {
                setActiveFile(file);
              }}
              showOverlay={showUpdateEvaluation}
              communicationProps={communicationProps}
              isWorkingSite={isWorkingSite}
              updateEvaluationDate={String(evaluationDate)}
              sites={sites}
              onPrev={onPrev}
              onNext={onNext}
              hasSites={hasSites}
              documentsFetching={documentsFetching}
              permissions={docDetailPermissions}
              action={action}
              setAction={setAction}
              canComment={showCommunications}
              isReadOnly={isReadOnly}
              filesCount={filesCount}
              type={type}
              validateEvaluation={validateEvaluation}
              approveIsLoading={approveIsLoading}
              onCloseModal={onClose}
            />
          )}
          <GridItem
            colSpan={1}
            rowSpan={11}
            w={"sm"}
            bg="white"
            h={"full"}
            borderRadius={"0 0 0 5px"}
            onDragEnter={() => handleDragIn()}
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => e.preventDefault()}
          >
            <Box
              h={docDetailPermissions.edit ? "90%" : "100%"}
              overflow={"auto"}
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
              borderRight={"1px solid #efefef"}
            >
              {fileIsLoading ? (
                <Stack>
                  <Skeleton height="140px" />
                  <Skeleton height="140px" />
                  <Skeleton height="140px" />
                  <Skeleton height="140px" />
                </Stack>
              ) : (
                <DocumentFileList
                siteGeneralDocument={siteGeneralDocument}
                activeFile={activeFile}
                canAddFile={canAddFileInDocument}
                deleteFileFromDoc={(file) => deleteFileFromDoc(file)}
                docDetailPermissions={docDetailPermissions}
                document={document}
                downloader={downloader}
                editFileDetails={editFileDetails}
                editFileNameAndDate={editFileNameAndDate}
                files={files}
                renameModels={renameModels}
                setActiveFile={setActiveFile}
                setEditFileNameAndDate={setEditFileNameAndDate}
                setUpdatedFile={setUpdatedFile}
                type={type}
                updatedFile={updatedFile}
                editFileIsLoading={editFileIsLoading}
                setAction={setAction}
                selectAllFiles={selectAllFiles}
                handleSelectAll={handleSelectAll}
                fileIds={fileIds}
                handleCheckItem={handleCheckItem}
                showRepropagate={showRepropagate}
                setShowSelectAllFiles={setShowSelectAllFiles}
                showSelectAllFiles={showSelectAllFiles}
              />
              )}
            </Box>
              {canAddFileInDocument && !showSelectAllFiles &&
                <Flex
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  h={"10%"}
                  borderRight={"1px solid #efefef"}
                >
                <ImportButton
                  isDisabled={action !== null || fileIsLoading}
                  bgColor={COLORS.sikuroBlue}
                  textColor="white"
                  description={t("add")}
                  type="btn"
                  uploadFile={(file) => {
                    if (file) {
                      addFileToDoc(file, "add");
                      setFileToAdd(file);
                    }
                  }}
                />
                </Flex>
              }
            <RenderIf permissions={docDetailPermissions.addFile}>
              {showSelectAllFiles && <Flex
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"10%"}
                borderRight={"1px solid #efefef"}
              >
                <Button 
                disabled={fileIds.length === 0}  
                textColor="white" 
                bgColor={COLORS.sikuroBlue}
                onClick={async() => {
                  const hasSiteItems = hasSites && (await hasSites('repropagateFiles', type === "document" && (document as Document).type.id ));
                  if (hasSiteItems) {
                    setAction('repropagateFiles')
                    setShowEdit(true)
                    setShowSites(true);
                  } else {
                    setShowErrorPropagation(true)
                  }
                }}>
                  {t('repropagateFiles')}
                </Button>

              </Flex>}
            </RenderIf>
          </GridItem>
          <GridItem
            colSpan={2}
            rowSpan={11}
            borderRadius={"5px"}
            zIndex={
              action === "evaluate" ||
              action === "add" ||
              action === "editFile" ||
              action === "delete" || 
              action === "repropagateEvaluation" ||
              action === "repropagateFiles" || 
              editFileIsLoading
                ? 999
                : 0
            }
            bg={
              action === "evaluate" ||
              action === "add" ||
              action === "editFile" ||
              action === "delete" ||
              action === "repropagateEvaluation" ||
              action === "repropagateFiles" || 
              editFileIsLoading
                ? COLORS.white
                : ""
            }
          >
            {showEdit ? (
              <Box
                p={4}
                h={"full"}
                overflow={"auto"}
                zIndex={
                  action === "evaluate" ||
                  action === "add" ||
                  action === "editFile" ||
                  action === "delete" || 
                  action === "repropagateEvaluation" ||
                  action === "repropagateFiles" ||
                  editFileIsLoading
                    ? 999
                    : 0
                }
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
                id="modal-body"
              >
                {showUpdateEvaluation && (
                  <Box>
                    {type === "requirements" && (
                      <>
                        <Text marginTop={2} fontWeight={"bold"}>
                          {t("manageEvaluationExpiration", {
                            ns: "siteResources",
                          })}
                        </Text>

                        <Flex
                          alignItems={"center"}
                          gap={2}
                          mt={1}
                          fontSize={"18px"}
                          width={"100%"}
                        >
                          <DocumentExpirationDate
                            setAction={setAction}
                            expiresAt={new Date(documentEvaluationValidity)}
                            documentDuration={
                              !isPendingEvaluation(document) &&
                              isDocument(document)
                                ? document?.type?.duration
                                : !isPendingEvaluation(document) &&
                                    !isDocument(document)
                                  ? document?.documentTypeDuration
                                  : 0
                            }
                            isDisabled={noEvaluationExpiration}
                            updateExpirationDate={(data) =>
                              setEvaluationDate(data)
                            }
                            noExpiration={noEvaluationExpiration}
                            setNoExpiration={setNoEvaluationExpiration}
                            showNoExpiration={true}
                            showButtons={false}
                          />
                        </Flex>
                        <Flex gap={4} py={4} justifyContent={"flex-end"}>
                          <Button
                            colorScheme="red"
                            onClick={() => {
                              let evaluationResult;
                              if (isDocumentWithEvaluationResult(document)) {
                                evaluationResult = document.evaluationResult;
                              } else {
                                evaluationResult = undefined;
                              }
                              setAction(null);
                              setShowErrorDate(false);
                              setShowUpdateEvaluation(false);
                              setShowEdit(false);
                              setSelectedEvaluationState(evaluationResult);
                            }}
                          >
                            {t("cancel", { ns: "common" })}
                          </Button>
                          <Button
                            colorScheme="blue"
                            isDisabled={
                              showErrorDate ||
                              (!noEvaluationExpiration && !evaluationDate)
                            }
                            onClick={() => {
                              evaluateDocument(
                                document.id,
                                selectedEvaluationState,
                                evaluationDate
                                  ? format(new Date(evaluationDate), dateFormat)
                                  : undefined,
                                noEvaluationExpiration,
                              );
                              setShowEdit(false);
                              setShowErrorDate(false);
                              setShowUpdateEvaluation(false);
                              setAction(null);
                            }}
                          >
                            {t("save", { ns: "common" })}
                          </Button>
                        </Flex>
                      </>
                    )}
                    {type === "document" && (
                      <>
                        {sites.length > 0 && (
                          <>
                            <Text marginTop={3} fontWeight={"bold"}>
                              {t("manageEvaluationExpiration", {
                                ns: "siteResources",
                              })}
                            </Text>
                            <Flex
                              alignItems={"center"}
                              gap={2}
                              mt={2}
                              fontSize={"18px"}
                              width={"100%"}
                            >
                              <DocumentExpirationDate
                                setAction={setAction}
                                expiresAt={evaluationDate}
                                documentDuration={
                                  !isPendingEvaluation(document) &&
                                  isDocument(document)
                                    ? document?.type?.duration
                                    : !isPendingEvaluation(document) &&
                                        !isDocument(document)
                                      ? document?.documentTypeDuration
                                      : 0
                                }
                                isDisabled={noEvaluationExpiration}
                                updateExpirationDate={(data) => {
                                  setEvaluationDate(data);
                                }}
                                noExpiration={noEvaluationExpiration}
                                setNoExpiration={setNoEvaluationExpiration}
                                showNoExpiration={true}
                                showButtons={false}
                              />
                            </Flex>
                          </>
                        )}
                      </>
                    )}
                  </Box>
                )}
                {showSites && (
                      <SelectSitesView
                        autosize
                        title={propagationType === 'evaluation' ? t("selectSitesForEvalRepropagation") : propagationType === 'files' ? t("selectSitesForFilesRepropagation") : t("selectSites")}
                        reminderText={t("noSiteSelected", { ns: "common" })}
                        alertText={t("siteSelectionAlert", { ns: "common" })}
                        onClose={() => {
                          setSelectedEvaluationState(
                            isDocumentWithEvaluationResult(document) &&
                              type === "requirements"
                              ? document.evaluationResult
                              : ResourceDocumentEvaluationState.UNDEFINED,
                          );
                          setShowEdit(false);
                          setAction(null);
                          setShowUpdateEvaluation(false);
                          setActiveFile(null);
                          updateFilterSites(null, null);
                          setEvaluationDate(null);
                          setNoEvaluationExpiration(false);
                          setFilterSites && setFilterSites(null);
                          setShowSelectAllFiles(false)
                        }}
                        isFetchingSites={isFetchingSites  || addFileIsLoading}
                        onSaveAction={(siteIds, selectAll) => {
                          handleSave(selectAll ? [] : siteIds, selectAll);
                          setAction(null);
                          setShowErrorDate(false);
                          setFilterSites && setFilterSites(null);
                          setShowSelectAllFiles(false)
                        }}
                        action={action}
                        isDisabled={
                          showErrorDate ||
                          (action === "evaluate" &&
                            selectedEvaluationState ===
                              ResourceDocumentEvaluationState.AVAILABLE &&
                            !noEvaluationExpiration &&
                            !evaluationDate)
                        }
                        siteList={sites}
                        includeFooterButton={true}
                        operationIsBusy={addFileIsLoading}
                        filterResourceSites={filterSites}
                        updateFilterResourceSites={updateFilterSites}
                        setSortResourceSites={setSortSites}
                        sortResourceSites={sortSites}
                        isTablet={isTablet}
                        hasNextPage={hasSitesNextPage}
                        fetchNextPage={fetchSitesNextPage}
                        siteCount={siteCount}
                        selectAllSites={selectAllSites}
                        setSelectAllSites={setSelectAllSites}
                        showSelectAll={true}
                      />
                )}
              </Box>
            ) : (
              <Flex
                p={4}
                h={"full"}
                overflow={"auto"}
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
                flexDirection={"column"}
                gap={4}
              >
                {!activeFile && !showErrorEvalution && (
                  <Flex
                    width="100%"
                    height="100%"
                    justifyContent="center"
                    alignContent="center"
                    style={{
                      border: "1px solid #E2E8F2",
                      borderRadius: "4px",
                      height: "100%",
                    }}
                  >
                      {isFetchingSites ||
                        fileIsLoading ||
                        addFileIsLoading ||
                        showUpdateEvaluation ||
                        repropagateFileToDocumentIsLoading ? (
                      <LoadingView />
                    ) : (
                      <Text alignSelf={"center"}>
                        {t("fileNotSelected", { ns: "documents" })}
                      </Text>
                    )}
                  </Flex>
                )}

                {showErrorEvalution && (
                  <Box gap={4} py={4}>
                    <Text color="red" textAlign={"center"} fontWeight={"bold"}>
                      {t("warning", { ns: "common" })}
                    </Text>
                    <Text color="red">{t("noSitesToAssociateEvaluation")}</Text>
                    <Flex gap={4} py={4} justifyContent={"flex-end"}>
                      <Button
                        colorScheme="yellow"
                        onClick={() => {
                          setAction(null);
                          setShowErrorEvalution(false);
                          setShowUpdateEvaluation(false);
                          setShowErrorDate(false);
                          setShowEdit(false);
                        }}
                      >
                        {t("cancel", { ns: "common" })}
                      </Button>
                    </Flex>
                  </Box>
                )}

                {showErrorPropagation && (
                  <Box gap={4} py={4}>
                    <Text color="red" textAlign={"center"} fontWeight={"bold"}>
                      {t("warning", { ns: "common" })}
                    </Text>
                    <Text color="red">{t("noSitesToAssociateEvaluation")}</Text>
                    <Flex gap={4} py={4} justifyContent={"flex-end"}>
                      <Button
                        colorScheme="yellow"
                        onClick={() => {
                          setAction(null);
                          setShowErrorPropagation(false);
                          setShowEdit(false);
                          setShowSelectAllFiles(false)
                        }}
                      >
                        {t("cancel", { ns: "common" })}
                      </Button>
                    </Flex>
                  </Box>
                )}

                {activeFile &&
                    (fileIsLoading ||
                    repropagateEvaluationIsLoading ||
                    repropagateFileToDocumentIsLoading ||
                    documentFilesFetching ||
                    evaluateDocumentIsLoading ? (
                    <LoadingView />
                  ) : notReadableExtensions.includes(activeFile.ext) ? (
                    <PreviewFileNotReadable activeFileName={activeFile.name} />
                  ) : (
                    <PreviewFile activeFile={activeFile} />
                  ))}
              </Flex>
            )}
          </GridItem>
        </Grid>
        {askConfirmDelete && (
          <DeleteActionAlert
            onConfirm={async () => {
              await handleDeleteFile();
              setAskConfirmDelete(false);
              setAction(null);
            }}
            onCancel={() => {
              setAskConfirmDelete(false);
              setAction(null);
            }}
            isLoading={deleteIsLoading}
            mainTitle={t("warning", { ns: "common" })}
            title={t("confirmDeleteFile")}
            leftButtonText={t("confirm", { ns: "common" })}
            rightButtonText={t("cancel", { ns: "common" })}
            isOpen={!!askConfirmDelete}
          />
        )}
        {errorMsg && (
          <Alert
            title={t("warning", { ns: "common" })}
            message={t(errorMsg, { ns: "errors" })}
            variant="info"
            onClose={() => {
              setAction(null);
              setErrorMsg(null);
            }}
          />
        )}
         {successMsg && (
          <Alert
            title={t("warning", { ns: "common" })}
            message={t(successMsg, { ns: "common" })}
            variant="info"
            onClose={() => {
              setAction(null);
              setSuccessMsg(null);
            }}
          />
        )} 
        {invalidFilename && (
          <Alert
            onClose={() => {
              setInvalidFilename(false);
              setAction(null);
            }}
            title={t("warning", { ns: "common" })}
            message={t("invalidFileName")}
            variant="question"
          />
        )}
      </ModalContent>
    </Modal>
  );
};
