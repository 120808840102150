import { useMemo, useState } from "react";

import { useAuth } from "../../providers/Auth0JWTProvider";
import BadgeReader from "../../../domain/entities/badgeReader";
import { useQuery, useMutation, useInfiniteQuery } from "@tanstack/react-query";
import { SiteBadgeReaderDetailViewModel } from "../../viewmodels/sites/SiteBadgeReaderDetailViewModel";
import { GetBadgeReaderHistoryFilters } from "../../../domain/repositories/badgeReaderRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { BadgeReaderState } from "../../../domain/entities/badgeReaderState.enum";
import { BadgeLogEntry } from "../../../domain/entities/badgeLogEntry";
import { BadgeReaderDirection } from "../../../domain/entities/badgeReaderDirection.enum";
import { updateFilterWithDelete } from "../../../utils";
import { BadgeReaderGate } from "../../../domain/entities/badgeReaderGate.enum";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";

const useSitesBadgeReaderDetailViewModel = (
  siteId: string,
  badgeReaderId: string
) => {
  const { companyId } = useAuth();
  const viewModel = useMemo(() => new SiteBadgeReaderDetailViewModel(), []);
  const [filters, setFilters] = useState<GetBadgeReaderHistoryFilters>({});
  const [sort, setSort] = useState<SortMeta>(null);
  const [stampingMgmtActive, setStampingMgmtActive] = useState(false);
  const [error, setError] = useState<string>();
  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>([]);
  const [filtersSites, setFiltersSites] = useState<GetSitesFilter>({});
  const [sortSites, setSortSites] = useState<SortMeta>(null);
  const [enableGetSites, setEnableGetSites] = useState<boolean>(false);

  const {
    data: badgeReader,
    isFetching: fetchingBadgeReader,
    refetch: getBadgeReader,
  } = useQuery<BadgeReader, Error>(
    ["worker", companyId],
    async () =>
      await viewModel.getSiteBadgeReaderDetail(
        companyId,
        siteId,
        badgeReaderId
      ),
    {
      retry: false,
      initialData: undefined,
    }
  );
  const {
    data: badgeReadersSites,
    isFetching: fetchingBadgeReaderSites,
    refetch: getBadgesReadersSites,
  } = useInfiniteQuery(
    ["getBadgeReadersSites", companyId, filtersSites, sortSites],
    async ({ pageParam = 1 }) => {
      const { results } = await viewModel.getSiteBadgeReadersSites(
        companyId,
        badgeReaderId,
        pageParam,
        filtersSites,
        sortSites
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetSites,
    }
  );

  const updateBadgeReaderStatusMutation = useMutation(
    (status: BadgeReaderState) =>
      viewModel.updateBadgeReaderStatus(companyId, siteId, {
        id: badgeReaderId,
        status,
      }),
    {
      onSuccess: () => getBadgeReader(),
    }
  );

  const updateBadgeReaderMutation = useMutation(
    (data: {
      name: string;
      serial: string;
      direction: BadgeReaderDirection;
      releConfiguration?: BadgeReaderGate;
      timeZone: string;
      delay?: number;
      notificationDelay?: number;
    }) => {
      return viewModel.updateBadgeReader(companyId, siteId, {
        id: badgeReaderId,
        name: data.name,
        serial: data.serial,
        direction: data.direction,
        releConfiguration: data?.releConfiguration,
        timeZone: data.timeZone,
        delay: data.delay,
        notificationDelay: data.notificationDelay,
      });
    },
    {
      onSuccess: () => getBadgeReader(),
    }
  );

  const getBadgeReaderHistoryQuery = useInfiniteQuery(
    ["badges", companyId, badgeReaderId, sort, filters],
    async ({ pageParam = 1 }) => {
      return await viewModel.getBadgeReaderHistory(
        companyId,
        siteId,
        badgeReaderId,
        sort,
        filters,
        pageParam
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );

  const createBadgeLogEntry = useMutation(
    (badgeLogEntry: BadgeLogEntry) =>
      viewModel.createBadgeLogEntry(
        companyId,
        siteId,
        badgeLogEntry,
        badgeReaderId
      ),
    {
      onSuccess: () => getBadgeReaderHistoryQuery.refetch(),
      onError: (error: Error) => {
        setError(error.message), getBadgeReaderHistoryQuery.refetch();
      },
    }
  );

  const deleteBadgeLogEntryMutation = useMutation(
    (badgeLogEntry: BadgeLogEntry) =>
      viewModel.deleteBadgeReaderLogEntry(
        companyId,
        siteId,
        badgeLogEntry,
      ),
    {
      onSuccess: () => {
        getBadgeReaderHistoryQuery.refetch();
      },
    }
  );

  const linkBadgeReaderToSitesMutation = useMutation(
    () =>
      viewModel.linkBadgeReaderToSites(
        companyId,
        siteId,
        badgeReaderId,
        selectedSiteIds
      ),
    {
      onSuccess: () => getBadgesReadersSites(),
    }
  );

  const getSiteBadgesQuery = useQuery(
    ["getBadges", companyId],
    async () => {
      return await viewModel.getSiteBadgesNoPaging(companyId, siteId);
    },
    {
      initialData: [],
      enabled: stampingMgmtActive,
    }
  );

  const updateFilter = (
    field: keyof GetBadgeReaderHistoryFilters,
    value: string | string[] | [Date, Date]
  ) => {
    updateFilterWithDelete(setFilters, field, value);
  };

  const updateSitesFilter = (
    field: keyof GetSitesFilter,
    value: string | string[] | [Date, Date]
  ) => {
    updateFilterWithDelete(setFiltersSites, field, value);
  };

  const sites = badgeReadersSites?.pages.flat() ?? [];

  return {
    badgeReader,
    getBadgeReader,
    badgesAvailable: getSiteBadgesQuery.data.filter(
      (badge) => badge.resource !== undefined
    ),
    setStampingMgmtActive,
    updateBadgeReaderStatus: updateBadgeReaderStatusMutation.mutate,
    updateBadgeReader: updateBadgeReaderMutation.mutate,
    history: getBadgeReaderHistoryQuery.data?.pages?.flat() ?? [],
    hasNextPage: getBadgeReaderHistoryQuery.hasNextPage,
    fetchNextPage: getBadgeReaderHistoryQuery.fetchNextPage,
    historyIsLoading: getBadgeReaderHistoryQuery.isLoading,
    filters,
    updateFilter,
    updateSitesFilter,
    filtersSites,
    sort,
    setSort,
    sortSites,
    setSortSites,
    fetchingBadgeReader,
    fetchingBadgeReaderSites,
    createBadgeLogEntry: createBadgeLogEntry.mutate,
    deleteBadgeLogEntry: deleteBadgeLogEntryMutation.mutateAsync,
    linkBadgeReaderToSites: linkBadgeReaderToSitesMutation.mutateAsync,
    linkBadgeReaderToSitesIsLoading: linkBadgeReaderToSitesMutation.isLoading,
    deleteBadgeLogEntryIsLoading: deleteBadgeLogEntryMutation.isLoading,
    setEnableGetSites,
    sites,
    error,
    setError,
    setSelectedSiteIds,
    selectedSiteIds,
  };
};

export default useSitesBadgeReaderDetailViewModel;
