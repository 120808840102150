import {
  Box,
  Button,
  Checkbox,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Tooltip,
  useSteps,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import BaseModal from "../../Common/alerts/BaseModal";
import Badge from "../../../../domain/entities/badge";
import Tag from "../../../../domain/entities/tag";
import { GetAvailableBadgesFilters } from "../../../../domain/repositories/badgeRepository";
import SelectBadgesTable from "./SelectBadgesTable";
import { SiteWorkerParams } from "../../../../domain/entities/siteWorker";
import { SiteResource } from "../../../../domain/entities/siteResource";
import { SiteMachineParams } from "../../../../domain/entities/siteMachine";
import { SiteVehicleParams } from "../../../../domain/entities/siteVehicle";
import { BadgeResourceType } from "../../../../domain/entities/badgeResourceType.enum";
import { AllResourcesFilters, GetSiteResourcesFilters } from "../../../hooks/Badge/useSiteBadgesListViewModel";
import Steps from "../../Common/stepper/Steps";
import SelectSiteResourcesTable from "./SelectSiteResourcesTable";
import TextInput from "../../Common/TextInput";
import { COLORS } from "../../../assets/theme/colors";
import { FaExclamationCircle } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import { useApp } from "../../../../app";
import RenderIf from "../../Permissions/RenderIf";
import { Permission } from "../../Permissions/Permissions";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";

  export type TableColumn = {
    field: AllResourcesFilters;
    width: string;
  };

  export type TableColumns = {
    [key in BadgeResourceType]: TableColumn[];
  };

type LinkBadgesToSiteResourcesModalProps = {
  availableBadges: Badge[];
  availableBadgesCount: number;
  availableBadgesTags: Tag[];
  availableBadgesHasNextPage: boolean;
  availableBadgesFetchNextPage: () => void;
  availableBadgesIsLoading?: boolean;
  availableBadgesFilters: { [p: string]: any };
  availableBadgesSort: SortMeta;
  updateAvailableBadgesFilter: (
    field: keyof GetAvailableBadgesFilters,
    value: string | string[],
  ) => void;
  setAvailableBadgesSort: (sort: SortMeta) => void;
  onConfirm: (
    badgeIds: string[],
    resourceIds: string[],
    selectAllResources: boolean,
    selectedResourcesNumber: number,
    siteResourcesFilters: GetSiteResourcesFilters,
    selectAllBadges: boolean,
    selectedBadgesNumber: number,
    availableBadgesFilters: GetAvailableBadgesFilters,
    automaticLinking: boolean,
  ) => void;
  onClose: () => void;
  siteResources: SiteResource<
    SiteWorkerParams | SiteMachineParams | SiteVehicleParams
  >[];
  siteResourcesIsLoading: boolean;
  registeredSiteResourceCount: number;
  resourceType: BadgeResourceType;
  setResourceType: (resourceType: BadgeResourceType) => void;
  siteResourcesFilters: GetSiteResourcesFilters;
  updateSiteResourcesFilter: (
    field: keyof GetSiteResourcesFilters,
    value: string,
  ) => void;
  siteResourcesSort: SortMeta;
  setSiteResourcesSort: (sort: SortMeta) => void;
  siteResourcesHasNextPage: boolean;
  siteResourcesFetchNextPage: () => void;
  openFileImport: () => void;
  linkingIsLoading: boolean;
  search?: string;
  setSearch?: (search: string) => void;
};
const LinkBadgesToSiteResourcesModal = ({
  onClose,
  onConfirm,
  availableBadges,
  availableBadgesCount,
  availableBadgesTags,
  availableBadgesFilters,
  updateAvailableBadgesFilter,
  availableBadgesSort,
  setAvailableBadgesSort,
  availableBadgesHasNextPage,
  availableBadgesFetchNextPage,
  resourceType,
  availableBadgesIsLoading,
  siteResources,
  siteResourcesIsLoading,
  registeredSiteResourceCount,
  setResourceType,
  siteResourcesFilters,
  updateSiteResourcesFilter,
  siteResourcesSort,
  setSiteResourcesSort,
  siteResourcesHasNextPage,
  siteResourcesFetchNextPage,
  openFileImport,
  linkingIsLoading,
  search,
  setSearch,
}: LinkBadgesToSiteResourcesModalProps) => {
  const { t } = useTranslation("badges");
  const { context } = useApp();

  const [badgeIds, setBadgeIds] = useState<string[]>([]);
  const [resourceIds, setResourceIds] = useState<string[]>([]);
  const [automaticLinking, setAutomaticLinking] = useState(false);

  const [selectAllBadgesVisible, setSelectAllBadgesVisible] = useState(false);
  const [selectAllBadgesMatching, setSelectAllBadgesMatching] = useState(false);
  // custom number of selected badges when selectAllMatching is true (select all takes 500 badges, we can choose with an input field to select just 200)
  const [selectedBadgesNumber, setSelectedBadgesNumber] = useState<number>(0);

  const [selectAllResourcesVisible, setSelectAllResourcesVisible] =
    useState(false);
  const [selectAllResourcesMatching, setSelectAllResourcesMatching] =
    useState(false);
  // custom number of selected resources when selectAllMatching is true (select all takes 500 resources, we can choose with an input field to select just 200)
  const [selectedResourcesNumber, setSelectedResourcesNumber] =
    useState<number>(0);
  const [warningMultipleLinking, setWarningMultipleLinking] =
    useState<boolean>(false);

  useEffect(() => {
    if (context.site?.options?.automaticBadgeLinking) {
      setAutomaticLinking(context.site?.options?.automaticBadgeLinking);
    }
  }, [context.site?.options?.automaticBadgeLinking]);

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: 3,
  });
  const onSelectStep = (index: number) => {
    if (activeStep === 1 && index === 2 && resourceIds.length === 0) return;
    setActiveStep(index);
  };

  const handleNext = async () => {
    if (activeStep === 2) {
      if (
        (badgeIds.length > 0 &&
          (resourceIds.length === badgeIds.length ||
            selectedBadgesNumber === selectedResourcesNumber)) ||
        automaticLinking
      ) {
        await onConfirm(
          badgeIds,
          resourceIds,
          selectAllResourcesMatching,
          selectedResourcesNumber,
          siteResourcesFilters,
          selectAllBadgesMatching,
          selectedBadgesNumber,
          availableBadgesFilters,
          automaticLinking,
        );
        onClose();
      }
    } else {
      onSelectStep(activeStep + 1);
      if (!selectAllResourcesMatching || resourceIds.length ) {
        setSelectedResourcesNumber(resourceIds?.length);
      }
    }
  };

  const handleSelectStep = (index: number) => {
    if (activeStep < 2 && index === 2 && resourceIds.length === 0) {
      return;
    }
    setActiveStep(index);
  };

  const tableColumns: TableColumns = {
    [BadgeResourceType.WORKER]: [
      {field: "lastName", width: "150px"},
      {field: "firstName", width: "150px"},
      {field: "fiscalCode", width: "230px"},
      {field: "jobTitle", width: "150px"},
      {field: "company", width: "150px"},
    ],
    [BadgeResourceType.MACHINE]: [
      { field: "name", width: "150px" },
      { field: "plate", width: "150px" },
      { field: "model", width: "110px" },
      { field: "company", width: "150px" }
    ],
    [BadgeResourceType.VEHICLE]: [
      {field: "name", width: "150px"}, 
      {field: "plate", width: "150px"}, 
      {field: "model", width: "110px"}, 
      { field: "company", width: "150px" }
    ],
  };

  return (
    <BaseModal
      onClose={onClose}
      footer={[
        <RenderIf permissions={Permission.Company_LinkBadgeResource} key={"message"} check={PermissionCheck.All}>
          <Text
            textAlign="center"
            mx="auto"
            key={"message"}
            display={activeStep === 1 ? "block" : "none"}
          >
            <Button
              mt="10px"
              ml="4px"
              colorScheme="blue"
              variant="link"
              onClick={() => openFileImport()}
            >
              {t("clickForLinkImport", { ns: "badges" })}
            </Button>
          </Text>
        </RenderIf>,
        <Button
          key="confirm"
          isDisabled={
            (activeStep === 1 && resourceIds.length === 0) ||
            (activeStep === 2 &&
              selectedBadgesNumber != selectedResourcesNumber &&
              !automaticLinking) ||
            (activeStep === 2 &&
              automaticLinking &&
              availableBadgesCount < selectedResourcesNumber)
          }
          colorScheme="blue"
          onClick={handleNext}
          isLoading={linkingIsLoading || siteResourcesIsLoading}
        >
          {t("confirm", { ns: "common" })}
        </Button>,
        <Button
          key="cancel"
          colorScheme="red"
          onClick={
            activeStep === 0
              ? onClose
              : () => {
                  onSelectStep(activeStep - 1);
                  if (activeStep === 1) {
                    setSelectedResourcesNumber(0);
                    setSelectAllResourcesMatching(false);
                    setSelectAllResourcesVisible(false);
                    setResourceIds([]);
                  }
                  if (activeStep === 2) {
                    setSelectedBadgesNumber(0);
                    setSelectAllBadgesVisible(false);
                    setSelectAllBadgesMatching(false);
                    setBadgeIds([]);
                  }
                }
          }
          isDisabled={linkingIsLoading}
        >
          {t("cancel", { ns: "common" })}
        </Button>,
      ]}
    >
      <Box
        sx={{
          padding: "1rem",
          border: "1px solid",
          borderColor: "gray.300",
          borderRadius: "5px",
          marginBottom: "20px",
        }}
      >
        <Steps
          selected={activeStep}
          steps={[
            { title: t("linkBadges.step1.label") },
            { title: t("linkBadges.step2.label") },
            { title: t("linkBadges.step3.label") },
          ]}
          onSelect={handleSelectStep}
        />
      </Box>
      <Text fontSize="xl" fontWeight={500}>
        {t(`linkBadges.step${activeStep + 1}.title`)}
      </Text>
      <Flex flexDirection={"row"} marginBottom={3}>
        {(!automaticLinking || (automaticLinking && activeStep === 1)) && (
          <Text fontSize="sm">
            {t(`linkBadges.step${activeStep + 1}.subtitle`)}
          </Text>
        )}
        {activeStep === 2 && automaticLinking && (
          <Text fontSize="medium">
            {availableBadgesCount >= selectedResourcesNumber
              ? t(`automaticLinkingEnoughBadgeSelection`, {
                  count: selectedResourcesNumber,
                })
              : t(`automaticLinkingNotEnoughBadgeSelection`, {
                  badgeCount: availableBadgesCount,
                  resourcesCount: selectedResourcesNumber,
                })}
          </Text>
        )}
        <Flex flex={1} />
        {activeStep === 1 && (
          <>
            <Text>
              <Tooltip
                shouldWrapChildren
                placement="bottom"
                label={t("enableAutomaticLinking", { ns: "badges" })}
                fontSize="md"
              >
                <FaExclamationCircle
                  style={{ fill: COLORS.sikuroBlue, margin: "-3px 8px" }}
                />
              </Tooltip>
            </Text>
            <Checkbox
              marginLeft={3}
              isChecked={automaticLinking}
              onChange={() => setAutomaticLinking(!automaticLinking)}
            >
              <Text fontSize="sm">
                {t("automaticLinking", { ns: "badges" })}
              </Text>
            </Checkbox>
          </>
        )}
      </Flex>
      {activeStep === 0 && (
        <Select
          value={resourceType}
          onChange={(e) => setResourceType(e.target.value as BadgeResourceType)}
        >
          {Object.values(BadgeResourceType).map((opt) => (
            <option key={opt} value={opt}>
              {t(opt)}
            </option>
          ))}
        </Select>
      )}
      {activeStep === 1 && (
        <>
          <TextInput value={search} onChange={(value) => setSearch(value)} />
          <SelectSiteResourcesTable
            autosize
            siteResources={siteResources}
            siteResourcesIsLoading={siteResourcesIsLoading}
            siteResourcesFilters={siteResourcesFilters}
            siteResourcesSort={siteResourcesSort}
            siteResourcesHasNextPage={siteResourcesHasNextPage}
            siteResourcesFetchNextPage={siteResourcesFetchNextPage}
            updateSiteResourcesFilter={updateSiteResourcesFilter}
            setSiteResourcesSort={setSiteResourcesSort}
            setSiteResourceIds={setResourceIds}
            siteResourceIds={resourceIds}
            columns={
              tableColumns[resourceType]
            }
            totalSelectableResourcesNumber={registeredSiteResourceCount}
            parentSelectAllVisible={selectAllResourcesVisible}
            setParentSelectAllVisible={setSelectAllResourcesVisible}
            parentSelectAllMatching={selectAllResourcesMatching}
            setParentSelectAllMatching={setSelectAllResourcesMatching}
            selectedResourcesNumber={selectedResourcesNumber}
            setSelectedResourcesNumber={setSelectedResourcesNumber}
            selectAllAvailable={true}
            setWarningMultipleLinking={setWarningMultipleLinking}
            warningMultipleLinking={warningMultipleLinking}
          />
        </>
      )}
      {activeStep === 2 && !automaticLinking && (
        <>
          <TextInput
            value={availableBadgesFilters?.code}
            onChange={(value) => updateAvailableBadgesFilter("code", value)}
          />
          <Text
            fontSize="sm"
            mt={3}
            color={
              selectedBadgesNumber == selectedResourcesNumber
                ? COLORS.sikuroBlue
                : COLORS.red
            }
          >
            {t("linkBadges.step3.selected", {
              amount: `${selectedBadgesNumber}/${selectedResourcesNumber}`,
            })}
          </Text>
          <SelectBadgesTable
            autosize
            key="step3"
            badges={availableBadges}
            allSelectableBadgeNumber={availableBadgesCount}
            badgesTags={availableBadgesTags}
            badgesFilters={availableBadgesFilters}
            badgesSort={availableBadgesSort}
            badgesHasNextPage={availableBadgesHasNextPage}
            badgesFetchNextPage={availableBadgesFetchNextPage}
            setBadgesSort={setAvailableBadgesSort}
            updateBadgesFilter={updateAvailableBadgesFilter}
            badgeSitesIsLoading={availableBadgesIsLoading}
            setBadgeIds={setBadgeIds}
            badgeIds={badgeIds}
            resourcesAmount={selectedResourcesNumber}
            selectAllAvailable={true}
            parentSelectAllVisibile={selectAllBadgesVisible}
            setParentSelectAllVisibile={setSelectAllBadgesVisible}
            parentSelectAllMatching={selectAllBadgesMatching}
            setParentSelectAllMatching={setSelectAllBadgesMatching}
            selectedBadgesNumber={selectedBadgesNumber}
            setSelectedBadgesNumber={setSelectedBadgesNumber}
            paginatedTable={true}
          />
        </>
      )}

      {warningMultipleLinking && (
        <Modal
          isOpen
          onClose={() => setWarningMultipleLinking(false)}
          closeOnOverlayClick={false}
          trapFocus={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              flexDirection="column"
              paddingLeft={8}
              paddingRight={8}
            >
              <Flex
                direction="row"
                alignItems="center"
                width="100%"
                textAlign="start"
                marginLeft={-3}
                justifyContent={"center"}
              >
                <FiAlertTriangle color="red" />
                <Text
                  textColor={"black"}
                  fontSize={20}
                  fontWeight={"bold"}
                  marginLeft={3}
                >
                  {t("warning", { ns: "common" })}
                </Text>
              </Flex>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody id="modal-body">
              <Text textAlign={"center"}>
                <Trans t={t} i18nKey="manualLinking" ns={"badges"}>
                  Associando le risorse ai badge tramite l&apos;associazione
                  manuale, è necessario considerare che l&apos;associazione di
                  un badge alla risorsa non sarà precisa, ma casuale. Nel caso
                  in cui desideri effettuare un&apos;associazione precisa, puoi
                  procedere tramite l&apos;importazione di un file cliccando{" "}
                  <Link
                    onClick={() => {
                      setWarningMultipleLinking(false), openFileImport();
                    }}
                    color={COLORS.sikuroBlue}
                  >
                    qui
                  </Link>
                  .
                </Trans>
              </Text>
            </ModalBody>
            <ModalFooter justifyContent={"center"}>
              <Button
                bg={COLORS.sikuroBlue}
                textColor="white"
                onClick={() => setWarningMultipleLinking(false)}
                borderRadius={15}
                marginRight={5}
              >
                {t("ok", { ns: "common" })}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </BaseModal>
  );
};

export default LinkBadgesToSiteResourcesModal;
