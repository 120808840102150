import React, { useState, useRef, ReactElement } from "react";
import { createPortal } from "react-dom";
import DatePicker from "react-datepicker";
import { CalendarIcon, RepeatIcon } from "@chakra-ui/icons";
import "react-datepicker/dist/react-datepicker.css";
import { IconButton, Box, useOutsideClick } from "@chakra-ui/react";

type Props = {
  dates: [Date | null, Date | null];
  setDates: (dates: [Date | null, Date | null]) => void;
  icon?: ReactElement;
};

const DateRangePicker = ({ dates, setDates, icon }: Props) => {
  const [startDate, endDate] = dates;
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [buttonX, setButtonX] = useState<number | null>(null);
  const [buttonY, setButtonY] = useState<number | null>(null);
  const ref = useRef<HTMLButtonElement | null>(null);
  const pickerRef = useRef<HTMLDivElement | null>(null);

  const handleShowDatePicker = () => {
    const rect = ref.current?.getBoundingClientRect();
    setButtonX(rect?.left ?? null);
    setButtonY(rect?.bottom ?? null);
    setShowDatePicker(true);
  };

  useOutsideClick({
    ref: pickerRef,
    handler: () => {
      if (showDatePicker) {
        setShowDatePicker(false);
      }
    },
  });

  return (
    <>
      <IconButton
        ref={ref}
        style={{ height: "16px" }}
        size="sm"
        variant="ghost"
        aria-label="Calendar"
        icon={icon ?? <CalendarIcon />}
        onClick={handleShowDatePicker}
      />
      {showDatePicker &&
        buttonX &&
        buttonY &&
        typeof document !== "undefined" &&
        createPortal(
          <Box
            ref={pickerRef}
            style={{
              position: "absolute",
              left: `${buttonX}px`,
              top: `${buttonY}px`,
              zIndex: 1400, // Ensure the picker is above other elements
            }}
          >
            <BaseDateRangePicker
              startDate={startDate}
              endDate={endDate}
              setDates={setDates}
            />
          </Box>,
          document.body,
        )}
    </>
  );
};

type BaseDateRangePicker = {
  startDate: Date | null;
  endDate: Date | null;
  setDates: (dates: [Date | null, Date | null]) => void;
};

export const BaseDateRangePicker = ({
  startDate,
  endDate,
  setDates,
}: BaseDateRangePicker) => {

  const handleChange = (dates: [Date | null, Date | null]) => {
    let [start, end] = dates;

    if (start) {
      start = new Date(start);
      start.setHours(0, 0, 0, 0);
    }

    if (end) {
      end = new Date(end);
      end.setHours(23, 59, 59, 999);
    }

    setDates([start, end]);
  };
  return (
  <>
    <DatePicker
      selected={startDate}
      startDate={startDate}
      showMonthDropdown
      showYearDropdown
      endDate={endDate}
      onChange={handleChange}
      selectsRange
      inline
    />
    {(startDate || endDate) && (
      <IconButton
        aria-label={"clear"}
        icon={<RepeatIcon />}
        onClick={() => setDates([null, null])}
        size="md"
        width={0}
        height={0}
        sx={{
          position: "absolute",
          right: "30px",
          top: "18px",
          color: "red",
        }}
      />
    )}
  </>
)};

export default DateRangePicker;
