import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { Evaluable } from "../../domain/entities/evaluable.enum";
import AccessDeniedMainView from "../components/Permissions/AccessDeniedMainView";
import AccessDeniedView from "../components/Permissions/AccessDeniedView";
import { getSectionPermissions, Permission } from "../components/Permissions/Permissions";
import RequirePermissions from "../components/Permissions/RequirePermissions";
import SiteApproveEvaluationsResourceView from "../components/Views/SiteDetails/SiteApproveEvaluationsResourceView";
import BadgeDetailView from "../screens/Badge/BadgeDetailView";
import CompanyBadgesView from "../screens/Badge/CompanyBadgesView";
import CompanyDetailView from "../screens/Company/CompanyDetailView";
import CompanyDocuTypesMainView from "../screens/Company/CompanyDocuTypesMainView";
import CompanyDocumentTypesView from "../screens/Company/CompanyDocumentTypesView";
import CompanyGeneralDocumentsView from "../screens/Company/CompanyGeneralDocumentsView";
import CompanyMainView from "../screens/Company/CompanyMainView";
import CompanyReqGroupDetailMainView from "../screens/Company/CompanyReqGroupDetailMainView";
import CompanyReqGroupDetailView from "../screens/Company/CompanyReqGroupDetailView";
import CompanyRequirementsGroupsView from "../screens/Company/CompanyRequirementsGroupView";
import DocumentsPresetsContainerView from "../screens/Company/DocumentsPresets/DocumentsPresetsContainerView";
import DocumentsPresetsView from "../screens/Company/DocumentsPresets/DocumentsPresetsView";
import DashboardMainView from "../screens/Dashboard/DashboardMainView";
import NotificationsList from "../screens/Notifications/NotificationsList";
import NotificationsMainView from "../screens/Notifications/NotificationsMainView";
import OnboardingView from "../screens/Onboarding/OnboardingView";
import ChemicalDetailView from "../screens/Resources/Chemicals/ChemicalDetailView";
import ChemicalsView from "../screens/Resources/Chemicals/ChemicalsView";
import MachineDetailView from "../screens/Resources/Machines/MachineDetailView";
import MachinesView from "../screens/Resources/Machines/MachinesView";
import ResourcesMainView from "../screens/Resources/ResourcesMainView";
import ToolDetailView from "../screens/Resources/Tools/ToolDetailView";
import ToolsView from "../screens/Resources/Tools/ToolsView";
import VehicleDetailView from "../screens/Resources/Vehicles/VehicleDetailView";
import VehiclesView from "../screens/Resources/Vehicles/VehiclesView";
import WorkerDetailView from "../screens/Resources/Workers/WorkerDetailView";
import WorkersView from "../screens/Resources/Workers/WorkersView";
import RoleDetailsMainView from "../screens/Settings/Roles/RoleDetailsMainView";
import RolesView from "../screens/Settings/Roles/RolesView";
import SettingsMainView from "../screens/Settings/SettingsMainView";
import UserDetailsMainView from "../screens/Settings/Users/UserDetailsMainView";
import UserDetailsView from "../screens/Settings/Users/UserDetailsView";
import UsersView from "../screens/Settings/Users/UsersView";
import SiteDocumentsView from "../screens/Site/Documents/SiteDocumentsView";
import SiteDocumentsViewContainer from "../screens/Site/Documents/SiteDocumentsViewContainer";
import SiteAccessView from "../screens/Site/SiteAccessView";
import SiteApproveEvaluationsView from "../screens/Site/SiteApproveEvaluationsView";
import SiteBadgeReaderDetail from "../screens/Site/SiteBadgeReaderDetail";
import SiteBadgeReadersView from "../screens/Site/SiteBadgeReadersView";
import SiteBadgesView from "../screens/Site/SiteBadgesView";
import SiteChemicalDetailView from "../screens/Site/SiteChemicalDetailView";
import SiteChemicalsView from "../screens/Site/SiteChemicalsView";
import SiteMachineDetailView from "../screens/Site/SiteMachineDetailView";
import SiteMachinesView from "../screens/Site/SiteMachinesView";
import SiteOptionsView from "../screens/Site/SiteOptionsView";
import SiteOverviewEvaluations from "../screens/Site/SiteOverviewEvaluationsView";
import SiteRequirementsView from "../screens/Site/SiteRequirementsView";
import SiteResourcesView from "../screens/Site/SiteResourcesView";
import SiteRequirementsMainView from "../screens/Site/SiteRquirementsMainView";
import SiteSupplierDetailView from "../screens/Site/SiteSupplierDetailView";
import SiteSuppliersView from "../screens/Site/SiteSuppliersView";
import SiteToolDetailView from "../screens/Site/SiteToolDetailView";
import SiteToolsView from "../screens/Site/SiteToolsView";
import SiteVehicleDetailView from "../screens/Site/SiteVehicleDetailView";
import SiteVehiclesView from "../screens/Site/SiteVehiclesView";
import SiteWorkerDetailView from "../screens/Site/SiteWorkerDetailView";
import SiteWorkersView from "../screens/Site/SiteWorkersView";
import SiteDetailsContainerView from "../screens/Sites/SiteDetailsContainerView";
import SiteDetailsMainView from "../screens/Sites/SiteDetailsMainView";
import SiteDetailsView from "../screens/Sites/SiteDetailsView";
import SiteListView from "../screens/Sites/SiteListView";
import SitesMainView from "../screens/Sites/SitesMainView";
import SupplierChemicalDetailView from "../screens/Suppliers/SupplierChemicalDetailView";
import SupplierChemicalsView from "../screens/Suppliers/SupplierChemicalsView";
import SupplierDetailView from "../screens/Suppliers/SupplierDetailView";
import SupplierListView from "../screens/Suppliers/SupplierListView";
import SupplierMachineDetailView from "../screens/Suppliers/SupplierMachineDetailView";
import SupplierMachinesView from "../screens/Suppliers/SupplierMachinesView";
import SupplierToolDetailView from "../screens/Suppliers/SupplierToolDetailView";
import SupplierToolsView from "../screens/Suppliers/SupplierToolsView";
import SupplierVehicleDetailView from "../screens/Suppliers/SupplierVehicleDetailView";
import SupplierVehiclesView from "../screens/Suppliers/SupplierVehiclesView";
import SupplierWorkerDetailView from "../screens/Suppliers/SupplierWorkerDetailView";
import SupplierWorkersView from "../screens/Suppliers/SupplierWorkersView";
import SuppliersMainView from "../screens/Suppliers/SuppliersMainView";
import TimeTableCalendarView from "../screens/TimeTable/TimeTableCalendarView";
import TimeTableMainView from "../screens/TimeTable/TimeTableMainView";
import WorkingSiteChemicalsView from "../screens/WorkingSite/WorkingSiteChemicalsView";
import WorkingSiteDocumentView from "../screens/WorkingSite/WorkingSiteDocumentsView";
import WorkingSiteMachinesView from "../screens/WorkingSite/WorkingSiteMachinesView";
import WorkingSiteResourcesView from "../screens/WorkingSite/WorkingSiteResourcesView";
import WorkingSiteSubcontractsView from "../screens/WorkingSite/WorkingSiteSubcontractsView";
import WorkingSiteToolsView from "../screens/WorkingSite/WorkingSiteToolsView";
import WorkingSiteVehiclesView from "../screens/WorkingSite/WorkingSiteVehiclesView";
import WorkingSiteWorkerDetailsView from "../screens/WorkingSite/WorkingSiteWorkerDetailView";
import WorkingSiteWorkersView from "../screens/WorkingSite/WorkingSiteWorkersView";
import WorkingSiteListView from "../screens/WorkingSites/WorkingSiteListView";
import WorkingSitesMainView from "../screens/WorkingSites/WorkingSitesMainView";
import RequireLicense from "../components/Permissions/RequireLicense";
import LicenseNotActiveMainView from "../components/Permissions/LicenseNotActiveMainView";
import ActivationMainView from "../screens/Activation/ActivationMainView";
import { PermissionCheck, useAuth } from "../providers/Auth0JWTProvider";
import SiteAuditsView from "../screens/Site/SiteAuditsView";
import SubNavLayout from "../layout/SubNavLayout";
import WebHookView from "../screens/Settings/WebHooks/WebHookView";
import WorkingSiteSubcontractorDetailView from "../screens/WorkingSite/WorkingSiteSubcontractorDetailView";
import { AiTaskSectionCode } from "../../domain/entities/aiTaskSectionCode";
import UserSettingsMainView from "../screens/UserSettings/UserSettingsMainView";
import { UserSettingsSubNav } from "../screens/UserSettings/UserSettingsSubNav";
import UserSettingsView from "../screens/UserSettings/UserSettingsView";
import UserNotificationsView from "../screens/Settings/Users/UserNotificationsView";
import CustomFieldsView from "../screens/Settings/CustomFields/CustomFieldsView";
import AccessSubNav from "../components/Views/Settings/AccessSubNav";
import CustomFieldsSubNav from "../components/Views/CustomFieldsSubNav";
import { RequirementSubject } from "../../domain/entities/requirementSubject.enum";
import { RoleDetailsView } from "../screens/Settings/Roles/RoleDetailsView";
import { commonCompanyFallbackRoutes, getCompanyFallbackRoutes } from "./CompanyFallbackRoutes";
import DashboardOverviewView from "../screens/Dashboard/DashboardOverviewView";
import { useHasPermissions } from "../components/Permissions/RenderIf";
import { commonResourcesFallbackRoutes, getResourcesFallbackRoutes } from "./ResourcesFallbackRoutes";
import { commonSupplierFallbackRoutes, getSupplierResourceFallbackRoutes } from "./SupplierFallbackRoutes";
import SupplierMain from "../screens/Suppliers/SupplierMain";
import SupplierResourcesMain from "../screens/Suppliers/SupplierResourcesMain";
import { commonSettingsFallbackRoutes, getSettingsFallbackRoutes } from "./SettingsFallbackRoutes";
import {
  commonSiteFallbackRoutes,
  getDocumentSupplierFallbackRoutes,
  getSiteFallbackRoutes
} from "./SiteFallbackRoutes";
import { commonWorkingSiteFallbackRoutes, getWorkingSiteFallbackRoutes } from "./WorkingsiteFallbackRoutes";
import WorkingSiteDetailsContainerView from "../screens/WorkingSites/WorkingSiteDetailsContainerView";
import WorkingSiteMainView from "../screens/WorkingSite/WorkingSiteMainView";
import WorkingSiteChemicalDetailView from "../screens/WorkingSite/WorkingSiteChemicalDetailView";
import WorkingSiteMachineDetailView from "../screens/WorkingSite/WorkingSiteMachineDetailView";
import WorkingSiteToolDetailView from "../screens/WorkingSite/WorkingSiteToolDetailView";
import WorkingSiteVehicleDetailView from "../screens/WorkingSite/WorkingSiteVehicleDetailView";

const AppRouter = () => {


  const { companyId, company } = useAuth();
  const isReviewEnabled = localStorage.getItem("isReviewEnabled");

  const siteResourcesPermissions = [
    Permission.Sites_ShowWorkers,
    Permission.Sites_ShowVehicles,
    Permission.Sites_ShowMachines,
    Permission.Sites_ShowTools,
    Permission.Sites_ShowChemicals,
  ];
  const workingSiteResourcesPermissions = [
    Permission.Worksite_ShowWorkers,
    Permission.Worksite_ShowVehicles,
    Permission.Worksite_ShowMachines,
    Permission.Worksite_ShowTools,
    Permission.Worksite_ShowChemicals
  ];

  return (
    <Routes>
      {/* HOME */}
      <Route path="/" element={<RequireLicense />}>
        <Route path="/" element={<OnboardingView />} />
        {/* DASHBOARD */}
        <Route path="/overview" element={<DashboardMainView />}>
          <Route path="" element={<DashboardOverviewView />} />
        </Route>

        {/* ACTIVATE USER */}
        <Route path="/activate-user" element={<ActivationMainView />} />
        {/* ACTIVATE SUPPLIER */}
        <Route path="/activate-supplier" element={<ActivationMainView />} />
        {/* ACTIVATE SUBCONTRACTOR */}
        <Route
          path="/activate-subcontractor"
          element={<ActivationMainView />}
        />
        {/* CONFIRM SUBCONTRACTOR */}
        <Route path="/confirm-subcontractor" element={<ActivationMainView />} />
        {/* CONFIRM USER EMAIL */}
        <Route path="/email-certification" element={<ActivationMainView />} />
        {/* USER SETTINGS */}
        <Route path="/userSettings" element={<UserSettingsMainView />}>
          <Route
            index
            element={<Navigate to={"details"} replace />}
          />
          <Route
            path="details"
            element={<SubNavLayout nav={<UserSettingsSubNav />} />}
          >
            <Route path="" element={<UserSettingsView />} />
          </Route>
          <Route
            path="notifications"
            element={<SubNavLayout nav={<UserSettingsSubNav />} />}
          >
            <Route path="" element={<UserNotificationsView />} />
          </Route>
        </Route>
        {/* OWNED SITES */}
        <Route
          path="/sites"
          element={
            <RequirePermissions
              routeName="sites"
              permissions={[Permission.Sites_Show]}
              element={<SitesMainView />}
              check={PermissionCheck.All}
              fallbackRoutes={commonSiteFallbackRoutes}
            />
          }
        >
          <Route path="" element={<SiteListView />} />
        </Route>
        <Route path="/sites/:siteId" element={<SiteDetailsMainView />}>
          {useHasPermissions(
            [
              Permission.Sites_ShowDetails,
              Permission.Sites_ShowSettings,
            ],
            PermissionCheck.Some,
          ) && <Route index element={<Navigate to="site" replace />} />}
          <Route
            path="site"
            element={
              <RequirePermissions
                permissions={[
                  Permission.Sites_ShowDetails,
                  Permission.Sites_ShowSettings,
                ]}
                element={<SiteDetailsContainerView />}
                check={PermissionCheck.Some}
                fallbackRoutes={getSiteFallbackRoutes("site")}
              />
            }
          >
            {useHasPermissions([Permission.Sites_ShowDetails]) && (
              <Route index element={<Navigate to="details" replace />} />
            )}
            <Route
              path={"details"}
              element={
                <RequirePermissions
                  permissions={[Permission.Sites_ShowDetails]}
                  element={
                    <SiteDetailsView
                      readonly={false}
                      showStatus={useHasPermissions(Permission.Sites_ShowState)}
                      isWorkingSite={false}
                      permissions={{
                        addDocument: Permission.Sites_AddGeneralDocs,
                        downloadDocuments: Permission.Sites_ShowGeneralDocs,
                        showStaff: Permission.Sites_ShowStaff,
                        editStaff: Permission.Sites_EditStaff,
                        documents: {
                          delete: Permission.Sites_DeleteGeneralDocs,
                          edit: Permission.Sites_EditGeneralDocs,
                          evaluate: Permission.Sites_EditGeneralDocs,
                          viewEvaluations: Permission.Sites_EditGeneralDocs,
                          downloadAll: Permission.Sites_EditGeneralDocs,
                          addFile: Permission.Sites_EditGeneralDocs,
                          setExpiration: Permission.Sites_EditGeneralDocs,
                          chat: Permission.Sites_EditGeneralDocs,
                          report: Permission.Sites_EditGeneralDocs,
                        },
                      }}
                    />
                  }
                  fallbackRoutes={getSiteFallbackRoutes("site/details")}
                />
              }
            />
            {useHasPermissions([Permission.Sites_ShowSettings]) && (
              <Route index element={<Navigate to="options" replace />} />
            )}
            <Route
              path={"options"}
              element={
                <RequirePermissions
                  permissions={[Permission.Sites_ShowSettings]}
                  element={<SiteOptionsView isWorkingSite={false} permissions={{
                    editGeneralSettings: Permission.Sites_EditGeneralSettings,
                    editUsersRole: Permission.Sites_EditUsersRole
                  }} />}
                  fallbackRoutes={getSiteFallbackRoutes("site/options")}
                />
              }
            />
          </Route>
          {useHasPermissions([
            Permission.Sites_ShowGeneralDocs,
            Permission.Sites_ShowOwnerReq,
            Permission.Sites_EvaluationOverview,
          ]) && <Route index element={<Navigate to="documents" replace />} />}
          <Route path="documents" element={<SiteDocumentsViewContainer />}>
            {useHasPermissions([Permission.Sites_ShowGeneralDocs]) && (
              <Route index element={<Navigate to="general" replace />} />
            )}

            <Route
              path="general"
              element={
                <RequirePermissions
                  permissions={[Permission.Sites_ShowGeneralDocs]}
                  element={
                    <SiteDocumentsView
                      permissions={{
                        addDocument: Permission.Sites_AddGeneralDocs,
                        manageDocumentWithAi: Permission.Company_AIManageFile,
                        documents: {
                          addFile: Permission.Sites_EditGeneralDocs,
                          chat: Permission.Sites_EditGeneralDocs,
                          report: Permission.Sites_EditGeneralDocs,
                          delete: Permission.Sites_DeleteGeneralDocs,
                          downloadAll: Permission.Sites_ShowGeneralDocs,
                          edit: Permission.Sites_EditGeneralDocs,
                          evaluate: Permission.Sites_EvaluateGeneralDocs,
                          setExpiration: Permission.Sites_EditGeneralDocs,
                          viewEvaluations: Permission.Sites_EvaluateGeneralDocs,
                        },
                      }}
                    />
                  }
                  fallbackRoutes={getSiteFallbackRoutes("documents/general")}
                />
              }
            />
            {useHasPermissions([Permission.Sites_ShowOwnerReq]) && (
              <Route
                index
                element={<Navigate to={`supplier/${companyId}`} replace />}
              />
            )}
            <Route
              path="supplier/:supplierId"
              element={
                <RequirePermissions
                  permissions={[Permission.Sites_ShowOwnerReq]}
                  fallbackRoutes={getDocumentSupplierFallbackRoutes(
                    "documents/supplier/:supplierId",
                    companyId,
                  )}
                  element={
                    <SiteSupplierDetailView
                      fromList={false}
                      deltaWidth={"280px"}
                      permissions={{
                        editVariant: Permission.Sites_EditOwnerVariant,
                        showGlobalEvaluation:Permission.Sites_ShowGlobalOwnerEvaluation,
                        setGlobalEvaluation:Permission.Sites_SetGlobalOwnerEvaluation,
                        showStatus: Permission.Sites_ShowOwnerReq,
                        setStatus: Permission.Sites_SetOwnerState,
                        manageDocumentWithAi: Permission.Company_AIManageFile,
                        chat: Permission.Sites_OwnerChat,
                        report: Permission.Sites_ShowReports,
                        company: {
                          downloadDocuments: Permission.Sites_DownloadOwnerReq,
                          addRequirement: Permission.Sites_AddOwnerReq,
                          viewRequirements: Permission.Sites_Show,
                          documents: {
                            delete: Permission.Sites_DeleteOwnerReq,
                            edit: Permission.Sites_EditOwnerReq,
                            evaluate: Permission.Sites_EvalOwnerReq,
                            viewEvaluations: Permission.Sites_ShowOwnerReqEvaluation,
                            downloadAll: Permission.Sites_DownloadOwnerReq,
                            addFile: Permission.Sites_EditOwnerReq,
                            setExpiration: Permission.Sites_EvalOwnerReq,
                          },
                        },
                        site: {
                          downloadDocuments: Permission.Sites_DownloadOwnerReq,
                          addRequirement: Permission.Sites_AddOwnerReq,
                          viewRequirements: Permission.Sites_Show,
                          documents: {
                            delete: Permission.Sites_DeleteOwnerReq,
                            edit: Permission.Sites_EditOwnerReq,
                            evaluate: Permission.Sites_EvalOwnerReq,
                            viewEvaluations: Permission.Sites_ShowOwnerReqEvaluation,
                            downloadAll: Permission.Sites_DownloadOwnerReq,
                            addFile: Permission.Sites_EditOwnerReq,
                            setExpiration: Permission.Sites_EvalOwnerReq,
                          },
                        },
                      }}
                      aiTaskCompanySectionCode={
                        AiTaskSectionCode.SITE_DOCUMENTS_COMPANY
                      }
                      aiTaskSiteSectionCode={
                        AiTaskSectionCode.SITE_DOCUMENTS_SITE
                      }
                    />
                  }
                />
              }
            />
            {useHasPermissions([Permission.Sites_EvaluationOverview]) && (
              <Route
                index
                element={<Navigate to={`siteRequirements/resource`} replace />}
              />
            )}
            <Route
              path="siteRequirements/:topic"
              element={
                <RequirePermissions
                  permissions={[Permission.Sites_EvaluationOverview]}
                  element={<SiteOverviewEvaluations />}
                  fallbackRoutes={getDocumentSupplierFallbackRoutes(
                    "siteRequirements/:topic",
                  )}
                />
              }
            />
          </Route>
          {useHasPermissions(
            siteResourcesPermissions,
            PermissionCheck.Some,
          ) && <Route index element={<Navigate to={`resources`} replace />} />}
          <Route
            path="resources"
            element={
              <RequirePermissions
                permissions={siteResourcesPermissions}
                check={PermissionCheck.Some}
                element={<SiteResourcesView />}
                fallbackRoutes={getSiteFallbackRoutes("resources")}
              />
            }
          >
            {useHasPermissions([Permission.Sites_ShowWorkers]) && (
              <Route index element={<Navigate to="workers" replace />} />
            )}
            <Route path="workers" element={<SiteWorkersView />} />
            {useHasPermissions([Permission.Sites_ShowVehicles]) && (
              <Route index element={<Navigate to="vehicles" replace />} />
            )}
            <Route path="vehicles" element={<SiteVehiclesView />} />
            {useHasPermissions([Permission.Sites_ShowMachines]) && (
              <Route index element={<Navigate to="machines" replace />} />
            )}
            <Route path="machines" element={<SiteMachinesView />} />
            {useHasPermissions([Permission.Sites_ShowTools]) && (
              <Route index element={<Navigate to="tools" replace />} />
            )}
            <Route path="tools" element={<SiteToolsView />} />
            {useHasPermissions([Permission.Sites_ShowChemicals]) && (
              <Route index element={<Navigate to="chemicals" replace />} />
            )}
            <Route path="chemicals" element={<SiteChemicalsView />} />
          </Route>
          <Route
            path="resources/workers/:workerId"
            element={
              <RequirePermissions
                permissions={[Permission.Sites_ShowWorkers]}
                check={PermissionCheck.All}
                element={<SiteWorkerDetailView />}
              />
            }
          />
          <Route
            path="resources/machines/:machineId"
            element={
              <RequirePermissions
                permissions={[Permission.Sites_ShowMachines]}
                check={PermissionCheck.All}
                element={<SiteMachineDetailView />}
              />
            }
          />
          <Route
            path="resources/tools/:toolId"
            element={
              <RequirePermissions
                permissions={[Permission.Sites_ShowTools]}
                check={PermissionCheck.All}
                element={<SiteToolDetailView />}
              />
            }
          />
          <Route
            path="resources/chemicals/:chemicalId"
            element={
              <RequirePermissions
                permissions={[Permission.Sites_ShowChemicals]}
                check={PermissionCheck.All}
                element={<SiteChemicalDetailView />}
              />
            }
          />
          <Route
            path="resources/vehicles/:vehicleId"
            element={
              <RequirePermissions
                permissions={[Permission.Sites_ShowVehicles]}
                check={PermissionCheck.All}
                element={<SiteVehicleDetailView />}
              />
            }
          />
          {useHasPermissions([Permission.Sites_ShowRequirements]) && (
            <Route index element={<Navigate to="requirements" replace />} />
          )}
          <Route
            path="requirements"
            element={
              <RequirePermissions
                permissions={[Permission.Sites_ShowRequirements]}
                check={PermissionCheck.Some}
                element={<SiteRequirementsMainView />}
                fallbackRoutes={getSiteFallbackRoutes("requirements")}
              />
            }
          >
            {useHasPermissions([Permission.Sites_ShowRequirements]) && (
              <Route index element={<Navigate to="supplier" replace />} />
            )}
            <Route path="supplier" element={<SiteRequirementsView />} />
            <Route path="worker" element={<SiteRequirementsView />} />
            <Route path="vehicle" element={<SiteRequirementsView />} />
            <Route path="machine" element={<SiteRequirementsView />} />
            <Route path="tool" element={<SiteRequirementsView />} />
            <Route path="chemical" element={<SiteRequirementsView />} />
          </Route>

          {useHasPermissions(
            [Permission.Sites_ShowBadges, Permission.Sites_ShowPhysicalBadgeReaders, Permission.Sites_ShowVirtualBadgeReaders],
            PermissionCheck.Some,
          ) && <Route index element={<Navigate to="access" replace />} />}
          <Route path="access" element={<SiteAccessView />}>
            {useHasPermissions([Permission.Sites_ShowBadges]) && (
              <Route index element={<Navigate to="badges" replace />} />
            )}
            <Route
              path="badges"
              element={
                <RequirePermissions
                  permissions={[Permission.Sites_ShowBadges]}
                  fallbackRoutes={getSiteFallbackRoutes("access/badges")}
                  element={
                    <SiteBadgesView
                      permissions={{
                        linkBadge: Permission.Sites_LinkBadge,
                        unlinkBadge: Permission.Sites_UnlinkBadge,
                        linkResourceToBadge: Permission.Sites_LinkResourceToBadge,
                        printBadges: Permission.Sites_PrintBadges,
                      }}
                    />
                  }
                />
              }
            />
            {useHasPermissions([Permission.Sites_ShowPhysicalBadgeReaders,
              Permission.Sites_ShowVirtualBadgeReaders], PermissionCheck.Some) && (
              <Route index element={<Navigate to="badge-readers" replace />} />
            )}
            <Route
              path="badge-readers"
              element={
                <RequirePermissions
                  permissions={[Permission.Sites_ShowPhysicalBadgeReaders, Permission.Sites_ShowVirtualBadgeReaders]}
                  check={PermissionCheck.Some}
                  fallbackRoutes={getSiteFallbackRoutes("access/badge-readers")}
                  element={
                    <SiteBadgeReadersView
                      permissions={{
                        viewPhysicalBadgeReader: Permission.Sites_ShowPhysicalBadgeReaders,
                        viewVirtualBadgeReader: Permission.Sites_ShowVirtualBadgeReaders,
                        createPhysicalBadgeReader: Permission.Sites_CreatePhysicalBadgeReader,
                        createVirtualBadgeReader: Permission.Sites_CreateVirtualBadgeReader,
                        editPhysicalBadgeReader: Permission.Sites_EditPhysicalBadgeReader,
                        editVirtualBadgeReader: Permission.Sites_EditVirtualBadgeReader,
                        deletePhysicalBadgeReader: Permission.Sites_DeletePhysicalBadgeReader,
                        deleteVirtualBadgeReader: Permission.Sites_DeleteVirtualBadgeReader,
                        showPhysicalState: Permission.Sites_ShowPhysicalBadgeReaderState,
                        showVirtualState: Permission.Sites_ShowVirtualBadgeReaderState,
                      }}
                    />
                  }
                />
              }
            />

            <Route
              path="badge-readers/:badgeReaderId"
              element={
                <RequirePermissions
                  permissions={[Permission.Sites_ShowPhysicalBadgeReaders, Permission.Sites_ShowVirtualBadgeReaders]}
                  check={PermissionCheck.Some}
                  element={
                    <SiteBadgeReaderDetail
                      permissions={{
                        detailPhysicalPermissions: {
                            showState: Permission.Sites_ShowPhysicalBadgeReaderState,
                            setState: Permission.Sites_SetPhysicalBadgeReaderState,
                            edit: Permission.Sites_EditPhysicalBadgeReader,
                            showRecords: Permission.Sites_ShowPhysicalBadgeReaderRecords,
                            addManualRecord: Permission.Sites_AddPhysicalBadgeReaderManualRecord,
                            deleteManualRecord: Permission.Sites_DeletePhysicalBadgeReaderRecord,
                        },
                        detailVirtualPermissions: {
                            showState: Permission.Sites_ShowVirtualBadgeReaderState,
                            setState: Permission.Sites_SetVirtualBadgeReaderState,
                            edit: Permission.Sites_EditVirtualBadgeReader,
                            showRecords: Permission.Sites_ShowVirtualBadgeReaderRecords,
                            addManualRecord: Permission.Sites_AddVirtualBadgeReaderManualRecord,
                            deleteManualRecord: Permission.Sites_DeleteVirtualBadgeReaderRecord,
                        }
                      }}
                    />
                  }
                />
              }
            />

            <Route
              path="badges/:badgeId"
              element={
                <BadgeDetailView
                  fromSite={true}
                  permissions={{
                    showBadgeState: Permission.Sites_ShowBadgeState,
                    setBadgeState: Permission.Sites_SetBadgeState,
                    showBadgeRecords: Permission.Sites_ShowBadgeRecords,
                    addBadgeManualRecord: Permission.Sites_AddBadgeManualRecord,
                    deleteBadgeRecord: Permission.Sites_DeleteBadgeRecord,
                  }}
                />
              }
            />
          </Route>
          {useHasPermissions([Permission.Sites_ShowSuppliers]) && (
            <Route index element={<Navigate to="suppliers" replace />} />
          )}
          <Route
            path="suppliers"
            element={
              <RequirePermissions
                fallbackRoutes={getSiteFallbackRoutes("suppliers")}
                permissions={[Permission.Sites_ShowSuppliers]}
                element={
                  <SiteSuppliersView
                    inviteSupplierPermission={Permission.Sites_InviteSupplier}
                    deleteSupplierPermission={Permission.Sites_DeleteSupplier}
                  />
                }
              />
            }
          />
          <Route
            path="suppliers/:supplierId"
            element={
              <SiteSupplierDetailView
                fromList={true}
                deltaWidth="220px"
                permissions={{
                  editVariant: Permission.Sites_EditSupplierVariant,
                  showGlobalEvaluation:
                  Permission.Sites_ShowGlobalSupplierEvaluation,
                  setGlobalEvaluation:
                  Permission.Sites_SetGlobalSupplierEvaluation,
                  showStatus: Permission.Sites_ShowSupplierState,
                  setStatus: Permission.Sites_SetSupplierState,
                  manageDocumentWithAi: Permission.Company_AIManageFile,
                  chat: Permission.Sites_SupplierChat,
                  report: Permission.Sites_AddReport,
                  company: {
                    downloadDocuments:
                      Permission.Sites_DownloadSupplierCompanyReq,
                    addRequirement: Permission.Sites_AddSupplierCompanyReq,
                    viewRequirements: Permission.Sites_ShowSupplierCompanyReq,
                    documents: {
                      delete: Permission.Sites_DeleteSupplierCompanyReq,
                      edit: Permission.Sites_EditSupplierCompanyReq,
                      evaluate: Permission.Sites_EvalSupplierCompanyReq,
                      viewEvaluations:
                        Permission.Sites_ShowSupplierCompanyReqEvaluation,
                      downloadAll: Permission.Sites_DownloadSupplierCompanyReq,
                      addFile: Permission.Sites_EditSupplierCompanyReq,
                      viewEvaluationExpiration: Permission.Sites_ShowSupplierCompanyReqEvaluationExpiration,
                      setExpiration: Permission.Sites_EvalSupplierCompanyReq,
                    },
                  },
                  site: {
                    downloadDocuments:
                      Permission.Sites_DownloadSupplierSiteReq,
                    addRequirement: Permission.Sites_AddSupplierSiteReq,
                    viewRequirements: Permission.Sites_ShowSupplierSiteReq,
                    documents: {
                      delete: Permission.Sites_DeleteSupplierSiteReq,
                      edit: Permission.Sites_EditSupplierSiteReq,
                      evaluate: Permission.Sites_EvalSupplierSiteReq,
                      viewEvaluations:
                        Permission.Sites_ShowSupplierSiteReqEvaluation,
                      downloadAll: Permission.Sites_DownloadSupplierSiteReq,
                      addFile: Permission.Sites_EditSupplierSiteReq,
                      viewEvaluationExpiration: Permission.Sites_ShowSupplierSiteReqEvaluationExpiration,
                      setExpiration: Permission.Sites_EvalSupplierSiteReq,
                    },
                  },
                }}
                aiTaskCompanySectionCode={
                  AiTaskSectionCode.SITE_SUPPLIERS_COMPANY
                }
                aiTaskSiteSectionCode={AiTaskSectionCode.SITE_SUPPLIERS_SITE}
              />
            }
          />
          {useHasPermissions(
            [
              Permission.Sites_ShowCompanyPendingEvaluations,
              Permission.Sites_ShowWorkerPendingEvaluations,
              Permission.Sites_ShowVehiclePendingEvaluations,
              Permission.Sites_ShowMachinePendingEvaluations,
              Permission.Sites_ShowToolPendingEvaluations,
              Permission.Sites_ShowChemicalPendingEvaluations,
            ],
            PermissionCheck.Some,
          ) && isReviewEnabled  === "true" && <Route index element={<Navigate to="approve" replace />} />}
          <Route path="approve" element={<SiteApproveEvaluationsView />}>
            {useHasPermissions([
              Permission.Sites_ShowCompanyPendingEvaluations,
            ]) && isReviewEnabled  === "true" && <Route index element={<Navigate to="companies" replace />} />}
            <Route
              path="companies"
              element={
                <RequirePermissions
                  permissions={[Permission.Sites_ShowCompanyPendingEvaluations]}
                  fallbackRoutes={getSiteFallbackRoutes("approve/companies")}
                  element={
                    <SiteApproveEvaluationsResourceView
                      type={Evaluable.COMPANY}
                      permissions={{
                        downloadAll: Permission.Sites_DownloadCompanyPendingReq,
                        approve: Permission.Sites_ApproveCompanyPendingEvaluation,
                        showExpiration: Permission.Sites_ShowCompanyPendingEvaluationExpiration,
                      }}
                    />
                  }
                />
              }
            />
            {useHasPermissions([
              Permission.Sites_ShowWorkerPendingEvaluations,
            ]) && isReviewEnabled  === "true" && <Route index element={<Navigate to="workers" replace />} />}
            <Route
              path="workers"
              element={
                <RequirePermissions
                  permissions={[Permission.Sites_ShowWorkerPendingEvaluations]}
                  fallbackRoutes={getSiteFallbackRoutes("approve/workers")}
                  element={
                    <SiteApproveEvaluationsResourceView
                      type={Evaluable.WORKER}
                      permissions={{
                        downloadAll: Permission.Sites_DownloadWorkerPendingReq,
                        approve: Permission.Sites_ApproveWorkerPendingEvaluation,
                        showExpiration: Permission.Sites_ShowWorkerPendingEvaluationExpiration,
                      }}
                    />
                  }
                />
              }
            />
            {useHasPermissions([
              Permission.Sites_ShowVehiclePendingEvaluations,
            ]) && isReviewEnabled  === "true" && <Route index element={<Navigate to="vehicles" replace />} />}
            <Route
              path="vehicles"
              element={
                <RequirePermissions
                  permissions={[Permission.Sites_ShowVehiclePendingEvaluations]}
                  fallbackRoutes={getSiteFallbackRoutes("approve/vehicles")}
                  element={
                    <SiteApproveEvaluationsResourceView
                      type={Evaluable.VEHICLE}
                      permissions={{
                        downloadAll: Permission.Sites_DownloadVehiclePendingReq,
                        approve: Permission.Sites_ApproveVehiclePendingEvaluation,
                        showExpiration: Permission.Sites_ShowVehiclePendingEvaluationExpiration,
                      }}
                    />
                  }
                />
              }
            />
            {useHasPermissions([
              Permission.Sites_ShowMachinePendingEvaluations,
            ]) && isReviewEnabled  === "true" && <Route index element={<Navigate to="machines" replace />} />}
            <Route
              path="machines"
              element={
                <RequirePermissions
                  permissions={[Permission.Sites_ShowMachinePendingEvaluations]}
                  fallbackRoutes={getSiteFallbackRoutes("approve/machines")}
                  element={
                    <SiteApproveEvaluationsResourceView
                      type={Evaluable.MACHINE}
                      permissions={{
                        downloadAll: Permission.Sites_DownloadMachinePendingReq,
                        approve: Permission.Sites_ApproveMachinePendingEvaluation,
                        showExpiration: Permission.Sites_ShowMachinePendingEvaluationExpiration,
                      }}
                    />
                  }
                />
              }
            />
            {useHasPermissions([
              Permission.Sites_ShowToolPendingEvaluations,
            ]) && isReviewEnabled  === "true" && <Route index element={<Navigate to="tools" replace />} />}
            <Route
              path="tools"
              element={
                <RequirePermissions
                  permissions={[Permission.Sites_ShowToolPendingEvaluations]}
                  fallbackRoutes={getSiteFallbackRoutes("approve/tools")}
                  element={
                    <SiteApproveEvaluationsResourceView type={Evaluable.TOOL}
                    permissions={{
                      downloadAll: Permission.Sites_DownloadToolPendingReq,
                      approve: Permission.Sites_ApproveToolPendingEvaluation,
                      showExpiration: Permission.Sites_ShowToolPendingEvaluationExpiration,
                    }} />
                  }
                />
              }
            />
            {useHasPermissions([
              Permission.Sites_ShowChemicalPendingEvaluations,
            ]) && isReviewEnabled  === "true" && <Route index element={<Navigate to="chemicals" replace />} />}
            <Route
              path="chemicals"
              element={
                <RequirePermissions
                  permissions={[
                    Permission.Sites_ShowChemicalPendingEvaluations,
                  ]}
                  fallbackRoutes={getSiteFallbackRoutes("approve/chemicals")}
                  element={
                    <SiteApproveEvaluationsResourceView
                      type={Evaluable.CHEMICAL}
                      permissions={{
                        downloadAll: Permission.Sites_DownloadChemicalPendingReq,
                        approve: Permission.Sites_ApproveChemicalPendingEvaluation,
                        showExpiration: Permission.Sites_ShowChemicalPendingEvaluationExpiration,
                      }}
                    />
                  }
                />
              }
            />
          </Route>
          {useHasPermissions([Permission.Sites_ShowReports]) && (
            <Route index element={<Navigate to="audits" replace />} />
          )}
          <Route
            path="audits"
            element={
              <RequirePermissions
                routeName="audits"
                permissions={[Permission.Sites_ShowReports]}
                element={<SiteAuditsView
                  create={[Permission.Sites_AddReport]}
                  remove={[Permission.Sites_DeleteReport]} />}
                fallbackRoutes={getSiteFallbackRoutes("audits")}
                check={PermissionCheck.All}
              />
            }
          />
        </Route>

        {/* WORKING SITES */}
        <Route
          path="/workingsites"
          element={
            <RequirePermissions
              routeName="workingSites"
              permissions={[Permission.Worksite_Show]}
              element={<WorkingSitesMainView />}
              check={PermissionCheck.All}
              fallbackRoutes={commonWorkingSiteFallbackRoutes}
            />
          }
        >
          <Route path="" element={<WorkingSiteListView />} />
        </Route>
        <Route path="/workingsites/:siteId" element={<WorkingSiteMainView />}>
          {useHasPermissions(
            [
              Permission.Worksite_ShowDetails,
              Permission.Worksite_ShowSettings
            ],
            PermissionCheck.Some
          ) && <Route index element={<Navigate to="site" replace />} />}
          <Route
            path="site"
            element={
              <RequirePermissions
                permissions={[
                  Permission.Worksite_ShowDetails,
                  Permission.Worksite_ShowSettings
                ]}
                element={<WorkingSiteDetailsContainerView />}
                check={PermissionCheck.Some}
                fallbackRoutes={getWorkingSiteFallbackRoutes("site")}
              />
            }
          >
            {useHasPermissions([Permission.Worksite_ShowDetails]) && (
              <Route index element={<Navigate to="details" replace />} />
            )}
            <Route
              path="details"
              element={<RequirePermissions
                permissions={[Permission.Worksite_ShowDetails]}
                element={
                  <SiteDetailsView
                    readonly={true}
                    showStatus={false}
                    isWorkingSite={true}
                    permissions={{
                      addDocument: Permission.Worksite_AddReq,
                      downloadDocuments: Permission.Worksite_ShowReq,
                      showGeneralDocs: Permission.Worksite_ShowGeneralDocs,
                      showStaff: Permission.Worksite_ShowStaff,
                      editStaff: Permission.Worksite_EditStaff,
                      documents: {
                        delete: Permission.Worksite_DeleteFile,
                        edit: Permission.Worksite_EditFile,
                        evaluate: Permission.Worksite_EvalReq,
                        viewEvaluations: Permission.Worksite_EvalReq,
                        downloadAll: Permission.Worksite_ShowReq,
                        addFile: Permission.Worksite_AddFile,
                        setExpiration: Permission.Worksite_SetExpiration,
                        chat: Permission.Worksite_Chat,
                        report: Permission.Worksite_Reports
                      }
                    }}
                  />
                }
                fallbackRoutes={getWorkingSiteFallbackRoutes("site/details")}
              />
              }
            />
            {useHasPermissions([Permission.Worksite_ShowSettings]) && (
              <Route index element={<Navigate to="options" replace />} />
            )}
            <Route
              path="options"
              element={
                <RequirePermissions
                  permissions={[Permission.Worksite_ShowSettings]}
                  element={<SiteOptionsView isWorkingSite={true} permissions={{
                    editUsersRole: Permission.Worksite_EditUsersRole
                  }} />}
                  fallbackRoutes={getWorkingSiteFallbackRoutes("site/options")}
                />
              }
            />
          </Route>
          {useHasPermissions(
            workingSiteResourcesPermissions,
            PermissionCheck.Some
          ) && <Route index element={<Navigate to={`resources`} replace />} />}
          <Route path="resources"
                 element={
                   <RequirePermissions
                     permissions={workingSiteResourcesPermissions}
                     check={PermissionCheck.Some}
                     element={<WorkingSiteResourcesView />}
                     fallbackRoutes={getWorkingSiteFallbackRoutes("resources")}
                   />
                 }>
            {useHasPermissions([Permission.Worksite_ShowWorkers]) && (
              <Route index element={<Navigate to="workers" replace />} />
            )}
            <Route path="workers" element={<WorkingSiteWorkersView />} />
            {useHasPermissions([Permission.Worksite_ShowVehicles]) && (
              <Route index element={<Navigate to="vehicles" replace />} />
            )}
            <Route path="vehicles" element={<WorkingSiteVehiclesView />} />
            {useHasPermissions([Permission.Worksite_ShowMachines]) && (
              <Route index element={<Navigate to="machines" replace />} />
            )}
            <Route path="machines" element={<WorkingSiteMachinesView />} />
            {useHasPermissions([Permission.Worksite_ShowTools]) && (
              <Route index element={<Navigate to="tools" replace />} />
            )}
            <Route path="tools" element={<WorkingSiteToolsView />} />
            {useHasPermissions([Permission.Worksite_ShowChemicals]) && (
              <Route index element={<Navigate to="chemicals" replace />} />
            )}
            <Route path="chemicals" element={<WorkingSiteChemicalsView />} />
          </Route>

          <Route
            path="resources/workers/:workerId"
            element={
              <RequirePermissions
                permissions={[Permission.Worksite_ShowWorkers]}
                check={PermissionCheck.All}
                element={<WorkingSiteWorkerDetailsView />}
              />
            }
          />
          <Route
            path="resources/chemicals/:chemicalId"
            element={
              <RequirePermissions
                permissions={[Permission.Worksite_ShowChemicals]}
                check={PermissionCheck.All}
                element={<WorkingSiteChemicalDetailView />}
              />
            }
          />
          <Route
            path="resources/machines/:machineId"
            element={
              <RequirePermissions
                permissions={[Permission.Worksite_ShowMachines]}
                check={PermissionCheck.All}
                element={<WorkingSiteMachineDetailView />}
              />
            }
          />
          <Route
            path="resources/tools/:toolId"
            element={
              <RequirePermissions
                permissions={[Permission.Worksite_ShowTools]}
                check={PermissionCheck.All}
                element={<WorkingSiteToolDetailView />}
              />
            }
          />
          <Route
            path="resources/vehicles/:vehicleId"
            element={
              <RequirePermissions
                permissions={[Permission.Worksite_ShowVehicles]}
                check={PermissionCheck.All}
                element={<WorkingSiteVehicleDetailView />}
              />
            }
          />
          {useHasPermissions([Permission.Worksite_ShowReq]) && (
            <Route index element={<Navigate to="documents" replace />} />
          )}
          <Route
            path="documents"
            element={
              <RequirePermissions
                permissions={[
                  Permission.Worksite_ShowReq,
                ]}
                check={PermissionCheck.All}
                element={
                  <WorkingSiteDocumentView
                    permissions={{
                      downloadAll: Permission.Worksite_DownloadReq,
                      addRequirement: Permission.Worksite_AddReq,
                      showGlobalEvaluation:
                        Permission.Worksite_ShowGlobalEvaluation,
                      manageDocumentWithAi: Permission.Company_AIManageFile,
                      documents: {
                        delete: Permission.Worksite_DeleteFile,
                        edit: Permission.Worksite_EvalReq,
                        evaluate: Permission.Worksite_EvalReq,
                        viewEvaluations: Permission.Worksite_ShowReqEvaluation,
                        downloadAll: Permission.Worksite_DownloadReq,
                        addFile: Permission.Worksite_EditReq,
                        setExpiration: Permission.Worksite_SetExpiration,
                        chat: Permission.Worksite_Chat,
                        report: Permission.Worksite_Reports,
                      },
                    }}
                  />
                }
                fallbackRoutes={getWorkingSiteFallbackRoutes("documents")}
              />
            }
          />
          {useHasPermissions([Permission.Worksite_ShowSubcontractors]) && (
            <Route index element={<Navigate to="subcontractors" replace />} />
          )}
          <Route
            path="subcontractors"
            element={
              <RequirePermissions
                routeName="subcontractors"
                permissions={[Permission.Worksite_ShowSubcontractors]}
                element={<WorkingSiteSubcontractsView
                  invitePermissions={[Permission.Worksite_InviteSubcontractor]}
                />}
                fallbackRoutes={getWorkingSiteFallbackRoutes("subcontractors")}
                check={PermissionCheck.All}
              />
            }
          />
          <Route
            path="subcontractors/:supplierId"
            element={
              <WorkingSiteSubcontractorDetailView
                fromList={true}
                isWorkingSite={true}
                isSubcontract={true}
                permissions={{
                  downloadDocuments: Permission.Worksite_EditSubcontractor,
                  showGlobalEvaluation: Permission.Worksite_Show,
                  setGlobalEvaluation:
                    Permission.Worksite_EditSubcontractor,
                  addRequirement: Permission.Worksite_EditSubcontractor,
                  viewRequirements: Permission.Worksite_Show,
                  editTags: Permission.Worksite_EditSubcontractor,
                  showStatus: Permission.Worksite_Show,
                  setStatus: Permission.Worksite_EditSubcontractor,
                  showResources: Permission.Worksite_Show,
                  documents: {
                    delete: Permission.Worksite_EditSubcontractor,
                    edit: Permission.Worksite_EditSubcontractor,
                    evaluate: Permission.Worksite_EditSubcontractor,
                    viewEvaluations: Permission.Worksite_Show,
                    downloadAll: Permission.Worksite_EditSubcontractor,
                    addFile: Permission.Worksite_EditSubcontractor,
                    setExpiration: Permission.Worksite_EditSubcontractor,
                    chat: Permission.Worksite_EditSubcontractor,
                    report: Permission.Worksite_EditSubcontractor,
                  },
                }}
              />
            }
          />
        </Route>

        {/* COMPANY */}
        <Route
          path="/company"
          element={
            <RequirePermissions
              permissions={getSectionPermissions("company")}
              element={<CompanyMainView />}
              fallbackRoutes={commonCompanyFallbackRoutes}
            />
          }
        >
          <Route index element={<Navigate to="records" replace />} />
          <Route
            path="records"
            element={
              <RequirePermissions
                permissions={[
                  Permission.Company_ShowDetails,
                  Permission.Company_ShowStaff,
                ]}
                element={<CompanyDetailView />}
                fallbackRoutes={getCompanyFallbackRoutes("records")}
              />
            }
          />
          <Route
            path="general-documents"
            element={
              <RequirePermissions
                permissions={[Permission.Company_ShowCompanyDocs]}
                element={<CompanyGeneralDocumentsView />}
                fallbackRoutes={getCompanyFallbackRoutes("general-documents")}
              />
            }
          />
          <Route
            path="requirements"
            element={
              <RequirePermissions
                permissions={[Permission.Company_ShowReqGroup]}
                element={
                  <CompanyRequirementsGroupsView
                    addGroupPermissions={[Permission.Company_AddReqGroup]}
                    manageGroupPermissions={[Permission.Company_EditReqGroup]}
                    deleteGroupPermissions={[Permission.Company_DeleteReqGroup]}
                  />
                }
                fallbackRoutes={getCompanyFallbackRoutes("requirements")}
              />
            }
          />
          <Route
            path="requirements/:id"
            element={
              <RequirePermissions
                permissions={[Permission.Company_ShowReqGroup]}
                element={<CompanyReqGroupDetailMainView />}
              />
            }
          >
            <Route index element={<Navigate to="supplier" replace />} />
            <Route path="supplier" element={<CompanyReqGroupDetailView />} />
            <Route path="worker" element={<CompanyReqGroupDetailView />} />
            <Route path="vehicle" element={<CompanyReqGroupDetailView />} />
            <Route path="machine" element={<CompanyReqGroupDetailView />} />
            <Route path="tool" element={<CompanyReqGroupDetailView />} />
            <Route path="chemical" element={<CompanyReqGroupDetailView />} />
          </Route>

          <Route
            path="document-types"
            element={
              <RequirePermissions
                permissions={[Permission.Company_ShowDocType]}
                element={<CompanyDocuTypesMainView />}
                fallbackRoutes={getCompanyFallbackRoutes("document-types")}
              />
            }
          >
            <Route index element={<Navigate to="company" replace />} />
            <Route
              path="company"
              element={
                <CompanyDocumentTypesView
                  permissions={{
                    editDocumentTypes: Permission.Company_EditDocType,
                    deleteDocumentTypes: Permission.Company_DeleteDocType,
                  }}
                />
              }
            />
            <Route
              path="worker"
              element={
                <CompanyDocumentTypesView
                  permissions={{
                    editDocumentTypes: Permission.Company_EditDocType,
                    deleteDocumentTypes: Permission.Company_DeleteDocType,
                  }}
                />
              }
            />
            <Route
              path="vehicle"
              element={
                <CompanyDocumentTypesView
                  permissions={{
                    editDocumentTypes: Permission.Company_EditDocType,
                    deleteDocumentTypes: Permission.Company_DeleteDocType,
                  }}
                />
              }
            />
            <Route
              path="machine"
              element={
                <CompanyDocumentTypesView
                  permissions={{
                    editDocumentTypes: Permission.Company_EditDocType,
                    deleteDocumentTypes: Permission.Company_DeleteDocType,
                  }}
                />
              }
            />
            <Route
              path="tool"
              element={
                <CompanyDocumentTypesView
                  permissions={{
                    editDocumentTypes: Permission.Company_EditDocType,
                    deleteDocumentTypes: Permission.Company_DeleteDocType,
                  }}
                />
              }
            />
            <Route
              path="chemical"
              element={
                <CompanyDocumentTypesView
                  permissions={{
                    editDocumentTypes: Permission.Company_EditDocType,
                    deleteDocumentTypes: Permission.Company_DeleteDocType,
                  }}
                />
              }
            />
            <Route
              path="site"
              element={
                <CompanyDocumentTypesView
                  permissions={{
                    editDocumentTypes: Permission.Company_EditDocType,
                    deleteDocumentTypes: Permission.Company_DeleteDocType,
                  }}
                />
              }
            />
          </Route>

          <Route
            path="documents-presets"
            element={
              <RequirePermissions
                permissions={[Permission.Company_ShowPreset]}
                element={<DocumentsPresetsContainerView />}
                fallbackRoutes={getCompanyFallbackRoutes("documents-presets")}
              />
            }
          >
            {useHasPermissions([Permission.Company_ShowPreset]) && (
              <Route index element={<Navigate to="workers" replace />} />
            )}
            <Route path="workers" element={<DocumentsPresetsView />} />
            <Route path="vehicles" element={<DocumentsPresetsView />} />
            <Route path="machines" element={<DocumentsPresetsView />} />
            <Route path="tools" element={<DocumentsPresetsView />} />
            <Route path="chemicals" element={<DocumentsPresetsView />} />
            <Route path="sites" element={<DocumentsPresetsView />} />
          </Route>

          <Route
            path="badges"
            element={
              <RequirePermissions
                permissions={[Permission.Company_ShowBadgeDetails]}
                element={<CompanyBadgesView />}
                fallbackRoutes={getCompanyFallbackRoutes("badges")}
              />
            }
          />
          <Route
            path="badges/:badgeId"
            element={
              <RequirePermissions
                permissions={[Permission.Company_ShowBadgeDetails]}
                routeName="companyBadgeDetails"
                element={
                  <BadgeDetailView
                    fromSite={false}
                    permissions={{
                      showBadgeState: Permission.Company_ShowBadgeDetails,
                      setBadgeState: Permission.Company_SetBadgeStatus,
                      linkResourceToBadge: Permission.Company_LinkBadgeResource,
                      unlinkResourceToBadge: Permission.Company_UnlinkBadgeResource
                    }}
                  />
                }
              />
            }
          />
        </Route>

        {/* RESOURCES */}
        <Route
          path="/resources"
          element={
            <RequirePermissions
              routeName="resources"
              permissions={getSectionPermissions("resources")}
              element={<ResourcesMainView />}
              fallbackRoutes={commonResourcesFallbackRoutes}
            />
          }
        >
          <Route index element={<Navigate to="workers" replace />} />
          <Route
            path="workers"
            element={
              <RequirePermissions
                routeName="resources"
                permissions={[Permission.Resources_ShowWorkersDetails]}
                element={<WorkersView />}
                fallbackRoutes={getResourcesFallbackRoutes("workers")}
              />
            }
          />
          <Route
            path="workers/:id/details"
            element={
              <RequirePermissions
                routeName="workers"
                permissions={[Permission.Resources_ShowWorkersDetails]}
                element={
                  <WorkerDetailView
                    permissions={{
                      editRecord: Permission.Resources_AddWorker,
                      showTypology: Permission.Resources_ShowWorkerTypology,
                      editTypology: Permission.Resources_EditWorkerTypology,
                      manageDocumentWithAi: Permission.Company_AIManageFile,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      showResourceSites: Permission.Resources_ShowWorkerSites,
                      documents: {
                        view: Permission.Resources_ShowWorkerDocs,
                        add: Permission.Resources_AddWorkerDoc,
                        edit: Permission.Resources_EditWorkerDocs,
                        delete: Permission.Resources_DeleteWorkerDoc,
                        downloadAll: Permission.Resources_DownloadWorkerDocs,
                        viewExpiration:
                          Permission.Resources_ShowWorkerDocExpiration,
                        setExpiration:
                          Permission.Resources_SetWorkerDocExpiration,
                        viewEvaluations:
                          Permission.Resources_ShowWorkerDocsEvaluation,
                        viewEvaluationsExpiration:
                          Permission.Resources_ShowWorkerDocsEvaluationExpiration,
                        evaluate: Permission.Resources_EvalWorkerDocs,
                        addFile: Permission.Resources_EditWorkerDocs,
                        chat: Permission.Resources_EditWorkerDocs,
                        report: Permission.Resources_EditWorkerDocs,
                      },
                    }}
                  />
                }
              />
            }
          />
          <Route
            path="vehicles"
            element={
              <RequirePermissions
                routeName="vehicles"
                permissions={[Permission.Resources_ShowVehicleDetails]}
                element={<VehiclesView />}
                fallbackRoutes={getResourcesFallbackRoutes("vehicles")}
              />
            }
          />
          <Route
            path="vehicles/:id/details"
            element={
              <RequirePermissions
                routeName="vehicles"
                permissions={[Permission.Resources_ShowVehicleDetails]}
                element={
                  <VehicleDetailView
                    permissions={{
                      editRecord: Permission.Resources_AddVehicle,
                      showTypology: Permission.Resources_ShowVehicleTypology,
                      editTypology: Permission.Resources_EditVehicleTypology,
                      manageDocumentWithAi: Permission.Company_AIManageFile,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      showResourceSites: Permission.Resources_ShowVehicleSites,
                      convertToMachine:
                        Permission.Resources_ConvertVehicleToMachine,
                      documents: {
                        view: Permission.Resources_ShowVehicleDocs,
                        add: Permission.Resources_AddVehicleDoc,
                        edit: Permission.Resources_EditVehicleDocs,
                        delete: Permission.Resources_DeleteVehicleDoc,
                        downloadAll: Permission.Resources_DownloadVehicleDocs,
                        viewExpiration:
                          Permission.Resources_ShowVehicleDocExpiration,
                        setExpiration:
                          Permission.Resources_SetVehicleDocExpiration,
                        viewEvaluations:
                          Permission.Resources_ShowVehicleDocsEvaluation,
                        viewEvaluationsExpiration:
                          Permission.Resources_ShowVehicleDocsEvaluationExpiration,
                        evaluate: Permission.Resources_EvalVehicleDocs,
                        addFile: Permission.Resources_EditVehicleDocs,
                        chat: Permission.Resources_EditVehicleDocs,
                        report: Permission.Resources_EditVehicleDocs,
                      },
                    }}
                  />
                }
              />
            }
          />

          <Route path="machines" element={<MachinesView />} />
          <Route
            path="machines"
            element={
              <RequirePermissions
                routeName="machines"
                permissions={[Permission.Resources_ShowMachineDetails]}
                element={<MachinesView />}
                fallbackRoutes={getResourcesFallbackRoutes("machines")}
              />
            }
          />
          <Route
            path="machines/:id/details"
            element={
              <RequirePermissions
                permissions={[Permission.Resources_ShowMachineDetails]}
                element={
                  <MachineDetailView
                    permissions={{
                      editRecord: Permission.Resources_AddMachine,
                      showTypology: Permission.Resources_ShowMachineTypology,
                      editTypology: Permission.Resources_EditMachineTypology,
                      manageDocumentWithAi: Permission.Company_AIManageFile,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      showResourceSites: Permission.Resources_ShowMachineSites,
                      convertToTool: Permission.Resources_ConvertMachineToTool,
                      convertToVehicle: Permission.Resources_ConvertMachineToVehicle,
                      documents: {
                        view: Permission.Resources_ShowMachineDocs,
                        add: Permission.Resources_AddMachineDoc,
                        edit: Permission.Resources_EditMachineDocs,
                        delete: Permission.Resources_DeleteMachineDoc,
                        downloadAll: Permission.Resources_DownloadMachineDocs,
                        viewExpiration:
                          Permission.Resources_ShowMachineDocExpiration,
                        setExpiration:
                          Permission.Resources_SetMachineDocExpiration,
                        viewEvaluations:
                          Permission.Resources_ShowMachineDocsEvaluation,
                        viewEvaluationsExpiration:
                          Permission.Resources_ShowMachineDocsEvaluationExpiration,
                        evaluate: Permission.Resources_EvalMachineDocs,
                        addFile: Permission.Resources_EditMachineDocs,
                        chat: Permission.Resources_EditMachineDocs,
                        report: Permission.Resources_EditMachineDocs,
                      },
                    }}
                  />
                }
              />
            }
          />

          <Route path="tools" element={<ToolsView />} />
          <Route
            path="tools"
            element={
              <RequirePermissions
                routeName={"tools"}
                permissions={[Permission.Resources_ShowToolDetails]}
                element={<ToolsView />}
                fallbackRoutes={getResourcesFallbackRoutes("tools")}
              />
            }
          />
          <Route
            path="tools/:id/details"
            element={
              <RequirePermissions
                routeName={"tools"}
                permissions={[Permission.Resources_ShowToolDetails]}
                element={
                  <ToolDetailView
                    permissions={{
                      editRecord: Permission.Resources_AddTool,
                      showTypology: Permission.Resources_ShowToolTypology,
                      editTypology: Permission.Resources_EditToolTypology,
                      manageDocumentWithAi: Permission.Company_AIManageFile,
                      showResourceSites: Permission.Resources_ShowToolSites,
                      convertToMachine:
                        Permission.Resources_ConvertToolToMachine,
                      documents: {
                        view: Permission.Resources_ShowToolDocs,
                        add: Permission.Resources_AddToolDoc,
                        edit: Permission.Resources_EditToolDocs,
                        delete: Permission.Resources_DeleteToolDoc,
                        downloadAll: Permission.Resources_DownloadToolDocs,
                        viewExpiration:
                          Permission.Resources_ShowToolDocExpiration,
                        setExpiration:
                          Permission.Resources_SetToolDocExpiration,
                        viewEvaluations:
                          Permission.Resources_ShowToolDocsEvaluation,
                        viewEvaluationsExpiration:
                          Permission.Resources_ShowToolDocsEvaluationExpiration,
                        evaluate: Permission.Resources_EvalToolDocs,
                        addFile: Permission.Resources_EditToolDocs,
                        chat: Permission.Resources_EditToolDocs,
                        report: Permission.Resources_EditToolDocs,
                      },
                    }}
                  />
                }
              />
            }
          />

          <Route path="chemicals" element={<ChemicalsView />} />
          <Route
            path="chemicals"
            element={
              <RequirePermissions
                routeName={"chemicals"}
                permissions={[Permission.Resources_ShowChemicalDetails]}
                element={<ChemicalsView />}
                fallbackRoutes={getResourcesFallbackRoutes("chemicals")}
              />
            }
          />
          <Route
            path="chemicals/:id/details"
            element={
              <RequirePermissions
                routeName={"chemicals"}
                permissions={[Permission.Resources_ShowChemicalDetails]}
                element={
                  <ChemicalDetailView
                    permissions={{
                      editRecord: Permission.Resources_AddChemical,
                      showTypology: Permission.Resources_ShowChemicalTypology,
                      editTypology: Permission.Resources_EditChemicalTypology,
                      manageDocumentWithAi: Permission.Company_AIManageFile,
                      showResourceSites: Permission.Resources_ShowChemicalSites,
                      documents: {
                        view: Permission.Resources_ShowChemicalDocs,
                        add: Permission.Resources_AddChemicalDoc,
                        edit: Permission.Resources_EditChemicalDocs,
                        delete: Permission.Resources_DeleteChemicalDoc,
                        downloadAll: Permission.Resources_DownloadChemicalDocs,
                        viewExpiration:
                          Permission.Resources_ShowChemicalDocExpiration,
                        setExpiration:
                          Permission.Resources_SetChemicalDocExpiration,
                        viewEvaluations:
                          Permission.Resources_ShowChemicalDocsEvaluation,
                        viewEvaluationsExpiration:
                          Permission.Resources_ShowChemicalDocsEvaluationExpiration,
                        evaluate: Permission.Resources_EvalChemicalDocs,
                        addFile: Permission.Resources_EditChemicalDocs,
                        chat: Permission.Resources_EditChemicalDocs,
                        report: Permission.Resources_EditChemicalDocs,
                      },
                    }}
                  />
                }
              />
            }
          />
        </Route>

        {/* SUPPLIERS */}
        <Route
          path="/suppliers"
          element={
            <RequirePermissions
              permissions={getSectionPermissions("supplier")}
              element={<SuppliersMainView />}
              fallbackRoutes={commonSupplierFallbackRoutes}
            />
          }
        >
          <Route path="" element={<SupplierListView />} />
        </Route>
        <Route path="/suppliers/:supplierId" element={<SupplierMain />}>
          <Route index element={<Navigate to="details" replace />} />
          <Route
            path="details"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("supplier", "records")}
                element={
                  <SupplierDetailView
                    permissions={{
                      addDocument: Permission.Supplier_DownloadAll,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      viewDocuments: Permission.Supplier_ShowDocuments,
                      documents: {
                        delete: Permission.Supplier_Global_DeleteFile,
                        edit: Permission.Supplier_Global_EditFile,
                        evaluate: Permission.Supplier_EvalCompanyReq,
                        viewEvaluations:
                          Permission.Supplier_ShowCompanyReqEvaluation,
                        viewEvaluationsExpiration: Permission.Supplier_ShowCompanyReqEvaluation,
                        downloadAll: Permission.Supplier_DownloadAll,
                        addFile: Permission.Supplier_Global_AddFile,
                        setExpiration: Permission.Supplier_Global_SetExpiration,
                        chat: Permission.Supplier_EvalCompanyReq,
                        report: Permission.Supplier_EvalCompanyReq,
                       
                      },
                    }}
                  />
                }
              />
            }
          />

          <Route path="resources" element={<SupplierResourcesMain />}>
            {useHasPermissions([Permission.Supplier_ShowWorkers]) && (
              <Route index element={<Navigate to="workers" replace />} />
            )}
            <Route
              path="workers"
              element={
                <RequirePermissions
                  permissions={getSectionPermissions("supplier", "workers")}
                  element={<SupplierWorkersView />}
                  fallbackRoutes={getSupplierResourceFallbackRoutes(
                    "workers",
                    useParams().supplierId,
                  )}
                />
              }
            />
            {useHasPermissions([Permission.Supplier_ShowVehicles]) && (
              <Route index element={<Navigate to="vehicles" replace />} />
            )}
            <Route
              path="vehicles"
              element={
                <RequirePermissions
                  permissions={getSectionPermissions("supplier", "vehicles")}
                  element={<SupplierVehiclesView />}
                  fallbackRoutes={getSupplierResourceFallbackRoutes(
                    "vehicles",
                    useParams().supplierId,
                  )}
                />
              }
            />
            {useHasPermissions([Permission.Supplier_ShowMachines]) && (
              <Route index element={<Navigate to="machines" replace />} />
            )}
            <Route
              path="machines"
              element={
                <RequirePermissions
                  permissions={getSectionPermissions("supplier", "machines")}
                  element={<SupplierMachinesView />}
                  fallbackRoutes={getSupplierResourceFallbackRoutes(
                    "machines",
                    useParams().supplierId,
                  )}
                />
              }
            />
            {useHasPermissions([Permission.Supplier_ShowTools]) && (
              <Route index element={<Navigate to="tools" replace />} />
            )}
            <Route
              path="tools"
              element={
                <RequirePermissions
                  permissions={getSectionPermissions("supplier", "tools")}
                  element={<SupplierToolsView />}
                  fallbackRoutes={getSupplierResourceFallbackRoutes(
                    "tools",
                    useParams().supplierId,
                  )}
                />
              }
            />
            {useHasPermissions([Permission.Supplier_ShowChemicals]) && (
              <Route index element={<Navigate to="chemicals" replace />} />
            )}
            <Route
              path="chemicals"
              element={
                <RequirePermissions
                  permissions={getSectionPermissions("supplier", "chemicals")}
                  element={<SupplierChemicalsView />}
                  fallbackRoutes={getSupplierResourceFallbackRoutes(
                    "chemicals",
                    useParams().supplierId,
                  )}
                />
              }
            />
          </Route>

          <Route
            path="resources/workers/:resourceId"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("supplier", "workers")}
                element={
                  <SupplierWorkerDetailView
                    permissions={{
                      addDocument: Permission.Supplier_DownloadWorkerDocs,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      viewDocuments: Permission.Supplier_ShowWorkerDocs,
                      documents: {
                        delete: Permission.Supplier_Global_Worker_DeleteFile,
                        edit: Permission.Supplier_Global_Worker_Edit,
                        evaluate: Permission.Supplier_EvalWorkerReq,
                        viewEvaluations:
                          Permission.Supplier_ShowWorkerDocReqEvaluation,
                        viewEvaluationsExpiration: Permission.Supplier_ShowWorkerDocReqEvaluation,
                        downloadAll: Permission.Supplier_DownloadWorkerDocs,
                        addFile: Permission.Supplier_Global_Worker_AddFile,
                        setExpiration:
                          Permission.Supplier_Global_Worker_Expiration,
                        chat: Permission.Supplier_EvalWorkerReq,
                        report: Permission.Supplier_EvalWorkerReq,
                      },
                    }}
                  />
                }
              />
            }
          />
          <Route
            path="resources/vehicles/:resourceId"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("supplier", "vehicles")}
                element={
                  <SupplierVehicleDetailView
                    permissions={{
                      addDocument: Permission.Supplier_DownloadVehicleDocs,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      viewDocuments: Permission.Supplier_ShowVehicleDocs,
                      documents: {
                        delete: Permission.Supplier_Global_Vehicle_DeleteFile,
                        edit: Permission.Supplier_Global_Vehicle_Edit,
                        evaluate: Permission.Supplier_EvalVehicleReq,
                        viewEvaluations:
                          Permission.Supplier_ShowVehicleReqEvaluation,
                        viewEvaluationsExpiration: Permission.Supplier_ShowVehicleReqEvaluation,
                        downloadAll: Permission.Supplier_DownloadVehicleDocs,
                        addFile: Permission.Supplier_Global_Vehicle_AddFile,
                        setExpiration:
                          Permission.Supplier_Global_Vehicle_Expiration,
                        chat: Permission.Supplier_EvalVehicleReq,
                        report: Permission.Supplier_EvalVehicleReq,
                      },
                    }}
                  />
                }
              />
            }
          />
          <Route
            path="resources/machines/:resourceId"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("supplier", "machines")}
                element={
                  <SupplierMachineDetailView
                    permissions={{
                      addDocument: Permission.Supplier_DownloadMachineDocs,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      viewDocuments: Permission.Supplier_ShowMachineDocs,
                      documents: {
                        delete: Permission.Supplier_Global_Machine_DeleteFile,
                        edit: Permission.Supplier_Global_Machine_Edit,
                        evaluate: Permission.Supplier_EvalMachineReq,
                        viewEvaluations:
                          Permission.Supplier_ShowMachineReqEvaluation,
                        viewEvaluationsExpiration: Permission.Supplier_ShowMachineReqEvaluation,
                        downloadAll: Permission.Supplier_DownloadMachineDocs,
                        addFile: Permission.Supplier_Global_Machine_AddFile,
                        setExpiration:
                          Permission.Supplier_Global_Machine_Expiration,
                        chat: Permission.Supplier_EvalMachineReq,
                        report: Permission.Supplier_EvalMachineReq,
                      },
                    }}
                  />
                }
              />
            }
          />
          <Route
            path="resources/tools/:resourceId"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("supplier", "tools")}
                element={
                  <SupplierToolDetailView
                    permissions={{
                      addDocument: Permission.Supplier_DownloadToolDocs,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      viewDocuments: Permission.Supplier_ShowToolDocs,
                      documents: {
                        delete: Permission.Supplier_Global_Tool_DeleteFile,
                        edit: Permission.Supplier_Global_Tool_Edit,
                        evaluate: Permission.Supplier_EvalToolReq,
                        viewEvaluations:
                          Permission.Supplier_ShowToolReqEvaluation,
                        viewEvaluationsExpiration: Permission.Supplier_ShowToolReqEvaluation,
                        downloadAll: Permission.Supplier_DownloadToolDocs,
                        addFile: Permission.Supplier_Global_Tool_AddFile,
                        setExpiration:
                          Permission.Supplier_Global_Tool_Expiration,
                        chat: Permission.Supplier_EvalToolReq,
                        report: Permission.Supplier_EvalToolReq,
                      },
                    }}
                  />
                }
              />
            }
          />
          <Route
            path="resources/chemicals/:resourceId"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("supplier", "chemicals")}
                element={
                  <SupplierChemicalDetailView
                    permissions={{
                      addDocument: Permission.Supplier_DownloadChemicalDocs,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      viewDocuments: Permission.Supplier_ShowChemicalDocs,
                      documents: {
                        delete: Permission.Supplier_Global_Chemical_DeleteFile,
                        edit: Permission.Supplier_Global_Chemical_Edit,
                        evaluate: Permission.Supplier_EvalChemicalReq,
                        viewEvaluations:
                          Permission.Supplier_ShowChemicalReqEvaluation,
                        viewEvaluationsExpiration: Permission.Supplier_ShowChemicalReqEvaluation,
                        downloadAll: Permission.Supplier_DownloadChemicalDocs,
                        addFile: Permission.Supplier_Global_Chemical_AddFile,
                        setExpiration:
                          Permission.Supplier_Global_Chemical_Expiration,
                        chat: Permission.Supplier_EvalChemicalReq,
                        report: Permission.Supplier_EvalChemicalReq,
                      },
                    }}
                  />
                }
              />
            }
          />
        </Route>

        {/* TIMETABLE */}
        <Route
          path="/timetable"
          element={
            <RequirePermissions
              permissions={getSectionPermissions("calendar")}
              element={<TimeTableMainView />}
            />
          }
        >
          <Route path="" element={<TimeTableCalendarView />} />
        </Route>

        {/* SETTINGS */}
        <Route
          path="/settings"
          element={
            <RequirePermissions
              check={PermissionCheck.Some}
              permissions={[
                Permission.Settings_ShowRoles,
                Permission.Settings_ShowUsers,
                Permission.Settings_EditWebHooks,
                Permission.Settings_EditCustomFields,
              ]}
              element={<SettingsMainView />}
              fallbackRoutes={commonSettingsFallbackRoutes}
            />
          }
        >
        {useHasPermissions([Permission.Settings_ShowRoles, Permission.Settings_ShowUsers]) &&   <Route index element={<Navigate to="access" replace />} />}
          <Route path="access">
            {useHasPermissions([Permission.Settings_ShowRoles]) && (
              <Route index element={<Navigate to="roles" replace />} />
            )}
            <Route
              path="roles"
              element={
                <RequirePermissions
                  permissions={[Permission.Settings_ShowRoles]}
                  element={<SubNavLayout nav={<AccessSubNav />} />}
                  fallbackRoutes={getSettingsFallbackRoutes("access/roles")}
                />
              }
            >
              <Route path="" element={<RolesView />} />
            </Route>
            {useHasPermissions([Permission.Settings_ShowUsers]) && (
              <Route index element={<Navigate to="users" replace />} />
            )}
            <Route
              path="users"
              element={
                <RequirePermissions
                  permissions={[Permission.Settings_ShowUsers]}
                  element={<SubNavLayout nav={<AccessSubNav />} />}
                  fallbackRoutes={getSettingsFallbackRoutes("access/users")}
                />
              }
            >
              <Route path="" element={<UsersView />} />
            </Route>
          </Route>
          {useHasPermissions([Permission.Settings_EditWebHooks]) && (
              <Route index element={<Navigate to="webhooks" replace />} />
            )}
          <Route
            path="webhooks"
            element={
              <RequirePermissions
                permissions={[Permission.Settings_EditWebHooks]}
                element={<WebHookView />}
                fallbackRoutes={getSettingsFallbackRoutes("webhooks")}
              />
            }
            
          />
            {useHasPermissions([Permission.Settings_EditCustomFields]) && (
              <Route index element={<Navigate to="custom-fields" replace />} />
            )}
          <Route
            path="custom-fields"
            element={
              <RequirePermissions
                permissions={[Permission.Settings_EditCustomFields]}
                element={<SubNavLayout nav={<CustomFieldsSubNav />} />}
                fallbackRoutes={getSettingsFallbackRoutes("custom-fields")}
              />
            }
          >
            <Route index element={<Navigate to="company" replace />} />
            {Object.values(RequirementSubject).filter(s => company?.type === "master" || s !== 'site').map((subject) => (
              <Route
                key={subject}
                path={subject}
                element={<CustomFieldsView />}
              />
            ))}
          </Route>
        </Route>

        <Route
          path="/settings/access/roles/:roleId"
          element={
            <RequirePermissions
              permissions={[Permission.Settings_EditRole]}
              element={<RoleDetailsMainView />}
            />
          }
        >
          <Route path="" element={<RoleDetailsView />} />
        </Route>
        <Route
          path="/settings/access/users/:userId"
          element={
            <RequirePermissions
              permissions={[Permission.Settings_ShowUsers]}
              element={<UserDetailsMainView />}
            />
          }
        >
          <Route path="" element={<UserDetailsView />} />
        </Route>

        {/* NOTIFICATIONS */}
        <Route path="/notifications" element={<NotificationsMainView />}>
          <Route index element={<Navigate to="unread" replace />} />
          <Route
            path="unread"
            element={<NotificationsList pageSize={25} status="new" />}
          />
          <Route
            path="archived"
            element={<NotificationsList pageSize={25} status="archived" />}
          />
        </Route>

        {/* Other */}
        <Route
          path="/access-denied"
          element={<AccessDeniedMainView layout="main" />}
        >
          <Route path="" element={<AccessDeniedView />} />
        </Route>

        <Route
          path="license-not-active"
          element={<LicenseNotActiveMainView />}
        ></Route>
      </Route>
    </Routes>
  );
};

export default AppRouter;