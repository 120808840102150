import { useTranslation } from "react-i18next";
import { Button, Text, Spacer, Center } from "@chakra-ui/react";

const OnboardingFooter = () => {
  const { t } = useTranslation();

  return (
    <Center width={"100%"}>
      <Spacer />
      <Text fontSize="xs" marginRight={2}>
        {t("ui.need help?", { ns: "onboarding" })}
      </Text>
      <Button fontSize="xs" variant="link" color="#32A2DB">
        <a href="mailto:helpdesk@sikuro.it">
          {t("ui.contact us", { ns: "onboarding" })}
        </a>
      </Button>
      <Spacer />
    </Center>
  );
};

export default OnboardingFooter;
