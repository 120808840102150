import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ResourceListView from "../ResourceListView";
import Vehicle from "../../../../domain/entities/vehicle";
import { Permission } from "../../../components/Permissions/Permissions";
import FormTextField from "../../../components/Common/forms/FormTextField";
import { EditableFormFieldProps } from "../../../components/Common/forms/FormField";
import { VehiclesListViewModel } from "../../../viewmodels/vehicles/VehiclesListViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { GetResourcesFilter } from "../../../../domain/repositories/workerRepository";
import FormImageField from "../../../components/Common/forms/FormImageField";
import {getFieldLabel} from "../../../../utils";

const VehiclesView = () => {
  const { t } = useTranslation("vehicles");
  const formMethods = useForm<Vehicle>({ mode: "all" });
  const requiredRule = { required: t("requiredField", { ns: "common" }) };
  const columns = [
    { field: "name", type: "text", label: t("name") },
    { field: "plate", type: "text", label: t("plate") },
    { field: "model", type: "text", label: t("model") },
  ] as {
    field: keyof GetResourcesFilter;
    type: "text" | "tags";
    label: string;
  }[];
  const fields: Array<ReactElement<Omit<EditableFormFieldProps, "isEditing">>> =
    [
      <FormTextField
        key="name"
        label={getFieldLabel(t, 'name', 'vehicle')}
        name="name"
        rules={requiredRule}
      />,
      <FormTextField
        key="plate"
        label={getFieldLabel(t, 'plate', 'vehicle')}
        name="plate"
        rules={requiredRule}
      />,
      <FormTextField
        key="model"
        label={getFieldLabel(t, 'model', 'vehicle')}
        name="model"
      />,
      <FormImageField
        key="photo"
        label={t("photo")}
        name="photo"
        colSpan={2}
      />,
    ];

  return (
    <ResourceListView<Vehicle>
      formMethods={formMethods}
      type={DocumentTypeCategory.VEHICLE}
      viewModel={new VehiclesListViewModel()}
      columns={columns}
      namespace="vehicles"
      permissions={{
        add: Permission.Resources_AddVehicle,
        delete: Permission.Resources_DeleteVehicle,
        import: Permission.Resources_ImportVehicle,
        showResourceSites: Permission.Resources_ShowVehicleSites,
        showCopyLastEvaluation: Permission.Resources_EvalVehicleDocs,
        download: Permission.Resources_DownloadVehicleDocs,
      }}
      createFields={fields}
      viewDetailsPermissions={[Permission.Resources_ShowVehicleDetails]}
      convertResourcePermissions={{
        machine: Permission.Resources_ConvertVehicleToMachine,
      }}
      twinTypes={["machine"]}
    />
  );
};

export default VehiclesView;
