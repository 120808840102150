import ContentLayout from "../../../layout/ContentLayout";
import ActionBar from "../../../components/Common/ActionBar";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import { MdClose } from "react-icons/md";
import { COLORS } from "../../../assets/theme/colors";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CompanyPermission,
  SitePermissions,
  WorkingSitePermissions
} from "../../../components/Permissions/NewPermissions";
import { AiOutlineSave } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import useUpsertRoleViewModel, { useRoleDetailsViewModelProps } from "../../../hooks/Roles/useRoleDetailsViewModel";
import LoadingView from "../../Common/LoadingView";
import { useAuth } from "../../../providers/Auth0JWTProvider";
import Role, { RoleContext } from "../../../../domain/entities/role";
import { CloseIcon, Search2Icon } from "@chakra-ui/icons";
import { LicenseType } from "../../../../domain/interactors/auth/args";

interface PermissionType {
  name: string;
  action: string;
  resource: string;
  id: string;
  enabled: boolean;
  children?: PermissionType[];
  title?: string;
  license: LicenseType[];
  description?: string;
}
type PermissionGroup = {
  title?: string;
  resource: string;
  permissions: PermissionType[];
};

const getPermissionsByContext = (role: Role): PermissionType[] => {
  const permissionMap = {
    [RoleContext.GENERAL]: CompanyPermission,
    [RoleContext.SITE]: SitePermissions,
    [RoleContext.WORKSITE]: WorkingSitePermissions
  };
  const permissions = permissionMap[role.context] || [];
  if (permissions.length === 0) {
    console.error(`No permissions found for role context: ${role.context}`);
  }
  return permissions;
};
const findParentById = (id: string, tree: PermissionType[]): PermissionType | undefined => {
  for (const node of tree) {
    if (node.children) {
      const child = node.children.find((child) => child.id === id);
      if (child) return node;
    }
    const found = findParentById(id, node.children || []);
    if (found) return found;
  }
  return undefined;
};
const findNodeById = (id: string, tree: PermissionType[]): PermissionType | undefined => {
  for (const node of tree) {
    if (node.id === id) return node;
    const found = findNodeById(id, node.children || []);
    if (found) return found;
  }
  return undefined;
};
export const RoleDetailsView = () => {
  const { roleId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const values: useRoleDetailsViewModelProps = useUpsertRoleViewModel(roleId);

  useEffect(() => {
    if (values?.role?.isSystem) {
      navigate("/settings/access/roles", {
        state: { message: t("noPermission", { ns: "documents" }) }
      });
    }
  }, [values]);

  const isReadyToRender =
    !values?.isFetching && !values?.isLoading && !values?.updateLoading && values?.role;

  return isReadyToRender ? (
    <RoleDetail {...values} />
  ) : (
    <LoadingView />
  );
};
const RoleDetail = ({ role, updatePermissions }: useRoleDetailsViewModelProps) => {
  const { roleId } = useParams();
  const { companyId, company, updateContext } = useAuth();
  const { t } = useTranslation("settings");
  const navigate = useNavigate();
  const [filter, setFilter] = useState<string>("");
  const [showLeavePage, setShowLeavePage] = useState(false);
  const FEPermissions = getPermissionsByContext(role).filter((perm) => {
    return perm.license &&  perm.license.includes(company.type);
  });
  const enabledPermissionsSet = new Set(
    role.permissions.map((bePerm) => bePerm.name).filter((name) =>
      FEPermissions.some((fePerm) => fePerm.name === name)
    )
  );

  const [userSelections, setUserSelections] = useState(new Set(enabledPermissionsSet));
  const flatIds = FEPermissions
    .filter((perm) => {
      if (!company.licenseAi && perm.name === "company:ai") return false;
      return !(!company.licenseDerivedTenants && perm.name === "other:create-derived-tenants");

    })
    .map((perm) => ({
      id: perm.id,
      name: perm.name,
      resource: perm.resource,
      action: perm.action,
      enabled: userSelections.has(perm.name),
      children: [],
    }));

  const buildTree = (flatPermissions) => {
    const tree = [];
    const findOrCreateNode = (path, level, resource, action) => {
      let currentLevel = tree;

      for (let i = 0; i <= level; i++) {
        let node = currentLevel.find((n) => n.name === path[i]);
        if (!node) {
          node = {
            name: path[i],
            id: path.slice(0, i + 1).join(" | "),
            resource: resource,
            action: action,
            children: []
          };
          currentLevel.push(node);
        }
        currentLevel = node.children;
      }
      return currentLevel;
    };

    flatPermissions.forEach((perm) => {
      const path = perm.id.split(" | ");
      findOrCreateNode(path, path.length - 1, perm.resource, perm.action);
    });
    return tree;
  };
  const tree = buildTree(flatIds);
  const selectParentNodes = (node: PermissionType | undefined, selections: Set<string>) => {
    if (!node) return;
    selections.add(node.name);
    const parentNode = findParentById(node.id, tree);
    if (parentNode) {
      selectParentNodes(parentNode, selections);
    }
  };
  const togglePermission = (name: string, nodeId: string, e?: React.ChangeEvent<HTMLInputElement>) => {
    setShowLeavePage(true);
    setUserSelections((prevSelections) => {
      const newSelections = new Set(prevSelections);
      const node = findNodeById(nodeId, tree);
      if (!node) return prevSelections;
      if (node.children && node.children.length > 0) {
        if (newSelections.has(name)) {
          deselectAllChildren(node, newSelections);
        } else {
          selectParentNodes(node, newSelections);
        }
      } else {
        if (newSelections.has(name)) {
          newSelections.delete(name);
        } else {
          newSelections.add(name);
          const parentNode = findParentById(nodeId, tree);
          if (parentNode) {
            selectParentNodes(parentNode, newSelections);
          }
        }
      }
      return newSelections;
    });
  };
  const deselectAllChildren = (node: PermissionType, selections: Set<string>) => {
    selections.delete(node.name);
    node.children?.forEach((child) => deselectAllChildren(child, selections));
  };
  const updatedBEPermissions = Array.from(userSelections).map((name) => {
    const permission = FEPermissions.find((perm) => perm.name === name);
    return permission || { name };
  });
  const updatedBEData = (permissions: any[]) => {
    return permissions.map((permission) => {
      if (permission.id?.includes(permission.name)) {
        return { ...permission, id: permission.name };
      }
      return permission;
    }).map((permission) => permission.id);
  };
  const close = () => {
    navigate("/settings/access/roles");
  };
  const save = async () => {
    close();
    await updatePermissions(
      { roleId, permissionIds: updatedBEData(updatedBEPermissions) }
    );
    await updateContext(companyId, company);
    setShowLeavePage(false);
  };

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            onClick={close}
            icon={MdClose}
            description={t("close", { ns: "common" })}
            color="white"
            bgColor={COLORS.sikuroBlue}
          />
          {showLeavePage && (
            <ActionBarItem
              onClick={save}
              icon={AiOutlineSave}
              description={t("save", { ns: "common" })}
            />
          )}
        </ActionBar>
      }
    >
      <Box position={"relative"} width={"100%"}>
        {showLeavePage && (
          <Box
            m={4}
            p={4}
            borderRadius="10px"
            bg={COLORS.lightRed}
            position={"fixed"}
            bottom={0}
            zIndex={1}
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <Text px={4} width={"100%"} textAlign={"center"}>{t("unsavedChanges", { ns: "sites" })}</Text>
          </Box>
        )}
        <Box p={4}>
          <Text
            fontSize="larger"
            fontWeight="semibold"
            color={COLORS.sikuroBlue}
            textTransform={"capitalize"}
          >
            {role.context == RoleContext.GENERAL &&
              t("roles.generalRoleTitle", { roleName: role.name })}
            {role.context == RoleContext.SITE &&
              t("roles.siteRoleTitle", { roleName: role.name })}
            {role.context == RoleContext.WORKSITE &&
              t("roles.workingSiteRoleTitle", { roleName: role.name })}
          </Text>
          <InputGroup my={4}>
            {filter !== "" && (
              <InputLeftElement onClick={() => setFilter("")}>
                <CloseIcon />
              </InputLeftElement>
            )}
            <Input
              variant="outline"
              value={filter}
              onChange={(v) => setFilter(v.target.value)}
            />
            <InputRightElement>
              <Search2Icon />
            </InputRightElement>
          </InputGroup>
          <PermissionsListGroupedByResource
            permissions={tree}
            togglePermission={togglePermission}
            userSelections={userSelections}
            setUserSelections={setUserSelections}
            setShowLeavePage={setShowLeavePage}
            role={role}
            filter={filter}
          />
        </Box>
      </Box>
    </ContentLayout>
  );
};

const PermissionNode = ({node, togglePermission, userSelections  }: {
  node: PermissionType;
  togglePermission: (name: string, id: string, e?: React.ChangeEvent<HTMLInputElement>) => void;
  userSelections: Set<string>;
}) => {
  return (
    <Box pl={4}>
      <Flex flexDirection={"column"}>
        <Checkbox
          isChecked={userSelections.has(node.name)}
          onChange={(e) => togglePermission(node.name, node.id, e)}
        >
          <Text as="span" fontWeight="bold">
            {node.title}
          </Text>
        </Checkbox>
        <Text pl={6} fontSize="sm" color="gray.800" align="left">
          {node.description}
        </Text>
      </Flex>
      {node.children && node.children.length > 0 && (
        <Stack spacing={2} pl={4} my={2}>
          {node.children.map((child: PermissionType) => (
            <PermissionNode
              key={child.id}
              node={child}
              togglePermission={togglePermission}
              userSelections={userSelections}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

const PermissionsListGroupedByResource = ({ permissions, togglePermission, userSelections, setUserSelections, setShowLeavePage,role, filter }: {
  permissions: PermissionType[];
  togglePermission: (name: string, id: string) => void;
  userSelections: Set<string>;
  setUserSelections: (selections: any) => void;
  setShowLeavePage: (show: boolean) => void;
  role: Role;
  filter: string;
}) => {
  const { t } = useTranslation("settings");

  const groupPermissions = (source: PermissionType[]): PermissionGroup[] => {
    const groups = new Map<string, PermissionGroup>();
    const mapTree = (node: PermissionType): PermissionType => {
      return {
        ...node,
        title: t(`permissions.${node.name.replaceAll(":", "_")}`, {
          ns: "permissions",
          context: "title"
        }),
        description: t(`permissions.${node?.name.replaceAll(":", "_")}`, {
          ns: "permissions",
          context: "description"
        }),
        children: node.children?.map(mapTree) || []
      };
    };


    for (const permission of source) {
      const group = groups.get(permission.resource) ?? {
        title: t(`permissionGroup.${permission.resource}`, { ns: "permissions" }),
        resource: permission.resource,
        permissions: []
      };

      const mappedPermission = mapTree(permission);
      group.permissions.push(mappedPermission);

      groups.set(permission.resource, group);

    }

    const keys = Array.from(groups.keys()).sort();
    const sorted: PermissionGroup[] = [];
    for (const k of keys) {
      sorted.push(groups.get(k));
    }

    const order = ()=> {
      switch (role.context){
        case RoleContext.GENERAL:
          return [
            "dashboard",
            "company_sites",
            "company_workingsites",
            "company",
            "documents",
            "document-presets",
            "preset",
            "document-types",
            "requirements-groups",
            "badge",
            "workers",
            "vehicles",
            "machines",
            "tools",
            "chemicals",
            "supplier",
            "calendar",
            "settings",
            "other"
          ];
        case RoleContext.SITE:
          return [
            "guardianship",
            "details",
            "documents",
            "company-documents",
            "overview-evaluations",
            "workers",
            "vehicles",
            "machines",
            "tools",
            "chemicals",
            "suppliers",
            "requirements",
            "access",
            "pending-evaluations",
            "reports"
          ];
        case RoleContext.WORKSITE:
          return [
            "details",
            "workers",
            "vehicles",
            "machines",
            "tools",
            "chemicals",
            "company-documents",
            "subcontractors",
          ]
      }
    }
    const sortOrder = order();
    sorted.sort((a, b) => {
      const indexA = sortOrder.indexOf(a.resource);
      const indexB = sortOrder.indexOf(b.resource);
      if (indexA === -1 || indexB === -1) {
        return indexA - indexB;
      }
      return indexA - indexB;
    });
    return sorted;
  };
  const groupedPermissions = groupPermissions(permissions)

  const toggleAllPermissionsInGroup = (
    permissions: PermissionType[],
    isSelected: boolean
  ) => {
    const handleNodeRecursively = (node: PermissionType, selections: Set<string>) => {
      if (isSelected) {
        selections.add(node.name);
      } else {
        selections.delete(node.name);
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => handleNodeRecursively(child, selections));
      }
    };
    setUserSelections((prevSelections) => {
      const newSelections = new Set(prevSelections);
      permissions.forEach((perm) => {
        handleNodeRecursively(perm, newSelections as Set<string>);
      });
      return newSelections;
    });
    setShowLeavePage(true);
  };

  function filteredPermissions(): PermissionGroup[] {
    const filterPermissionWithChildren = (permission: PermissionType): PermissionType | null => {
      const lowerCaseFilter = filter.toLowerCase().trim();
      const matchesSelf =
        permission.title.toLowerCase().includes(lowerCaseFilter) ||
        permission.name.toLowerCase().includes(lowerCaseFilter) ||
        (permission.description && permission.description.toLowerCase().includes(lowerCaseFilter));
      const filteredChildren = permission.children
        ?.map(filterPermissionWithChildren)
        .filter(Boolean) as PermissionType[];
      if (matchesSelf || (filteredChildren && filteredChildren.length > 0)) {
        return {
          ...permission,
          children: filteredChildren
        };
      }
      return null;
    };
    return groupedPermissions
      .map(group => {
        const filteredPermissions = group.permissions
          .map(filterPermissionWithChildren)
          .filter(Boolean) as PermissionType[];

        if (filteredPermissions.length > 0) {
          return {
            ...group,
            permissions: filteredPermissions
          };
        }
        return null;
      })
      .filter(Boolean) as PermissionGroup[];
  }

  return (
    <Accordion allowToggle>
      {Object.entries(filteredPermissions()).map(([resource, perms]) => {
        return (
          <AccordionItem key={resource}>
            <AccordionButton>
              <Box onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  isChecked={perms.permissions.every(p => userSelections.has(p.name))}
                  isIndeterminate={
                    perms.permissions.some(p => userSelections.has(p.name)) &&
                    !perms.permissions.every(p => userSelections.has(p.name))
                  }
                  onChange={(e) => {
                    e.stopPropagation()
                    toggleAllPermissionsInGroup(perms.permissions, e.target.checked);
                  }}
                  mr={2}
                />
              </Box>
              <Box flex="1" textAlign="left" fontWeight="bold">
                {perms.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Stack spacing={2}>
                {perms.permissions.map((perm) => (
                  <PermissionNode
                    key={perm.id}
                    node={perm}
                    togglePermission={togglePermission}
                    userSelections={userSelections}
                  />
                ))}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

