import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

const France = (props: IconProps) => {
  return (
    <Icon
      viewBox="0 0 640 480"
      {...props}
    >
      <path fill="#fff" d="M0 0h640v480H0z"/>
      <path fill="#000091" d="M0 0h213.3v480H0z"/>
      <path fill="#e1000f" d="M426.7 0H640v480H426.7z"/>
    </Icon>
  );
};

export default France;
